export default {
	common: {
		learn: {
			title1: "Số dư chính",
			content1: "StratTrade cung cấp dịch vụ cân bằng tiền mặt và tiền điện tử được quản lý của chúng tôi miễn phí, cho phép bạn lưu trữ tiền mặt và tiền điện tử được hỗ trợ miễn phí. Bạn có thể chuyển tiền điện tử từ số dư chính của một người dùng StratTrade sang tài khoản chính của người dùng khác mà không mất bất kỳ khoản phí nào.\n" +
				"\n" +
				"Phí giao dịch trực tuyến\n" +
				"\n" +
				"Đối với các giao dịch trên mạng lưới tiền điện tử (ví dụ: chuyển tiền điện tử từ nền tảng StratTrade), StratTrade tạo và trả phí giao dịch mạng (ví dụ: phí khai thác). Khi bạn gửi tiền điện tử từ ví StratTrade sang ví khác, chúng tôi sẽ tính phí dựa trên ước tính của chúng tôi về phí mạng hiện tại được gửi từ ví độc lập đến ví. Tuy nhiên, do các yếu tố như giao dịch khối lượng lớn hoặc thay đổi mức độ tắc nghẽn mạng, phí cuối cùng mà StratTrade phải trả có thể khác với phí ước tính. Tất cả các khoản phí được tiết lộ tại thời điểm giao dịch.\n" +
				"\n" +
				"Tăng hiệu quả và chi phí mạng\n" +
				"\n" +
				"Tổng phí mạng ước tính mà người dùng phải trả trong một lô nhất định có thể vượt quá phí mạng cuối cùng được trả bởi StratTrade do lợi ích hiệu quả tiềm năng từ các giao dịch lô.\n" +
				"\n" +
				"Phí xử lý Lightning Network\n" +
				"\n" +
				"Lightning Network là một mạng lưới thanh toán nhỏ có khả năng chuyển Bitcoin mà không phải chịu phí giao dịch liên quan đến blockchain Bitcoin. Khi bạn gửi Bitcoin từ StratTrade thông qua Lightning Network, chúng tôi sẽ tính phí xử lý tương đương 0,1% số tiền chuyển Bitcoin.\n" +
				"\n" +
				"Thêm tiền mặt và rút tiền mặt\n" +
				"\n" +
				"Khi bạn thêm tiền mặt hoặc rút tiền mặt, bạn có thể bị tính phí dựa trên phương thức thanh toán bạn chọn. Điều quan trọng là phải xem chi tiết phí trước khi hoàn thành giao dịch để biết bất kỳ khoản phí nào liên quan.\n" +
				"\n" +
				"Giao dịch tín dụng\n" +
				"\n" +
				"Nếu bạn vay USD từ StratTrade hoặc một chi nhánh của StratTrade và chúng tôi phải bán tài sản thế chấp BTC của bạn (được ủy quyền theo thỏa thuận cho vay hiện hành), chúng tôi sẽ tính phí cố định 2% tổng số giao dịch",
			title2: "Phí giao dịch và spread",
			content2: "Phí của StratTrade\n" +
				"\n" +
				"Một khoản phí được tính khi bạn mua, bán hoặc chuyển đổi tiền điện tử trên StratTrade. Các khoản phí này được tính khi bạn đặt hàng và có thể bị ảnh hưởng bởi các yếu tố như phương thức thanh toán bạn chọn, kích thước đơn đặt hàng, điều kiện thị trường, thẩm quyền và các chi phí khác mà chúng tôi phải chịu để tạo điều kiện thuận lợi cho giao dịch của bạn.\n" +
				"\n" +
				"Bạn có thể xem phí trong màn hình xem trước giao dịch trước khi gửi giao dịch. Xin lưu ý rằng phí cho các giao dịch tương tự có thể khác nhau. Bằng cách hoàn thành đơn đặt hàng, bạn đồng ý thanh toán phí StratTrade áp dụng cho giao dịch đó.\n" +
				"\n" +
				"Phân phối\n" +
				"\n" +
				"Khi bạn đặt lệnh mua và bán đơn giản, StratTrade bao gồm spread trong báo giá. Spread cũng được bao gồm trong tỷ giá hối đoái khi chuyển đổi từ cryptocurrency này sang cryptocurrency khác.\n" +
				"\n" +
				"Để xem chênh lệch giá trước khi xem trước lệnh mua, bán hoặc chuyển đổi trên màn hình xem trước, hãy làm như sau:\n" +
				"\n" +
				"Lệnh mua và bán: Nhấp vào mẹo công cụ bên cạnh mục dòng giá của tài sản để xem spread.\n" +
				"\n" +
				"Chuyển đổi lệnh: Nhấp vào mẹo công cụ bên cạnh tỷ giá hối đoái báo giá.\n" +
				"\n" +
				"Spread giúp tăng khả năng giao dịch thành công và cho phép StratTrade tạm thời khóa báo giá của bạn trong khi xử lý đơn đặt hàng của bạn. StratTrade có thể giữ bất kỳ spread quá mức nào trong giao dịch. Xin lưu ý rằng spread có thể khác nhau cho các giao dịch tương tự.\n" +
				"\n" +
				"StratTrade Cao cấp\n" +
				"\n" +
				"StratTrade Advanced không bao gồm spread vì bạn tương tác trực tiếp với order book.\n" +
				"\n" +
				"StratTrade One\n" +
				"\n" +
				"StratTrade One là một sản phẩm đăng ký cung cấp mua và bán tiền điện tử miễn phí với một số hạn chế nhất định. Spread vẫn có thể được bao gồm trong báo giá của các thành viên.\n" +
				"\n" +
				"Thay đổi về phí giao dịch và spread\n" +
				"\n" +
				"StratTrade đôi khi kiểm tra các thay đổi về phí và chênh lệch. Những thay đổi này có thể diễn ra ở các khu vực khác nhau, tài sản, quy mô lệnh và loại giao dịch.\n" +
				"\n" +
				"StratTrade cho bạn thấy tổng số tiền, bao gồm spread và phí, mỗi khi bạn xem trước giao dịch và trong lịch sử giao dịch sau đó.\n" +
				"\n" +
				"Thực hiện giao dịch\n" +
				"\n" +
				"Tất cả các giao dịch trong số dư chính của bạn được thực hiện thông qua sổ lệnh giới hạn trung tâm của StratTrade Exchange hoặc đấu giá cạnh tranh. Bạn có thể xem các đơn đặt hàng, giá thầu, yêu cầu và độ sâu của thị trường trong thời gian thực. Những sổ lệnh này xác định các báo giá bạn thấy trong giao dịch.\n" +
				"\n" +
				"StratTrade không có bất kỳ mối quan hệ thanh toán dòng lệnh (PFOF) nào với các nhà tạo lập thị trường.",
			title3: "Thẻ StratTrade",
			content3: "Không có phí giao dịch để chi tiêu bằng thẻ StratTrade. \n" +
				"\n" +
				"StratTrade bao gồm chênh lệch giá để mua hoặc bán tiền điện tử. Điều này cho phép chúng tôi tạm thời khóa giá thực hiện giao dịch trong khi xem chi tiết giao dịch trước khi bạn gửi giao dịch. \n" +
				"\n" +
				"Các nhà khai thác ATM có thể tính phí cho bạn. Để biết thêm chi tiết, vui lòng tham khảo Thỏa thuận chủ thẻ StratTrade của bạn.",
			title4: "Dịch vụ vốn chủ sở hữu",
			content4: "Không tính phí đặt cược hoặc rút tiền cược. StratTrade nhận hoa hồng dựa trên phần thưởng bạn nhận được từ mạng. Hoa hồng tiêu chuẩn cho ADA, ATOM, DOT, MATIC, SOL và XTZ là 35%. Đối với ADA, ATOM, DOT, SOL và XTZ, hoa hồng cho các thành viên đủ điều kiện của StratTrade One là 26,3%. Bạn có thể tìm thấy tỷ lệ hoa hồng ETH của mình trong chi tiết tài sản. Một số người dùng có thể nhận được khuyến mãi đặt cược với tỷ lệ thấp hơn. Các APY và phần thưởng được hiển thị trong tài khoản của bạn luôn phản ánh thu nhập bạn nhận được sau hoa hồng của chúng tôi. Bạn có thể tìm hiểu thêm về hoa hồng trong Thỏa thuận người dùng StratTrade.",
			title5: "Thu hồi tài sản",
			content5: "Nếu bạn gửi tiền điện tử không được hỗ trợ vào tài khoản StratTrade của mình, nó có thể đủ điều kiện để phục hồi. Chúng tôi sẽ tính phí mạng cho nỗ lực khôi phục. Đối với tái chế ước tính trị giá hơn 100 đô la, chúng tôi cũng sẽ tính phí 5% cho hơn 100 đô la. Giá trị ước tính của việc tái chế có thể khác với giá trị thị trường thực tế của việc tái chế.",
		},
		award1: "Nhà môi giới CFD tốt nhất châu Á năm 2023",
		award2: "Nhà môi giới tốt nhất thế giới 2022",
		award3: "Nhà môi giới đáng tin cậy nhất năm 2022",
		award4: "Thương hiệu đáng tin cậy nhất năm 2021",
		award5: "Nhà môi giới Fintech tốt nhất",
		award6: "Nhà môi giới minh bạch giao dịch nhất",
		analysis: "Phân tích",
		health: "Sức khỏe",
		country: "Quốc gia",
		information: "Thông tin",
		help: "Trợ giúp",
		confirm: "Xác định",
		cancel: "Hủy bỏ",
		tip: "Mẹo",
		place_select: "Vui lòng chọn",
		placeEnter: "Vui lòng nhập",
		countryCode: "Mã quốc gia",
		submit: "Giới thiệu",
		more: "Thêm",
		deposit: "Nhập tiền",
		real_trade: "Giao dịch thực",
		paper_trade: "Giao dịch demo",
		build_paperTrade: "Tạo tài khoản demo",
		start_trade: "Bắt đầu giao dịch ngay",
		app_name: "StratTrade",
		success: "Thành công！",
		dataSources_name: "Trading Central",
		reset: "Đặt lại",
		iknow: "Hiểu rồi.",
		noData: "Không có dữ liệu.",
		modify: "Sửa đổi",
		pingcang: "Đóng vị trí",
		all: "Tất cả",
		tip_cardMain: "Chụp hoặc tải lên ảnh mặt trước của thẻ ID",
		tip_cardBack: "Chụp hoặc tải lên ảnh ở mặt sau của ID",
		tip_cardMain1: "Chụp hoặc tải lên ảnh mặt trước của giấy phép lái xe",
		tip_cardBack1: "Chụp hoặc tải lên hình ảnh đằng sau bằng lái xe của bạn",
		tip_cardMain2: "Chụp hoặc tải lên ảnh hộ chiếu",
		tip_cardBack2: "Chụp hoặc tải lên ảnh hộ chiếu",
	},
	currency: {
		btcusdt: "Viet Nam",
		ethusdt: "Trang chủ",
		ltcusdt: "Viet Nam",
		xrpusdt: "Đồng Ripple",
		adausdt: "Trang chủ Tiền xu",
		eosusdt: "Đồng xu bưởi",
		dotusdt: "Đồng xu Poca",
		trxusdt: "Trang chủ",
		xmrusdt: "Tiền Monero",
		dogeusdt: "Chó đồng xu",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "Đô la Úc Đô la Mỹ",
		fx_sgbpusd: "GBP Đô la Mỹ",
		fx_seurusd: "Đô la Mĩ",
		fx_snzdusd: "Đô la New Zealand (NZD) Đô la Mĩ",
		fx_susdcad: "Đô la Mỹ CAD",
		fx_susdchf: "Đô la Mỹ Thụy Sĩ",
		fx_susdjpy: "Đô la Mĩ JPY",
		fx_saudcad: "Đô la Úc Đô la Canada",
		fx_saudchf: "Đô la Úc Thụy Sĩ",
		fx_saudjpy: "Đô la Úc JPY",
		fx_saudnzd: "Đô la Úc Đô la New Zealand (NZD)",
		fx_scadchf: "Đô la Canada Thụy Sĩ",
		fx_scadjpy: "Đô la Canada JPY",
		fx_schfjpy: "Thụy Sĩ Yên Nhật",
		fx_seuraud: "Euro Đô la Úc",
		fx_seurcad: "Euro Đô la Canada",
		fx_seurgbp: "Euro Bảng Anh",
		fx_seurjpy: "Euro Yên Nhật",
		fx_seurnzd: "Euro Đô la New Zealand (NZD)",
		fx_sgbpaud: "Bảng Anh AUD",
		fx_sgbpchf: "Bảng Anh Thụy Sĩ",
		fx_sgbpjpy: "GBP Yên Nhật",
		fx_sgbpnzd: "Bảng Anh Đô la New Zealand (NZD)",
		fx_scadnzd: "Đô la Canada NZD",
		hf_CL: "Dầu thô WTI New York",
		hf_OIL: "Dầu thô Brent",
		hf_GC: "Vàng New York",
		hf_SI: "New York Bạc",
		hf_HG: "Đồng Mỹ",
		hf_NG: "Mỹ Gas",
		hf_CAD: "Luân Đôn Copper",
		hf_AHD: "London nhôm",
		HX_AAPL: "Táo",
		HX_BABA: "Viet Nam",
		HX_GOOG: "Trang chủ",
		HX_WMT: "Việt Nam",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Viet Nam",
		HX_N225: "Kinh nguyệt 225",
		HX_GDAXI: "Đức DAX30",
		HX_IBEX: "Tây Ban Nha IBEX35",
		HX_SPX: "Chỉ số S&P 500",
		HX_FTSE: "FTSE 100 ở Anh",
		HX_KS11: "Hàn Quốc KOSPI",
		HX_NZ50: "New Zealand 50",
		HX_PSI20: "Bồ Đào Nha PSI20",
		hf_ZSD: "Luân Đôn Kẽm",
		hf_NID: "Luân Đôn Niken",
		hf_PBD: "Luân Đôn Lead",
		hf_SND: "Luân Đôn Tin",
		hf_XAU: "London Gold (Vàng tại chỗ)",
		hf_XAG: "London Silver (bạc tại chỗ)",
		hf_XPT: "Hợp đồng tương lai Platinum",
		hf_XPD: "Tương lai Palladium",
		hf_CT: "Bông Mỹ",
		hf_SM: "Bột đậu nành",
		hf_BO: "Dầu đậu nành",
		HX_SENSEX: "Ấn Độ Mumbai Sensex",
		HX_KLSE: "Thương hiệu: FTSE Malaysia KLCI",
		HX_KSE100: "Karachi, Pakistan",
		HX_FCHI: "Pháp CAC40",
		HX_SX5E: "Châu Âu Snooker 50",
		HX_ICEXI: "Băng Đảo ICEX",
		HX_ASE: "Hy Lạp Athens ASE",
		HX_OMXC20: "OMX Nhật Bản 20",
		HX_OSEBX: "Na Uy OSEBX",
		HX_OMXSPI: "Thụy Điển OMXSPI",
		HX_AMD: "Chất bán dẫn siêu bền",
		HX_DIS: "Việt",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "Trang chủ",
		HX_NVDA: "Trang chủ",
		HX_INTC: "Thông tin",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Công nghệ Seagate",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "Đồng xu Astro",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
	currenctType: {
        title: "Vui lòng chọn tiền tệ",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Đô la Mỹ',
        currenctType15: 'Hồng Kông Dollar',
        currenctType16: 'Đài tệ',
        currenctType17: 'Đồng xu ngựa',
        currenctType18: 'Đồng xu mới',
        currenctType19: 'Yên Nhật',
        currenctType20: 'Châu Âu',
        currenctType21: 'Đô la Úc',
        currenctType22: 'Rupiah',
        currenctType23: 'Bảng Anh',
        currenctType24: 'Thai baht',
        currenctType25: 'Đô la Canada',
        currenctType26: 'Việt Nam',
        currenctType27: 'Hàn Quốc',
        currenctType28: 'Tiền Ma Cao',
    },
    addnew2: {
        address: {
			account_type: "Loại tài khoản",
			ifsc_code: "Mã IFSC",
            title: "Địa chỉ rút tiền",
            account_number: "Số tài khoản",
            add_btn: "Thêm địa chỉ",
            label_type: "Chọn loại",
            place_type: "Vui lòng chọn loại",
            label_number: "Số tài khoản",
            place_number: "Vui lòng nhập số tài khoản",
            wallet_address: 'Địa chỉ ví',
            bank_name: 'Tên ngân hàng',
            bank_address: "Địa chỉ ngân hàng",
            name: "Tên",
            payee_address: 'Địa chỉ người nhận',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Tên chi nhánh",
            branch_code: "Mã chi nhánh",
            bank_code: "Mã ngân hàng",
            phone: "Số điện thoại",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Vui lòng nhập nội dung",
        },
    },
	addnew: {
		paypassTit: "Thay đổi mật khẩu rút tiền",
		paypassTit1: "Vui lòng đặt mật khẩu rút tiền",
		paypassTit2: "Mật khẩu giao tiếp ban đầu là mật khẩu đăng nhập",
		loan: "Trang chủ",
		repaid: "Đã thanh toán",
        unpaid: "Chưa hoàn trả",
		loanMore: "Tôi muốn vay tiền.",
		immediateRepayment: "Trả tiền ngay",
		accountType1: "Tài khoản Coin",
		accountType2: "Tài khoản hợp đồng",
		accountType3: "Tài khoản tùy chọn",
		from: "Từ",
        to: "Đến",
		get: "sẽ nhận được",
		labelBreed: "Tiền tệ",
		placeBreed: "Vui lòng chọn đồng tiền",
		labelTransAccount: "Tài khoản chuyển khoản",
		labelAmount: "Số tiền chuyển khoản",
		placeAmount: "Vui lòng nhập số tiền chuyển khoản",
		transferTit:"Chuyển khoản",
		convertTit:"Trao đổi Flash Coin",
		balanceTit:"Số dư tài khoản",
		available: "Hạn mức có sẵn",
        pending: "Khóa",
        equivalent: "Liên kết",
		coinTrade: "Giao dịch tiền tệ",
		secondContract: "Hợp đồng giây",
		number: "Số lượng",
		labelNumber: "Số lượng",
		placeNumber: "Vui lòng nhập số tiền chuộc",
		time: "Thời gian",
        loadAmount: "Số tiền vay dự kiến",
        repaymentCycle: "Chu kỳ hoàn trả khoản vay",
        dailyRate: "Lãi suất hàng ngày",
        repaymentMethod: "Phương thức thanh toán",
        loanTip: "Cho vay tín dụng (đảm bảo hình ảnh rõ ràng)",
        loanTip1: "Tải lên giấy chứng nhận bất động sản",
        loanTip2: "Bằng chứng về thu nhập (quan hệ lao động)",
        loanTip3: "Chi tiết sao kê ngân hàng",
        loanTip4: "Tải lên ảnh mặt trước của ID",
        interest: "Sở thích",
		repaymentMethod1: "Hết hạn thanh toán một lần",
	},
	params: {
		product_foreign: "Ngoại hối",
		product_shop: "Hàng hóa",
		product_number: "Chỉ số",
		product_stock: "Cổ phiếu",
		product_coin: "Mã hóa",
		billTypes: [
            { v: 101, name: 'Nạp tiền' },
            { v: 102, name: 'Thêm' },
            { v: 103, name: 'Đóng băng' },
            { v: 104, name: 'Làm tan băng' },
			{v: 105, name: 'Đăng ký'},
			{v: 106, name: 'Đăng ký'},
            { v: 201, name: 'Rút tiền đóng băng' },
            { v: 202, name: 'Khấu trừ' },
            { v: 203, name: 'Rút tiền thành công' },
            { v: 204, name: 'Rút tiền thất bại' },
            { v: 205, name: 'Phí rút tiền' },
			{v: 206, name: 'Chuyển ra'},
			{v: 207, name: 'Chuyển tiếp'},
			{v: 208, name: 'Chuyển tiền xu'},
			{v: 209, name: 'Chuyển tiền.'},
            { v: 301, name: 'Khấu trừ ký quỹ' },
            { v: 302, name: 'Khấu trừ phí thủ tục' },
            { v: 303, name: 'Hoàn tiền ký quỹ' },
            { v: 304, name: 'Lợi nhuận' },
            { v: 305, name: 'Mất tiền' },
			{v: 311, name: 'Tùy chọn mua'},
			{v: 312, name: 'Lợi nhuận tùy chọn'},
			{v: 313, name: 'Tùy chọn trả lại'},
			{v: 314, name: 'Phí xử lý quyền chọn'},
			{v: 315, name: 'Coin mua đóng băng'},
			{v: 316, name: 'Tiền xu mua vào khấu trừ'},
			{v: 317, name: 'Coin Mua Trở Lại'},
			{v: 318, name: 'Tiền mua vào tài khoản'},
			{v: 319, name: 'Tiền mua vào tài khoản'},
			{v: 320, name: 'Bán hàng đóng băng'},
			{v: 321, name: 'Bán đồng xu khấu trừ'},
			{v: 322, name: 'Bán coin trở lại'},
			{v: 323, name: 'Bán đồng xu vào tài khoản'},
			{v: 324, name: 'Bán đồng xu vào tài khoản'},
			{v: 325, name: 'Phí xử lý tiền tệ'},
			{v: 401, name: 'Miner tham gia'},
			{v: 402, name: 'Miner trở lại'},
			{v: 403, name: 'Thu nhập Miner'},
			{v: 404, name: 'Máy đào thoát'},
			{v: 405, name: 'Phí rút tiền máy đào'},
			{v: 411, name: 'Thu nhập vay'},
			{v: 412, name: 'Phí thủ tục vay'},
			{v: 413, name: 'Trả nợ thành công'},
        ],
	},
	message: {
		logout: "Đăng nhập",
		register_success: "Đăng ký thành công",
		place_inner_personal: "Vui lòng nhập thông tin cá nhân trước",
		submit_success: "Gửi thành công！",
		copy_success: "Sao chép thành công！",
		copy_error: "Sao chép thất bại！",
		modify_success: "Sửa đổi thành công",
		no_balance: "Số dư không đủ để thanh toán ký quỹ",
		palce_password: "Vui lòng nhập mật khẩu",
		trade_success: "Giao dịch thành công",
		placeEnterAmount: "Vui lòng nhập số tiền",
		tipTit1: "Bạn có chắc chắn để trả tiền cho lệnh này?",
	},
	web: {
		account: {
			menu1: "Thông tin tài khoản",
			menu2: "Tài sản của tôi",
			menu3: "Báo cáo tài khoản",
			menu4: "Bảo mật tài khoản",
			menu5: "Thông báo",
			menu6: "Trung tâm sự kiện",
			menu7: "Ví của tôi",
			menu8: "Xuất tiền",
			walletAdd: "Thêm ví mới",
			report: {
				history_tit: "Báo cáo lịch sử giao dịch",
				stream_tit: "Báo cáo chảy tiền",
				desc: "Vui lòng chọn phạm vi ngày thích hợp để xuất báo cáo tài khoản. (Bạn có thể xem thông tin tài khoản trong vòng 1 năm qua)",
				select_date: "Chọn phạm vi ngày",
				week: "Gần một tuần",
				month: "Gần một tháng",
				threeMonth: "Gần 3 tháng",
				sixMonth: "Gần nửa năm",
				time: "Gần nửa n ă m",
				view_report: "Hiển thị báo cáo",
				email_send: "Gửi qua E-mail",
				dialog_tit: "Báo cáo tài khoản",
				dialog_p1: "StratTrade Holding Ltd được đăng ký tại Quần đảo Cayman (số SIB 1612446) và xử lý các giao dịch của bạn với tư cách là chủ thể của giao dịch.",
				tit2: "Báo cáo lịch sử giao dịch Mitade",
				tit4: "Báo cáo dòng vốn Mitade",
				tit3: "Thông tin tài khoản",
				label1: "Tên tài khoản",
				label2: "ID tài khoản",
				label3: "Tiền tệ tài khoản",
				label4: "Thời gian báo cáo",
				label5: "Thời gian báo cáo",
				label6: "Tiền chảy",
				empty: "Bạn không có giao dịch đóng",
				email_des: "Báo cáo sẽ được gửi đến hộp thư của bạn",
				tab1: "Báo cáo",
				tab2: "Danh sách thanh toán hàng ngày",
				tab3: "Danh sách thanh toán hàng tháng",
			},
			account: {
				tit1: "Chào mừng đến với StratTrade",
				tit2: "Hoàn thành xác thực để thực hiện giao dịch nạp tiền thật",
				p1: "Mở tài khoản giao dịch thực",
				p2: "Xác thực",
				btn1: "Xác thực",
				tit3: "Thông tin cá nhân",
				label_name: "Tên",
				label_email: "Thư điện tử",
				label_address: "Địa chỉ cư trú",
				tit4: "Thông tin tài khoản",
				label_account: "Tài khoản giao dịch (Real Trading)",
				label_currency: "Tiền tệ cơ bản (giao dịch thực)",
				label_company: "Mở tài khoản công ty",
				company_tip: "StratTrade Holding Ltd, một công ty thuộc Tập đoàn StratTrade Holding Ltd, đã được cấp phép và ủy quyền bởi Cơ quan Tiền tệ Quần đảo Cayman (CIMA).",
				tit5: "Mở tài khoản giao dịch thực",
				tit6: "Hoàn thiện thông tin",
				tit7: "Điền thông tin cơ bản của bạn",
				tit8: "Đánh giá rủi ro",
				tit9: "Đánh giá khả năng chịu rủi ro giao dịch",
				tit10: "Xác thực",
				tit11: "Hoàn thành xác thực theo yêu cầu quy định",
				p3: "Vui lòng chọn bất kỳ tài liệu hỗ trợ nào dưới đây để xác minh",
				type1: "Chứng minh thư",
				type2: "Giấy phép lái xe",
				type3: "Hộ chiếu",
				p4: "Tôi xác nhận rằng tất cả các thông tin và tài liệu được cung cấp là đầy đủ, trung thực và chính xác và trong trường hợp có bất kỳ cập nhật nào về thông tin, tôi cam kết cung cấp thông tin cập nhật cho StratTrade trong vòng 30 ngày.",
				next: "Tiếp theo",
				btn2: "Chứng nhận thông qua",
				modify_personal_tit: "Sửa đổi thông tin cá nhân",
			},
			activity: {
				tit: "Trung tâm sự kiện",
				desc: "Bạn có thể tham gia vào các hoạt động để nhận phần thưởng và tăng lợi nhuận đầu tư của bạn.",
			},
			capital: {
				real_trade: "Giao dịch thực",
				label_nowAccount: "Tài khoản hiện tại",
				label_tradeAccount: "Số tài khoản giao dịch",
				label_currency: "Tiền tệ cơ bản",
				deposit_withdraw_record: "Hồ sơ tiền xuất nhập cảnh",
				capital_flow_details: "Nguồn vốn chi tiết",
			},
			notification: {
				tit: "Thiết lập thông báo",
				tit1: "Mở kênh thông báo",
				email: "Thư điện tử",
				message: "Tin nhắn",
				push: "đẩy",
				verify: "Kiểm tra",
				verified: "Đã kiểm tra",
				p1: "Vui lòng mở/xác minh các kênh thông báo sau đây, không bỏ lỡ các tin nhắn quan trọng!",
				type1: "Phân loại Marketing",
				tit2: "Thông báo chiến dịch tiếp thị",
				desc2: "Thông tin về mặt bằng ưu đãi, hoạt động vận hành, v. v.",
				tit3: "Hướng dẫn vận hành",
				desc3: "Tài liệu kiến thức phong phú và chuyên nghiệp",
			},
			security: {
				tit: "Mật khẩu",
				isSet: "Thiết lập",
				set: "Bỏ cài đặt",
				verify: "Kiểm tra",
				verified: "Đã kiểm tra",
				tit1: "Xác minh bảo mật",
				email: "Thư điện tử",
				phone: "Số điện thoại",
				phone_tit: "Số điện thoại liên kết",
				password_tit: "Thay đổi mật khẩu",
			},
		},
		market: {
			hot: "Nóng bỏng",
			prev: "Trang trước",
			next: "Trang tiếp theo",
			line: "Chia sẻ giờ",
			main_subplot: "Chỉ số biểu đồ phụ chính",
			main: "Chỉ số biểu đồ chính",
			subplot: "Chỉ số biểu đồ phụ",
			go_trade: "Chuyển đến trang giao dịch",
		},
		news: {
			tip: "Tuyên bố miễn trừ trách nhiệm: Thông tin trên là thông tin chung, chỉ nhằm mục đích thông tin và không làm cơ sở cho các quyết định giao dịch.",
		},
		trade: {
			state1: "Giữ vị trí",
			state2: "Danh sách chờ",
			state3: "Lịch sử",
			state: "Trạng thái",
			state4: "Đóng vị trí",
			state5: "Hủy bỏ",
			type: "Loại",
			type1: "Tất cả",
			type2: "Bán hàng",
			type3: "Mua vào",
			left_label1: "Tự chọn",
			left_label2: "Xem gần đây",
			left_type1: "Chế độ cột nhỏ gọn",
			left_type2: "Mô hình cột lỏng lẻo",
			left_type3: "Hiển thị biểu đồ chuyển động",
			all: "Tất cả",
			right_time: "Thời gian cập nhật",
			right_bp: "Hôm qua nhận",
			right_sp: "Thu nhập hiện tại",
			right_height: "Cao nhất",
			right_low: "Thấp nhất",
			remind_tit: "Nhắc nhở mới",
			remindList: "Danh sách nhắc nhở",
			remind_btn: "Nhắc nhở mới",
			right_tab1: "Giao dịch",
			right_tab2: "Chi tiết",
			right_sell: "Bán hàng",
			right_buy: "Mua vào",
			right_type: "Loại",
			right_type1: "Thị trường ",
			right_type2: "Đang chờ xử lý ",
			right_priceMoney: "Đơn giá treo",
			right_pointerNumber: "Số lượng (tay)",
			right_lever: "Trang chủ",
			right_tip: "Yêu cầu ký quỹ",
			right_balance: "Số dư có sẵn",
			right_stop_profit: "Dừng lại.",
			right_stop_loss: "Dừng lỗ",
			right_profit_loss: "Lợi nhuận và lỗ",
			now_order: "Đặt hàng ngay",
			confirm_order: "Xác nhận đơn hàng",
			right_tit1: "Chiến lược giao dịch",
			right_rinei: "Trong ngày",
			right_short: "Ngắn hạn",
			right_middle: "Giữa",
			right_tit2: "Chiến lược thay thế",
			right_tit3: "Đánh giá kỹ thuật",
			right_tit4: "Dữ liệu",
			right_tit5: "Cảm xúc giao dịch",
			right_label_sell: "Người bán",
			right_label_buy: "Khách hàng",
			right_tip2: "Cập nhật mỗi 15 phút",
			right_tit6: "Giá tăng giảm",
			minute: "phút",
			today: "Hôm nay",
			right_tit7: "Phạm vi thay đổi giá",
			now_price: "Giá hiện tại",
			right_low_price: "Giá thấp nhất",
			right_height_price: "Giá cao nhất",
			right_tit8: "Thông tin hợp đồng",
			right_tit9: "AUD đến CHF",
			right_label1: "Số lượng giao dịch duy nhất",
			right_label2: "Đòn bẩy tối đa",
			right_label3: "Kích thước hợp đồng",
			right_label4: "Tổng số vị trí tối đa",
			right_label5: "Chênh lệch giá nổi",
			right_label6: "Phí qua đêm",
			hand: "Bàn tay",
			right_collection_time: "Thời gian tính phí",
			right_tip3: "Charge benchmark: được tính từ toàn bộ kích thước giao dịch",
			right_label7: "Tỷ lệ Margin",
			right_label8: "Duy trì tỷ lệ margin",
			right_label9: "Phiên giao dịch",
			right_label10: "Phiên giao dịch hiện tại",
			right_label11: "Phiên giao dịch tiếp theo",


		},
	},
	home: {
		banner_p1: "Thông qua",
		banner_p2: "Sàn giao dịch",
		banner_p3: "Forex, Hàng hóa, Chỉ số, Cổ phiếu",
		banner_p4: "Và nhiều sản phẩm tài chính nóng hơn.",
		banner_p5: "Bạn có thể khám phá các thị trường tài chính nóng nhất trên thế giới. StratTrade cung cấp hoa hồng 0, chênh lệch giá thấp cực kỳ thuận lợi và cấu hình đòn bẩy linh hoạt.",
		hot: "Nóng bỏng",
		tit1: "Giao dịch thân thiện hơn",
		p1: "Giao diện đơn giản và trực quan, hoạt động dễ dàng",
		p2: "Hỗ trợ các trang web, ứng dụng điện thoại di động và máy tính để bàn, giao dịch trong tầm tay",
		p3: "Các công cụ quản lý rủi ro như Stop Loss/Tracking Stop Loss",
		p4: "Nhiều biểu đồ kỹ thuật và lịch tài chính, tin tức thời gian thực",
		p5: "Thư trực tiếp, SMS và thông báo đẩy",
		p6: "Cải tiến liên tục để có trải nghiệm giao dịch tốt hơn",
		btn1: "Khám phá nền tảng của chúng tôi",
		row3_tit: "Tự do giao dịch mọi lúc, mọi nơi",
		row3_tip: "Hỗ trợ các trang web, ứng dụng điện thoại di động và máy tính để bàn, giao dịch trong tầm tay",
		row3_tit2: "Quét mã tải xuống",
		row3_tit3: "Đầu bàn",
		row3_tit4: "Đầu di chuyển",
		regular_tit: "Nền tảng quốc tế đáng tin cậy",
		regular_tip: "Chúng tôi cam kết cung cấp cho khách hàng một môi trường giao dịch an toàn và có trách nhiệm.",
		regular_tit1: "Quy định cơ quan có thẩm quyền",
		regular_p1: "Cơ quan quản lý và ủy quyền của ngành để giành được sự tin tưởng của khách hàng toàn cầu",
		regular_tit2: "Bảo vệ tiền",
		regular_p2: "Tiền gửi của khách hàng bán lẻ được giữ riêng trong tài khoản ủy thác theo yêu cầu theo quy định",
		regular_tit3: "Bảo vệ số dư âm",
		regular_p3: "Cung cấp cho bạn bảo vệ số dư âm trong tài khoản, số dư âm được xóa sạch kịp thời để bạn không mất nhiều hơn số tiền nạp, giao dịch an tâm",
		regular_tit4: "5 * 24 giờ dịch vụ khách hàng",
		regular_p4: "Đội ngũ dịch vụ khách hàng chân thành và chuyên nghiệp, hỗ trợ trực tuyến 24 giờ, vui lòng giải quyết mọi vấn đề của bạn",
		service_tit: "Thêm dịch vụ giúp bạn",
		service_tit1: "Chiến lược giao dịch",
		service_p1: "Chiến lược giao dịch trực tiếp giúp bạn cập nhật các biến động thị trường mới nhất và nắm bắt tốt hơn thời điểm giao dịch.",
		service_tit2: "Kiến thức giao dịch",
		service_p2: "Cùng với StratTrade, hãy học kiến thức giao dịch miễn phí và nâng cao kỹ năng giao dịch của bạn.",
		service_tit3: "Quản lý rủi ro",
		service_p3: "Tìm hiểu về các công cụ quản lý rủi ro miễn phí mà StratTrade cung cấp để bảo vệ tài sản của bạn tốt hơn.",
		step_tit: "Mở tài khoản dễ dàng và tiện lợi",
		step_tip: "3 bước đơn giản để mở tài khoản trong vài phút",
		step_tit1: "Đăng ký",
		step_p1: "Điền thông tin và nộp đơn",
		step_tit2: "Nhập tiền",
		step_p2: "Nạp tiền nhanh chóng bằng nhiều cách",
		step_tit3: "Bắt đầu giao dịch",
		step_p3: "Khám phá cơ hội giao dịch và đặt lệnh nhanh",
		award_tit: "Thành tích của chúng tôi",
		award_p1: "Chúng tôi luôn theo đuổi sự xuất sắc, xuất sắc, cam kết cung cấp dịch vụ giao dịch chất lượng cao cho khách hàng.",
		award_p2: "Thương hiệu StratTrade rất tự hào khi nhận được giải thưởng từ các tổ chức nổi tiếng trong ngành, khẳng định sự nỗ lực và cam kết của đội ngũ đối với khách hàng.",
		row7_tit: "Thông tin tài chính toàn cầu theo thời gian thực",
		row7_tit1: "Tin tức",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "Click để download",
		p1: "Giao dịch mọi lúc mọi nơi",
		p2: "Giao dịch nhanh hơn, thông minh hơn",
		p3: "0 Giao dịch hoa hồng",
		p4: "Loại đầu tư nóng nhất thế giới",
		renderDom1: "Ngoại hối",
		renderDom2: "Cổ phiếu đẹp",
		renderDom3: "Vàng",
		renderDom4: "Dầu thô",
		renderDom5: "Chỉ số",
		renderDom6: "Tiền điện tử",
		login: "Đăng nhập",
		elail: "Hộp thư",
		phone: "Số điện thoại",
		no_account: "Không có tài khoản？",
		go_register: "Đăng ký",
		register: "Đăng ký",
		now_register: "Đăng ký ngay",
		have_account: "Đã có tài khoản？",
		go_login: "Đăng nhập",
	},
	about: {
		serviceFee: "Phí dịch vụ",
		introduction: "Giới thiệu nền tảng",
		trade_platform: "Sàn giao dịch",
		complaint_process: "Quy trình khiếu nại",
		page: "Trang",
		place_search: "Tìm kiếm những gì bạn muốn biết",
		no_answer: "Không tìm thấy câu trả lời bạn cần?",
		contact_us: "Liên hệ",
		awards: {
			h1: "Giải thưởng và danh dự",
			p1: "Chúng tôi luôn theo đuổi sự xuất sắc, xuất sắc, cam kết cung cấp dịch vụ giao dịch chất lượng cao cho khách hàng. Thương hiệu StratTrade rất tự hào khi nhận được giải thưởng từ các tổ chức nổi tiếng trong ngành, khẳng định sự nỗ lực và cam kết của đội ngũ đối với khách hàng.",
			p2: "Hoặc trải nghiệm nền tảng giao dịch trực tuyến từng đoạt giải thưởng của chúng tôi ngay bây giờ"
		},
		charges: {
			h1: "Phí&lệ phí",
			p1: "Là một phần không thể thiếu trong việc nâng cao trải nghiệm của khách hàng, StratTrade hy vọng có thể cung cấp cho khách hàng dịch vụ giao dịch với tỷ lệ tình dục tốt hơn. Chúng tôi cam kết công khai và minh bạch về phí và lệ phí, không bao gồm phí ẩn. Chi phí giao dịch chính của khách hàng là chênh lệch giá mua và lãi suất giữ qua đêm.",
			h2: "Đầu tiên, bạn có thể thưởng thức khi giao dịch với StratTrade",
			li1: "Báo giá sản phẩm ngay lập tức",
			li2: "Chỉ số kỹ thuật chuyên nghiệp",
			li3: "Phân tích thị trường độc quyền",
			free: "Miễn phí",
			view: "Xem trên",
			h3: "Chênh lệch giá mua và bán",
			p2: "StratTrade tính phí chênh lệch giá mua/bán như một khoản bồi thường cho dịch vụ. Phần phí này đã được phản ánh trong báo giá sản phẩm và khi bạn thiết lập vị trí của mình, bạn đã thực sự trả chênh lệch. Vì phí chênh lệch không cố định, chúng tôi khuyên bạn nên truy cập trang báo giá sản phẩm cụ thể hoặc đăng nhập vào",
			h4: "Phí nạp và rút tiền",
			p3: "Để giảm chi phí cho khách hàng, trong hầu hết các trường hợp, chúng tôi sẽ không tính phí tiền gửi và rút tiền của bạn *. Tuy nhiên, các công ty hoặc ngân hàng bên thứ ba có thể tính phí xử lý trong quá trình truy cập của bạn, chẳng hạn như phí ngân hàng trung chuyển.",
			p4: "Đối với trường hợp cần thu phí, bạn có thể hỏi chuyên viên chăm sóc khách hàng của chúng tôi.。",
			p5: "Ví dụ: thông qua giao dịch thẻ tín dụng quốc tế, chuyển/chuyển tài khoản ngân hàng hoặc giao dịch bằng tiền tệ không được hỗ trợ (chuyển đổi ngoại tệ), v.v.",
			h5: "Chi phí giữ kho qua đêm",
			p6: "Nếu bạn vẫn giữ vị trí của mình lúc 10 giờ tối GMT (GMT 22:00), bạn sẽ bị tính phí/gửi qua đêm. Yêu cầu về chi phí qua đêm cho mỗi sản phẩm có thể được truy cập vào trang báo giá sản phẩm cụ thể hoặc đăng nhập vào",
			h6: "Chi phí khác",
			p7: "Không có chi phí khác. Chính sách thu phí của chúng tôi hoàn toàn minh bạch, bất cứ dự án nào cần thu phí đều sẽ được công bố và thông báo trước, không cần lo lắng thu phí ẩn."
		},
		contact: {
			h1: "Liên hệ",
			address: "Địa chỉ",
			custom_email: "Email liên hệ khách hàng",
			business_email: "Email hợp tác kinh doanh",
			h2: "Viết cho chúng tôi",
			p1: "Nếu bạn có thắc mắc gì về dịch vụ của chúng tôi, xin vui lòng điền vào mẫu dưới đây để gửi câu hỏi, nhân viên dịch vụ khách hàng của chúng tôi sẽ trả lời sớm nhất có thể. Ngoài ra, để đăng ký chiến lược giao dịch hàng ngày mà chúng tôi cung cấp với Trading Central, vui lòng chọn 'Đăng ký phân tích thị trường hàng ngày' theo loại biểu mẫu dưới đây và điền vào ngôn ngữ bạn muốn tính phí và chúng tôi sẽ đăng ký cho bạn. * Dịch vụ đăng ký chỉ có sẵn cho khách hàng thực của StratTrade hiện tại.",
			h3: "Thủ tục khiếu nại",
			p2: "Tất cả các khiếu nại chúng tôi sẽ có một đội ngũ chuyên gia để giải quyết cho bạn, chi tiết có thể tham khảo",
		},
		faq: {
			h1: "Câu hỏi thường gặp",
			tit1: "Tài khoản demo có ích lợi gì?？",
			tit2: "Cách mở tài khoản thật？",
			tit3: "Cách thiết lập market order？",
			tit4: "Tại sao tôi không thể đặt hàng？",
			p1: "Tài khoản demo và tài khoản thực hầu hết đều giống nhau về giao diện giao dịch, dữ liệu và hoạt động, với 50.000 đô la tiền ảo trong tài khoản demo, nhằm mục đích giúp khách hàng làm quen với các chức năng của nền tảng thông qua hoạt động demo mà không gặp rủi ro về tiền.",
			p2: "Mở tài khoản thật vui lòng làm theo các bước dưới đây：",
			p3: "1. Truy cập trang đăng ký StratTrade, theo lời nhắc: đăng ký hộp thư/số điện thoại di động, thiết lập mật khẩu đăng nhập, tự động tạo [tài khoản demo] sau khi gửi xác nhận.",
			p4: "2.Trên trang tài khoản demo, chọn Chuyển sang tài khoản thực để mở [Tài khoản thực] theo quy trình.",
			p5: "Trước khi bạn mở tài khoản thực, chúng tôi khuyên bạn nên đọc các tài liệu tiết lộ pháp lý liên quan đến việc hiểu và giao dịch.",
			p6: "Xin lưu ý rằng bạn chỉ có thể nạp tiền vào tài khoản thực của mình sau khi hoàn tất xác thực.",
			p7: "Nhấp vào Giao dịch (Trade) sẽ hiển thị tất cả các sản phẩm mà nền tảng có thể cung cấp để giao dịch, bạn có thể nhập mã giống hoặc tên vào hộp nhập bên phải để tìm kiếm, chọn một sản phẩm nhấp vào Mua/Bán (Sell/BUY) sẽ bật lên cửa sổ giao dịch, trong đó bạn có thể thấy giá hiện tại và mức ký quỹ mong muốn. Bạn có thể điều chỉnh số lượng giao dịch mở theo cách thủ công, thiết lập điểm dừng lợi nhuận để kiểm soát rủi ro và nhấp vào nút 'Mua/Bán' (Sell/BUY) bên dưới để hoàn tất việc mở. Xin lưu ý rằng giá của tất cả các sản phẩm tài chính có thể thay đổi trước khi bạn nhấn nút mua/bán.",
			p8: "Trước tiên, hãy kiểm tra xem kết nối mạng có hoạt động không. Khi thị trường giống giao dịch tạm thời đóng cửa báo giá hoặc đóng cửa thị trường đều sẽ gây ra không thể đặt hàng. Vui lòng liên hệ với dịch vụ khách hàng trực tuyến của chúng tôi để được hỗ trợ thêm.",

		},
		fundSecurity: {
			h1: "An toàn tài chính",
			p1: "StratTrade được ủy quyền và quản lý bởi các cơ quan quản lý có thẩm quyền. Tuân thủ là trung tâm và văn hóa của mọi công việc chúng tôi làm. Sự an toàn của tiền của bạn là tối quan trọng và tất cả các khoản tiền gửi của khách hàng được giữ cách ly.",
			p2: "Tiền gửi của khách hàng bán lẻ được giữ riêng trong tài khoản ủy thác khi cần thiết theo yêu cầu của quy định.",
			p3: "StratTrade không sử dụng tiền của khách hàng cho các hoạt động thương mại ngoài bất kỳ giấy phép pháp lý và quy định nào.",
			p4: "StratTrade sẽ không đầu cơ vào bất kỳ thị trường nào",
			p5: "Kiểm toán độc lập của công ty kế toán bên thứ ba",
		},
		help: {
			h1: "Chào mừng bạn đến với Trung tâm trợ giúp StratTrade",
		},
		media: {
			h1: "Trung tâm truyền thông",
			p1: "Xem thông tin, thông cáo báo chí, tính năng truyền thông, thông báo và các thông tin khác liên quan đến thương hiệu StratTrade.",
		},
		mitrade: {
			h1: "Về StratTrade",
			view_certificate: "（Phương pháp kiểm tra）",
			p1: "StratTrade là một công ty fintech được ủy quyền và quy định bởi một cơ quan có thẩm quyền tập trung vào việc cung cấp cho các nhà đầu tư trải nghiệm giao dịch dễ dàng và thuận tiện. Nền tảng giao dịch đa dạng và sáng tạo của StratTrade đã giành được nhiều giải thưởng như nền tảng giao dịch di động tốt nhất và nhà môi giới sáng tạo nhất.",
			p2: "Với nền tảng StratTrade, bạn có thể đầu tư, giao dịch trên thị trường tài chính quốc tế rộng lớn hơn bao gồm hàng trăm loại phổ biến như cổ phiếu, chỉ số, hàng hóa, ngoại hối và hơn thế nữa. Không chỉ giới hạn trong việc trải nghiệm nền tảng giao dịch trên điện thoại di động và web thân thiện, StratTrade còn cung cấp cho bạn chi phí giao dịch cạnh tranh, thực hiện giao dịch nhanh, dịch vụ khách hàng tuyệt vời và hỗ trợ thông tin phong phú để nắm bắt cơ hội đầu tư, giao dịch kịp thời.",
			p3: "StratTrade được quy định chặt chẽ bởi các cơ quan có thẩm quyền trong ngành, đa cơ quan. StratTrade International được ủy quyền và quản lý bởi Ủy ban Dịch vụ Tài chính Mauritius (FSC) theo giấy phép số GB20025791. Để biết chi tiết về giấy phép Mauritius, hãy truy cập trang web chính thức của FSC https://www.fscmauritius.org",
			p4: "Tiến hành truy vấn. MStratTrade Global có Giấy phép Dịch vụ Tài chính (AFSL 398528) được ủy quyền bởi Ủy ban Chứng khoán và Đầu tư Úc (ASIC). Để biết thêm chi tiết về giấy phép của Úc, hãy truy cập trang web chính thức của ASIC tại www.asic.gov.au",
			p5: "Tiến hành truy vấn. Itrade Holding được ủy quyền và quản lý bởi Cơ quan Tiền tệ Quần đảo Cayman (CIMA) với số giấy phép SIB 1612446. Để biết thêm chi tiết về giấy phép Cayman, hãy truy cập trang web chính thức của CIMA tại www.cima.ky",
			p6: "Tiến hành truy vấn. Tất cả các hoạt động kinh doanh của StratTrade được tiến hành và tuân thủ tất cả các quy định dưới sự giám sát chặt chẽ.",
			h2: "Bối cảnh của StratTrade",
			p7: "StratTrade được thành lập tại Melbourne, Úc bởi một đội ngũ giàu kinh nghiệm và kiến thức về giao dịch tài chính và ngành công nghiệp fintech. Triết lý của StratTrade là giao dịch trở nên dễ dàng và thân thiện hơn.",
			p8: "Chiến lược và mô hình kinh doanh tổng thể của chúng tôi cho phép chúng tôi có cái nhìn sâu sắc và nhận thức toàn cục về sự phát triển của ngành và nhu cầu thị trường, từ đó có thể phân bổ nguồn lực có mục tiêu hơn, không ngừng đổi mới công nghệ và tối ưu hóa hiệu quả, liên tục mang lại trải nghiệm giao dịch thuận tiện và thân thiện hơn cho khách hàng. ​",
			h3: "Tại sao chọn StratTrade",
			why1: "Điều kiện giao dịch ngưỡng thấp",
			why2: "Được quy định bởi cơ quan công nghiệp",
			why3: "Nền tảng giao dịch đơn giản và trực quan",
			why4: "Mức độ hỗ trợ trực tuyến cao",
			why5: "Chi phí giao dịch cạnh tranh",
			why6: "Bảo vệ số dư âm",
			h4: "Giải thưởng và danh dự",
			p9: "Chúng tôi luôn theo đuổi sự xuất sắc, xuất sắc, cam kết cung cấp dịch vụ giao dịch chất lượng cao cho khách hàng.",
			p10: "Thương hiệu StratTrade rất tự hào khi nhận được giải thưởng từ các tổ chức nổi tiếng trong ngành, khẳng định sự nỗ lực và cam kết của đội ngũ đối với khách hàng.",
			new_add: "Người dùng mới trên toàn thế giới",
			ol1: "Nhà cung cấp tin tức và phân tích tốt nhất",
			ol2: "Nhà môi giới ngoại hối tốt nhất Châu Á",
			ol3: "Giải thưởng Sự hài lòng của khách hàng Forex Úc",
			ol4: "Ứng dụng di động tốt nhất",
			ol5: "Thị trường chứng khoán tăng trưởng nhanh nhất Australia",
			h5: "Triết lý hoạt động của StratTrade",
			tip_tit1: "Trách nhiệm",
			tip_tit2: "Jane đẹp",
			tip_tit3: "Trong suốt",
			tip_tit4: "Đổi mới",
			tip_p1: "Tất cả các giao dịch đều liên quan đến rủi ro và StratTrade thực hiện nhiều bước hơn để cung cấp cho khách hàng một môi trường giao dịch có trách nhiệm. StratTrade được quản lý chặt chẽ bởi nhiều cơ quan có thẩm quyền trong ngành và tuân thủ các tiêu chuẩn hoạt động an toàn và ổn định cao. Nền tảng của chúng tôi liên tục cung cấp một số công cụ giao dịch có trách nhiệm, bao gồm quản lý rủi ro.",
			tip_p2: "Đơn giản là vẻ đẹp, chi tiết thành tựu xuất sắc, StratTrade cần mẫn với từng chi tiết của sự đơn giản, dễ sử dụng và trải nghiệm người dùng. Chúng tôi liên tục tối ưu hóa nền tảng và dịch vụ của mình và hy vọng rằng tất cả mọi người, cả người mới và nhà đầu tư có kinh nghiệm, có thể dễ dàng nắm bắt cơ hội giao dịch thông qua nền tảng StratTrade.",
			tip_p3: "Để khách hàng của chúng tôi được hưởng lợi đầy đủ từ môi trường giao dịch công bằng và hiệu quả, StratTrade tuân theo một hệ thống quản lý chặt chẽ và minh bạch, tự điều chỉnh, cung cấp thông tin sản phẩm nền tảng minh bạch toàn diện, môi trường giá cả và dữ liệu, tất cả các khoản phí đều rõ ràng và công khai, đảm bảo sự minh bạch hoàn toàn chân thành trong toàn bộ quy trình dịch vụ.",
			tip_p4: "StratTrade tin rằng tiến bộ khoa học và công nghệ, đổi mới tài chính và dịch vụ rộng lớn hơn là động lực thúc đẩy tăng trưởng để không ngừng tạo ra giá trị và chia sẻ giá trị. Vì thế chúng tôi khuyến khích tư duy cởi mở, tiếp tục thúc đẩy sáng tạo, nhằm trở thành người sáng tạo và dẫn dắt công nghệ tài chính.",
			row6_tit1: "Sứ mệnh",
			row6_tit2: "Tầm nhìn",
			row6_tit3: "Tuyển dụng",
			row6_p1: "Cung cấp một môi trường giao dịch công bằng, hiệu quả và minh bạch không bị hạn chế bởi không gian, thời gian và thị trường cho nhiều người muốn giao dịch, dẫn đầu đổi mới tài chính và công nghệ trong ngành.",
			row6_p2: "Chúng tôi tin rằng tiến bộ công nghệ, đổi mới tài chính và dịch vụ đa dạng là những gì thúc đẩy sự phát triển của chúng tôi, cho phép chúng tôi tiếp tục tạo ra và chia sẻ giá trị trong xã hội.",
			row6_p3: "Để đạt được điều này, chúng tôi khuyến khích tư duy cởi mở và thúc đẩy sự đổi mới nhằm thông qua sự tăng trưởng và tiến bộ liên tục, trở thành",
			row6_p4: "Công ty Forex hàng đầu thế giới",
			row6_p5: "Chúng tôi là một đội ngũ toàn cầu hóa trải dài trên 7 quốc gia và khi đội ngũ phát triển nhanh chóng, chúng tôi luôn tìm kiếm các chuyên gia để tham gia cùng chúng tôi. Làm những gì bạn yêu thích với một nhóm đa văn hóa!",
			row6_p6: "Xin vui lòng gửi email cho chúng tôi CV mới nhất của bạn và lý do bạn muốn làm việc với chúng tôi:",
			row6_p7: "Ngoài ra, bạn cũng có thể ở",
			row6_p8: "hoặc",
			row6_p9: "Xem các vị trí mở của chúng tôi trên。",
			row6_p10: "StratTrade bảo vệ nghiêm ngặt thông tin cá nhân của bạn. Trong quá trình ứng tuyển, StratTrade sẽ không yêu cầu bạn cung cấp thông tin tài chính. Tuyển dụng của chúng tôi không yêu cầu bất kỳ liên quan tài chính, thẻ tín dụng, thông tin tài khoản ngân hàng hoặc bất kỳ hình thức thanh toán nào.",
			row6_p11: "Nếu bạn nghi ngờ một vị trí có gian lận, vui lòng thông qua",
			row6_p12: "Liên hệ với bộ phận nhân sự của StratTrade. Nếu bạn xác nhận rằng bạn đã bị lừa bởi thông tin tuyển dụng giả mạo, hãy liên hệ với cơ quan có liên quan tại địa phương của bạn.",

		}
	},
	product: {
		row2: {
			tit: "Giống giao dịch",
			sell: "Bán hàng",
			buy: "Mua vào",
			trade: "Giao dịch",
			more_product: "Thêm sản phẩm giao dịch",
		},
		row3: {
			tit: "Hỗ trợ giao dịch nhiều thiết bị",
			tit1: "Hỗ trợ giao dịch nhiều thiết bị",
			p1: "StratTrade cung cấp cho bạn nền tảng giao dịch trên web, điện thoại di động và máy tính để bàn. Bạn có thể giao dịch mọi lúc mọi nơi.",
			download: "Tải về",
		},
		row4: {
			tit: "Chọn StratTrade",
			tit1: "Nền tảng đơn giản và trực quan",
			p1: "Tích hợp giá thị trường, giao dịch, quản lý tài khoản, thông tin và quản lý rủi ro, mạnh mẽ cho trải nghiệm sử dụng tốt hơn",
			tit2: "Chi phí giao dịch cạnh tranh",
			p2: "0 hoa hồng giao dịch, chênh lệch giá thấp rất cạnh tranh, minh bạch, lãi suất qua đêm thấp, cho phép bạn tận hưởng các giao dịch chi phí thấp",
			tit3: "Điều kiện giao dịch ngưỡng thấp",
			p3: "Mỗi lot giao dịch tối thiểu là 0,01 lot, ký quỹ thấp cho phép mở các vị trí giao dịch",
			tit4: "Quy định bởi Authority",
			p4: "Cơ quan có thẩm quyền và quy định của ngành, tất cả các khoản tiền gửi của khách hàng được lưu trữ riêng biệt để bảo vệ tài sản của khách hàng",
			tit5: "Bảo vệ số dư âm",
			p5: "Trong bất kỳ điều kiện thị trường nào, khoản lỗ tài khoản của bạn sẽ không vượt quá số tiền gốc, số dư âm sẽ được thanh toán kịp thời, nâng cao khả năng quản lý rủi ro.",
			tit6: "Mức độ hỗ trợ trực tuyến cao",
			p6: "Cung cấp dịch vụ khách hàng trực tuyến đáp ứng nhanh chóng, sẽ cung cấp dịch vụ chất lượng cao hơn thông qua nỗ lực không ngừng của đội ngũ chuyên nghiệp",
		},
		row5: {
			tit: "Câu hỏi liên quan",
		},
		commodities: {
			title: "Giao dịch hàng hóa",
			p: "Các mặt hàng như kim loại quý và năng lượng, được coi là vừa có khả năng chống lạm phát vừa có cơ hội tăng giá trị đầu tư, là một phần quan trọng trong việc đa dạng hóa đầu tư. Trong đó, vàng, bạc, dầu là các loại hàng hóa được giao dịch với khối lượng lớn, giá cả công khai, minh bạch cũng như tính thanh khoản cao, được các nhà đầu tư đón nhận rộng rãi. Giá hàng hóa dao động đáng kể do bị ảnh hưởng bởi các yếu tố như cung và cầu, tình hình kinh tế và chính sách, tiền tệ, v.v., tạo cơ hội cho lợi nhuận rủi ro.",
		},
		forex: {
			title: "Giao dịch Forex",
			p: "Giao dịch ngoại hối đề cập đến việc chuyển đổi một loại tiền tệ sang một loại tiền tệ khác và thị trường ngoại hối là thị trường tài chính lớn nhất thế giới về khối lượng giao dịch. Thị trường ngoại hối có rất nhiều người tham gia, thanh toán thông qua thị trường ngoại hối, phòng ngừa rủi ro chuyển động tiền tệ hoặc giao dịch để thu được lợi nhuận. Giao dịch ngoại hối được thực hiện thông qua mạng lưới giữa các ngân hàng, tổ chức và nhà giao dịch cá nhân trên toàn thế giới, với biến động giá 24 giờ một ngày, 5 ngày một tuần, mang lại cơ hội kiếm tiền từ rủi ro.",
		},
		indices: {
			title: "indices",
			p: "Chỉ số chứng khoán là giá trị thống kê đại diện cho một loại cổ phiếu đặc trưng trên một sàn giao dịch cụ thể và là chỉ số phản ánh mức giá tổng thể và chuyển động của một loại cổ phiếu cấu thành trên thị trường. Giao dịch chỉ số chứng khoán, phản ánh cơ hội đầu tư vào thị trường hoặc ngành công nghiệp nói chung, có thể làm giảm rủi ro khi đầu tư vào một cổ phiếu. Các chỉ số nóng nhất trên thị trường Mỹ, Châu Âu, Châu Á và Úc, chẳng hạn như Úc 200, US Tech 100, Hồng Kông 50, Đức 30, v.v., cung cấp các cơ hội khác nhau cho thị trường chứng khoán toàn cầu.",
		},
		shares: {
			title: "Giao dịch chứng khoán",
			p: "Cổ phiếu là một loại chứng khoán có giá trị được phát hành trên sàn giao dịch. Để huy động vốn, các công ty sẽ phân phối quyền sở hữu công ty thông qua chứng khoán thông qua các sàn giao dịch niêm yết. Với hơn 60 nghìn tỷ đô la được giao dịch chỉ riêng trong năm 2019, cổ phiếu thế giới rất lớn và thanh khoản cao, vì vậy ngay cả sau nhiều năm, nó vẫn là một trong những công cụ tài chính phổ biến nhất trong mắt các nhà đầu tư toàn cầu. Trên nền tảng StratTrade, nhà giao dịch có thể sử dụng đòn bẩy để giao dịch thay đổi giá cổ phiếu. Giá cổ phiếu bị ảnh hưởng bởi nhiều yếu tố như kinh tế vĩ mô, triển vọng công nghiệp và hoạt động của công ty, biến động cao và luôn có cơ hội giao dịch đầu tư.",
		},
		coins: {
			title: "Tiền điện tử",
			p: "Tiền điện tử là một loại tiền kỹ thuật số được tạo ra thông qua mã. Họ hoạt động độc lập bên ngoài giới hạn của các hệ thống ngân hàng và chính phủ truyền thống. Như tên cho thấy, tiền điện tử sử dụng tiền điện tử để đảm bảo an toàn cho các giao dịch và quy định việc tạo ra các đơn vị khác, trong đó Bitcoin là loại tiền điện tử ban đầu và nổi tiếng nhất, được tạo ra bởi Satoshi Nakamoto và ra mắt vào tháng 1 năm 2009. Thậm chí đến ngày nay, việc cái tên Satoshi Nakamoto đề cập đến một người hay một nhóm vẫn chưa được biết đến. Bitcoin được coi là cryptocurrency phi tập trung đầu tiên. Giống như tất cả các loại tiền điện tử, nó được kiểm soát thông qua cơ sở dữ liệu giao dịch blockchain hoạt động như một sổ cái công khai phân tán. Cho đến ngày nay, đã có hơn 1.000 loại tiền điện tử có sẵn để giao dịch trực tuyến."
		}
	},
	trade: {
		data_sources: "Nguồn dữ liệu",
		tip: "Thông tin trên chỉ để tham khảo. StratTrade không đảm bảo tính chính xác, thời gian thực và đầy đủ của nội dung thông tin, vì vậy bạn không nên quá phụ thuộc vào thông tin được cung cấp. Thông tin này không chứa hồ sơ giá giao dịch của chúng tôi, hoặc đề nghị hoặc lời mời giao dịch bất kỳ công cụ tài chính nào. StratTrade không phải là một công ty cung cấp tư vấn tài chính và chỉ cung cấp các dịch vụ có tính chất của các chỉ thị thực hiện. Chúng tôi đề nghị độc giả tự tìm kiếm lời khuyên đầu tư liên quan. Xem tuyên bố từ chối trách nhiệm đầy đủ của chúng tôi.",
		tip2: "Cập nhật hàng tuần lúc 15:00 (GMT)",
		tip3: "Quan điểm giao dịch là một công cụ dự đoán tâm lý giao dịch trên thị trường, phản ánh quan điểm ngắn hạn và trung hạn và dự báo xu hướng của các chuyên gia trong ngành đối với các sản phẩm phổ biến.",
		analysis: {
			tit: "Chiến lược giao dịch",
			all: "Tất cả",
			day: "Trong ngày",
			tit1: "Chiến lược giao dịch",
			tit2: "Đánh giá kỹ thuật",
		},
		calendar: {
			tit: "Lịch tài chính",
			prev_week: "Tuần trước",
			next_week: "Tuần tới",
			place_date: "Ngày tìm kiếm",
			select: "Tùy chọn",
			select1: "Quốc gia",
			select2: "Tầm quan trọng",
			start_time: "Thời gian bắt đầu",
			end_time: "Thời gian kết thúc",
			search: "Tìm kiếm",
		},
		contract: {
			tit: "Điều kiện hợp đồng",
			type1: "Tất cả",
			type2: "Ngoại hối",
			type3: "Hàng hóa",
			type4: "Chỉ số",
			type5: "Cổ phiếu Mỹ",
			type6: "Cổ phiếu Australia",
			tit1: "Ngoại hối chính",
			tit2: "Ngoại hối nhỏ",
			tit3: "Hàng hóa",
			tit4: "Chỉ số chính",
			tit5: "Chỉ số phụ",
			tit6: "Cổ phiếu Mỹ",
			tit7: "Cổ phiếu Australia",
			tit8: "Quy tắc chung",
			p1: "Tỷ lệ phẳng mạnh",
			p2: "Ngày hết hạn của lệnh chờ",
			p3: "Không giới hạn",
			p4: "Thời gian thanh toán phí qua đêm",
			p5: "Thanh toán hàng ngày; Mùa hè: 05:00, Mùa đông: 06:00",
			p6: "Thông tin về đòn bẩy, tỷ lệ qua đêm và nhiều hơn nữa",
			p7: "Vui lòng tham khảo phần mềm giao dịch",
			p8: "Những điều trên áp dụng cho tất cả các loại giao dịch",
			tit9: "Lịch giao dịch hàng tuần",
			p9: "Thành phố Hugh",
			p10: "Các giờ giao dịch trên không tính đến ảnh hưởng của các ngày lễ hoặc điều chỉnh đặc biệt của thị trường.。",
			p11: "Đề xuất thời gian giao dịch cụ thể",
			p12: "Đăng nhập nền tảng",
			p13: "Kiểm tra trong giới thiệu vắn tắt về các chủng loại liên quan.。",
		},
		forecast: {
			tit: "Quan điểm giao dịch",
			p1: "Trung bình",
			p2: "Tăng giá",
			p3: "Giảm giá",
			p4: "Đĩa chéo",
			p5: "Xu hướng",
		},
		news: {
			tit: "Tin tức",
		},
		platforms: {
			tit: "Sàn giao dịch StratTrade",
			p: "Giao diện đơn giản và trực quan, hiệu suất giao dịch tuyệt vời, đáp ứng nhu cầu của nhiều nhà đầu tư hơn. Bắt đầu giao dịch tại StratTrade ngay hôm nay.",
			tit1: "Nền tảng giao dịch StratTrade tiện lợi và dễ sử dụng",
			tit2: "Giao dịch mọi lúc mọi nơi với StratTrade App",
			li1: "Giao dịch hàng trăm thị trường nóng, quy định có thẩm quyền đảm bảo tiền an toàn",
			li2: "Theo dõi diễn biến thị trường trong thời gian thực, xem phân tích giao dịch, xem tin tức tài chính mới nhất",
			li3: "Xem các loại biểu đồ giao dịch khác nhau bất cứ lúc nào và áp dụng chức năng vẽ mà không gặp trở ngại",
			li4: "Trải nghiệm nền tảng giao dịch từng đoạt giải thưởng của StratTrade trên thiết bị Android hoặc iOS của bạn",
			code_download: "Quét mã tải xuống",
			tit3: "Không cần tải xuống, giao dịch trực tuyến qua trình duyệt",
			li5: "Không yêu cầu tải xuống, giao dịch trực tuyến trực tiếp trên trình duyệt phía PC",
			li6: "Giải thưởng: Nhà môi giới Forex tốt nhất Châu Á năm 2022, Nền tảng giao dịch tốt nhất Châu Úc năm 2022, Nhà môi giới Forex Fintech phát triển nhanh nhất thế giới năm 2022",
			li7: "Cung cấp các chỉ số kỹ thuật mạnh mẽ: MACD, KDJ, RSI, TRIX, DMA, CCI, v.v.",
			li8: "Được trang bị các công cụ quản lý rủi ro như dừng lỗ và bảo vệ số dư âm để giảm thiểu rủi ro tiềm ẩn cho giao dịch của bạn",
			tit4: "Giải pháp hoàn hảo cho giao dịch trên máy tính để bàn của bạn",
			li9: "Dễ dàng xem giá thị trường trong danh sách tùy chọn",
			li10: "Bật chức năng nhắc nhở để nhận thông báo thay đổi giá và thông báo thông tin khác",
			li11: "Nhiều bố cục biểu đồ cho phép bạn theo dõi chuyển động thị trường từ các chiều thời gian khác nhau",
			li12: "Thông số kỹ thuật hợp đồng, tâm lý giao dịch và phân tích giao dịch đều nằm trong cùng một giao diện mà không cần chuyển đổi",
			li13: "Nhận tin tức giao dịch mới nhất trực tiếp trong StratTrade",
			tit5: "Tại sao chọn nền tảng giao dịch StratTrade?",
			row5_p1: "Bảo mật",
			row5_p2: "0 Hoa hồng",
			row5_p3: "Hỗ trợ đa nền tảng",
			row5_p4: "Sơ đồ kỹ thuật",
			row5_p5: "Truy cập mọi lúc mọi nơi",
			row5_p6: "Các công cụ vẽ khác nhau",
		},
		quotes: {
			tit: "Báo giá thời gian thực",
			p1: "Cung cấp cho bạn báo giá ngay lập tức, tâm lý thị trường và tin tức liên quan về các loại tài chính phổ biến hiện nay.",
			hot: "Nóng bỏng",
		},
		risk: {
			tit: "Quản lý rủi ro",
			p1: "Tất cả các giao dịch đều liên quan đến rủi ro và sử dụng linh hoạt các công cụ quản lý rủi ro miễn phí do StratTrade cung cấp để kiểm soát rủi ro một cách hiệu quả bất kể điều kiện thị trường thuận lợi.",
			function_tit1: "Chức năng Stop/Stop Loss",
			function_tit2: "Theo dõi chức năng dừng lỗ",
			function_p1: "Khóa lợi nhuận",
			function_p2: "Hạn chế thua lỗ",
			function_p3: "Tối đa hóa lợi nhuận khóa",
			function_p4: "Không cần giám sát vị trí tự động",
			function_p5: "Điều chỉnh giá đóng lỗ",
			row2_tit1: "Chức năng Stop/Stop Loss",
			row2_p1: "Khi thiết lập một lệnh mới hoặc sửa đổi một lệnh hiện có, chúng tôi có các tùy chọn để thiết lập 'chốt lời' và 'chốt lỗ'. Sau khi thiết lập, lệnh thường được đóng dựa trên giá mục tiêu bạn đã đặt, giúp bạn khóa lợi nhuận khi đạt được mục tiêu hoặc giảm tổn thất khi thị trường chuyển động không thuận lợi. Xin lưu ý rằng bất kỳ lệnh nào cũng có thể có trường hợp nhảy ngắn do biến động thị trường, khi hệ thống sẽ không thực hiện lệnh ở mức giá mặc định nhưng sẽ đóng vị trí của bạn ở mức giá thuận lợi nhất so với giá mục tiêu.",
			row2_tit2: "Ví dụ",
			row2_p2: "Giá hiện tại của EUR/USD là 1.13816/1.13837 (Bán/Mua). Bạn đã thiết lập lệnh mua 1 lô (1 lô=100.000 EUR) tại mức 1.13837 và đặt lệnh dừng lỗ tại mức 1.13806.",
			row2_p3: "Nói chung, khi giá giảm xuống mức 1.13806, lệnh dừng lỗ của bạn sẽ được kích hoạt và vị trí của bạn sẽ được đóng ở mức 1.13806 với tổng số lỗ là 31 đô la.",
			row2_p4: "Tuy nhiên, khi môi trường thị trường thay đổi và giá giảm trực tiếp từ 1.13837 xuống 1.13795, bỏ qua mục tiêu dừng lỗ của bạn, thị trường sẽ nhảy vọt, hệ thống sẽ không thể đóng vị trí của bạn ở mức 1.13806, nhưng sẽ đóng vị trí của bạn ở mức giá thuận lợi nhất tiếp theo, đó là 1.13795, tổn thất cuối cùng sẽ là 42 đô la.",
			row3_tit1: "Theo dõi chức năng dừng lỗ",
			row3_tit2: "Ví dụ",
			row3_p1: "Điểm dừng theo dõi (còn được gọi là điểm dừng di động) rất mạnh mẽ và giá dừng có thể tự động thay đổi theo lợi nhuận, cho phép bạn tối đa hóa lợi nhuận hoặc giảm tổn thất mà không cần phải theo dõi vị trí của mình mọi lúc. Khi đặt lệnh mới, bạn chỉ cần thiết lập một điểm dừng theo dõi và khi giá đi theo hướng có lợi cho bạn, lệnh dừng của bạn sẽ được cập nhật tự động dựa trên giá mới nhất. Ngược lại, khi giá di chuyển theo hướng chống lại bạn, lệnh dừng lỗ sẽ được bắt đầu và đóng vị trí của bạn ở khoảng cách pip bạn đã đặt. Xin lưu ý rằng bất kỳ lệnh nào cũng có thể có trường hợp nhảy ngắn do biến động thị trường, khi hệ thống sẽ không thực hiện lệnh ở mức giá mặc định của bạn, nhưng sẽ đóng vị trí cho bạn ở mức giá thuận lợi nhất so với giá mục tiêu.",
			row3_p2: "Giá hiện tại của EUR/USD là 1.13816/1.13837 (Bán/Mua). Bạn đã thiết lập lệnh mua 1 lô tại 1.13837 trong khi đặt lệnh dừng 100 pip (0.00100).",
			row3_p3: "Khi giá của sản phẩm ở mức 1.13816, mức dừng lỗ của bạn là 1.13716. Nếu giá bán của sản phẩm tăng lên 1.13845, mức dừng lỗ sẽ được cập nhật theo khoảng cách bạn đã đặt, với mức dừng cập nhật là 1.13745.",
			row3_p4: "Ngược lại, khi giá sản phẩm giảm từ 1.13845 xuống 1.13745, lệnh dừng theo dõi sẽ được kích hoạt và lệnh đóng được thực hiện tại 1.13745.",
			tip: "Điểm: Thông thường, đơn vị nhỏ nhất của biến động giá của một công cụ tài chính được gọi là điểm. Trong nền tảng StratTrade, nó đề cập đến chữ số cuối cùng hoặc số thập phân của giá công cụ.",
		},
		sentiment: {
			tit: "Chỉ số cảm xúc",
			type1: "Tất cả",
			type2: "Ngoại hối",
			type3: "Hàng hóa",
			type4: "Chỉ số",
			long: "Nhiều đầu",
			short: "Đầu rỗng",
		},
	},

	layout: {
		aside: {
			nav1: "Giao dịch",
			nav2: "Giá thị trường",
			nav3: "Thông tin",
			nav4: "Học đường",
			nav5: "Của tôi",
			newsDialog: {
				title: "Trung tâm thông điệp",
				type1: "Thông báo hệ thống",
				type2: "Thông báo",
				allRead: "All Tagged as đã đọc",
			},
			settingDialog: {
				title: "Thiết lập",
				nav1: "Thông thường",
				nav2: "Hiện sở thích",
				nav3: "Thông tin hệ thống",
				logout: "Đăng nhập",
				setting1: "Ngôn ngữ",
				setting2: "Giao dịch",
				setting2_tip: "Mở giống tự động thêm tự chọn",
				setting3: "Phản hồi",
				setting3_tip: "Đề xuất chức năng",
				setting4: "Xác thực tên thật",
				setting4_tip: "Xác thực tên thật",
				setting5: "Chế độ chủ đề",
				setting5_label1: "Màu tối",
				setting5_label2: "Màu sáng",
				setting6: "Màu sắc tăng và giảm",
				setting6_label1: "Xanh lá cây tăng đỏ giảm",
				setting6_label2: "Đỏ lên, xanh xuống.",
				setting7: "Thiết lập biểu đồ",
				setting7_label1: "Phiên bản chuẩn",
				setting7_label2: "Phiên bản TradingView",
				setting8: "Tuyên bố&Thỏa thuận",
				setting8_label1: "Chính sách bảo mật",
				setting8_label2: "Tuyên bố công bố sản phẩm",
				setting8_label3: "Tuyên bố công bố rủi ro",
				setting8_label4: "Thỏa thuận khách hàng",
				feedback_title: "Đề xuất chức năng",
			}
		},
		footer: {
			hot: "Giống phổ biến",
			sort: "Đa dạng",
			code: "Mã số",
			buy_price: "Giá bán",
			sell_price: "Giá bán",
			chg: "Tăng giảm",
		},
		header: {
			place_search: "Tìm kiếm giống giao dịch",
			balance: "Số dư có sẵn",
			balance_tip: "Số dư có sẵn: Số tiền trong tài khoản có sẵn để mở một vị trí mới",
			profit_loss: "Lợi nhuận và lỗ",
			asset_view: "Hồ sơ tài sản",
			netValue: "Giá trị ròng",
			netValue_tip: "Giá trị ròng: Giá trị tài khoản hiện tại, bao gồm cả lãi và lỗ trên tất cả các vị trí đang mở",
			marginLevel: "Mức ký quỹ",
			margin: "Ký quỹ",
			margin_tip: "Margin: Số tiền cần thiết để mở và duy trì một vị thế",
			maintainsMargin: "Ký quỹ duy trì",
			maintainsMargin_tip: "Duy trì ký quỹ: Số tiền ký quỹ tối thiểu cần thiết để duy trì trong tài khoản của bạn khi bạn giữ tất cả các vị trí.",

		},
	},
	table: {
		label_name: "Tên",
		label_buyPrice: "Giá mua",
		label_sellPrice: "Giá bán",
		label_variableValue: "Giá trị thay đổi",
		trade: "Giao dịch",
		label_code: "Mã sản phẩm",
		label_title: "Tên",
		label_start: "Tầm quan trọng",
		label_country: "Quốc gia",
		label_event: "Sự kiện",
		label_previous: "Giá trị trước",
		label_predictive: "Giá trị dự đoán",
		label_announced: "Thông báo giá trị",
		label_stop_loss_min: "Khoảng cách tối thiểu của lệnh dừng lỗ",
		label_difference: "Chênh lệch động",
		label_contract_size: "Kích thước hợp đồng",
		label_max_hand: "Số lot giao dịch tối đa",
		label_min_hand: "Số lot giao dịch tối thiểu",
		label_trade_week: "Lịch giao dịch hàng tuần",
		label_week: "1Tuần",
		label_month: "1Tuần",
		label_quarter: "1Hàng quý",
		label_dailyTrend: "Hành động một ngày",
		label_tradeId: "ID giao dịch",
		label_breed: "Đa dạng",
		label_tradeType: "Loại giao dịch",
		label_currency: "Tiền tệ",
		label_amount: "Số lượng",
		label_balance: "Số dư",
		label_type: "Loại",
		label_time: "Thời gian",
		label_orderNumber: "Số thứ tự",
		label_pointer_number: "Số tay",
		label_price_money: "Giá mở",
		label_stop_win_price: "Dừng lại.",
		label_stop_lose_price: "Dừng lỗ",
		label_openTime: "Thời gian mở vị trí",
		label_profit_loss: "Lợi nhuận và lỗ",
		label_sell_buy: "Giá bán/Giá mua",
		label_chg: "Tăng giảm",
		label_sell_price: "Giá bán",
		label_buy_price: "Giá bán",
		label_condition: "Điều kiện",
	},
	form: {
		label_feedback: "Câu hỏi và đề xuất",
		place_feedback: "Vui lòng nhập câu hỏi hoặc đề xuất của bạn",
		label_img: "Hình ảnh",
		label_img_tip: "Chọn, cung cấp ảnh chụp màn hình câu hỏi",
		feedback_tip: "Nếu bạn có câu hỏi khẩn cấp, vui lòng liên hệ",
		online: "Hỗ trợ trực tuyến",
		label_name: "Tên",
		place_name: "Vui lòng nhập tên",
		label_phone: "Điện thoại",
		place_phone: "Vui lòng nhập số điện thoại",
		label_email: "Thư điện tử",
		place_email: "Địa chỉ Email",
		message_email: "Vui lòng nhập hộp thư",
		label_nationality: "Quốc tịch",
		place_nationality: "Vui lòng nhập quốc tịch",
		place_questionType: "Loại câu hỏi",
		message_questionType: "Vui lòng chọn loại",
		questionType0: "Sàn giao dịch",
		questionType1: "Sản phẩm và phí",
		questionType2: "Đăng ký phân tích thị trường hàng ngày",
		questionType3: "Dịch vụ khách hàng",
		questionType4: "Khác",
		place_question: "Câu hỏi của bạn",
		message_question: "Vui lòng nhập câu hỏi",
		submit_question: "Gửi câu hỏi",
		message_phone: "Vui lòng nhập số điện thoại di động",
		label_password: "Mật khẩu",
		place_password: "Vui lòng nhập mật khẩu",
		message_password: "Vui lòng nhập mật khẩu",
		label_confirmPassword: "Xác nhận mật khẩu",
		place_confirmPassword: "Vui lòng nhập lại mật khẩu",
		message_confirmPassword: "Vui lòng nhập lại mật khẩu",
		label_captcha: "Mã xác nhận",
		place_captcha: "Vui lòng nhập CAPTCHA",
		message_captcha: "Vui lòng nhập CAPTCHA",
		get_captcha: "Nhận CAPTCHA",
		label_inviteId: "Mã mời",
		place_inviteId: "Mã mời (tùy chọn)",
		to: "至",
		start_time: "Thời gian bắt đầu",
		end_time: "Thời gian kết thúc",
		label_cardNumber: "Số giấy tờ",
		place_cardNumber: "Vui lòng nhập số giấy tờ",
		message_cardNumber: "Vui lòng nhập số giấy tờ",
		label_cardMain: "Mặt trước giấy tờ",
		message_cardMain: "Vui lòng nhập mặt trước giấy tờ",
		label_cardBack: "Giấy tờ ngược",
		message_cardBack: "Vui lòng nhập ngược giấy tờ",
		confirm_modify: "Xác nhận sửa đổi",
		label_realName: "Họ tên",
		place_realName: "Vui lòng nhập tên của bạn",
		message_realName: "Vui lòng nhập tên đầy đủ",
		label_firstName: "Họ",
		place_firstName: "Vui lòng nhập họ của bạn",
		message_firstName: "Vui lòng nhập họ",
		label_lastName: "Tên",
		place_lastName: "Vui lòng nhập tên đệm của bạn",
		message_lastName: "Vui lòng nhập tên",
		label_birthday: "Ngày sinh",
		place_birthday: "Vui lòng nhập ngày sinh của bạn",
		message_birthday: "Vui lòng nhập ngày sinh",
		place_nowPsd: "Vui lòng nhập mật khẩu hiện tại",
		message_nowPsd: "Vui lòng nhập mật khẩu hiện tại",
		place_newPsd: "Vui lòng nhập mật khẩu mới",
		message_newPsd: "Vui lòng nhập mật khẩu mới",
		place_crmPsd: "Vui lòng xác nhận mật khẩu mới",
		message_crmPsd: "Vui lòng xác nhận mật khẩu mới",
		label_breed: "Đa dạng",
		place_breed: "Vui lòng nhập giống",
		label_phase: "Khi",
		label_buyPrice: "Giá bán",
		label_sellPrice: "Giá bán",
		label_do: "Đối với",
		label_height: "Cao hơn",
		label_low: "Thấp hơn",
		label_equal: "hoặc bằng",
		labelWalletName: "Tên ví",
		messageWalletName: "Vui lòng nhập tên ví",
		placeWalletName: "Vui lòng nhập tên ví",
		labelWalletAddress: "Địa chỉ ví",
		messageWalletAddress: "Vui lòng nhập địa chỉ ví",
		placeWalletAddress: "Vui lòng nhập địa chỉ ví",
		labelAmount: "Số lượng",
		messageAmount: "Vui lòng nhập số tiền",
		placeAmount: "Vui lòng nhập số tiền",
		placeTimeLimit: "Vui lòng nhập giới hạn thời gian",
		messageTimeLimit: "Vui lòng nhập giới hạn thời gian",
	},
	pay: {
		title: "Tiền ra vào dễ dàng",
		p1: "Nhiều kênh thanh toán phổ biến, hỗ trợ tiền truy cập nhanh",
		p2: "Một số phương thức thanh toán có thể không khả dụng ở quốc gia của bạn",
		p3: "Chào mừng đến với dịch vụ StratTrade",
		btn: "Mở tài khoản giao dịch ngay",
	},
	header: {
		tip1: "Giao dịch đòn bẩy là các sản phẩm tài chính phức tạp và có nguy cơ mất tiền nhanh chóng.",
		btn1: "Sàn giao dịch",
		btn2: "Đăng nhập",
		btn3: "Đăng nhập",
		BigUint64Array: "Giao dịch ngay",
	},
	footer: {
		help: "Liên hệ với chúng tôi",
		tip1: "Theo dõi cộng đồng của chúng tôi",
		tip2: "Xin lưu ý rằng StratTrade không thành lập nhóm Telegram chính thức và bất kỳ nhóm Telegram nào được thành lập dưới tên StratTrade đều bị nghi ngờ là lừa đảo.",
		tip3: "Cảnh báo rủi ro: Giao dịch có nguy cơ khiến bạn mất toàn bộ số tiền của mình. Giao dịch phái sinh OTC không dành cho tất cả mọi người. Vui lòng đọc kỹ các tài liệu pháp lý của chúng tôi trước khi sử dụng dịch vụ của chúng tôi và đảm bảo bạn hiểu đầy đủ các rủi ro liên quan trước khi giao dịch. Bạn không thực sự sở hữu hoặc nắm giữ bất kỳ tài sản cơ bản nào có liên quan.",
		tip4: "StratTrade không cung cấp bất kỳ lời khuyên, lời chứng thực hoặc ý kiến nào liên quan đến việc mua, giữ hoặc bán. Tất cả các sản phẩm chúng tôi cung cấp là các phái sinh OTC dựa trên tài sản toàn cầu. Tất cả các dịch vụ được cung cấp bởi StratTrade chỉ dựa trên việc thực hiện các hướng dẫn giao dịch.",
		tip5: "StratTrade là một thương hiệu kinh doanh được sử dụng bởi nhiều công ty hoạt động thông qua:",
		tip6: "StratTrade International Ltd là nhà phát hành các sản phẩm tài chính được mô tả hoặc cung cấp để sử dụng trên trang web này. StratTrade International Ltd được ủy quyền và quản lý bởi Ủy ban Dịch vụ Tài chính Mauritius (FSC) số giấy phép GB20025791 và có địa chỉ đăng ký tại 6 St Denis Street, 1st Floor River Court, Port Louis 11328, Mauritius",
		tip7: "Số đăng ký của StratTrade Global Pty Ltd là ABN 90 149 011 361 và Giấy phép Dịch vụ Tài chính Úc (AFSL) 398528.",
		tip8: "StratTrade Holding Ltd được ủy quyền và quản lý bởi Cơ quan Tiền tệ Quần đảo Cayman (CIMA) theo giấy phép SIB 1612446.",
		tip9: "Thông tin trên trang web này không dành cho cư dân của Hoa Kỳ, Canada, Nhật Bản và New Zealand; Thông tin trên trang web này cũng không phù hợp với bất kỳ ai ở bất kỳ quốc gia hoặc khu vực pháp lý nào vi phạm luật pháp hoặc quy định địa phương do việc xuất bản hoặc sử dụng nó. Xin lưu ý rằng tiếng Anh là ngôn ngữ chính được sử dụng trong các dịch vụ của chúng tôi và là ngôn ngữ có hiệu lực pháp lý trong tất cả các tài liệu hợp đồng của chúng tôi. Các bản dịch ngôn ngữ khác chỉ để tham khảo, nếu bản dịch có nghĩa khác với tiếng Anh, đều lấy tiếng Anh làm chuẩn.",
		tip10: "Mã hóa giao tiếp bảo mật SSL. ©  Tất cả các quyền được bảo lưu bởi StratTrade.",
		link1: "Chính sách bảo mật",
		link2: "Tuyên bố công bố sản phẩm",
		link3: "Quy trình khiếu nại",
		link4: "Tuyên bố công bố rủi ro",
		link5: "Thỏa thuận khách hàng",
		toTop: "Đặt đỉnh",
	},
	nav: {
		tit1: "Sản phẩm",
		tit2: "Giao dịch",
		tit3: "Đầu tư học tập",
		tit4: "Về chúng tôi",
		nav1: "Ngoại hối",
		nav2: "Chỉ số",
		nav3: "Hàng hóa",
		nav4: "Cổ phiếu",
		nav5: "Sàn giao dịch",
		nav6: "Chiến lược giao dịch",
		nav7: "Quan điểm giao dịch",
		nav8: "Lịch tài chính",
		nav9: "Tin tức",
		nav10: "Báo giá thời gian thực",
		nav11: "Chỉ số cảm xúc",
		nav12: "Quản lý rủi ro",
		nav13: "Điều kiện hợp đồng",
		nav14: "Đầu tư vào",
		nav15: "Đầu tư mắt",
		nav16: "Academy",
		nav17: "Về StratTrade",
		nav18: "Giải thưởng và danh dự",
		nav19: "Trung tâm truyền thông",
		nav20: "An toàn tài chính",
		nav21: "Phí&lệ phí",
		nav22: "Affiliates",
		nav23: "Liên hệ",
		nav24: "Câu hỏi thường gặp",
		nav25: "Trung tâm trợ giúp",
	},

	city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentina",
		armenia: "Armenia",
		australia: "Úc",
		pakistan: "Pakistan",
		austria: "Áo",
		bahrain: "Bahrain",
		belgium: "Bỉ",
		bosnia_and_Herzegovina: "Bosnia và Herzegovina",
		brazil: "Brazil",
		brunei: "Vương quốc Bru-nây",
		bulgaria: "Bulgaria",
		cambodia: "Campuchia",
		canada: "Canada",
		cameroon: "Ca-mơ-run",
		chile: "Chile",
		colombia: "Colombia",
		costa_Rica: "Costa Rica",
		croatia: "Croatia (Hrvatska)",
		cyprus: "Síp",
		czech_Republic: "Cộng hòa Séc",
		denmark: "Đan Mạch",
		dominican_Republic: "Cộng hòa Dominican",
		egypt: "Ai Cập",
		estonia: "Estonia",
		ethiopia: "Ethiopia",
		finland: "Phần Lan",
		france: "Pháp",
		georgia: "Georgia",
		germany: "Đức",
		ghana: "Ghana",
		greece: "Hy Lạp",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hồng Kông",
		hungary: "Hungary",
		iceland: "Cộng hòa Ai-xơ-len / Iceland",
		ireland: "Cộng Hòa Ai-len / Ái Nhĩ Lan",
		italy: "Ý",
		india: "Ấn Độ",
		indonesia: "Indonesia",
		israel: "Người israel",
		iran: "Iran",
		iraq: "Iraq",
		japan: "Nhật Bản",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "Hàn Quốc",
		kuwait: "Cô-oét / Kuwait",
		kyrgyzstan: "Kyrgyzstan",
		laos: "Cộng hòa Dân chủ nhân dân Lào",
		latvia: "Latvia",
		lithuania: "Lithuania",
		luxembourg: "Luxembourg",
		macao_China: "Ma Cao",
		macedonia: "Bắc Macedonia",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Mexico",
		moldova: "Moldova, Cộng hòa",
		monaco: "Monaco",
		mongolia: "Mông Cổ",
		montenegro: "Montenegro",
		morocco: "Ma-rốc",
		myanmar: "Myanmar",
		netherlands: "Hà Lan",
		new_Zealand: "New Zealand",
		nepal: "Nepal",
		nigeria: "Nigeria",
		norway: "Na Uy",
		oman: "Ô-man",
		palestine: "Palestine",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "Philippines",
		poland: "Ba Lan",
		portugal: "Bồ Đào Nha",
		puerto_Rico: "Puerto Rico",
		qatar: "Qatar",
		romania: "Romania",
		russia: "Nga",
		republic_of_Trinidad_and_Tobago: "Trinidad và Tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Ả Rập Saudi",
		serbia: "Serbia",
		singapore: "Singapore",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		south_Africa: "Nam Phi",
		spain: "Tây Ban Nha",
		sri_Lanka: "Sri Lanka",
		sweden: "Thụy Điển",
		switzerland: "Thụy Sĩ",
		taiwan_China: "Đài Loan",
		tajikistan: "Tajikistan",
		tanzania: "Tanzania, Cộng hòa Thống nhất",
		thailand: "Thái Lan",
		turkey: "Thổ Nhĩ Kỳ",
		turkmenistan: "Turkmenistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "Các Tiểu Vương Quốc Ả Rập Thống Nhất",
		united_Kingdom: "Vương quốc Anh",
		united_States: "Hoa Kỳ",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Việt Nam",
		afghanistan: "Afghanistan",
		angola: "Ăng-gô-la",
		azerbaijan: "Azerbaijan",
		bangladesh: "Bangladesh",
		belarus: "Bêlarut",
		belize: "Belize",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bôlivia",
		botswana: "Botswana",
		british_Virgin_Islands: "Cộng hòa Dominican",
		burkina_Faso: "Burkina Faso",
		burundi: "Burundi",
		cape_Verde: "Mũi Verde",
		cayman_Islands: "Quần đảo Cayman",
		central_African_Republic: "Central African Republic",
		chad: "Chad",
		comoros: "Comoros",
		the_Republic_of_the_Congo: "Công-Gô",
		democratic_Republic_of_the_Congo: "Cộng hòa Dân chủ Congo",
		djibouti: "Djibouti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Equatorial Guinea",
		eritrea: "Eritrea",
		fiji: "Phi-gi",
		gabon: "Gabon",
		gambia: "Gambia",
		greenland: "Greenland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Vương quốc Anh",
		cote_d_Ivoire: "Cote d'Ivoire",
		jamaica: "Jamaica",
		jordan: "Jordan",
		lebanon: "Lebanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libyan Arab Jamahiriya",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Mô-ri-xơ / Mauritius",
		mozambique: "Mozambique",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Nigeria",
		north_Korea: "North Korea",
		reunion: "Réunion",
		san_Marino: "San Marino",
		senegal: "Sê-nê-gan",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalia",
		sudan: "Sudan",
		suriname: "Suriname",
		eswatini: "Swaziland",
		syria: "Syria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "U.S. Virgin Islands",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatican",
		yemen: "Yemen",
		yugoslavia: "Serbia",
		zambia: "Zambia",
		zimbabwe: "Cộng hòa Zimbabwe",
		china: "Trung Quốc đại lục",
	}
};
