export default {
	common: {
		learn: {
			title1: "基本餘額",
			content1: "StratTrade免費提供現金和託管加密貨幣餘額服務，讓您可以免費存儲現金和支持的加密貨幣。 您可以將加密貨幣從一個StratTrade用戶的主餘額轉移到另一個用戶的主餘額，無需支付任何費用。\n" +
				"\n" +
				"網絡交易費\n" +
				"\n" +
				"對於加密貨幣網絡上的交易（即，從StratTrade平臺轉移加密貨幣），StratTrade產生並支付網絡交易費用（如礦工費）。 當您將加密貨幣從StratTrade錢包發送到另一個錢包時，我們將根據我們對獨立錢包到錢包發送的現行網絡費用的估計收取費用。 然而，由於批量交易或網絡擁塞水准的變化等因素，StratTrade支付的最終費用可能與估計費用不同。 所有費用在交易時披露。\n" +
				"\n" +
				"效率提升和網絡費用\n" +
				"\n" +
				"由於批量交易的潜在效率收益，用戶在給定批量內支付的估計網絡費用總額可能超過StratTrade支付的最終網絡費用。\n" +
				"\n" +
				"閃電網絡處理費\n" +
				"\n" +
				"閃電網絡是一個微型支付網絡，它能够在不產生與比特幣區塊鏈相關的交易費用的情况下轉移比特幣。 當您通過閃電網絡從StratTrade發送比特幣時，我們將收取相當於比特幣轉帳金額0.1%的處理費。\n" +
				"\n" +
				"添加現金和套現\n" +
				"\n" +
				"當您添加現金或套現時，可能會根據您選擇的付款方式向您收取費用。 在完成交易之前查看費用詳細資訊以瞭解任何相關費用是很重要的。\n" +
				"\n" +
				"信貸交易\n" +
				"\n" +
				"如果您從StratTrade或StratTrade俱樂部的附屬公司借款美元，而我們必須出售您的BTC抵押品（根據適用的貸款協定授權），我們將收取總交易2%的固定費用。",
			title2: "交易費用和價差",
			content2: "StratTrade費用\n" +
				"\n" +
				"當您在StratTrade上購買、出售或轉換加密貨幣時，會收取費用。 這些費用是在您下訂單時計算的，可能會受到您選擇的付款方式、訂單規模、市場條件、管轄地以及我們為促進您的交易而產生的其他成本等因素的影響。\n" +
				"\n" +
				"在提交交易之前，您可以在交易預覽荧幕中查看費用。 請注意，類似交易的費用可能有所不同。 完成訂單即表示您同意為該交易支付適用的StratTrade費用。\n" +
				"\n" +
				"傳播\n" +
				"\n" +
				"當你下簡單的買賣訂單時，StratTrade會在報價中包含價差。 當從一種加密貨幣轉換為另一種時，價差也包括在匯率中。\n" +
				"\n" +
				"要在預覽荧幕上預覽買入、賣出或轉換訂單之前查看價差，請執行以下操作：\n" +
				"\n" +
				"買賣訂單：點擊資產價格行項目旁邊的工具提示以查看價差。\n" +
				"\n" +
				"轉換訂單：點擊報價匯率旁邊的工具提示。\n" +
				"\n" +
				"價差有助於新增交易成功的可能性，並允許StratTrade在處理您的訂單時暫時鎖定您的報價。 StratTrade可以保留交易中的任何超額價差。 請注意，類似交易的價差可能有所不同。\n" +
				"\n" +
				"StratTrade高級\n" +
				"\n" +
				"StratTrade Advanced不包含點差，因為您直接與訂單簿互動。\n" +
				"\n" +
				"StratTrade One\n" +
				"\n" +
				"StratTrade One是一款訂閱產品，提供免費買賣加密貨幣，但有一定的限制。 成員的報價中可能仍包含價差。\n" +
				"\n" +
				"交易費用和價差的變化\n" +
				"\n" +
				"StratTrade偶爾會測試費用和價差的變化。 這些變化可能會在不同的地區、資產、訂單規模和交易類型中展開。\n" +
				"\n" +
				"StratTrade shows you the total amount, inclusive of spread and fees, each time you preview a trade and afterwards in your transaction history. \n" +
				"\n" +
				"交易執行\n" +
				"\n" +
				"您的主餘額中的所有交易都通過StratTrade Exchange的中央限額訂單簿或競爭性拍賣執行。 您可以實时查看市場的訂單、出價、詢問和深度。 這些訂單簿决定了你在交易中看到的報價。\n" +
				"\n" +
				"StratTrade與做市商沒有任何訂單流支付（PFOF）關係。",
			title3: "StratTrade 卡片",
			content3: "使用StratTrade卡消費不收取交易費用。\n" +
				"\n" +
				"StratTrade確實包括購買或出售加密貨幣的價格價差。 這使我們能够在您提交交易前查看交易詳細資訊時臨時鎖定交易執行價格。 \n" +
				"\n" +
				"ATM運營商可能會向您收取費用。 有關更多詳細資訊，請參閱您的StratTrade持卡人協定。",
			title4: "股權服務",
			content4: "不收取賭注或拆出賭注的費用。 StratTrade根據您從網絡獲得的獎勵收取傭金。ADA、ATOM、DOT、MATIC、SOL和XTZ的標準傭金為35%。 對於ADA、ATOM、DOT、SOL和XTZ，符合條件的StratTrade One會員的傭金為26.3%。 您可以在資產詳細資訊中找到您的ETH傭金率。 一些用戶可能會收到更低費率的賭注促銷優惠。 您帳戶中顯示的APY和獎勵始終反映您在我們的傭金後獲得的收入。 您可以在StratTrade使用者協定中找到更多關於傭金的資訊。",
			title5: "資產回收",
			content5: "如果您將不受支持的加密貨幣發送到您的StratTrade帳戶，它可能有資格進行恢復。 我們將為恢復嘗試收取網絡費用。 對於估計價值超過100美元的回收，我們還將對超過100美元收取5%的回收費。 回收的估計價值可能與回收的實際市場價值不同。",
		},
		award1: "2023年亞洲最佳差價合約經紀商",
		award2: "2022全球最佳經紀商",
		award3: "2022年最值得信賴經紀商",
		award4: "2021年度最值得信賴品牌",
		award5: "最佳金融科技經紀商",
		award6: "最具交易透明度經紀商",
		country: "分析",
		health: "健康",
		analysis: "國",
		information: "訊息",
		help: "幫助",
		confirm: "確定",
		cancel: "取消",
		tip: "提示",
		place_select: "請選擇",
		placeEnter: "請輸入",
		countryCode: "國家/地區代碼",
		submit: "提交",
		more: "更多",
		deposit: "入金",
		real_trade: "真實交易",
		paper_trade: "模擬交易",
		build_paperTrade: "建立類比帳戶",
		start_trade: "立即開始交易",
		app_name: "StratTrade",
		success: "成功！",
		dataSources_name: "Trading Central",
		reset: "重置",
		iknow: "知道了",
		noData: "暫無數據",
		modify: "修改",
		pingcang: "平倉",
		all: "inteiro",
		tip_cardMain: "拍攝或上傳身份證正面的照片",
		tip_cardBack: "拍攝或上傳身份證背面的照片",
		tip_cardMain1: "拍攝或上傳駕駛執照正面的照片",
		tip_cardBack1: "拍攝或上傳駕駛執照背後的照片",
		tip_cardMain2: "拍攝或上傳護照照片",
		tip_cardBack2: "拍攝或上傳護照照片",
	},
	currency: {
		btcusdt: "比特幣",
		ethusdt: "以太坊",
		ltcusdt: "萊特幣",
		xrpusdt: "瑞波幣",
		adausdt: "艾達幣",
		eosusdt: "柚子幣",
		dotusdt: "波卡幣",
		trxusdt: "波場幣",
		xmrusdt: "門羅幣",
		dogeusdt: "狗狗幣",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "澳元美元",
		fx_sgbpusd: "英鎊美元",
		fx_seurusd: "歐元美元",
		fx_snzdusd: "新西蘭元（紐元）美元",
		fx_susdcad: "美元加元",
		fx_susdchf: "美元瑞郎",
		fx_susdjpy: "美元日元",
		fx_saudcad: "澳元加元",
		fx_saudchf: "澳元瑞郎",
		fx_saudjpy: "澳元日元",
		fx_saudnzd: "澳元新西蘭元（紐元）",
		fx_scadchf: "加元瑞郎",
		fx_scadjpy: "加元日元",
		fx_schfjpy: "瑞郎日元",
		fx_seuraud: "歐元澳元",
		fx_seurcad: "歐元加元",
		fx_seurgbp: "歐元英鎊",
		fx_seurjpy: "歐元日元",
		fx_seurnzd: "歐元新西蘭元（紐元）",
		fx_sgbpaud: "英鎊澳元",
		fx_sgbpchf: "英鎊瑞郎",
		fx_sgbpjpy: "英鎊日元",
		fx_sgbpnzd: "英鎊新西蘭元（紐元）",
		fx_scadnzd: "加元紐幣",
		hf_CL: "WTI紐約原油",
		hf_OIL: "布倫特原油",
		hf_GC: "紐約黃金",
		hf_SI: "紐約白銀",
		hf_HG: "美銅",
		hf_NG: "美國瓦斯",
		hf_CAD: "倫敦銅",
		hf_AHD: "倫敦鋁",
		HX_AAPL: "蘋果",
		HX_BABA: "阿裡巴巴",
		HX_GOOG: "穀歌",
		HX_WMT: "沃爾瑪",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "特斯拉",
		HX_N225: "日經225",
		HX_GDAXI: "德國DAX30",
		HX_IBEX: "西班牙IBEX35",
		HX_SPX: "標普500指數",
		HX_FTSE: "英國富時100",
		HX_KS11: "韓國KOSPI",
		HX_NZ50: "紐西蘭50",
		HX_PSI20: "葡萄牙PSI20",
		hf_ZSD: "倫敦鋅",
		hf_NID: "倫敦鎳",
		hf_PBD: "倫敦鉛",
		hf_SND: "倫敦錫",
		hf_XAU: "倫敦金（現貨黃金）",
		hf_XAG: "倫敦銀（現貨白銀）",
		hf_XPT: "鉑金期貨",
		hf_XPD: "鈀金期貨",
		hf_CT: "美國棉花",
		hf_SM: "美黃豆粉",
		hf_BO: "美黃豆油",
		HX_SENSEX: "印度孟買SENSEX",
		HX_KLSE: "富時馬來西亞KLCI",
		HX_KSE100: "巴基斯坦卡拉奇",
		HX_FCHI: "法國CAC40",
		HX_SX5E: "歐洲斯諾克50",
		HX_ICEXI: "冰島ICEX",
		HX_ASE: "希臘雅典ASE",
		HX_OMXC20: "OMX哥本哈根20",
		HX_OSEBX: "挪威OSEBX",
		HX_OMXSPI: "瑞典OMXSPI",
		HX_AMD: "超微電晶體",
		HX_DIS: "迪士尼",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "萬事達",
		HX_NVDA: "英偉達",
		HX_INTC: "英特爾",
		HX_GOGO: "Gogo Inc",
		HX_STX: "希捷科技",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "阿童木幣",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
	currenctType: {
        title: "請選擇貨幣",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: '美元',
        currenctType15: '港幣',
        currenctType16: '台幣',
        currenctType17: '馬幣',
        currenctType18: '新幣',
        currenctType19: '日元',
        currenctType20: '歐元',
        currenctType21: '澳元',
        currenctType22: '印尼盾',
        currenctType23: '英鎊',
        currenctType24: '泰銖',
        currenctType25: '加元',
        currenctType26: '越南盾',
        currenctType27: '韓幣',
        currenctType28: '澳門幣',
    },
    addnew2: {
        address: {
			account_type: "帳戶類型",
			ifsc_code: "IFSC程式碼",
            title: "取款地址",
            account_number: "帳戶號碼",
            add_btn: "添加地址",
            label_type: "選擇類型",
            place_type: "請選擇類型",
            label_number: "帳戶號碼",
            place_number: "請輸入帳戶號碼",
            wallet_address: '錢包地址',
            bank_name: '銀行名字',
            bank_address: "銀行地址",
            name: "姓名",
            payee_address: '收款人地址',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "分行名字",
            branch_code: "分行程式碼",
            bank_code: "銀行程式碼",
            phone: "手機號",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "請輸入內容",
        },
    },
	addnew: {
		paypassTit: "修改提款密碼",
		paypassTit1: "請設定您的提款密碼",
		paypassTit2: "交際密碼初始為登入密碼",
		loan: "貸款",
		repaid: "已還款",
        unpaid: "未還款",
		loanMore: "我要貸款",
		immediateRepayment: "立即還款",
		accountType1: "幣幣帳戶",
		accountType2: "合約帳戶",
		accountType3: "期權帳戶",
		from: "從",
        to: "至",
		get: "將得到",
		labelBreed: "幣種",
		placeBreed: "請選擇幣種",
		labelTransAccount: "轉帳帳戶",
		labelAmount: "轉帳金額",
		placeAmount: "請輸入轉帳金額",
		transferTit:"帳戶轉帳",
		convertTit:"幣幣閃兌",
		balanceTit:"帳戶餘額",
		available: "可用額度",
        pending: "鎖定",
        equivalent: "折合",
		coinTrade: "幣幣交易",
		secondContract: "秒合約",
		number: "數量",
		labelNumber: "數量",
		placeNumber: "請輸入贖數量",
		time: "時間",
        loadAmount: "預期貸款金額",
        repaymentCycle: "貸款償還週期",
        dailyRate: "日利率",
        repaymentMethod: "還款方式",
        loanTip: "信用貸款（請確保圖片清晰可見）",
        loanTip1: "上傳房產證書",
        loanTip2: "收入證明（雇傭關係）",
        loanTip3: "銀行對帳單詳細資訊",
        loanTip4: "上傳身份證正面照片",
        interest: "興趣",
		repaymentMethod1: "到期一次還款",
	},
	params: {
		product_foreign: "外匯",
		product_shop: "商品",
		product_number: "指數",
		product_stock: "股票",
		product_coin: "加密",
		billTypes: [
            { v: 101, name: '充值' },
            { v: 102, name: '添加' },
            { v: 103, name: '凍結' },
            { v: 104, name: '解凍' },
			{v: 105, name: '存入'},
			{v: 106, name: '存入'},
            { v: 201, name: '提現凍結' },
            { v: 202, name: '扣除' },
            { v: 203, name: '提現成功' },
            { v: 204, name: '提現失敗' },
            { v: 205, name: '提現手續費費' },
			{v: 206, name: '轉出'},
			{v: 207, name: '轉入'},
			{v: 208, name: '幣幣轉出'},
			{v: 209, name: '幣幣轉入'},
            { v: 301, name: '保證金扣除' },
            { v: 302, name: '手續費扣除' },
            { v: 303, name: '保證金返還' },
            { v: 304, name: '盈利' },
            { v: 305, name: '虧損' },
			{v: 311, name: '購買期權'},
			{v: 312, name: '期權收益'},
			{v: 313, name: '期權退回'},
			{v: 314, name: '期權手續費'},
			{v: 315, name: '幣幣買入凍結'},
			{v: 316, name: '幣幣買入扣除'},
			{v: 317, name: '幣幣買入返回'},
			{v: 318, name: '幣幣買入到賬'},
			{v: 319, name: '幣幣買入到賬'},
			{v: 320, name: '幣幣賣出凍結'},
			{v: 321, name: '幣幣賣出扣除'},
			{v: 322, name: '幣幣賣出返回'},
			{v: 323, name: '幣幣賣出到賬'},
			{v: 324, name: '幣幣賣出到賬'},
			{v: 325, name: '幣幣手續費'},
			{v: 401, name: '礦機加入'},
			{v: 402, name: '礦機返回'},
			{v: 403, name: '礦機加入'},
			{v: 404, name: '礦機退出'},
			{v: 405, name: '礦機退出手續費'},
			{v: 411, name: '借款收入'},
			{v: 412, name: '借款手續費'},
			{v: 413, name: '還款成功'},
        ],
	},
	message: {
		logout: "登出",
		register_success: "注册成功",
		place_inner_personal: "請先輸入個人資訊",
		submit_success: "提交成功！",
		copy_success: "複製成功！",
		copy_error: "複製失敗！",
		modify_success: "修改成功",
		no_balance: "餘額不足支付保證金",
		palce_password: "請輸入密碼",
		trade_success: "交易成功",
		placeEnterAmount: "請輸入金額",
		tipTit1: "你確定支付這個訂單嗎",
	},
	web: {
		account: {
			menu1: "帳戶資訊",
			menu2: "我的資產",
			menu3: "帳戶報告",
			menu4: "帳戶安全",
			menu5: "通知",
			menu6: "活動中心",
			menu7: "我的钱包",
			menu8: "出金",
			walletAdd: "添加新钱包",
			report: {
				history_tit: "交易歷史報告",
				stream_tit: "資金流水報告",
				desc: "請選擇合適的日期範圍以匯出帳戶報告。（您可以查閱最近1年內的帳戶資訊）",
				select_date: "選擇日期範圍",
				week: "近一周",
				month: "近一個月",
				threeMonth: "近三個月",
				sixMonth: "近半年",
				time: "時間",
				view_report: "顯示報告",
				email_send: "通過電子郵箱發送",
				dialog_tit: "帳戶報告",
				dialog_p1: "StratTrade Holding Ltd 於開曼群島注册（SIB號碼為：1612446），並以交易主體的身份處理您的交易。",
				tit2: "Mitade 交易歷史報告",
				tit4: "Mitade 資金流水報告",
				tit3: "帳戶資訊",
				label1: "帳戶名稱",
				label2: "賬戶ID",
				label3: "賬戶貨幣",
				label4: "報告時間",
				label5: "報告期",
				label6: "資金流水",
				empty: "您沒有已平倉交易",
				email_des: "報告將發送至您的郵箱",
				tab1: "報告",
				tab2: "日結單",
				tab3: "月結單",
			},
			account: {
				tit1: "歡迎來到StratTrade",
				tit2: "完成身份驗證即可進行真實入金交易",
				p1: "開通真實交易帳號",
				p2: "身份驗證",
				btn1: "身份驗證",
				tit3: "個人資訊",
				label_name: "姓名",
				label_email: "電子郵箱",
				label_address: "居住地址",
				tit4: "帳戶資訊",
				label_account: "交易帳號(真實交易)",
				label_currency: "基礎貨幣(真實交易)",
				label_company: "現開戶公司",
				company_tip: "StratTrade Holding Ltd,StratTrade Holding Ltd StratTrade 集團旗下的公司，已獲得開曼群島金融管理局 (CIMA)的許可和授權。",
				tit5: "開通真實交易帳號",
				tit6: "完善資料",
				tit7: "填寫您的基本資訊",
				tit8: "風險評估",
				tit9: "評估交易風險承受能力",
				tit10: "身份驗證",
				tit11: "根據監管要求完成身份驗證",
				p3: "請選擇以下任一證明檔案進行驗證",
				type1: "身份證",
				type2: "駕照",
				type3: "護照",
				p4: "我確認所提供的所有資訊和檔案都是完整、真實和準確的，如果資訊發生任何更新，我承諾在 30 天內提供更新資訊給StratTrade。",
				next: "下一步",
				btn2: "認證通過",
				modify_personal_tit: "修改個人資訊",
			},
			activity: {
				tit: "活動中心",
				desc: "您可參與活動領取獎勵，提升您的投資收益",
			},
			capital: {
				real_trade: "真實交易",
				label_nowAccount: "當前帳戶",
				label_tradeAccount: "交易帳號",
				label_currency: "基礎貨幣",
				deposit_withdraw_record: "出入金記錄",
				capital_flow_details: "資金流水明細",
			},
			notification: {
				tit: "通知設定",
				tit1: "打開通知通路",
				email: "電子郵箱",
				message: "簡訊",
				push: "推送",
				verify: "驗證",
				verified: "已驗證",
				p1: "請打開/驗證以下通知通路，重要消息不錯過!",
				type1: "行銷類",
				tit2: "行銷活動通知",
				desc2: "平臺的優惠資訊、運營活動等消息",
				tit3: "操作指導",
				desc3: "豐富且專業的知識資料",
			},
			security: {
				tit: "密碼",
				isSet: "已設定",
				set: "去設定",
				verify: "驗證",
				verified: "已驗證",
				tit1: "安全驗證",
				email: "電子郵箱",
				phone: "手機號碼",
				phone_tit: "手機號碼綁定",
				password_tit: "修改密碼",
			},
		},
		market: {
			hot: "熱門",
			prev: "上一頁",
			next: "下一頁",
			line: "分時",
			main_subplot: "主副圖名額",
			main: "主圖名額",
			subplot: "副圖名額",
			go_trade: "跳轉到交易頁",
		},
		news: {
			tip: "免責聲明：以上資訊為一般資訊，僅供參考，不作為交易決策依據。",
		},
		trade: {
			state1: "持倉",
			state2: "掛單",
			state3: "歷史",
			state: "狀態",
			state4: "已平倉",
			state5: "已撤銷",
			type: "類型",
			type1: "全部",
			type2: "賣出",
			type3: "買入",
			left_label1: "自選",
			left_label2: "最近瀏覽",
			left_type1: "緊湊列模式",
			left_type2: "寬鬆列模式",
			left_type3: "顯示走勢圖",
			all: "全部",
			right_bp: "昨收",
			right_sp: "今收",
			right_height: "最高",
			right_low: "最低",
			remind_tit: "新建提醒",
			remindList: "提醒清單",
			remind_btn: "新建提醒",
			right_tab1: "交易",
			right_tab2: "詳情",
			right_sell: "賣出",
			right_buy: "買入",
			right_type: "類型",
			right_type1: "市價單 ",
			right_type2: "掛單 ",
			right_priceMoney: "掛單價",
			right_pointerNumber: "數量(手)",
			right_lever: "杠杆",
			right_balance: "可用餘額",
			right_stop_profit: "止盈",
			right_stop_loss: "止損",
			right_profit_loss: "盈虧",
			now_order: "立即下單",
			confirm_order: "確認訂單",
			right_tit1: "交易策略",
			right_rinei: "日内",
			right_short: "短期",
			right_middle: "中期",
			right_time: "發佈時間",
			right_tit2: "備選策略",
			right_tit3: "科技點評",
			right_tit4: "數據",
			right_tit5: "交易情緒",
			right_label_sell: "賣家",
			right_label_buy: "買家",
			right_tip: "僅供參考，不作為本司立場",
			right_tip2: "每15分鐘更新",
			right_tit6: "價格漲跌幅",
			minute: "分鐘",
			today: "今天",
			right_tit7: "價格變化區間",
			now_price: "當前價格",
			right_low_price: "最低價",
			right_height_price: "最高價",
			right_tit8: "合約資訊",
			right_tit9: "澳元兌瑞郎",
			right_label1: "單筆交易數量",
			right_label2: "最大杠杆",
			right_label3: "合約大小",
			right_label4: "最大總持倉數量",
			right_label5: "浮動價差",
			right_label6: "隔夜費",
			hand: "手",
			right_collection_time: "收取時間",
			right_tip3: "收費基準：從整個交易規模中收取",
			right_label7: "保證金比例",
			right_label8: "維持保證金比例",
			right_label9: "交易時段",
			right_label10: "當前交易時段",
			right_label11: "下一個交易時段",


		},
	},
	home: {
		banner_p1: "通過",
		banner_p2: "平臺交易",
		banner_p3: "外匯、商品、指數、股票",
		banner_p4: "和更多熱門金融產品",
		banner_p5: "您可以探索全球最熱門的金融市場。StratTrade提供0傭金、極具優勢的低價差，以及靈活的杠杆配寘。",
		hot: "熱門",
		tit1: "更友好的交易",
		p1: "簡單直觀介面，輕鬆操作",
		p2: "支持網頁、手機APP和案頭端，交易盡在指尖",
		p3: "止損/追跡止損等風險管理工具",
		p4: "多種科技圖表和財經行事曆、即時新聞",
		p5: "實时郵件、簡訊和推送通知",
		p6: "不斷改進，實現更好交易體驗",
		btn1: "探索我們的平臺",
		row3_tit: "隨時隨地，自由交易",
		row3_tip: "支持網頁、手機APP和案頭端，交易盡在指尖",
		row3_tit2: "掃碼下載",
		row3_tit3: "案頭端",
		row3_tit4: "移動端",
		regular_tit: "值得信賴的國際平臺",
		regular_tip: "我們致力於為客戶提供安全負責的交易環境。",
		regular_tit1: "權威機构監管",
		regular_p1: "行業權威監管及授權，贏得全球客戶的信賴",
		regular_tit2: "保護資金安全",
		regular_p2: "零售客戶存款依照監管法規要求按需單獨存放在信託帳戶",
		regular_tit3: "負數餘額保護",
		regular_p3: "為您提供帳戶負數餘額保護，負數餘額及時清零，讓您的損失不超過入金額，安心交易",
		regular_tit4: "5*24小時客服",
		regular_p4: "真誠專業的客戶服務團隊，24小時線上支持，樂意解决您的任何問題",
		service_tit: "更多服務助力您",
		service_tit1: "交易策略",
		service_p1: "實时交易策略助您瞭解最新市場走勢，更好把握交易時機。",
		service_tit2: "交易知識",
		service_p2: "與StratTrade一起，免費學習交易知識，提升您的交易技能。",
		service_tit3: "風險管理",
		service_p3: "瞭解StratTrade提供的免費風險管理工具，更好的保護您的資產。",
		step_tit: "開設帳戶簡單便捷",
		step_tip: "簡單三步，數分鐘內即可開通帳戶",
		step_tit1: "注册",
		step_p1: "填寫資訊並提交您的申請",
		step_tit2: "入金",
		step_p2: "通過多種方式可快速存入資金",
		step_tit3: "開始交易",
		step_p3: "發掘交易機會及快速下單",
		award_tit: "我們的成績",
		award_p1: "我們一向追求卓越，精益求精，致力為客戶提供優質的交易服務。",
		award_p2: "StratTrade品牌非常榮幸獲得了行業內著名的機构所頒發的殊榮，肯定了團隊的一直努力及對客戶的承擔。",
		row7_tit: "實时全球金融資訊",
		row7_tit1: "即時新聞",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "點擊下載",
		p1: "隨時隨地交易",
		p2: "交易更快，更智慧",
		p3: "0傭金交易",
		p4: "全球最熱門投資品種",
		renderDom1: "外匯",
		renderDom2: "美股",
		renderDom3: "黃金",
		renderDom4: "原油",
		renderDom5: "指數",
		renderDom6: "加密貨幣",
		login: "登入",
		elail: "郵箱",
		phone: "手機號",
		no_account: "沒有帳號？",
		go_register: "去注册",
		register: "注册",
		now_register: "立即注册",
		have_account: "已有帳號？",
		go_login: "去登入",
	},
	about: {
		serviceFee: "服務收費",
		introduction: "平臺介紹",
		trade_platform: "交易平臺",
		complaint_process: "投訴流程",
		page: "頁面",
		place_search: "蒐索您想知道的",
		no_answer: "找不到您需要的答案?",
		contact_us: "聯絡我們",
		awards: {
			h1: "獎勵及榮譽",
			p1: "我們一向追求卓越，精益求精，致力為客戶提供優質的交易服務。 StratTrade品牌非常榮幸獲得了行業內著名的機构所頒發的殊榮，肯定了團隊的一直努力及對客戶的承擔。",
			p2: "，或⽴即體驗我們屢獲殊榮的線上交易平臺"
		},
		charges: {
			h1: "收費與費用",
			p1: "作為提升客戶體驗的組成部分，StratTrade希望能夠為客戶提供性價比更優的交易服務。 我們在費用和收費上致力保持公開透明，不含隱藏收費。 客戶的主要交易成本，是買賣價差和隔夜的持倉利息。",
			h2: "首先，您在使用StratTrade進行交易時可享受",
			li1: "即時產品報價",
			li2: "專業技術指標",
			li3: "獨家市場分析",
			free: "免費",
			view: "上查看",
			h3: "買賣價差",
			p2: "StratTrade以收取買賣價差作為服務補償。 此部分費用已反映在產品報價中，當您在建立倉位時，實際上已支付了差價。 由於差價費用並非固定，建議您可到具體產品報價頁，或登入到",
			h4: "存款與取款收費",
			p3: "為了降低客戶的成本，我們在大部分情况下是不會對您的存取款進行收費*。 但協力廠商公司或銀行在您進行存取的過程中可能會收取手續費例如中轉銀行費用",
			p4: "針對需要收費的情况可向我們的客服專員査詢。",
			p5: "例如通過國際信用卡交易、銀行帳戶轉入/轉出或使用未支持的貨幣進行交易（外幣轉換）等",
			h5: "隔夜持倉成本",
			p6: "如您在格林威治時間晚上10時（GMT 22:00）仍然持有倉位，您將會被收取/存入隔夜費用。 有關每個產品的隔夜費用查詢可到具體產品報價頁，或登入到",
			h6: "其他費用",
			p7: "無其他費用。 我們的收費政策完全透明，任何需要收費的項目都將提前公佈並告知，無需擔心隱性收費。"
		},
		contact: {
			h1: "聯絡我們",
			address: "地址",
			custom_email: "客戶聯絡電郵",
			business_email: "商務合作電郵",
			h2: "給我們寫信",
			p1: "如您對我們的服務有任何疑問，請填寫以下表格提交問題，我們的客服專員會儘快回復。 另外，如欲報名獲取我們與Trading Central合作所提供的每日交易策略，請在下錶類型選擇「訂閱每日市場分析」，並填上您希望收取的語言版本，我們會為您進行登記。* 訂閱服務只適用於現有StratTrade的真實客戶。",
			h3: "申述程式",
			p2: "所有投訴我們會有專門團隊為您調查解决，詳情可參攷",
		},
		faq: {
			h1: "常見問題",
			tit1: "類比帳戶有什麼用處？",
			tit2: "如何開立真實帳戶？",
			tit3: "如何建立市價單？",
			tit4: "為什麼我無法下單？",
			p1: "類比帳戶跟真實帳戶在交易介面、數據、操作上絕大部分都是一樣的，類比帳戶裏有5萬美元虛擬資金，目的是讓客戶在沒有資金風險的情况下通過類比操作熟悉平臺的各項功能。",
			p2: "開立真實帳戶請依照以下步驟進行操作：",
			p3: "1. 進入StratTrade注册頁面，依據提示：登記郵箱/手機號碼、設定登入密碼、提交確認後自動創建【類比帳戶】。",
			p4: "2. 在類比帳戶頁面，選擇「切換至真實帳戶」，依照流程進行操作即可開立【真實帳戶】。",
			p5: "在您開立真實帳戶前，我們建議您先閱讀瞭解和交易相關的法律披露檔案。",
			p6: "請注意，您只能在完成身份驗證後，才能入金至您的真實帳戶。",
			p7: "點擊「交易」（Trade）會顯示平臺可提供交易的所有產品，您可在右側輸入框中輸入品種程式碼或名稱進行蒐索，選擇一種產品點擊「買入/賣出」（SELL/BUY）會彈出交易視窗，在交易視窗中，您可看到當前價格和預計所需保證金。 您可手動調節開倉交易數量，設定止盈止損來進行風險控制，再點擊下方的「買入/賣出」（SELL/BUY）即完成開倉。 請注意，所有金融產品價格都在隨時跟著市場波動及更新，當您按下買入/賣出鍵之前，行情可能已經變化。",
			p8: "首先，請查看網絡連接是否正常。 當交易品種市場暫時關閉報價或者休市都會造成無法下單。 詳情請聯繫我們的線上客服以獲得進一步協助。",

		},
		fundSecurity: {
			h1: "資金安全",
			p1: "StratTrade 獲權威監管機构的授權和監管。 合規是我們每項工作的覈心和文化所在。 您的資金安全至為重要，所有的客戶存款都隔離存放。",
			p2: "零售客戶存款按照監管法規要求在必要時單獨存放在信託帳戶",
			p3: "StratTrade不使用客戶資金進行任何法律法規許可之外的商業活動。",
			p4: "StratTrade不會在任何市場進行投機活動",
			p5: "協力廠商會計師事務所獨立稽核",
		},
		help: {
			h1: "歡迎來到StratTrade幫助中心",
		},
		media: {
			h1: "媒體中心",
			p1: "查看與 StratTrade 品牌相關的資訊、新聞稿、媒體特寫、公告和其他資訊。",
		},
		mitrade: {
			h1: "關於StratTrade",
			view_certificate: "（查證方法）",
			p1: "StratTrade是由權威機构授權監管的金融科技公司，專注於為投資者提供簡單便捷的交易體驗。 創新型的StratTrade多元化交易平臺，屢次獲得最佳移動交易平臺、最具創新力券商等殊榮。",
			p2: "通過StratTrade平臺，您可以投資、交易更廣泛的國際金融市場，涵蓋股票、指數、商品、外匯等數百個熱門品種。 不僅限於體驗友好的手機版和網頁版交易平臺，StratTrade還為您提供有競爭力的交易成本、快速的交易執行、優秀的客戶服務和豐富的資訊支持，助力您及時把握投資、交易的良機。",
			p3: "StratTrade受到行業權威、多機构的嚴格監管。 StratTrade International獲模裡西斯金融服務委員會（FSC）授權並受其監管，許可證號碼為GB20025791。 如欲瞭解模裡西斯牌照詳情，可到FSC官方網站 https://www.fscmauritius.org",
			p4: "進行査詢。 MStratTrade Global持有澳大利亞證劵投資委員會（ASIC）授權的金融服務牌照（AFSL 398528）。 如欲瞭解更多澳大利亞牌照詳情，可到ASIC官方網站www.asic.gov.au",
			p5: "進行査詢。 itrade Holding獲開曼群島金融管理局（CIMA）授權並受其監管，SIB牌照號碼為1612446。 如欲瞭解更多開曼牌照詳情，可到CIMA官方網站www.cima.ky",
			p6: "進行査詢。 StratTrade一切業務運營均在嚴格監管下進行及遵從所有規定。",
			h2: "StratTrade的背景",
			p7: "StratTrade 由富有金融交易和金融科技行業經驗與知識的資深團隊組成，創立於澳大利亞墨爾本。 StratTrade的理念是交易變得更簡便、更友好",
			p8: "我們的整體化戰畧和商業模式使得我們對行業發展和市場需求擁有全域性的深度觀察和認知，從而能够更有針對性的配寘資源，不斷創新科技和優化效率，持續為客戶帶來更便捷、更友好的交易體驗。​",
			h3: "為何選擇StratTrade",
			why1: "低門檻的交易條件",
			why2: "受行業權威監管",
			why3: "簡潔直觀交易平臺",
			why4: "高水準的線上支持",
			why5: "有競爭力的交易成本",
			why6: "負數餘額保護",
			h4: "獎項及榮譽",
			p9: "我們一向追求卓越，精益求精，致力為客戶提供優質的交易服務。",
			p10: "StratTrade品牌非常榮幸獲得了行業內著名的機构所頒發的殊榮，肯定了團隊的一直努力及對客戶的承擔。",
			new_add: "全球新增用戶",
			ol1: "最佳新聞及分析資訊提供者",
			ol2: "亞洲最佳外匯劵商",
			ol3: "澳大利亞外匯客戶滿意大獎",
			ol4: "最佳移動應用程式",
			ol5: "澳大利亞增長最快劵商",
			h5: "StratTrade的運營理念",
			tip_tit1: "責任",
			tip_tit2: "簡而美",
			tip_tit3: "透明",
			tip_tit4: "創新",
			tip_p1: "所有交易皆涉及風險，StratTrade採取更多措施，為客戶提供負責任的交易環境。 StratTrade受到行業權威，多機构的嚴格監管，並遵循高級別的安全與穩定性營運標準。 我們的平臺持續提供包括風險管理在內的多項負責任的交易工具。",
			tip_p2: "簡單就是美，細節成就卓越，StratTrade對簡便易用和用戶體驗的每一個細節孜孜以求。 我們持續優化平臺和服務，希望每個人，無論是新手還是經驗豐富的投資者，都能够通過StratTrade平臺輕鬆把握交易良機。",
			tip_p3: "為了讓我們的客戶充分受益於公平、高效的交易環境，StratTrade遵循嚴謹透明的自律監管體系，提供全面透明的平臺產品資訊、價格環境和數據，所有費用一律清晰公開，確保整個服務流程的完全真誠透明。",
			tip_p4: "StratTrade深信科技進步、金融創新、更廣泛服務才是推動成長的力量，才能够不斷創造價值、分享價值。 為此我們鼓勵開放思維，持續推動創新，旨在成為金融科技的創新者和引領者。",
			row6_tit1: "我們的使命",
			row6_tit2: "我們的願景",
			row6_tit3: "工作機會",
			row6_p1: "為更多想進行交易的人，提供不受空間、時間、市場限制的公平、高效、透明的交易環境，引領行業的金融與科技創新。",
			row6_p2: "我們相信技術進步、金融創新和多元化服務是推動我們成長的力量，使我們能持續在社會上創造、分享價值。",
			row6_p3: "為此，我們鼓勵開放思維，推動創新，旨在通過不斷的成長和進步，成為",
			row6_p4: "全球領先的外匯公司",
			row6_p5: "我們是一個橫跨7個國家的全球化團隊，隨著團隊快速發展，我們一直在尋找專業人才加入我們。 與一個多元文化的團隊一起做你喜歡的事情！",
			row6_p6: "請將您的最新簡歷和您希望與我們一起工作的原因通過電子郵件發送給我們：",
			row6_p7: "。另外，您也可以在",
			row6_p8: "或",
			row6_p9: "上查看我們公開的空缺職位。",
			row6_p10: "StratTrade嚴格保護您的個人資訊安全。 在應聘流程中，StratTrade不會向您索要財務資訊。 我們的招聘不需要任何財務相關、信用卡、銀行帳戶資訊或任何形式的付款。",
			row6_p11: "如果您懷疑某個職位存在欺詐行為，請通過",
			row6_p12: "聯系StratTrade人力資源部。 如果您確認自己受到虛假招聘資訊欺騙，請聯系您當地的相關機構部門。",

		}
	},
	product: {
		row2: {
			tit: "交易品種",
			sell: "賣出",
			buy: "買入",
			trade: "交易",
			more_product: "更多交易產品",
		},
		row3: {
			tit: "支持多種設備交易",
			tit1: "支持多種設備交易",
			p1: "StratTrade為您提供網頁版、手機端及案頭端交易平臺。 您可以隨時隨地進行交易。",
			download: "下載",
		},
		row4: {
			tit: "選擇StratTrade",
			tit1: "簡潔直觀平臺",
			p1: "集行情、交易、帳戶管理、資訊、風險管理為一體，功能强大，實現更好使用體驗",
			tit2: "有競爭力的交易成本",
			p2: "0交易傭金，非常有競爭力、透明的低價差，低隔夜利息，讓您盡享低成本的交易",
			tit3: "低門檻的交易條件",
			p3: "每次最小交易手數低至0.01手，低保證金即可開倉交易",
			tit4: "受權威監管",
			p4: "行業權威授權及監管，所有客戶的存款單獨隔離存放，以保護客戶資產安全",
			tit5: "負數餘額保護",
			p5: "任何市况下，您的帳戶虧損都不會超過本金，負數餘額及時清零，提升風險管理能力",
			tit6: "高水準的線上支持",
			p6: "提供快速回應的線上客戶服務，將通過專業團隊的不懈努力，提供更優質的服務",
		},
		row5: {
			tit: "相關問題",
		},
		commodities: {
			title: "商品交易",
			p: "貴金屬、能源等大宗商品被認為既可抵禦通脹，又有投資增值的機會，是多元化投資的重要組成部分。 其中，黃金、白銀、石油等是交易量巨大的商品交易品種，公開、透明的價格以及高流動性，受到了投資者的廣泛歡迎。 商品價格由於受到供需、經濟和政策形勢、貨幣等因素的影響而顯著波動，提供了風險收益的機會。",
		},
		forex: {
			title: "外匯交易",
			p: "外匯交易是指將一種貨幣轉換成另一種貨幣，外匯市場是全球交易量最大的金融市場。 外匯市場有眾多的參與者，通過外匯市場進行支付、對沖貨幣變動風險，或者為了獲取收益進行交易。 外匯交易通過網絡在全球的銀行、機构和個人交易者之間進行，每週5天，每天24小時的價格波動，提供了風險收益的機會。",
		},
		indices: {
			title: "indices",
			p: "股票指數是代表特定交易所某類特性股票的統計數值，是反映市場上某類組成股票的總體價格水准及變動的名額。 交易股票指數，反應的是整體市場或行業的投資機會，能够减少投資個股的風險。 美國、歐洲、亞洲和澳大利亞市場最熱門的指數，如澳大利亞200、美國科技股100、香港50、德國30等，提供了全球股市的不同機會。",
		},
		shares: {
			title: "股票交易",
			p: "股票是在交易所發行的一種有價證劵。 企業為了籌集資金，會透過上市交易所把公司的所有權利用證劵進行分配。 單在2019年，世界股票的交易總額就超過了60萬億美元，規模龐大，而且流動性很强，所以就算經歷多年，它仍然是在全球投資者眼中最受歡迎的金融工具之一。 在StratTrade平臺上，交易者可利用杠杆來交易股票的價格變化。 股票價格受總體經濟、產業前景和公司運營等多種因素影響，波動性大，時刻存在投資交易的機會。",
		},
		coins: {
			title: "加密貨幣",
			p: "加密貨幣是通過程式碼創建的一種數位貨幣。 它們在傳統銀行和政府系統範圍之外自主運作。 顧名思義，加密貨幣使用加密技術來保證交易安全並規定其它組織的創建，其中比特幣是最原始也是到目前為止最著名的加密貨幣，比特幣由中本聰創造，於2009年1月推出。 即使到了今天，中本聰這個名字是指一個人還是一個群體依然是未知的。 比特幣被認為是第一個去中心化加密貨幣。 像所有加密貨幣一樣，它通過區塊鏈交易資料庫進行控制，該資料庫用作分佈式公共分類帳。 時至今日，已經有超過1000種加密貨幣可線上上交易。"
		}
	},
	trade: {
		data_sources: "數據來源",
		tip: "以上資訊僅供參考。 StratTrade並不保證資訊內容之準確性、實时性和完整性，所以您不應過於依賴提供之資訊。 此資訊不包含我們的交易價格記錄，或任何金融工具交易的要約或招攬。 StratTrade並非提供財務建議的公司，亦只提供執行指令性質之服務。 我們建議讀者自行尋求相關投資建議。 請參閱我們完整的免責聲明。",
		tip2: "每週五15:00（GMT）更新",
		tip3: "交易觀點是一個預測市場交易情緒的工具，它反映了業界專家對於各熱門產品的短期和中期看法及趨勢預測。",
		analysis: {
			tit: "交易策略",
			all: "全部",
			day: "日内",
			tit1: "交易策略",
			tit2: "科技點評",
		},
		calendar: {
			tit: "財經行事曆",
			prev_week: "上周",
			next_week: "下周",
			place_date: "蒐索日期",
			select: "選項",
			select1: "國家",
			select2: "重要性",
			start_time: "開始時間",
			end_time: "結束時間",
			search: "蒐索",
		},
		contract: {
			tit: "合約細則",
			type1: "全部",
			type2: "外匯",
			type3: "商品",
			type4: "指數",
			type5: "美國股票",
			type6: "澳洲股票",
			tit1: "主要外汇",
			tit2: "次要外汇",
			tit3: "商品",
			tit4: "主要指数",
			tit5: "次要指数",
			tit6: "美国股票",
			tit7: "澳大利亞股票",
			tit8: "通用規則",
			p1: "强平比例",
			p2: "掛單有效期",
			p3: "無限制",
			p4: "隔夜费结算时间",
			p5: "每天結算； 夏：05:00，冬：06:00",
			p6: "杠杆、隔夜費率等資訊",
			p7: "請參閱交易平臺",
			p8: "以上內容適用於所有交易品種",
			tit9: "交易周曆",
			p9: "休市",
			p10: "以上交易時間並無考慮節假日或市場特殊調整的影響。",
			p11: "具體交易時間建議",
			p12: "登入平臺",
			p13: "，在相關品種的簡介裏進行查看。",
		},
		forecast: {
			tit: "交易觀點",
			p1: "平均值",
			p2: "看漲",
			p3: "看跌",
			p4: "橫盤",
			p5: "傾向",
		},
		news: {
			tit: "即時新聞",
		},
		platforms: {
			tit: "StratTrade交易平臺",
			p: "簡單直觀的介面、卓越的交易效能，滿足更多投資者的需要。 今天就開始在StratTrade進行交易吧。",
			tit1: "便捷易用的StratTrade交易平臺",
			tit2: "隨時隨地用StratTrade App進行交易",
			li1: "交易數以百計的熱門市場，權威監管保證資金安全",
			li2: "即時監控市場走勢，查看交易分析，瀏覽最新財經新聞",
			li3: "隨時查看不同類型的交易圖表，無障礙應用繪圖功能",
			li4: "在您的安卓或iOS設備上體驗StratTrade屢獲殊榮的交易平臺",
			code_download: "掃碼下載",
			tit3: "無需下載，通過瀏覽器線上交易",
			li5: "不需要任何下載，直接在PC端瀏覽器線上交易",
			li6: "屢獲殊榮：2022年亞洲最佳外匯經紀商，2022年澳大利亞最佳交易平臺，2022年全球發展最快的外匯金融科技經紀商",
			li7: "提供强大的技術指標：MACD，KDJ，RSI，TRIX，DMA，CCI等",
			li8: "配備風險管理工具，如止損和負餘額保護，以減輕您交易的潛在風險",
			tit4: "您案頭端交易的完美解決方案",
			li9: "輕鬆查看自選清單中的市場行情",
			li10: "開啟提醒功能，接收價格變化提醒和其他資訊通知",
			li11: "多種圖表佈局讓您可以從不同的時間維度來追跡市場走勢",
			li12: "合約規格、交易情緒和交易分析均在同一介面，無需切換",
			li13: "在StratTrade中直接獲得最新的交易新聞",
			tit5: "為什麼選擇StratTrade交易平臺？",
			row5_p1: "安全保障",
			row5_p2: "0傭金",
			row5_p3: "多平臺支持",
			row5_p4: "科技圖表",
			row5_p5: "隨時隨地訪問",
			row5_p6: "各種繪圖工具",
		},
		quotes: {
			tit: "實时報價",
			p1: "為您提供當下熱門金融品種的即時報價，市場情緒及相關新聞。",
			hot: "熱門",
		},
		risk: {
			tit: "風險管理",
			p1: "所有交易皆涉及風險，靈活運用StratTrade提供的免費風險管理工具，無論市况順逆，風險都能有效的控制。",
			function_tit1: "止盈/止損功能",
			function_tit2: "追跡止損功能",
			function_p1: "鎖定利潤",
			function_p2: "限制虧損",
			function_p3: "極大化鎖定利潤",
			function_p4: "無需監控倉位自動",
			function_p5: "調整虧損平倉價格",
			row2_tit1: "止盈/止損功能",
			row2_p1: "在建立新訂單或修改現有訂單時，我們可選擇設定”止盈”和”止損”。 設定後，訂單在一般情况下會根據您所設定的目標價格進行平倉，助您在到達目標時鎖住利潤，或在市場走勢不利時減少損失。 請注意，任何訂單都可能因市况波動出現跳空的情况，此時系統將未能在默認的價格上執行訂單，但會在距離目標價下一個最有利價格為您進行平倉。",
			row2_tit2: "例子",
			row2_p2: "歐元/美元的現價為1.13816/1.13837（賣/買）。 您在1.13837建立了一張1手（1手= 100000歐元）的買入單，同時設定止損單在1.13806。",
			row2_p3: "在一般情况下，當價格下跌至1.13806時，您的止損單將會被觸發，並在1.13806的價位進行平倉，合計虧損31美元。",
			row2_p4: "可是當市場環境出現突變，價格直接從1.13837下滑到1.13795，直接跳過了您的止損目標時，市場出現跳空，系統將無法在1.13806進行平倉，但會在下一個最有利價格，即1.13795為您執行平倉，最終虧損將會是42美元。",
			row3_tit1: "追跡止損功能",
			row3_tit2: "例子",
			row3_p1: "追跡止損（又稱移動止損）功能强大，止損價能隨著盈利自動變化，使您無需時刻監控倉位，都能極大化鎖定利潤或減少損失。 在新建訂單時，您只需設定一個追跡止損點數，當價格朝向對您有利的方向行走時，您的止損訂單會根據最新價格自動進行更新。 相反，當價格朝向對您不利的方向變動時，止損訂單將會被啟動，並在您所設定的虧損點數距離下平倉。 請注意，任何訂單都可能因市况波動出現跳空的情况，此時系統將未能在您默認的價格上執行訂單，但會在距離目標價下一個最有利價格為您進行平倉。",
			row3_p2: "歐元/美元的現有價格為1.13816/1.13837（賣/買）。 您在1.13837建立了一張1手的買入單，同時設定一個100點（0.00100）的追跡止損。",
			row3_p3: "當產品價格在1.13816時，您的止損價為1.13716。 若產品賣出價往上升至1.13845，止損價將會按您所設定的距離進行更新，更新的止損價為1.13745。",
			row3_p4: "相反，當產品價格從1.13845下滑至1.13745時，追跡止損將會觸發，並在1.13745執行平倉。",
			tip: "點：通常，金融工具價格的最小變動組織稱為點。 在StratTrade平臺，它指的是工具價格的最後一比特數位或小數。",
		},
		sentiment: {
			tit: "情緒指數",
			type1: "全部",
			type2: "外匯",
			type3: "商品",
			type4: "指數",
			long: "多頭",
			short: "空頭",
		},
	},

	layout: {
		aside: {
			nav1: "交易",
			nav2: "行情",
			nav3: "資訊",
			nav4: "學堂",
			nav5: "我的",
			newsDialog: {
				title: "消息中心",
				type1: "系統通知",
				type2: "公告",
				allRead: "全部標記為已讀",
			},
			settingDialog: {
				title: "設定",
				nav1: "常規",
				nav2: "顯示偏好",
				nav3: "系統資訊",
				logout: "登出",
				setting1: "語言",
				setting2: "交易",
				setting2_tip: "開倉品種自動添加自選",
				setting3: "反饋",
				setting3_tip: "功能建議",
				setting4: "實名認證",
				setting4_tip: "實名認證",
				setting5: "主題模式",
				setting5_label1: "暗色",
				setting5_label2: "亮色",
				setting6: "漲跌顏色",
				setting6_label1: "綠漲紅跌",
				setting6_label2: "紅漲綠跌",
				setting7: "圖表設定",
				setting7_label1: "標準版",
				setting7_label2: "TradingView版",
				setting8: "聲明與協定",
				setting8_label1: "隱私政策",
				setting8_label2: "產品披露聲明",
				setting8_label3: "風險披露聲明",
				setting8_label4: "客戶協定",
				feedback_title: "功能建議",
			}
		},
		footer: {
			hot: "熱門品種",
			sort: "品種",
			code: "代碼",
			buy_price: "買價",
			sell_price: "賣價",
			chg: "漲跌幅",
		},
		header: {
			place_search: "蒐索交易品種",
			balance: "可用餘額",
			balance_tip: "可用餘額：帳戶中可用於開立新倉位的金額",
			profit_loss: "盈虧",
			asset_view: "資產概況",
			netValue: "淨值",
			netValue_tip: "淨值：當前的帳戶價值，包括所有持倉的盈虧",
			marginLevel: "保證金水准",
			margin: "保證金",
			margin_tip: "保證金：開立和維持所以持倉所需金額",
			maintainsMargin: "維持保證金",
			maintainsMargin_tip: "維持保證金：您持有所有倉位時帳戶內所需要維持的最低保證金金額。",

		},
	},
	table: {
		label_name: "名字",
		label_buyPrice: "買入價",
		label_sellPrice: "賣出價",
		label_variableValue: "變動值",
		trade: "交易",
		label_code: "產品程式碼",
		label_title: "名稱",
		label_start: "重要性",
		label_country: "國家",
		label_event: "事件",
		label_previous: "前值",
		label_predictive: "預測值",
		label_announced: "公佈值",
		label_stop_loss_min: "止損單最小距離",
		label_difference: "動態價差",
		label_contract_size: "合約大小",
		label_max_hand: "最大單筆交易手數",
		label_min_hand: "最小單筆交易手數",
		label_trade_week: "交易周曆",
		label_week: "1周",
		label_month: "1月",
		label_quarter: "1季度",
		label_dailyTrend: "單日走勢",
		label_tradeId: "交易ID",
		label_breed: "品種",
		label_tradeType: "交易類型",
		label_currency: "貨幣",
		label_amount: "金額",
		label_balance: "結餘",
		label_type: "類型",
		label_time: "時間",
		label_orderNumber: "訂單號",
		label_pointer_number: "手數",
		label_price_money: "開倉價",
		label_stop_win_price: "止盈",
		label_stop_lose_price: "止損",
		label_openTime: "開倉時間",
		label_profit_loss: "盈虧",
		label_sell_buy: "賣價/買價",
		label_chg: "漲跌幅",
		label_sell_price: "賣價",
		label_buy_price: "買價",
		label_condition: "條件",
	},
	form: {
		label_feedback: "問題和建議",
		place_feedback: "請輸入您的問題或建議",
		label_img: "圖片",
		label_img_tip: "選填，提供問題截圖选填，提供问题截图",
		feedback_tip: "如果您有緊急問題，請聯系",
		online: "線上客服",
		label_name: "姓名",
		place_name: "請輸入姓名",
		label_email: "電子郵箱",
		place_email: "電子郵箱地址",
		message_email: "請輸入郵箱",
		label_nationality: "國籍",
		place_nationality: "請輸入國籍",
		place_questionType: "問題類型",
		message_questionType: "請選擇類型",
		questionType0: "交易平臺",
		questionType1: "產品及收費",
		questionType2: "訂閱每日市場分析",
		questionType3: "客戶服務",
		questionType4: "其他",
		place_question: "您的問題",
		message_question: "請輸入問題",
		submit_question: "提交問題",
		label_phone: "手機號",
		place_phone: "請輸入手機號碼",
		message_phone: "請輸入手機號碼",
		label_password: "密碼",
		place_password: "請輸入密碼",
		message_password: "請輸入密碼",
		label_confirmPassword: "確認密碼",
		place_confirmPassword: "請再次輸入密碼",
		message_confirmPassword: "請再次輸入密碼",
		label_captcha: "驗證碼",
		place_captcha: "請輸入驗證碼",
		message_captcha: "請輸入驗證碼",
		get_captcha: "獲取驗證碼",
		label_inviteId: "邀請碼",
		place_inviteId: "邀請碼（選填）",
		to: "至",
		start_time: "開始時間",
		end_time: "結束時間",
		label_cardNumber: "證件號碼",
		place_cardNumber: "請輸入證件號碼",
		message_cardNumber: "請輸入證件號碼",
		label_cardMain: "證件正面",
		message_cardMain: "請輸入證件正面",
		label_cardBack: "證件反面",
		message_cardBack: "請輸入證件反面",
		confirm_modify: "確認修改",
		label_realName: "全名",
		place_realName: "請輸入您的名字",
		message_realName: "請輸入全名",
		label_firstName: "姓氏",
		place_firstName: "請輸入您的姓氏",
		message_firstName: "請輸入姓氏",
		label_lastName: "名稱",
		place_lastName: "請輸入您的中間名",
		message_lastName: "請輸入名稱",
		label_birthday: "出生日期",
		place_birthday: "請輸入您的出生日期",
		message_birthday: "請輸入出生日期",
		place_nowPsd: "請輸入當前密碼",
		message_nowPsd: "請輸入當前密碼",
		place_newPsd: "請輸入新密碼",
		message_newPsd: "請輸入新密碼",
		place_crmPsd: "請確認新密碼",
		message_crmPsd: "請確認新密碼",
		label_breed: "品種",
		place_breed: "請輸入品種",
		label_phase: "當",
		label_buyPrice: "買價",
		label_sellPrice: "賣價",
		label_do: "為",
		label_height: "高於",
		label_low: "低於",
		label_equal: "或等於",
		labelWalletName: "錢包名字",
		messageWalletName: "請輸入錢包名字",
		placeWalletName: "請輸入錢包名字",
		labelWalletAddress: "錢包地址",
		messageWalletAddress: "請輸入錢包地址",
		placeWalletAddress: "請輸入錢包地址",
		labelAmount: "金額",
		messageAmount: "請輸入金額",
		placeAmount: "請輸入金額",
		placeTimeLimit: "請輸入時間限制",
		messageTimeLimit: "請輸入時間限制",
	},
	pay: {
		title: "出入金便捷",
		p1: "多種常用支付通路，支持快速出入金",
		p2: "某些支付方式在您的國家/地區可能不可用",
		p3: "歡迎體驗StratTrade服務",
		btn: "立即開戶交易",
	},
	header: {
		tip1: "杠杆交易為複雜的金融產品，存在迅速虧損的高風險。",
		btn1: "交易平臺",
		btn2: "登出",
		btn3: "登入",
		BigUint64Array: "立即交易",
	},
	footer: {
		help: "聯繫我們",
		tip1: "關注我們的社群",
		tip2: "請注意，StratTrade沒有成立Telegram官方群，凡是以StratTrade名義組建的Telegram群組都有欺騙嫌疑。",
		tip3: "風險警告：交易有可能導致您損失全部資金。 場外衍生品交易並不適合所有人。 敬請在使用我們的服務前仔細閱讀我們的法律檔，並確保在交易前充分瞭解所涉及的風險。 您並不實際擁有或持有任何相關基礎資產。",
		tip4: "StratTrade不提供任何關於購買、持有或出售的建議、推薦或意見。 我們提供的所有產品都是以全球資產作為基礎的場外衍生品。 StratTrade提供的所有服務僅基於執行交易指令。",
		tip5: "StratTrade是由多家公司共同使用的業務品牌，透過以下公司進行運營：",
		tip6: "StratTrade International Ltd是本網站描述的或可提供使用的金融產品的發行人。 StratTrade International Ltd獲模裡西斯金融服務委員會（FSC）授權並受其監管，許可證號碼為GB20025791，註冊地址是：6 St Denis Street，1st Floor River Court，Port Louis 11328，Mauritius",
		tip7: "StratTrade Global Pty Ltd注册號碼為ABN 90 149 011 361，澳大利亞金融服務牌照（AFSL）號碼為398528。",
		tip8: "StratTrade Holding Ltd獲開曼群島金融管理局（CIMA）授權並受其監管，SIB牌照號碼為1612446。",
		tip9: "本網站資訊不針對美國，加拿大，日本，紐西蘭的居民； 本網站資訊也不適合由於其發佈或使用而違反當地法律或法規的任何國家或司法管轄區的任何人使用。 敬請注意英語是我們服務所使用的主要語言，亦是我們所有契约檔案中具有法律效力的語言。 其他語言譯本僅供參考，文譯如與英文有歧義，概以英文為准。",
		tip10: "SSL安全通訊加密。 ©  StratTrade版權所有，保留一切權利。",
		link1: "隱私政策",
		link2: "產品披露聲明",
		link3: "投訴流程",
		link4: "風險披露聲明",
		link5: "客戶協定",
		toTop: "置頂",
	},
	nav: {
		tit1: "產品",
		tit2: "交易",
		tit3: "投資學習",
		tit4: "關於我們",
		nav1: "外匯",
		nav2: "指數",
		nav3: "商品",
		nav4: "股票",
		nav5: "交易平臺",
		nav6: "交易策略",
		nav7: "交易觀點",
		nav8: "財經行事曆",
		nav9: "即時新聞",
		nav10: "實时報價",
		nav11: "情緒指數",
		nav12: "風險管理",
		nav13: "合約細則",
		nav14: "投資入門",
		nav15: "投資慧眼",
		nav16: "Academy",
		nav17: "關於StratTrade",
		nav18: "獎項及榮譽",
		nav19: "媒體中心",
		nav20: "資金安全",
		nav21: "收費與費用",
		nav22: "Affiliates",
		nav23: "聯絡我們",
		nav24: "常見問題",
		nav25: "幫助中心",
	},

	city: {
		albania: "阿爾巴尼亞",
		algeria: "阿爾及利亞",
		argentina: "阿根廷",
		armenia: "亞美尼亞",
		australia: "澳大利亞",
		pakistan: "巴基斯坦",
		austria: "奧地利",
		bahrain: "巴林",
		belgium: "比利時",
		bosnia_and_Herzegovina: "波黑",
		brazil: "巴西",
		brunei: "汶萊",
		bulgaria: "保加利亞",
		cambodia: "高棉",
		canada: "加拿大",
		cameroon: "喀麥隆",
		chile: "智利",
		colombia: "哥倫比亞",
		costa_Rica: "哥斯达黎加",
		croatia: "克羅地亞",
		cyprus: "賽普勒斯",
		czech_Republic: "捷克",
		denmark: "丹麥",
		dominican_Republic: "多米尼亞共和國",
		egypt: "埃及",
		estonia: "愛沙尼亞",
		ethiopia: "衣索比亞",
		finland: "芬蘭",
		france: "法國",
		georgia: "格魯吉亞",
		germany: "德國",
		ghana: "加納",
		greece: "希臘",
		guyana: "蓋亞那",
		honduras: "洪都拉斯",
		hong_Kong_China: "中國香港",
		hungary: "匈牙利",
		iceland: "冰島",
		ireland: "愛爾蘭",
		italy: "義大利",
		india: "印度",
		indonesia: "印尼",
		israel: "以色列",
		iran: "伊朗",
		iraq: "伊拉克",
		japan: "日本",
		kazakstan: "哈薩克共和國",
		kenya: "肯雅",
		korea: "韓國",
		kuwait: "科威特",
		kyrgyzstan: "吉爾吉斯斯坦",
		laos: "老撾",
		latvia: "拉脫維亞",
		lithuania: "立陶宛",
		luxembourg: "盧森堡",
		macao_China: "中國澳門",
		macedonia: "馬其頓",
		malaysia: "馬來西亞",
		malta: "馬爾他",
		mexico: "墨西哥",
		moldova: "莫爾達瓦",
		monaco: "摩納哥",
		mongolia: "蒙古",
		montenegro: "黑山",
		morocco: "摩洛哥",
		myanmar: "緬甸",
		netherlands: "荷蘭",
		new_Zealand: "紐西蘭",
		nepal: "尼泊爾",
		nigeria: "奈及利亞",
		norway: "挪威",
		oman: "阿曼",
		palestine: "巴勒斯坦",
		panama: "巴拿馬",
		paraguay: "巴拉圭",
		peru: "秘魯",
		philippines: "菲律賓",
		poland: "波蘭",
		portugal: "葡萄牙",
		puerto_Rico: "波多黎各",
		qatar: "卡塔爾",
		romania: "羅馬尼亞",
		russia: "俄羅斯",
		republic_of_Trinidad_and_Tobago: "千裡達托貝哥",
		rwanda: "盧安達",
		saudi_Arabia: "沙烏地阿拉伯",
		serbia: "塞爾維亞",
		singapore: "新加坡",
		slovakia: "斯洛伐克",
		slovenia: "斯洛文尼亞",
		south_Africa: "南非",
		spain: "西班牙",
		sri_Lanka: "斯里蘭卡",
		sweden: "瑞典",
		switzerland: "瑞士",
		taiwan_China: "中國臺灣",
		tajikistan: "塔吉克共和國",
		tanzania: "坦尚尼亞",
		thailand: "泰國",
		turkey: "土耳其",
		turkmenistan: "土庫曼共和國",
		ukraine: "烏克蘭",
		united_Arab_Emirates: "阿聯酋",
		united_Kingdom: "英國",
		united_States: "美國",
		uzbekistan: "烏茲別克",
		venezuela: "委內瑞拉",
		vietnam: "越南",
		afghanistan: "阿富汗",
		angola: "安哥拉",
		azerbaijan: "阿塞拜疆",
		bangladesh: "孟加拉",
		belarus: "白俄羅斯",
		belize: "貝裡斯",
		benin: "貝南",
		bhutan: "不丹",
		bolivia: "玻利維亞",
		botswana: "波劄那",
		british_Virgin_Islands: "英屬維京群島",
		burkina_Faso: "伯基納法索",
		burundi: "布隆迪",
		cape_Verde: "佛得角",
		cayman_Islands: "開曼群島",
		central_African_Republic: "中非共和國",
		chad: "查德",
		comoros: "科摩羅",
		the_Republic_of_the_Congo: "剛果（布）",
		democratic_Republic_of_the_Congo: "剛果（金）",
		djibouti: "吉佈提",
		ecuador: "厄瓜多尔",
		el_Salvador: "薩爾瓦多",
		equatorial_Guinea: "赤道幾內亞",
		eritrea: "厄利垂亞",
		fiji: "斐濟",
		gabon: "加蓬",
		gambia: "岡比亞",
		greenland: "格陵蘭",
		guatemala: "瓜地馬拉",
		guinea: "幾內亞",
		haiti: "海地",
		isle_of_Man: "曼島",
		cote_d_Ivoire: "象牙海岸",
		jamaica: "牙買加",
		jordan: "約旦",
		lebanon: "黎巴嫩",
		lesotho: "萊索托",
		liberia: "賴比瑞亞",
		libya: "利比亞",
		madagascar: "馬拉加西",
		malawi: "馬拉威",
		maldives: "瑪律地夫",
		mali: "馬利",
		mauritania: "茅利塔尼亞",
		mauritius: "模裡西斯",
		mozambique: "莫三比克",
		namibia: "納米比亞",
		nicaragua: "尼加拉瓜",
		republic_of_Niger: "尼日尔",
		north_Korea: "朝鮮",
		reunion: "留尼汪",
		san_Marino: "聖馬利諾",
		senegal: "塞內加爾",
		sierra_Leone: "塞拉里昂",
		somalia: "索馬利亞",
		sudan: "蘇丹",
		suriname: "蘇利南",
		eswatini: "史瓦濟蘭",
		syria: "敘利亞",
		togo: "多哥",
		tonga: "東加",
		tunisia: "突尼斯",
		united_States_Virgin_Islands: "美屬維爾京群島",
		uganda: "烏干達",
		uruguay: "烏拉圭",
		vatican: "梵蒂岡",
		yemen: "葉門",
		yugoslavia: "南斯拉夫",
		zambia: "尚比亞",
		zimbabwe: "辛巴威",
		china: "中國",
	}
};
