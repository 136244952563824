export default {
    common: {
        learn: {
            title1: "Primärsaldo",
            content1:
                "StratTrade bietet einen kostenlosen Bargeld- und Hosted Cryptocurrency-Guthaben-Service an, der es Ihnen ermöglicht, Ihr Bargeld und die unterstützten Kryptowährungen ohne Kosten zu speichern. Sie können Kryptowährungen vom Primärguthaben eines StratTrade-Benutzers auf das Primärguthaben eines anderen Benutzers übertragen, ohne dass Gebühren anfallen.\n" +
                "\n" +
                "Gebühren für Netzwerktransaktionen\n" +
                "\n" +
                "Für Transaktionen in Kryptowährungsnetzwerken (d.h. Übertragungen von Kryptowährungen außerhalb der StratTrade-Plattform) fallen bei StratTrade Netzwerk-Transaktionsgebühren (z.B. Miner-Gebühren) an und werden bezahlt. Wenn Sie Kryptowährung von Ihrer StratTrade-Brieftasche an eine andere Brieftasche senden, berechnen wir eine Gebühr, die auf unserer Schätzung der vorherrschenden Netzwerkgebühren für einen eigenständigen Versand von Brieftasche zu Brieftasche basiert. Die endgültige Gebühr, die StratTrade zahlt, kann jedoch von der geschätzten Gebühr aufgrund von Faktoren wie Stapeltransaktionen oder Änderungen der Netzwerküberlastung abweichen. Alle Gebühren werden zum Zeitpunkt der Transaktion bekannt gegeben.\n" +
                "\n" +
                "Effizienzgewinne und Netzgebühren\n" +
                "\n" +
                "Aufgrund potenzieller Effizienzgewinne durch das Stapeln von Transaktionen kann der Gesamtbetrag der geschätzten Netzgebühren, die von den Nutzern innerhalb eines bestimmten Stapels gezahlt werden, die endgültige von StratTrade gezahlte Netzgebühr übersteigen.\n" +
                "\n" +
                "Lightning Network Bearbeitungsgebühr\n" +
                "\n" +
                "Das Lightning Network ist ein Micropayment-Netzwerk, das die Übertragung von Bitcoin ermöglicht, ohne dass die mit der Bitcoin-Blockchain verbundenen Transaktionsgebühren anfallen. Wenn Sie Bitcoin von StratTrade über das Lightning Network senden, berechnen wir eine Bearbeitungsgebühr in Höhe von 0,1 % des übertragenen Bitcoin-Betrags.\n" +
                "\n" +
                "Hinzufügen von Bargeld und Auszahlung\n" +
                "\n" +
                "Wenn Sie Bargeld ein- oder auszahlen lassen, kann je nach der von Ihnen gewählten Zahlungsmethode eine Gebühr anfallen. Es ist wichtig, dass Sie sich vor Abschluss der Transaktion über die anfallenden Gebühren informieren, damit Sie wissen, welche Kosten damit verbunden sind.\n" +
                "\n" +
                "Kreditgeschäfte\n" +
                "\n" +
                "Wenn Sie sich USD von StratTrade oder einer Tochtergesellschaft von StratTrade leihen und wir Ihre BTC-Sicherheiten verkaufen müssen (wie in einem anwendbaren Darlehensvertrag genehmigt), berechnen wir eine Pauschalgebühr von 2 % der gesamten Transaktion.",
            title2: "Handelsgebühren und Spread",
            content2:
                "StratTrade-Gebühren\n" +
                "\n" +
                "Wenn Sie Kryptowährungen auf StratTrade kaufen, verkaufen oder konvertieren, werden Gebühren erhoben. Diese Gebühren werden zum Zeitpunkt Ihrer Bestellung berechnet und können durch Faktoren wie die von Ihnen gewählte Zahlungsmethode, die Größe der Bestellung, die Marktbedingungen, den Gerichtsstand und andere Kosten, die uns entstehen, um Ihre Transaktion zu erleichtern, beeinflusst werden.\n" +
                "\n" +
                "Bevor Sie Ihre Transaktion einreichen, können Sie die Gebühren in der Handelsvorschau einsehen. Bitte beachten Sie, dass die Gebühren für ähnliche Transaktionen variieren können. Wenn Sie einen Auftrag abschließen, erklären Sie sich damit einverstanden, die geltenden StratTrade-Gebühren für diese Transaktion zu zahlen.\n" +
                "\n" +
                "Verbreitung\n" +
                "\n" +
                "Wenn Sie einfache Kauf- und Verkaufsaufträge erteilen, schließt StratTrade einen Spread in den angebotenen Preis ein. Der Spread ist auch im Wechselkurs enthalten, wenn Sie von einer Kryptowährung in eine andere umwandeln.\n" +
                "\n" +
                "Um den Spread vor der Vorschau eines Kauf-, Verkaufs- oder Umwandlungsauftrags zu sehen, können Sie auf dem Vorschaubildschirm:\n" +
                "\n" +
                "Kauf- und Verkaufsaufträge: Tippen Sie auf den Tooltip neben der Preisposition Ihres Vermögenswerts, um den Spread anzuzeigen.\n" +
                "\n" +
                "Aufträge umrechnen: Tippen Sie auf den Tooltip neben dem von Ihnen angegebenen Wechselkurs.\n" +
                "\n" +
                "Der Spread trägt dazu bei, die Wahrscheinlichkeit einer erfolgreichen Transaktion zu erhöhen und ermöglicht es StratTrade, den von Ihnen angegebenen Preis vorübergehend festzuhalten, während Ihr Auftrag bearbeitet wird. StratTrade kann jeden überschüssigen Spread aus einer Transaktion einbehalten. Bitte beachten Sie, dass der Spread für ähnliche Transaktionen variieren kann.\n" +
                "\n" +
                "StratTrade Fortgeschrittene\n" +
                "\n" +
                "Für StratTrade Advanced ist kein Spread enthalten, da Sie direkt mit dem Orderbuch interagieren.\n" +
                "\n" +
                "StratTrade One\n" +
                "\n" +
                "StratTrade One ist ein Abonnementprodukt, das den gebührenfreien Kauf und Verkauf von Kryptowährungen bietet, allerdings mit gewissen Einschränkungen. Die Mitglieder können immer noch einen Spread in ihren notierten Preisen haben.\n" +
                "\n" +
                "Änderungen der Handelsgebühren und des Spreads\n" +
                "\n" +
                "StratTrade testet gelegentlich Änderungen an Gebühren und Spreads. Diese Änderungen können in verschiedenen Regionen, Vermögenswerten, Auftragsgrößen und Arten von Trades eingeführt werden.\n" +
                "\n" +
                "StratTrade zeigt Ihnen den Gesamtbetrag, einschließlich Spread und Gebühren, jedes Mal, wenn Sie einen Handel in der Vorschau anzeigen und danach in Ihrem Transaktionsverlauf. \n" +
                "\n" +
                "Ausführung des Handels\n" +
                "\n" +
                "Alle Trades aus Ihrem Primärguthaben werden über das zentrale Limit-Orderbuch von StratTrade Exchange oder über wettbewerbsfähige Auktionen ausgeführt. Sie können das Orderbuch eines Marktes, die Geld- und Briefkurse sowie die Tiefe in Echtzeit einsehen. Diese Orderbücher bestimmen die Preise, die Sie während des Handels sehen.\n" +
                "\n" +
                "StratTrade unterhält keine Payment-for-Order-Flow (PFOF)-Beziehungen zu Market Makern.",
            title3: "StratTrade-Karte",
            content3:
                "Bei Ausgaben mit der StratTrade Card fallen keine Transaktionsgebühren an. \n" +
                "\n" +
                "StratTrade schließt einen Spread in den Preis für den Kauf oder Verkauf von Kryptowährungen ein. Dies ermöglicht es uns, vorübergehend einen Preis für die Handelsausführung festzulegen, während Sie die Transaktionsdetails überprüfen, bevor Sie Ihre Transaktion einreichen.  \n" +
                "\n" +
                "Ihnen können vom Geldautomatenbetreiber Gebühren berechnet werden. Weitere Einzelheiten entnehmen Sie bitte Ihrer StratTrade-Karteninhabervereinbarung.",
            title4: "Absteckdienste",
            content4:
                "Es gibt keine Gebühr für das Einsetzen oder Aufheben von Einsätzen. StratTrade nimmt eine Provision basierend auf den Belohnungen, die Sie vom Netzwerk erhalten. Unsere Standardprovision beträgt 35% für ADA, ATOM, DOT, MATIC, SOL und XTZ. Für ADA, ATOM, DOT, SOL und XTZ beträgt die Provision 26,3% für berechtigte StratTrade One Mitglieder. Sie können Ihren ETH-Provisionssatz in den Asset-Details finden. Einige Nutzer können Angebote für Staking-Aktionen mit niedrigeren Sätzen erhalten. Der APY und die Rewards, die in Ihrem Konto angezeigt werden, spiegeln immer den Gewinn wider, den Sie nach unserer Provision erhalten. Weitere Informationen über Provisionen finden Sie in der StratTrade-Nutzungsvereinbarung.",
            title5: "Einziehung von Vermögenswerten",
            content5:
                "Wenn Sie eine nicht unterstützte Kryptowährung an Ihr StratTrade-Konto gesendet haben, kann diese möglicherweise wiederhergestellt werden. Wir werden eine Netzwerkgebühr für den Wiederherstellungsversuch berechnen. Für Wiederherstellungen mit einem geschätzten Wert von mehr als $100, werden wir auch eine 5%ige Wiederherstellungsgebühr auf den Betrag über $100 berechnen. Der geschätzte Wert der Rückgewinnung kann vom tatsächlichen Marktwert der Rückgewinnung abweichen.",
        },
        award1: "Asiens bester Differenzkontrakt-Makler im Jahr 2023",
        award2: "2022 Global bester Broker",
        award3: "Der vertrauenswürdigste Makler im Jahr 2022",
        award4: "Die vertrauenswürdigste Marke des Jahres 2021",
        award5: "Bester Finanztechnologie-Broker",
        award6: "Der transparenteste Broker für Transaktionen",
        analysis: "Analyse",
        health: "Gesundheit",
        country: "Land",
        information: "Informationen",
        help: "Hilfe",
        confirm: "Erkennen Sie",
        cancel: "Abschaffung von",
        tip: "Aufforderungen",
        place_select: "Bitte auswählen",
        placeEnter: "Bitte eingeben",
        countryCode: "Ländercode",
        submit: "Einreichen ",
        more: "Mehr",
        deposit: "Einzahlung",
        real_trade: "Real Deal",
        paper_trade: "Demo-Handel",
        build_paperTrade: "Ein Demokonto erstellen",
        start_trade: "Jetzt mit dem Handel beginnen",
        app_name: "StratTrade",
        success: "Erfolge！",
        dataSources_name: "Handelszentrale",
        reset: "Reprovision",
        iknow: "Verstanden!",
        noData: "Keine Daten verfügbar",
        modify: "bearbeiten",
        pingcang: "Eine Position schließen",
        all: "Vollständig",
        tip_cardMain: "Machen Sie ein Foto von der Vorderseite Ihres Personalausweises oder laden Sie es hoch.",
        tip_cardBack: "Machen Sie ein Foto von der HINTERSEITE Ihres Personalausweises oder laden Sie es hoch",
        tip_cardMain1:
            "Machen Sie ein Foto von der Vorderseite Ihres Führerscheins oder laden Sie es hoch.",
        tip_cardBack1:
            "Machen Sie ein Foto von der HINTERSEITE Ihres Führerscheins oder laden Sie es hoch.",
        tip_cardMain2: "Nehmen Sie ein Foto Ihres Reisepasses auf oder laden Sie es hoch",
        tip_cardBack2: "Nehmen Sie ein Foto Ihres Reisepasses auf oder laden Sie es hoch",
    },
    currency: {
        btcusdt: "Bitcoin",
        ethusdt: "Etherlands",
        ltcusdt: "Litecoin",
        xrpusdt: "Ripple (Münze)",
        adausdt: "Ada-Währung",
        eosusdt: "Pomelo-Münze",
        dotusdt: "Boca-Münze",
        trxusdt: "Wellenfeldmünze",
        xmrusdt: "Monroe-Münze",
        dogeusdt: "Dogecoin",
        bnbusdt: "BNB",
        solusdt: "SOL",
        aptusdt: "Aptusdt",
        fx_saudusd: "AUD USD",
        fx_sgbpusd: "GBP USD",
        fx_seurusd: "Eurodollar",
        fx_snzdusd: "New Zealand Dollar (NZD) USD",
        fx_susdcad: "USD CAD",
        fx_susdchf: "USD CHF",
        fx_susdjpy: "USD JPY",
        fx_saudcad: "AUD CAD",
        fx_saudchf: "AUD CHF",
        fx_saudjpy: "AUD JPY",
        fx_saudnzd: "AUD New Zealand Dollar (NZD)",
        fx_scadchf: "CAD Swiss Franc",
        fx_scadjpy: "Canadian yen",
        fx_schfjpy: "CHF JPY",
        fx_seuraud: "EUR AUD",
        fx_seurcad: "EUR CAD",
        fx_seurgbp: "Euro Pound",
        fx_seurjpy: "Euro Yen",
        fx_seurnzd: "Euro New Zealand dollar (NZD)",
        fx_sgbpaud: "GBP AUD",
        fx_sgbpchf: "GBP CHF",
        fx_sgbpjpy: "GBP JPY",
        fx_sgbpnzd: "GBP NZD",
        fx_scadnzd: "Kanadische und neuseeländische Dollar",
        hf_CL: "WTI New Yorker Rohöl",
        hf_OIL: "Rohöl der Sorte Brent",
        hf_GC: "New Yorker Gold",
        hf_SI: "New Yorker Silber",
        hf_HG: "Kupfer (US)",
        hf_NG: "U.S.-Erdgas",
        hf_CAD: "Kupfer (Chemie)",
        hf_AHD: "Londoner Aluminium",
        HX_AAPL: "Granatapfel",
        HX_BABA: "Alibaba, chinesisches Unternehmen für elektronischen Handel",
        HX_GOOG: "Google",
        HX_WMT: "Wal-Mart, Walmart (Einzelhändler)",
        HX_AMZN: "HX_AMZN",
        HX_TSLA: "Tesla",
        HX_N225: "Nikkei 225",
        HX_GDAXI: "DAX30, Deutschland",
        HX_IBEX: "IBEX35, Spanien",
        HX_SPX: "S&P 500 index",
        HX_FTSE: "FTSE 100",
        HX_KS11: "KOSPI Korea",
        HX_NZ50: "New Zealand 50",
        HX_PSI20: "Portugal PSI20",
        hf_ZSD: "London Zinc",
        hf_NID: "London Nickel",
        hf_PBD: "London lead",
        hf_SND: "London tin",
        hf_XAU: "London Gold (Spot Gold)",
        hf_XAG: "London Silver (Spot Silver)",
        hf_XPT: "Platin-Futures",
        hf_XPD: "Palladium Futures",
        hf_CT: "US Cotton",
        hf_SM: "Amerikanisches Sojabohnenmehl",
        hf_BO: "U.S. Sojabohnenöl",
        HX_SENSEX: "SENSEX, Mumbai, India",
        HX_KLSE: "FTSE Malaysia KLCI",
        HX_KSE100: "Karachi, Pakistan",
        HX_FCHI: "CAC40, France",
        HX_SX5E: "Europäisches Snooker 50",
        HX_ICEXI: "ICEX, Island",
        HX_ASE: "ASE Athens, Griechenland",
        HX_OMXC20: "OMX Kopenhagen 20",
        HX_OSEBX: "OSEBX, Norwegen",
        HX_OMXSPI: "OMXSPI, Schweden",
        HX_AMD: "Chaowei Halbleiter",
        HX_DIS: "Disney (name)",
        HX_MELI: "MercadoLibre Inc",
        HX_PSX: "Phillips 66",
        HX_TRUE: "TrueCar Inc",
        HX_SDRL: "Seadrill Ltd",
        HX_MA: "MasterCard (brand)",
        HX_NVDA: "NVIDIA, Computer-Grafikkarten-Unternehmen",
        HX_INTC: "Intel",
        HX_GOGO: "Gogo Inc",
        HX_STX: "Seagate-Technologie",
        usdcusdt: "USDC",
        filusdt: "FIL",
        maticusdt: "MATIC",
        linkusdt: "LINK",
        avaxusdt: "AVAX",
        atomusdt: "AstroCoin",
        icpusdt: "ICP",
        uniusdt: "UNI",
    },
    currenctType: {
        title: "Bitte wählen Sie eine Währung",
        currenctType6: "USDT-ERC20",
        currenctType7: "USDT-TRC20",
        currenctType8: "BTC",
        currenctType13: "ETH",
        currenctType14: "Dollar",
        currenctType15: "Hong Kong currency",
        currenctType16: "Taiwan currency",
        currenctType17: "Ringgit",
        currenctType18: "Singapore dollar",
        currenctType19: "Yen",
        currenctType20: "Euro",
        currenctType21: "Dollar A",
        currenctType22: "Rupiah",
        currenctType23: "Pound",
        currenctType24: "Thai baht",
        currenctType25: "Dollar",
        currenctType26: "VND",
        currenctType27: "Korean won",
        currenctType28: "MOP",
    },
    addnew2: {
        address: {
            account_type: "Konto-Typ",
            ifsc_code: "IFSC CODE",
            title: "Adresse der Brieftasche",
            account_number: "Kontonummer",
            add_btn: "Adresse hinzufügen",
            label_type: "Typ auswählen",
            place_type: "Bitte Typ auswählen",
            label_number: "Konto",
            place_number: "Bitte geben Sie die Kontonummer ein",
            wallet_address: "Adresse der Brieftasche",
            bank_name: "NAME DER BANK",
            bank_address: "Adresse der Bank",
            name: "Name",
            payee_address: "Adresse des Begünstigten",
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Name der Niederlassung",
            branch_code: "BRANCHENCODE",
            bank_code: "Bankleitzahl",
            phone: "Handynummer",
            iban: "IBAN",
            sort_code: "Sortiercode",
            place_inner: "Bitte Inhalt eingeben",
        },
    },
    addnew: {
        paypassTit: "Auszahlungspasswort ändern",
        paypassTit1: "Bitte legen Sie Ihr Abhebungspasswort fest",
        paypassTit2: "Initialisieren Sie Ihr Passwort mit Ihrem Anmeldepasswort.",
        loan: "Darlehen",
        repaid: "Erstattet",
        unpaid: "Unbezahlt",
        loanMore: "Ich brauche ein Darlehen.",
        immediateRepayment: "Sofortige Rückzahlung",
        accountType1: "Krypto-Konto",
        accountType2: "Vertragskonto",
        accountType3: "Optionskonten",
        from: "Befolgen Sie ",
        to: "An",
        get: "Bekommt",
        labelBreed: "Währung",
        placeBreed: "Bitte wählen Sie eine Währung",
        labelTransAccount: "Konto",
        labelAmount: "Betrag",
        placeAmount: "Bitte geben Sie den Überweisungsbetrag ein",
        transferTit: "Übertragung",
        convertTit: "Konvertieren",
        balanceTit: "Geschätzter Saldo",
        available: "Verfügbar",
        pending: "Mit einem Riegel schließen",
        equivalent: "Gleichwertig sein mit",
        coinTrade: "Krypto-Handel",
        secondContract: "Zweiter Vertrag",
        number: "Mengen",
        labelNumber: "Mengen",
        placeNumber: "Bitte geben Sie die Anzahl der Lösegelder ein",
        time: "Timing",
        loadAmount: "Erwarteter Darlehensbetrag",
        repaymentCycle: "Zyklus der Darlehensrückzahlung",
        dailyRate: "Tagessatz",
        repaymentMethod: "Rückzahlungsmethode",
        loanTip: "Kreditdarlehen (bitte stellen Sie sicher, dass das Bild gut sichtbar ist)",
        loanTip1: "Eigentumsnachweis hochladen",
        loanTip2: "Einkommensnachweis (Beschäftigung)",
        loanTip3: "Einzelheiten zu den Kontoauszügen",
        loanTip4: "Foto der Vorderseite Ihres Personalausweises hochladen",
        interest: "Interesse (Wunsch, etwas zu wissen)",
        repaymentMethod1: "Fällig zur Rückzahlung in einem Pauschalbetrag",
    },
    params: {
        product_foreign: "Forex",
        product_shop: "Rohstoffe",
        product_number: "Indizes",
        product_stock: "Lagerbestand",
        product_coin: "Krypto",
        billTypes: [
            { v: 101, name: "Aufladen" },
            { v: 102, name: "Einzahlung" },
            { v: 103, name: "Gefroren" },
            { v: 104, name: "Auftauen" },
            { v: 105, name: "Einzahlung" },
            { v: 106, name: "Einzahlung" },
            { v: 201, name: "Abhebungsstopp" },
            { v: 202, name: "Geld abheben" },
            { v: 203, name: "Rückzug Erfolgreich" },
            { v: 204, name: "Geld nicht abheben" },
            { v: 205, name: "Rücknahmegebühr" },
            { v: 206, name: "Übertragung aus" },
            { v: 207, name: "Verschieben nach" },
            { v: 208, name: "Münzübertragung" },
            { v: 209, name: "Münzübertragung" },
            { v: 301, name: "Vertragsgebühr" },
            { v: 302, name: "Vertragliche Einkünfte" },
            { v: 303, name: "Vertragsverlust" },
            { v: 304, name: "Vertragsspanne" },
            { v: 305, name: "Vertragsspanne Rendite" },
            { v: 311, name: "Kaufoptionen" },
            { v: 312, name: "Einkommen aus Optionen" },
            { v: 313, name: "Option Rückgabe" },
            { v: 314, name: "Option Bearbeitungsgebühr" },
            { v: 315, name: "Münzkaufstopp" },
            { v: 316, name: "Abzug für Münzkäufe" },
            { v: 317, name: "Münzkauf-Rückgaben" },
            { v: 318, name: "Münzkauf auf Konto" },
            { v: 319, name: "Münzkauf auf Konto" },
            { v: 320, name: "Münzverkaufsstopp" },
            { v: 321, name: "Abzug für Münzverkauf" },
            { v: 322, name: "Münzen verkaufen Retouren" },
            { v: 323, name: "Münze Verkaufen an Konto" },
            { v: 324, name: "Münze Verkaufen an Konto" },
            { v: 325, name: "Gebühr für Münzverarbeitung" },
            { v: 401, name: "Bergleute schließen sich an" },
            { v: 402, name: "Bergwerksrückkehr" },
            { v: 403, name: "Einnahmen aus dem Bergbau" },
            { v: 404, name: "Bergbau Exit" },
            { v: 405, name: "Ausstiegsgebühr für den Bergbau" },
            { v: 411, name: "Einnahmen aus Anleihen" },
            { v: 412, name: "Leihgebühr" },
            { v: 413, name: "Erfolgreiche Rückzahlung" },
        ],
    },
    message: {
        logout: "Abmelden",
        register_success: "Erfolgreiche Registrierung",
        place_inner_personal: "Bitte geben Sie zuerst Ihre persönlichen Daten ein",
        submit_success: "Erfolgreich abgeschickt！",
        copy_success: "Erfolg kopieren！",
        copy_error: "Fehlgeschlagene Fortpflanzung！",
        modify_success: "Erfolgreich modifiziert",
        no_balance: "Unzureichendes Guthaben zur Deckung der Kaution",
        palce_password: "Bitte geben Sie Ihr Passwort ein",
        trade_success: "Erfolgreiche Transaktion",
        placeEnterAmount: "Bitte geben Sie den Betrag ein",
        tipTit1: "Sind Sie sicher, dass Sie für diese Bestellung bezahlen möchten?",
    },
    web: {
        account: {
            menu1: "Konto-Informationen",
            menu2: "Mein Vermögen",
            menu3: "Konto-Berichte",
            menu4: "Konto Sicherheit",
            menu5: "Benachrichtigungen",
            menu6: "Aktivitätszentren",
            menu7: "Mein Portemonnaie",
            menu8: "Zurückziehen",
            walletAdd: "Neue Geldbörse hinzufügen",
            report: {
                history_tit: "Transaktionsverlaufsbericht",
                stream_tit: "Bericht über die Mittelflüsse",
                desc: "Bitte wählen Sie den entsprechenden Datumsbereich aus, um den Kontobericht zu exportieren. (Sie haben Zugriff auf die Kontoinformationen der letzten 1 Jahr)",
                select_date: "Datumsbereich auswählen",
                week: "Letzte Woche",
                month: "Etwa ein Monat",
                threeMonth: "Drei Monate",
                sixMonth: "Sechs Monate",
                time: "Timing",
                view_report: "Bericht anzeigen",
                email_send: "Per E-Mail versenden",
                dialog_tit: "Konto-Berichte",
                dialog_p1:
                    "Die StratTrade Holding Ltd ist auf den Cayman Islands registriert (SIB-Nummer: 1612446) und wickelt Ihre Transaktionen als Handelsunternehmen ab.",
                tit2: "Mitade Transaktionsverlaufsbericht",
                tit4: "Mitade Bericht über die Mittelflüsse",
                tit3: "Konto-Informationen",
                label1: "Name des Kontos",
                label2: "Konto-ID",
                label3: "Konto Währung",
                label4: "Meldezeit",
                label5: "Berichtszeitraum",
                label6: "Cash flow",
                empty: "Sie haben keine abgeschlossenen Geschäfte",
                email_des: "Der Bericht wird an Ihre E-Mail Adresse geschickt",
                tab1: "Berichterstattung",
                tab2: "Tägliche Erklärung",
                tab3: "Monatliche Aufstellung",
            },
            account: {
                tit1: "Willkommen bei StratTrade",
                tit2: "Sie können Einzahlungen vornehmen und in der Live-Umgebung handeln, sobald Sie Ihre ID-Verifizierung abgeschlossen haben.",
                p1: "Live-Konto eröffnen",
                p2: "Identitätsüberprüfung",
                btn1: "ID-Überprüfung",
                tit3: "Persönliche Informationen",
                label_name: "Namen",
                label_email: "E-mail",
                label_address: "Wohnanschrift",
                tit4: "Konto-Informationen",
                label_account: "Konto-Nr.",
                label_currency: "Basiswährung",
                label_company: "Aktuelle Dienststelle",
                company_tip:
                    "Die StratTrade Holding Ltd of Companies ist von der Cayman Islands Monetary Authority (CIMA) lizenziert und zugelassen.",
                tit5: "Live-Konto eröffnen",
                tit6: "Vollständiges Profil",
                tit7: "Vervollständigen Sie Ihre Basisinformationen",
                tit8: "Risikobewertung",
                tit9: "Beurteilen Sie Ihre Risikotragfähigkeit",
                tit10: "Identitätsüberprüfung",
                tit11:
                    "Vervollständigen Sie Ihre ID-Verifizierung in Übereinstimmung mit den gesetzlichen Anforderungen",
                p3: "Bitte wählen Sie einen der folgenden Nachweise zur Identitätsprüfung aus",
                type1: "Nationale ID",
                type2: "Führerschein",
                type3: "Reisepass",
                p4: "Ich bestätige, dass alle bereitgestellten Informationen und Dokumente vollständig, wahrheitsgemäß und korrekt sind. Ich bestätige, dass ich Mitrade innerhalb von 30 Tagen über etwaige Änderungen der angegebenen Informationen informieren werde.",
                next: "Nächster Schritt",
                btn2: "Zertifiziert",
                modify_personal_tit: "Ihre Informationen bearbeiten",
            },
            activity: {
                tit: "Aktivitätszentren",
                desc: "Sie können an Aktivitäten teilnehmen, um Belohnungen zu erhalten, die Ihr Investitionseinkommen erhöhen",
            },
            capital: {
                real_trade: "Real Deal",
                label_nowAccount: "Girokonto",
                label_tradeAccount: "Handelskonto",
                label_currency: "Basiswährung",
                deposit_withdraw_record: "Aufzeichnungen über Einzahlungen und Abhebungen",
                capital_flow_details: "Details zum Geldfluss",
            },
            notification: {
                tit: "Einstellungen für Benachrichtigungen",
                tit1: "Offene Benachrichtigungskanäle",
                email: "E-Mail Adresse",
                message: "Textnachrichten",
                push: "Schieben Sie",
                verify: "Validieren (einer Theorie)",
                verified: "Validieren (einer Theorie)",
                p1: "Bitte öffnen/überprüfen Sie die folgenden Benachrichtigungskanäle, um über wichtige Neuigkeiten informiert zu werden!",
                type1: "Marketing",
                tit2: "Benachrichtigung über Marketingaktivitäten",
                desc2:
                    "Spezielle Informationen der Plattform, Betriebsaktivitäten und andere Neuigkeiten",
                tit3: "Betriebsanleitung",
                desc3: "Reichhaltiges und spezialisiertes Wissensmaterial",
            },
            security: {
                tit: "Kryptographisch",
                isSet: "Konfiguriert",
                set: "Machen Sie weiter und richten Sie",
                verify: "Validieren (einer Theorie)",
                verified: "Validiert",
                tit1: "Sicherheitsbescheinigung",
                email: "E-Mail Adresse",
                phone: "Telefonnummer des Mobiltelefons",
                phone_tit: "Handy-Nummer Bindung",
                password_tit: "Ändern Sie Ihr Passwort",
            },
        },
        market: {
            hot: "Beliebt",
            prev: "Vorangehende Seite",
            next: "Nächste Seite",
            line: "Time-sharing",
            main_subplot: "Haupt- und stellvertretende Diagrammindikatoren",
            main: "Hauptdiagrammindikator",
            subplot: "Chart-Indikator",
            go_trade: "Weiter zur Transaktionsseite",
        },
        news: {
            tip: "Haftungsausschluss: Die obigen Informationen dienen nur als allgemeine Referenz und sollten nicht als Grundlage für Handelsentscheidungen verwendet werden..",
        },
        trade: {
            state1: "Bestände",
            state2: "Ausstehender Auftrag",
            state3: "Historien",
            state: "Modus",
            state4: "Geschlossene Position",
            state5: "Aufgehoben",
            type: "Typologie",
            type1: "Vollständig",
            type2: "Verkaufen",
            type3: "Kaufen",
            left_label1: "Selbstbedienung",
            left_label2: "Kürzlich gesehen",
            left_type1: "Kompakter Säulenmodus",
            left_type2: "Lose säulenförmiges Muster",
            left_type3: "Tabelle anzeigen",
            all: "Vollständig",
            right_bp: "Die Ernte von gestern",
            right_sp: "Moderne Zeit",
            right_height: "Supreme",
            right_low: "Niedrigste",
            remind_tit: "Neue Warnungen",
            remindList: "Erinnerungsliste",
            remind_btn: "Neue Warnungen",
            right_tab1: "Geschäfte",
            right_tab2: "Einzelheiten",
            right_sell: "Verkaufen",
            right_buy: "Kaufen",
            right_type: "Typologie",
            right_type1: "Marktliste",
            right_type2: "Ausstehender Auftrag",
            right_priceMoney: "Preis des ausstehenden Auftrags",
            right_pointerNumber: "Anzahl (Lose)",
            right_lever: "Brechstange",
            right_balance: "Verfügbar",
            right_stop_profit: "Gewinn mitnehmen",
            right_stop_loss: "Stop-loss",
            right_profit_loss: "P/L",
            now_order: "Jetzt bestellen",
            confirm_order: "Bestätigung der Bestellung",
            right_tit1: "Handelsstrategien",
            right_rinei: "In ein paar Tagen",
            right_short: "Kurzfristig",
            right_middle: "Mittelfristig",
            right_time: "Freigabezeit",
            right_tit2: "Alternative Strategie",
            right_tit3: "Technische Überprüfung",
            right_tit4: "Digital",
            right_tit5: "Stimmung im Handel",
            right_label_sell: "Verkäufer (von Waren)",
            right_label_buy: "Käufer",
            right_tip:
                "Nur zu Informationszwecken, keine Stellungnahme der Abteilung",
            right_tip2: "Alle 15 Minuten aktualisiert",
            right_tit6: "Preisvariation",
            minute: "Protokoll",
            today: "Zum gegenwärtigen Zeitpunkt",
            right_tit7: "Bereich der Preisänderung",
            now_price: "Aktuelle Preise",
            right_low_price: "Niedrigster Preis",
            right_height_price: "Höchster Preis",
            right_tit8: "Informationen zum Vertrag",
            right_tit9: "AUD/CHF",
            right_label1: "Anzahl der einzelnen Transaktionen",
            right_label2: "Maximale Hebelwirkung",
            right_label3: "Größe des Vertrags",
            right_label4: "Maximale Gesamtzahl von Positionen",
            right_label5: "Gleitende Spanne",
            right_label6: "Nachtladung",
            hand: "Hand",
            right_collection_time: "Erhebungszeit",
            right_tip3: "Gebührenbasis: aus dem Gesamtvolumen der Transaktion",
            right_label7: "Margenverhältnis",
            right_label8: "Instandhaltungskostenquote",
            right_label9: "Handelszeiten",
            right_label10: "Aktuelle Handelssitzung",
            right_label11: "Nächste Handelssitzung",
        },
    },
    home: {
        banner_p1: "",
        banner_p2: "Handel ",
        banner_p3: "Forex, Rohstoffe,",
        banner_p4: "Indizes, Aktien und mehr",
        banner_p5:
            "Sie können die angesagtesten Finanzmärkte der Welt erkunden. StratTrade bietet 0 Kommissionen, extrem vorteilhafte niedrige Spreads und flexible Leverage-Konfigurationen.",
        hot: "Beliebt",
        tit1: "Freundlichere Transaktionen",
        p1: "Einfache und intuitive Schnittstelle für leichte Bedienung",
        p2: "Unterstützung von Web, mobiler APP und Desktop, Handel an Ihren Fingerspitzen",
        p3: "Risikomanagementinstrumente wie Stop-Loss/Trailing-Stop",
        p4: "Mehrere technische Charts und Finanzkalender, Echtzeit-Nachrichten",
        p5: "E-Mail-, SMS- und Push-Benachrichtigungen in Echtzeit",
        p6: "Continuous improvement for a better trading experience",
        btn1: "Erkunden Sie unsere Plattform",
        row3_tit: "Jederzeit und überall frei handeln",
        row3_tip: "Unterstützung von Web, mobiler APP und Desktop, Handel an Ihren Fingerspitzen",
        row3_tit2: "Scannen Sie den Code zum Herunterladen",
        row3_tit3: "Schreibtisch",
        row3_tit4: "Mobil",
        regular_tit: "Vertrauenswürdige internationale Plattform",
        regular_tip:
            "Wir verpflichten uns, unseren Kunden ein sicheres und verantwortungsvolles Handelsumfeld zu bieten.",
        regular_tit1: "Von einer autorisierten Stelle geregelt",
        regular_p1:
            "Regulierung und Zulassung durch die Industriebehörden, Gewinnung des Vertrauens der weltweiten Kunden",
        regular_tit2: "Schutz der Sicherheit der Mittel",
        regular_p2:
            "Die Einlagen von Privatkunden werden gemäß den aufsichtsrechtlichen Anforderungen nach Bedarf auf Treuhandkonten getrennt.",
        regular_tit3: "Schutz vor negativem Saldo",
        regular_p3:
            "Bieten Sie Ihnen Schutz vor negativem Saldo, negativem Saldo rechtzeitig, um die Null zu löschen, so dass Ihr Verlust den Betrag des Eintritts nicht überschreitet, Seelenfrieden Handel",
        regular_tit4: "5*24 Stunden Kundendienst",
        regular_p4:
            "Aufrichtiges und professionelles Kundendienstteam, 24-Stunden-Online-Support, glücklich, jedes Problem zu lösen, das Sie haben!",
        service_tit: "Weitere Dienstleistungen, die Ihnen helfen",
        service_tit1: "Handelsstrategien",
        service_p1:
            "Echtzeit-Handelsstrategien, die Ihnen helfen, die neuesten Markttrends zu verstehen und das Timing des Handels besser zu verstehen.",
        service_tit2: "Handelswissen",
        service_p2:
            "Lernen Sie kostenlos mit StratTrade zu handeln und verbessern Sie Ihre Handelsfähigkeiten.",
        service_tit3: "Risikomanagement",
        service_p3:
            "Erfahren Sie mehr über die kostenlosen Risikomanagement-Tools, die StratTrade anbietet, um Ihre Vermögenswerte besser zu schützen.",
        step_tit: "Einfach und bequem ein Konto zu eröffnen",
        step_tip: "Drei einfache Schritte, um ein Konto in Minuten zu eröffnen",
        step_tit1: "Einschreibung",
        step_p1: "Füllen Sie die Informationen aus und senden Sie Ihre Bewerbung",
        step_tit2: "Kaution",
        step_p2: "Schnelle Einzahlung von Geldern durch eine Vielzahl von Methoden",
        step_tit3: "Beginn des Handels",
        step_p3: "Entdecken Sie Handelsmöglichkeiten und platzieren Sie Aufträge schnell",
        award_tit: "Unsere Leistungen",
        award_p1:
            "Wir streben immer nach Exzellenz, streben nach Exzellenz und verpflichten uns, unseren Kunden qualitativ hochwertige Transaktionsdienstleistungen zu bieten.",
        award_p2:
            "Die Marke StratTrade wird geehrt, eine Auszeichnung von einer renommierten Organisation der Branche zu erhalten, die die ständigen Bemühungen und das Engagement des Teams für seine Kunden würdigt.",
        row7_tit: "Globale Finanzinformationen in Echtzeit",
        row7_tit1: "Nachrichten in Echtzeit",
        exchange_tit1: "Gestaltung der Krypto-Optionsbranche.",
        exchange_tit2: "Seit 2016.",
        exchange_subtit: "StratTrade Exchange",
        exchange_card1_tit: "Für Anfänger.",
        exchange_card1_p:
            "Starten Sie Ihre Trading-Fähigkeiten mit unserem kostenlosen Optionskurs. Experimentieren Sie auf unserer Testplattform ohne Risiko. Nutzen Sie bei Fragen unseren 24/7 Support. Ihre Derivate-Reise beginnt hier.",
        exchange_card2_tit: "Erfahrene Händler.",
        exchange_card2_p:
            "Tauchen Sie ein in unser institutionelles Handelsumfeld. Verwenden Sie die fortschrittlichsten Werkzeuge der Branche auf dem liquidesten Optionsmarkt der Welt.",
        exchange_card3_tit: "Für Institutionen.",
        exchange_card3_p:
            "Erfahren Sie, warum die prominentesten Institutionen StratTrade seit 2016 bevorzugen. Colo Services in LD4, Portfolio Margin Model, Blocktrades, Combos, die beste Liquidität, Multicast und mehr.",
    },
    learn: {
        education: {},
    },
    login: {
        click_download: "Zum Download klicken",
        p1: "Handeln Sie jederzeit und überall",
        p2: "Handeln Sie schneller und intelligenter",
        p3: "0 Provisionshandel",
        p4: "Die heißeste Investition der Welt",
        renderDom1: "Devisenwechsel",
        renderDom2: "US-Börse",
        renderDom3: "Platin",
        renderDom4: "Rohöl",
        renderDom5: "Indizes",
        renderDom6: "Kryptowährung",
        login: "Anmelden",
        elail: "Email",
        phone: "Telefonnummer",
        no_account: "Kein Konto？",
        go_register: "Zur Registrierung gehen",
        register: "Einschreibung",
        now_register: "Jetzt registrieren",
        have_account: "Sie haben bereits ein Konto？",
        go_login: "Loggen Sie sich ein.",
    },
    about: {
        serviceFee: "Servicegebühr",
        introduction: "Einführung der Plattform",
        trade_platform: "Handelsplattform",
        complaint_process: "Beschwerdeverfahren",
        page: "Webseite",
        place_search: "Suchen Sie nach dem, was Sie wissen wollen",
        no_answer: "Sie können die Antwort nicht finden, die Sie benötigen?",
        contact_us: "Kontaktieren Sie uns",
        awards: {
            h1: "Auszeichnungen und Auszeichnungen",
            p1: "In unserem Streben nach Exzellenz und Engagement, unseren Kunden qualitativ hochwertige Handelsdienstleistungen zu bieten, wird die Marke StratTrade geehrt, eine Auszeichnung von einer renommierten Organisation in der Branche zu erhalten, die die kontinuierlichen Bemühungen und das Engagement des Teams für unsere Kunden würdigt.",
            p2: "Oder erleben Sie sofort unsere preisgekrönte Online-Handelsplattform!",
        },
        charges: {
            h1: "Gebühren und Gebühren",
            p1: "Als Teil unserer Bemühungen, das Kundenerlebnis zu verbessern, möchte StratTrade unseren Kunden einen kostengünstigeren Handelsservice bieten. Wir verpflichten uns, offen und transparent über unsere Gebühren und Gebühren zu sein, ohne versteckte Gebühren. Die wichtigsten Transaktionskosten für unsere Kunden sind der Bid/Ask Spread und der Overnight Positionszins.",
            h2: "Zunächst einmal, wenn Sie mit StratTrade handeln, können Sie genießen",
            li1: "Sofortiges Produktangebot",
            li2: "Spezialisierte technische Indikatoren",
            li3: "Exklusive Marktanalyse",
            free: "Kostenlos (kostenlos)",
            view: "Schauen Sie nach oben",
            h3: "Bid-ask spread",
            p2: "StratTrade berechnet einen Bid/Ask Spread als Entschädigung für seine Dienstleistungen. Diese Gebühr spiegelt sich im Produktangebot wider und Sie zahlen den Spread tatsächlich, wenn Sie eine Position eröffnen. Da die Spread-Gebühr nicht fest ist, empfehlen wir Ihnen, auf die spezifische Angebotsseite zu gehen oder sich bei der",
            h4: "Ein- und Auszahlungsgebühren",
            p3: "Um die Kosten für unsere Kunden zu minimieren, berechnen wir Ihnen in den meisten Fällen keine Gebühren für den Zugriff auf Ihre Gelder. Ein Drittunternehmen oder eine Bank kann Ihnen jedoch eine Gebühr für eine Ein- oder Auszahlung berechnen, z. B. eine Transitbankgebühr.",
            p4: "Sie können unsere Kundenservice-Spezialisten um Informationen über die anfallenden Gebühren fragen.",
            p5: "Z.B. durch internationale Kreditkartentransaktionen, Banküberweisungen in/aus oder Transaktionen in nicht unterstützten Währungen (Forex Umrechnung), etc.",
            h5: "Übernachtungskosten",
            p6: "Wenn Sie noch eine Position um 10pm GMT (22:00 GMT) halten, wird Ihnen eine Übernachtungsgebühr berechnet/hinterlegt. Anfragen bezüglich der Übernachtungskosten für jedes Produkt können auf der jeweiligen Angebotsseite gestellt werden, oder indem Sie sich auf der",
            h6: "Sonstige Aufwendungen",
            p7: "Keine weiteren Gebühren. Unsere Gebührenrichtlinie ist vollständig transparent und alle Artikel, die eine Gebühr erfordern, werden im Voraus angekündigt und kommuniziert, so dass Sie sich keine Sorgen über versteckte Gebühren machen müssen.",
        },
        contact: {
            h1: "Kontaktieren Sie uns",
            address: "Adresse",
            custom_email: "Kundenkontakt E-Mail",
            business_email: "Business Cooperation Email",
            h2: "Schreiben Sie uns.",
            p1: "Wenn Sie Fragen zu unseren Dienstleistungen haben, füllen Sie bitte das untenstehende Formular aus, um Ihre Frage zu stellen. Einer unserer Kundenservice-Spezialisten wird Ihnen so schnell wie möglich antworten. Um sich für die täglichen Handelsstrategien zu registrieren, die von unserer Partnerschaft mit Trading Central angeboten werden, wählen Sie bitte Tägliche Marktanalyse abonnieren im untenstehenden Formular aus und füllen Sie die Sprachversion aus, die Sie erhalten möchten, und wir werden Sie registrieren. Der Abonnementservice ist nur für bestehende StratTrade-Kunden verfügbar.",
            h3: "Vertretungsverfahren",
            p2: "Alle Beschwerden werden wir ein spezialisiertes Team für Sie haben, um zu untersuchen und zu lösen, Details können an Sie verwiesen werden",
        },
        faq: {
            h1: "Häufige Probleme",
            tit1: "Was nützt ein Demokonto？",
            tit2: "Wie man ein echtes Konto eröffnet？",
            tit3: "So erstellen Sie eine Marktorder？",
            tit4: "Warum kann ich keine Bestellung aufgeben？",
            p1: "Demokonten sind in Bezug auf Handelsschnittstelle, Daten und Operationen meist identisch mit echten Konten. Demokonten enthalten $50.000 in virtuellen Fonds und sind so konzipiert, dass Kunden sich mit den Funktionen der Plattform durch simulierte Operationen vertraut machen können, ohne ihr Geld zu riskieren.",
            p2: "Um ein Live-Konto zu eröffnen, befolgen Sie bitte die folgenden Schritte:",
            p3: "1. Gehen Sie auf die StratTrade-Registrierungsseite und folgen Sie den Anweisungen: Registrieren Sie Ihre E-Mail-/Mobiltelefonnummer, legen Sie Ihr Login-Passwort ein und senden Sie die Bestätigung, um automatisch ein [Demo-Konto] zu erstellen.",
            p4: "2. Wählen Sie auf der Demo-Konto-Seite 'Zu echtem Konto wechseln' und folgen Sie dem Verfahren, um ein echtes Konto zu eröffnen.",
            p5: "Bevor Sie ein Live-Konto eröffnen, empfehlen wir Ihnen, die rechtlichen Hinweise im Zusammenhang mit dem Handel zu lesen und zu verstehen.",
            p6: "Bitte beachten Sie, dass Sie nur nach Abschluss der Identitätsprüfung auf Ihr Live-Konto einzahlen können.",
            p7: "Klicken Sie auf 'Handel' (Handel) um alle Produkte anzuzeigen, die für den Handel auf der Plattform verfügbar sind. Sie können den Code oder Namen des Produkts in das Eingabefeld auf der rechten Seite eingeben, um danach zu suchen, ein Produkt auswählen und auf 'VERKAUFEN/KAUFEN' (VERKAUFEN/KAUFEN) klicken, um das Handelsfenster aufzurufen, in dem Sie den aktuellen Preis und die geschätzte Marge sehen können. Sie können die Anzahl der zu eröffnenden Trades manuell anpassen, einen Stop Loss und Take Profit zur Risikokontrolle festlegen und dann auf 'VERKAUFEN/KAUFEN' klicken, um die Eröffnung der Position abzuschließen. Bitte beachten Sie, dass die Preise aller Finanzprodukte schwanken und sich jederzeit mit dem Markt aktualisieren und der Markt sich geändert haben kann, bevor Sie die Schaltfläche KAUFEN/VERKAUFEN drücken.",
            p8: "Überprüfen Sie zunächst, ob die Internetverbindung normal ist. Beim Handel mit verschiedenen Märkten können vorübergehend geschlossene Quoten oder geschlossene Märkte die Unfähigkeit verursachen, Orders zu platzieren. Für weitere Informationen wenden Sie sich bitte an unseren Online-Kundenservice.",
        },
        fundSecurity: {
            h1: "Finanzielle Sicherheit",
            p1: "StratTrade ist autorisiert und reguliert von zuständigen Aufsichtsbehörden. Compliance steht im Mittelpunkt unseres Handelns und unserer Kultur. Die Sicherheit Ihres Geldes ist von größter Bedeutung und alle Kundeneinlagen werden an getrennten Orten aufbewahrt.",
            p2: "Einlagen von Privatkunden werden nach Bedarf getrennt auf Treuhandkonten gehalten, sofern dies gesetzlich vorgeschrieben ist.",
            p3: "StratTrade verwendet Kundengelder nicht für andere kommerzielle Aktivitäten als die gesetzlich zulässigen.",
            p4: "StratTrade wird auf keinem Marktplatz spekulieren",
            p5: "Unabhängige Prüfung durch eine externe Wirtschaftsprüfungsgesellschaft",
        },
        help: {
            h1: "Willkommen im StratTrade Help Center!",
        },
        media: {
            h1: "Media Center",
            p1: "Informationen, Pressemitteilungen, Medienfeatures, Ankündigungen und andere Informationen zur Marke StratTrade anzeigen.",
        },
        mitrade: {
            h1: "Über StratTrade",
            view_certificate: "(Identifikationsmethoden)",
            p1: "StratTrade ist ein von autoritativen Institutionen autorisiertes und reguliertes Finanztechnologieunternehmen, das sich darauf konzentriert, Anlegern eine einfache und bequeme Handelserfahrung zu bieten. Die innovative diversifizierte Handelsplattform StratTrade hat wiederholt Auszeichnungen wie Best Mobile Trading Platform und Most Innovative Brokerage gewonnen.",
            p2: "Über die StratTrade-Plattform können Sie in einer breiteren Palette von internationalen Finanzmärkten investieren und handeln, darunter Aktien, Indizes, Rohstoffe, Devisen und Hunderte anderer beliebter Sorten. StratTrade ist nicht nur auf die benutzerfreundlichen mobilen und webbasierten Handelsplattformen beschränkt, sondern bietet Ihnen auch wettbewerbsfähige Handelskosten, schnelle Handelsausführung, exzellenten Kundenservice und eine Fülle von Informationsunterstützung und hilft Ihnen, rechtzeitig Investitions- und Handelsmöglichkeiten zu ergreifen.",
            p3: "StratTrade wird von der Branchenbehörde und mehreren Agenturen streng reguliert. StratTrade International ist von der Financial Services Commission (FSC) von Mauritius unter der Lizenznummer GB20025791 autorisiert und reguliert. Details zur Mauritius-Lizenz finden Sie auf der offiziellen Website des FSC unter https://www fscmauritius.org",
            p4: "MStratTrade Global ist von der Australian Securities and Investments Commission (ASIC) unter der Financial Services License (AFSL 398528) lizenziert. Weitere Informationen zu australischen Lizenzen finden Sie auf der ASIC-Website unter www.asic.gov.au.",
            p5: "Itrade Holding ist von der Cayman Islands Monetary Authority (CIMA) unter der SIB-Lizenz Nr. 1612446 autorisiert und reguliert. Weitere Informationen zur Caymanian License finden Sie auf der offiziellen CIMA-Website unter www.cima.ky.",
            p6: "Alle Geschäftstätigkeiten von StratTrade werden unter strenger Aufsicht und in Übereinstimmung mit allen Vorschriften durchgeführt.",
            h2: "Hintergrund von StratTrade",
            p7: "StratTrade wurde in Melbourne, Australien, von einem Team erfahrener und sachkundiger Finanz- und Fintech-Experten gegründet, die den Handel einfacher und benutzerfreundlicher machen wollen!",
            p8: "Unsere ganzheitliche Strategie und unser Geschäftsmodell ermöglichen uns eine globale und tiefgreifende Beobachtung und Kenntnis der Branchenentwicklung und Marktnachfrage, so dass wir Ressourcen gezielter zuweisen, Technologien kontinuierlich innovieren und Effizienz optimieren können und unseren Kunden weiterhin eine bequemere und freundlichere Handelserfahrung bieten können. ",
            h3: "Warum StratTrade",
            why1: "Niedrigschwellige Handelsbedingungen",
            why2: "Regulierung durch Industriebehörden",
            why3: "Einfache und intuitive Handelsplattform",
            why4: "Hohes Maß an Online-Support",
            why5: "Wettbewerbsfähige Transaktionskosten",
            why6: "Schutz vor negativem Saldo",
            h4: "Auszeichnungen und Auszeichnungen",
            p9: "Wir streben immer nach Exzellenz, streben nach Exzellenz und verpflichten uns, unseren Kunden qualitativ hochwertige Transaktionsdienstleistungen zu bieten.",
            p10: "Die Marke StratTrade wird geehrt, eine Auszeichnung von einer renommierten Organisation der Branche zu erhalten, die die ständigen Bemühungen und das Engagement des Teams für seine Kunden würdigt.",
            new_add: "Neue Nutzer weltweit",
            ol1: "Best News and Analysis Provider",
            ol2: "Bester Forex Broker in Asien",
            ol3: "Australian Forex Customer Satisfaction Awards",
            ol4: "Beste mobile Anwendung",
            ol5: "Australiens am schnellsten wachsender Broker",
            h5: "Die Betriebsphilosophie von StratTrade",
            tip_tit1: "Verpflichtungen",
            tip_tit2: "Einfach aber schön",
            tip_tit3: "Offen (nicht sekretiv)",
            tip_tit4: "Neue Wege beschreiten",
            tip_p1:
                "Jeder Handel beinhaltet Risiken und StratTrade hat zusätzliche Schritte unternommen, um seinen Kunden ein verantwortungsvolles Handelsumfeld zu bieten. StratTrade wird von Branchenbehörden, mehreren Organisationen streng reguliert und arbeitet unter hohen Sicherheits- und Stabilitätsstandards. Unsere Plattform bietet weiterhin eine Reihe von verantwortungsvollen Handelstools, einschließlich Risikomanagement.",
            tip_p2:
                "Einfachheit ist Schönheit, Details machen Exzellenz, StratTrade widmet sich der Benutzerfreundlichkeit und Benutzererfahrung in jedem Detail. Wir optimieren weiterhin unsere Plattform und Dienstleistungen in der Hoffnung, dass jeder, vom Anfänger bis zum erfahrenen Anleger, in der Lage sein wird, die Handelsmöglichkeiten über die StratTrade-Plattform zu nutzen.",
            tip_p3:
                "Damit unsere Kunden in vollem Umfang von einem fairen und effizienten Handelsumfeld profitieren können, folgt StratTrade einem strengen und transparenten Selbstregulierungssystem, bietet umfassende und transparente Plattformproduktinformationen, Preisumfeld und -daten und alle Gebühren werden klar und offen offengelegt, um vollständige Aufrichtigkeit und Transparenz des gesamten Serviceprozesses zu gewährleisten.",
            tip_p4:
                "StratTrade Wir sind fest davon überzeugt, dass technologischer Fortschritt, finanzielle Innovation und umfassendere Dienstleistungen die treibenden Kräfte für Wachstum sind, und nur dann können wir kontinuierlich Werte schaffen und teilen. Aus diesem Grund fördern wir offenes Denken, fördern kontinuierlich Innovation und streben danach, Innovatoren und Marktführer in der Finanztechnologie zu werden.",
            row6_tit1: "Unsere Mission",
            row6_tit2: "Unsere Vision",
            row6_tit3: "Beschäftigungsmöglichkeiten",
            row6_p1:
                "Schaffung eines fairen, effizienten und transparenten Handelsumfelds, das nicht durch Raum, Zeit und Markt begrenzt ist, für mehr Menschen, die handeln wollen, und Führen der Branche bei finanziellen und technologischen Innovationen.",
            row6_p2:
                "Wir glauben, dass technologischer Fortschritt, finanzielle Innovation und diversifizierte Dienstleistungen die Kräfte sind, die unser Wachstum antreiben und es uns ermöglichen, weiterhin Werte in der Gesellschaft zu schaffen und zu teilen.",
            row6_p3:
                "Zu diesem Zweck fördern wir die Aufgeschlossenheit und fördern Innovation, mit dem Ziel, durch kontinuierliches Wachstum und Fortschritt",
            row6_p4: "Das weltweit führende Devisenunternehmen",
            row6_p5:
                "Wir sind ein globales Team in sieben Ländern und mit einem schnell wachsenden Team sind wir immer auf der Suche nach Fachleuten, die sich uns anschließen!",
            row6_p6:
                "Bitte senden Sie uns Ihren aktuellen Lebenslauf und warum Sie mit uns zusammenarbeiten möchten:",
            row6_p7: "Alternativ können Sie auch mehr über die",
            row6_p8: "Vielleicht",
            row6_p9: "Sehen Sie sich unsere offenen Stellen an",
            row6_p10:
                "StratTrade schützt streng die Sicherheit Ihrer persönlichen Daten. StratTrade wird Sie während des Bewerbungsprozesses nicht nach finanziellen Informationen fragen. Wir benötigen keine finanziellen, Kreditkarten-, Bankkontoinformationen oder Zahlungen jeglicher Art für unsere Rekrutierung.",
            row6_p11:
                "Wenn Sie vermuten, dass eine Position betrügerisch ist, kontaktieren Sie uns bitte über die",
            row6_p12:
                "Kontaktieren Sie StratTrade Personalabteilung. Wenn Sie bestätigen, dass Sie durch eine falsche Stellenausschreibung getäuscht wurden, wenden Sie sich bitte an Ihre lokale Abteilung der entsprechenden Organisation.",
        },
    },
    product: {
        row2: {
            tit: "Handel mit Sorten",
            sell: "Verkaufen",
            buy: "Kaufen",
            trade: "Geschäfte",
            more_product: "Weitere Handelsprodukte",
        },
        row3: {
            tit: "Unterstützt Transaktionen mit mehreren Geräten",
            tit1: "Unterstützt Transaktionen mit mehreren Geräten",
            p1: "StratTrade bietet Ihnen webbasierte, mobile und Desktop-Handelsplattformen. Sie können jederzeit und überall handeln.",
            download: "Herunterladen",
        },
        row4: {
            tit: "StratTrade auswählen",
            tit1: "Einfache und intuitive Plattform",
            p1: "Integration von Angeboten, Handel, Kontoverwaltung, Informationen, Risikomanagement, leistungsstarken Funktionen, um eine bessere Benutzererfahrung zu erreichen",
            tit2: "Wettbewerbsfähige Transaktionskosten",
            p2: "0 Handelsprovisionen, sehr wettbewerbsfähige und transparente niedrige Spreads, niedrige SWAPs, damit Sie kostengünstigen Handel genießen können!",
            tit3: "Niedrigschwellige Handelsbedingungen",
            p3: "Die Mindestlotgröße für jede Transaktion ist so niedrig wie 0.01 Lot, und Sie können eine Position mit einer niedrigen Marge eröffnen.",
            tit4: "Behördlich geregelt",
            p4: "Autorisiert und reguliert von Branchenbehörden, werden alle Kundeneinlagen separat gelagert und getrennt, um die Sicherheit der Vermögenswerte der Kunden zu schützen",
            tit5: "Schutz vor negativem Saldo",
            p5: "Ihr Konto verliert nie mehr als sein Kapital unter Marktbedingungen, und negative Salden werden rechtzeitig bereinigt, um Ihr Risikomanagement zu verbessern.",
            tit6: "Hohes Maß an Online-Support",
            p6: "Bieten Sie schnelle Antwort Online-Kundenservice, bieten Sie besseren Service durch die unermüdlichen Bemühungen des professionellen Teams",
        },
        row5: {
            tit: "Verwandte Fragen",
        },
        commodities: {
            title: "Rohstoffhandel",
            p: "Rohstoffe wie Edelmetalle und Energie gelten als wichtiger Bestandteil diversifizierter Anlagen, da sie sowohl inflationssicher als auch wertsteigernd gelten. Unter ihnen sind Gold, Silber und Öl Rohstoffe, die in großen Mengen gehandelt werden, und ihre offenen und transparenten Preise sowie ihre hohe Liquidität wurden von Investoren weithin begrüßt. Rohstoffpreise schwanken aufgrund von Faktoren wie Angebot und Nachfrage, Wirtschafts- und Politiksituationen sowie Währungen erheblich und bieten Chancen für Rendite-Risiko.",
        },
        forex: {
            title: "Devisenhandel",
            p: "Forex-Handel ist die Umwandlung einer Währung in eine andere, und der Devisenmarkt ist der am stärksten gehandelte Finanzmarkt der Welt. Der Devisenmarkt hat eine große Anzahl von Teilnehmern, die über ihn handeln, um Zahlungen zu tätigen, sich gegen das Risiko von Währungsbewegungen abzusichern oder Gewinne zu erzielen. Der Forex-Handel findet über ein Netzwerk von Banken, Institutionen und Einzelhändlern rund um den Globus statt, mit Kursschwankungen rund um die Uhr, fünf Tage die Woche, bieten Chancen für Risiko-Rendite.",
        },
        indices: {
            title: "Indizes",
            p: "Ein Aktienindex ist ein statistischer Wert, der eine bestimmte Art von charakteristischen Aktien an einer bestimmten Börse darstellt und ein Indikator für das allgemeine Preisniveau und die Bewegung einer bestimmten Art von konstituierenden Aktien auf dem Markt ist. Der Handel mit Aktienindizes, die Anlagechancen im Gesamtmarkt oder Sektor widerspiegeln, kann das Risiko verringern, in einzelne Aktien zu investieren. Die beliebtesten Indizes in den USA, europäischen, asiatischen und australischen Märkten, wie der Australia 200, der U.S. Tech 100, der Hongkong 50, der Deutschland 30, etc., bieten verschiedene Möglichkeiten an den globalen Aktienmärkten.",
        },
        shares: {
            title: "Aktienhandel",
            p: "Eine Aktie ist ein marktfähiges Wertpapier, das an einer Börse ausgegeben wird. Um Kapital zu beschaffen, verteilen die Unternehmen das Eigentum an der Gesellschaft über die börsennotierten Börsen unter Verwendung der Zertifikate. Allein im 2019 betrug der Weltaktienhandel mehr als 60 Billionen Dollar, der so groß und liquid ist, dass er in den Augen von Investoren auf der ganzen Welt auch nach vielen Jahren eines der beliebtesten Finanzinstrumente bleibt. Auf der StratTrade-Plattform können Trader Hebel nutzen, um Kursänderungen in Aktien zu handeln. Aktienkurse werden durch eine Vielzahl von Faktoren wie Makroökonomie, Branchenaussichten und Unternehmensaktivitäten beeinflusst, was sie sehr volatil macht und es immer Möglichkeiten für den Investmenthandel gibt.",
        },
        coins: {
            title: "Kryptowährungen",
            p: "Kryptowährungen sind eine digitale Währung, die durch Code erstellt wird. Sie operieren autonom außerhalb traditioneller Banken- und Regierungssysteme. Wie der Name schon sagt, verwenden Kryptowährungen Verschlüsselung, um Transaktionen zu sichern und für die Schaffung anderer Einheiten zu sorgen, wobei Bitcoin die ursprüngliche und mit Abstand berühmteste Kryptowährung ist. Bitcoin wurde von Satoshi Nakamoto erstellt und im Januar 2009 eingeführt. Noch heute ist es unbekannt, ob sich der Name Satoshi Nakamoto auf eine Person oder eine Gruppe von Menschen bezieht. Bitcoin gilt als die erste dezentrale Kryptowährung. Wie alle Kryptowährungen wird sie über eine Blockchain-Transaktionsdatenbank gesteuert, die als verteiltes öffentliches Hauptbuch dient. Bis heute werden mehr als 1.000 Kryptowährungen online gehandelt.",
        },
    },
    trade: {
        data_sources: "Datenquellen",
        tip: " Die obigen Informationen dienen nur als Referenz. StratTrade übernimmt keine Gewähr für die Richtigkeit, Aktualität oder Vollständigkeit der Informationen und Sie sollten sich nicht übermäßig auf die bereitgestellten Informationen verlassen. Diese Informationen umfassen weder unsere Preisaufzeichnungen noch Angebote oder Aufforderungen zum Handel mit Finanzinstrumenten. StratTrade ist kein Finanzberatungsunternehmen und erbringt nur Dienstleistungen mit Ausführungscharakter. Wir empfehlen den Lesern, ihre eigene Anlageberatung einzuholen. Bitte beachten Sie unseren vollständigen Haftungsausschluss.",
        tip2: "Aktualisiert jeden Freitag um 15:00 (GMT)",
        tip3: "Trading Perspectives ist ein Tool zur Vorhersage der Markthandelsstimmung und spiegelt die kurz- und mittelfristigen Ansichten und Trendprognosen von Branchenexperten für jedes heiße Produkt wider.",
        analysis: {
            tit: "Handelsstrategien",
            all: "Voll",
            day: "In ein paar Tagen",
            tit1: "Handelsstrategien",
            tit2: "Technische Überprüfung",
        },
        calendar: {
            tit: "Finanzkalender",
            prev_week: "Letzte Woche",
            next_week: "Nächste Woche",
            place_date: "Suchdatum",
            select: "Optionen",
            select1: "Unser Land",
            select2: "Bedeutung",
            start_time: "Startzeit",
            end_time: "Endzeit",
            search: "Suchen Sie nach sth.",
        },
        contract: {
            tit: "Vertragsspezifikationen",
            type1: "Voll",
            type2: "Forex",
            type3: "Rohstoffe",
            type4: "Indizes",
            type5: "US-Aktien",
            type6: "Australische Aktien",
            tit1: "Wichtige Devisen",
            tit2: "Minderwertige Devisen",
            tit3: "Rohstoffe",
            tit4: "Hauptindizes",
            tit5: "Kleiner Index",
            tit6: "US-Aktien",
            tit7: "Australische Aktien",
            tit8: "Allgemeine Vorschriften",
            p1: "Erzwungene Ebenheit",
            p2: "Gültigkeit ausstehender Aufträge",
            p3: "Grenzenlos",
            p4: "Abrechnungszeit der Übernachtungsgebühren",
            p5: "Tägliche Abrechnung; Sommer: 05:00, Winter: 06:00",
            p6: "Informationen über Hebelwirkung, Übernachtraten usw.",
            p7: "Siehe Handelsplattformen",
            p8: "Dies gilt für alle Handelssorten",
            tit9: "Trading Wochenkalender",
            p9: "Geschlossen",
            p10: "Die oben genannten Handelszeiten berücksichtigen nicht die Auswirkungen von Feiertagen oder speziellen Marktanpassungen.",
            p11: "Spezifische Handelszeitempfehlungen",
            p12: "Anmeldeplattform",
            p13: "Schauen Sie sich die Profile der jeweiligen Sorten an.",
        },
        forecast: {
            tit: "Handelsperspektiven",
            p1: "Durchschnittswert",
            p2: "Bullisch",
            p3: "Bärisch",
            p4: "Seitlich",
            p5: "Orientierungen",
        },
        news: {
            tit: "Nachrichten in Echtzeit",
        },
        platforms: {
            tit: "StratTrade Handelsplattform",
            p: "Einfache und intuitive Benutzeroberfläche, ausgezeichnete Handelsleistung, erfüllt die Bedürfnisse von mehr Investoren. Beginnen Sie noch heute mit StratTrade zu handeln.",
            tit1: "Bequeme und einfach zu bedienende StratTrade Handelsplattform.",
            tit2: "Handeln Sie jederzeit und überall mit der StratTrade App!",
            li1: "Handel mit Hunderten beliebter Märkte mit maßgebender Regulierung, um die Sicherheit von Fonds zu gewährleisten",
            li2: "Überwachen Sie Markttrends in Echtzeit, sehen Sie sich Handelsanalysen an und durchsuchen Sie die neuesten Finanznachrichten.",
            li3: "Sehen Sie sich jederzeit verschiedene Arten von Handelscharts an und wenden Sie Charting-Funktionen problemlos an",
            li4: "Erleben Sie StratTrade preisgekrönte Handelsplattform auf Ihrem Android- oder iOS-Gerät!",
            code_download: "Scannen Sie den Code zum Herunterladen",
            tit3: "Kein Download erforderlich, Handel online über Ihren Browser",
            li5: "Kein Download erforderlich, handeln Sie online direkt über Ihren PC-Browser",
            li6: "Preisgekrönt: Bester Forex Broker in Asien 2022, Beste Handelsplattform in Australien 2022, Schnellster wachsender Forex Fintech Broker der Welt 2022",
            li7: "Bietet leistungsstarke technische Indikatoren: MACD, KDJ, RSI, TRIX, DMA, CCI, etc.",
            li8: "Ausgestattet mit Risikomanagement-Tools wie Stop-Loss- und Negativsaldo-Schutz, um das potenzielle Risiko Ihrer Trades zu minimieren",
            tit4: "Die perfekte Lösung für Ihre Desktop-Transaktionen",
            li9: "Einfache Anzeige der Marktquoten in der selbstgewählten Liste",
            li10: "Aktivieren Sie Benachrichtigungen, um Benachrichtigungen über Preisänderungen und andere Informationen zu erhalten",
            li11: "Mehrere Diagrammlayouts ermöglichen es Ihnen, den Markt aus verschiedenen Zeitdimensionen zu verfolgen.",
            li12: "Vertragsspezifikationen, Handelsstimmung und Handelsanalysen in einer Benutzeroberfläche, kein Umschalten erforderlich",
            li13: "Erhalten Sie die neuesten Handelsnachrichten direkt in StratTrade!",
            tit5: "Warum StratTrade Handelsplattform wählen?",
            row5_p1: "Sicherheit",
            row5_p2: "0% Provision",
            row5_p3: "Unterstützung für mehrere Plattformen",
            row5_p4: "Technische Diagramme",
            row5_p5: "Zugriff jederzeit und überall",
            row5_p6: "Verschiedene Zeichenwerkzeuge",
        },
        quotes: {
            tit: "Marktdaten",
            p1: "Bietet Ihnen sofortige Quoten, Marktstimmung und verwandte Nachrichten zu beliebten Finanzinstrumenten des Augenblicks.",
            hot: "Beliebt",
        },
        risk: {
            tit: "Risikomanagementsystem",
            p1: "Jeder Handel beinhaltet Risiken. Durch die Nutzung der kostenlosen Risikomanagement-Tools von StratTrade können Sie Ihr Risiko unabhängig von den Marktbedingungen effektiv kontrollieren.",
            function_tit1: "Take Profit/Stop Loss Funktion",
            function_tit2: "Trailing Stop Funktion",
            function_p1: "Gewinnbindung",
            function_p2: "Limitverluste",
            function_p3: "Maximierung der Gewinnbindung",
            function_p4: "Keine Notwendigkeit, Positionen automatisch zu überwachen",
            function_p5: "Anpassung des Verlustabschlusspreises",
            row2_tit1: "Take Profit/Stop Loss Funktion",
            row2_p1:
                " Wenn Sie eine neue Order erstellen oder eine bestehende Order ändern, bieten wir die Option 'Take Profit'und 'Stop Loss' einzustellen. Einmal gesetzt, wird die Order normalerweise zu dem von Ihnen festgelegten Zielpreis geschlossen, was Ihnen hilft, Ihre Gewinne zu sichern, wenn Sie Ihr Ziel erreichen, oder Ihre Verluste zu minimieren, wenn sich der Markt ungünstig bewegt. Bitte beachten Sie, dass jede Order aufgrund von Marktvolatilität shorted werden kann. In diesem Fall wird das System die Order nicht zum Standardpreis ausführen, sondern Ihre Position zum nächstgünstigsten Preis vom Zielpreis schließen.",
            row2_tit2: "Beispiel ",
            row2_p2:
                "Der aktuelle Preis von EUR/USD ist 1.13816/1.13837 (Verkaufen/Kaufen). Sie öffnen einen Kaufauftrag für 1 lot (1 lot.100.000 EUR) bei 1.13837 und setzen einen Stop Loss auf 1.13806.",
            row2_p3:
                "Im Allgemeinen, wenn der Kurs auf 1.13806 fällt, wird Ihre Stop-Loss-Order ausgelöst und die Position wird bei 1.13806 für einen kombinierten Verlust von $31 geschlossen.",
            row2_p4:
                "Wenn es jedoch eine plötzliche Änderung des Marktumfelds gibt und der Preis direkt von 1.13837 auf 1.13795 rutscht und Ihr Stop-Loss-Ziel direkt überspringt, springt der Markt und das System kann die Position bei 1.13806 nicht schließen, aber es wird zum nächstgünstigsten Preis ausgeführt, d.h. bei 1.13795, und der endgültige Verlust wird 42 USD betragen.",
            row3_tit1: "Trailing Stop Funktion",
            row3_tit2: "Beispiel",
            row3_p1:
                "Trailing Stops (auch Trailing Stops genannt) sind leistungsstark, da sie sich automatisch in Übereinstimmung mit Ihren Gewinnen ändern, sodass Sie Ihre Gewinne maximieren oder Ihre Verluste minimieren können, ohne Ihre Positionen ständig überwachen zu müssen. Wenn Sie eine neue Order erstellen, legen Sie einfach einen Trailing Stop fest, und wenn sich der Preis zu Ihren Gunsten bewegt, wird Ihre Stop Order automatisch mit dem neuesten Preis aktualisiert. Wenn sich der Kurs dagegen gegen Sie bewegt, wird die Stop-Loss-Order aktiviert und die Position in der Entfernung geschlossen, die Sie für den Verlust festgelegt haben. Bitte beachten Sie, dass jede Order aufgrund von Marktvolatilität shorted werden kann. In diesem Fall kann das System die Order nicht zu Ihrem Standardpreis ausführen, sondern die Position für Sie zum nächstgünstigsten Preis vom Zielpreis schließen.",
            row3_p2:
                "Der aktuelle Preis von EUR/USD beträgt 1.13816/1.13837 (verkaufen/kaufen). Sie haben eine 1-Lot Kauforder bei 1.13837 mit einem Trailing Stop Loss von 100 Pips (0.00100) etabliert.",
            row3_p3:
                "Wenn der Preis des Produkts bei 1.13816 liegt, beträgt Ihr Stop Loss 1.13716. Wenn sich der Verkaufspreis des Produkts auf 1.13845 bewegt, wird der Stop Loss um die von Ihnen eingestellte Entfernung aktualisiert und der aktualisierte Stop Loss beträgt 1.13745.",
            row3_p4:
                " Im Gegenteil, wenn der Produktpreis von 1.13845 auf 1.13745 rutscht, wird der Trailing Stop ausgelöst und die Position wird bei 1.13745 geschlossen.",
            tip: "Punkt: Normalerweise wird die kleinste Bewegungseinheit im Preis eines Finanzinstruments Punkt genannt. Auf der StratTrade-Plattform bezieht es sich auf die letzte Ziffer oder Dezimalstelle des Kurses eines Instruments.",
        },
        sentiment: {
            tit: "Emotionaler Index",
            type1: "Voll",
            type2: "Forex",
            type3: "Rohstoffe",
            type4: "Indizes",
            long: "Langfristig (Finanzen)",
            short: "Kurz",
        },
    },
    layout: {
        aside: {
            nav1: "Geschäfte",
            nav2: "Markt",
            nav3: "News",
            nav4: "Hochschule",
            nav5: "Meine",
            newsDialog: {
                title: "News Center",
                type1: "Systembenachrichtigung",
                type2: "Bulletin",
                allRead: "Alle als gelesen markieren",
            },
            settingDialog: {
                title: "Einrichten",
                nav1: "Allgemeines",
                nav2: "Einstellungen anzeigen",
                nav3: "Systeminformationen",
                logout: "Abmelden",
                setting1: "Mehrsprachigkeit",
                setting2: "Geschäfte",
                setting2_tip:
                    "Selbstausgewählte Sorten automatisch zu offenen Positionen hinzufügen",
                setting3: "Informationen zurücksenden",
                setting3_tip: "Funktionsempfehlungen",
                setting4: "Überprüfung des realen Namens",
                setting4_tip: "Überprüfung des realen Namens",
                setting5: "Theme-Modus",
                setting5_label1: "Obskur",
                setting5_label2: "Helle Farbe",
                setting6: "Steigende oder fallende Farbe",
                setting6_label1: "Literat. Grün steigt und rot fällt",
                setting6_label2: "Literat. rot steigt, grün fällt",
                setting7: "Diagrammeinstellungen",
                setting7_label1: "Standard Edition",
                setting7_label2: "TradingView Edition",
                setting8: "Erklärungen und Vereinbarungen",
                setting8_label1: "Datenschutzerklärung",
                setting8_label2: "Erklärung zur Produktinformation",
                setting8_label3: "Risikohinweis",
                setting8_label4: "Kundenvereinbarung",
                feedback_title: "Funktionsempfehlungen",
            },
        },
        footer: {
            hot: "Beliebte Sorten",
            sort: "Symbol",
            code: "Codierung",
            buy_price: "Kaufpreis",
            sell_price: "Preis des Verkäufers",
            chg: "Anstieg oder Rückgang des Preises",
        },
        header: {
            place_search: "Suche nach Handelssorten",
            balance: "Verfügbar",
            balance_tip:
                "Verfügbar: der Geldbetrag auf dem Konto, der verwendet werden kann, um neue Positionen zu eröffnen",
            profit_loss: "P/L",
            asset_view: "Überblick über Vermögenswerte",
            netValue: "Nettowert",
            netValue_tip:
                "Nettowert: der Leistungsbilanzwert einschließlich Gewinn und Verlust aller Positionen",
            marginLevel: "Margenniveau",
            margin: "Marge (im Derivatehandel)",
            margin_tip: "Margin: der Betrag, der benötigt wird, um eine Position zu eröffnen und aufrechtzuerhalten",
            maintainsMargin: "Erhaltungsspanne",
            maintainsMargin_tip:
                "Maintenance Margin: Der Mindestbetrag an Margin, der auf Ihrem Konto beibehalten werden muss, während Sie alle Ihre Positionen halten.",
        },
    },
    table: {
        label_name: "Name",
        label_buyPrice: "Kaufen",
        label_sellPrice: "Verkaufen",
        label_variableValue: "Veränderung",
        trade: "Geschäfte",
        label_code: "Symbol",
        label_title: "Name",
        label_start: "Bedeutung",
        label_country: "Unser Land",
        label_event: "Ereignis",
        label_previous: "Vorheriger Wert",
        label_predictive: "Voraussichtlicher Wert",
        label_announced: "Veröffentlichter Wert",
        label_stop_loss_min: "Mindestdistanz für Stop-Loss-Aufträge",
        label_difference: "Dynamische Spreads",
        label_contract_size: "Vertragsgröße",
        label_max_hand: "Maximale Losgröße für eine einzelne Transaktion",
        label_min_hand: "Mindestlosgröße",
        label_trade_week: "Trading Wochenkalender",
        label_week: "1 Woche",
        label_month: "1 Januar",
        label_quarter: "1 Viertel",
        label_dailyTrend: "Single Day Trend",
        label_tradeId: "Transaktions-ID",
        label_breed: "Symbol",
        label_tradeType: "Art der Transaktion",
        label_currency: "Geld",
        label_amount: "Geld",
        label_balance: "Waagen",
        label_type: "Typologie",
        label_time: "Timing",
        label_orderNumber: "Bestellnummer",
        label_pointer_number: "Handvoll (von)",
        label_price_money: "Eröffnung",
        label_stop_win_price: "Gewinnbeteiligung",
        label_stop_lose_price: "Stop-loss",
        label_openTime: "OpenTime",
        label_profit_loss: "P/L",
        label_sell_buy: "Fragen/Kauf Preis",
        label_chg: "Anstieg oder Rückgang des Preises",
        label_sell_price: "Preis des Verkäufers",
        label_buy_price: "Kaufpreis",
        label_condition: "Voraussetzung",
    },
    form: {
        label_feedback: "Fragen und Empfehlungen",
        place_feedback: "Bitte geben Sie Ihre Frage oder Ihren Vorschlag ein",
        label_img: "Foto",
        label_img_tip: "Optional, geben Sie einen Screenshot der Frage an",
        feedback_tip: "Wenn Sie eine dringende Frage haben, wenden Sie sich bitte an",
        online: "Online Kundenservice",
        label_name: "Namen",
        place_name: "Bitte geben Sie Ihren Namen ein",
        label_email: "E-Mail-Adresse",
        place_email: "E-Mail-Adresse",
        message_email: "Bitte geben Sie Ihre E-Mail-Adresse ein",
        label_nationality: "Staatsbürgerschaft",
        place_nationality: "Bitte geben Sie Ihre Staatsangehörigkeit ein",
        place_questionType: "Art des Problems",
        message_questionType: "Bitte wählen Sie Typ",
        questionType0: "Handelsplattform",
        questionType1: "Produkte und Gebühren",
        questionType2: "Abonnieren Sie die tägliche Marktanalyse",
        questionType3: "Kundendienst",
        questionType4: "Der Rest",
        place_question: "Ihre Frage",
        message_question: "Bitte geben Sie eine Frage ein",
        submit_question: "Einreichung von Fragen",
        label_phone: "Handynummer",
        place_phone: "Bitte geben Sie Ihre Handynummer ein",
        message_phone: "Bitte geben Sie Ihre Handynummer ein",
        label_password: "Kryptographie",
        place_password: "Bitte geben Sie Ihr Passwort ein",
        message_password: "Bitte geben Sie Ihr Passwort ein",
        label_confirmPassword: "Passwort bestätigen",
        place_confirmPassword: "Bitte geben Sie Ihr Passwort erneut ein",
        message_confirmPassword: "Bitte geben Sie Ihr Passwort erneut ein",
        label_captcha: "CAPTCHA, eine Art Challenge-Response-Test (Computing)",
        place_captcha: "Bitte geben Sie den Bestätigungscode ein",
        message_captcha: "Bitte geben Sie den Bestätigungscode ein",
        get_captcha: "Prüfcode",
        label_inviteId: "Einladungscode",
        place_inviteId: "Einladungscode (optional)",
        to: "An",
        start_time: "Startzeit",
        end_time: "Endzeit",
        label_cardNumber: "ID-Nummer",
        place_cardNumber: "Bitte geben Sie Ihre ID-Nummer ein",
        message_cardNumber: "Bitte geben Sie Ihre ID-Nummer ein",
        label_cardMain: "Vorderseite des Dokuments",
        message_cardMain: "Bitte geben Sie die Vorderseite des Dokuments ein",
        label_cardBack: "Rückseite eines Dokuments",
        message_cardBack: "Bitte geben Sie die Rückseite des Dokuments ein",
        confirm_modify: "Bestätigung der Änderungen",
        label_realName: "Vorname",
        place_realName: "Bitte geben Sie Ihren Vornamen ein",
        message_realName: "Bitte geben Sie Ihren Vornamen ein",
        label_firstName: "Nachname",
        place_firstName: "Bitte geben Sie Ihren Nachnamen ein",
        message_firstName: "Bitte geben Sie Ihren Nachnamen ein",
        label_lastName: "Mittelname",
        place_lastName: "Optional",
        message_lastName: "Bitte geben Sie Mittelname ein",
        label_birthday: "Geburt",
        place_birthday: "Bitte geben Sie Ihr Geburtsdatum ein",
        message_birthday: "Bitte geben Sie Ihr Geburtsdatum ein",
        place_nowPsd: "Bitte geben Sie Ihr aktuelles Passwort ein",
        message_nowPsd: "Bitte geben Sie Ihr aktuelles Passwort ein",
        place_newPsd: "Bitte geben Sie ein neues Passwort ein",
        message_newPsd: "Bitte geben Sie ein neues Passwort ein",
        place_crmPsd: "Bitte bestätigen Sie das neue Passwort",
        message_crmPsd: "Bitte bestätigen Sie das neue Passwort",
        label_breed: "Symbol",
        place_breed: "Bitte geben Sie eine Art ein",
        label_phase: "Sei",
        label_buyPrice: "Kaufpreis",
        label_sellPrice: "Preis des Verkäufers",
        label_do: "Wegen",
        label_height: "Größer als",
        label_low: "Unterer sein als",
        label_equal: "Oder gleich",
        labelWalletName: "Name der Geldbörse",
        messageWalletName: "Bitte geben Sie Ihren Wallet-Namen ein",
        placeWalletName: "Bitte geben Sie Ihren Wallet-Namen ein",
        labelWalletAddress: "Wallet-Adresse",
        messageWalletAddress: "Bitte geben Sie Ihre Wallet-Adresse ein",
        placeWalletAddress: "Bitte geben Sie Ihre Wallet-Adresse ein",
        labelAmount: "Geld",
        messageAmount: "Bitte geben Sie den Betrag ein",
        placeAmount: "Bitte geben Sie den Betrag ein",
        placeTimeLimit: "Bitte geben Sie eine Frist ein",
        messageTimeLimit: "Bitte geben Sie eine Frist ein",
    },
    pay: {
        title: "Einfacher Zugang zu Finanzmitteln",
        p1: "Eine Vielzahl von häufig verwendeten Zahlungskanälen, Unterstützung für schnelle Ein- und Auszahlungen",
        p2: "Einige Zahlungsmethoden sind in Ihrem Land möglicherweise nicht verfügbar.",
        p3: "Willkommen bei StratTrade Service!",
        btn: "Jetzt handeln ",
    },
    header: {
        tip1: "Leveraged Trading ist ein komplexes Finanzprodukt mit einem hohen Risiko für schnellen Verlust.",
        btn1: "Handelsplattform",
        btn2: "Abmelden",
        btn3: "Anmelden",
        BigUint64Array: "Jetzt handeln",
    },
    footer: {
        help: "Kontaktieren Sie uns",
        tip1: "Folgen Sie unserer Community",
        tip2: "Bitte beachten Sie, dass StratTrade keine offizielle Telegram-Gruppe eingerichtet hat und jede Telegram-Gruppe, die im Namen StratTrade gebildet wurde, des Spoofings verdächtigt wird.",
        tip3: "RISIKOWARNUNG: Der Handel kann zum Verlust all Ihrer Gelder führen. Der Handel mit OTC-Derivaten ist nicht für jedermann geeignet. Bitte lesen Sie unsere rechtlichen Dokumente sorgfältig durch, bevor Sie unsere Dienstleistungen in Anspruch nehmen und stellen Sie sicher, dass Sie die damit verbundenen Risiken vor dem Handel vollständig verstehen. Sie besitzen oder halten keine der zugrunde liegenden Vermögenswerte.",
        tip4: "StratTrade gibt keine Ratschläge, Empfehlungen oder Meinungen zum Kauf, Halten oder Verkaufen. Alle von uns angebotenen Produkte sind OTC-Derivate auf Basis globaler Vermögenswerte. Alle Dienstleistungen von StratTrade basieren ausschließlich auf der Ausführung von Handelsaufträgen.",
        tip5: "StratTrade ist eine Geschäftsmarke, die von mehreren Unternehmen geteilt wird und über folgende Unternehmen betrieben wird:",
        tip6: "StratTrade International Ltd ist der Emittent der Finanzprodukte, die auf dieser Website beschrieben oder zur Verwendung verfügbar sind StratTrade International Ltd ist autorisiert und reguliert von der Financial Services Commission (FSC) von Mauritius unter der Lizenznummer GB20025791, mit Sitz in 6 St Denis Street, 1st Floor River Court, Port Louis 11328, Mauritius",
        tip7: "StratTrade Global Pty Ltd ist registriert unter ABN 90 149 011 361, Australian Financial Services License (AFSL) Nummer 398528.",
        tip8: "StratTrade Holding Ltd ist von der Cayman Islands Monetary Authority (CIMA) unter SIB-Lizenznummer 1612446 autorisiert und reguliert.",
        tip9: "Die Informationen auf dieser Website sind weder für Einwohner der Vereinigten Staaten von Amerika, Kanada, Japan oder Neuseeland bestimmt, noch sind sie für die Verwendung durch jemanden in Ländern oder Gerichtsbarkeiten bestimmt, in denen ihre Veröffentlichung oder Verwendung gegen lokale Gesetze oder Vorschriften verstößt. Bitte beachten Sie, dass Englisch die Hauptsprache unserer Dienstleistungen und die rechtsverbindliche Sprache aller Vertragsdokumente ist. Übersetzungen in andere Sprachen dienen nur als Referenz, und im Falle von Abweichungen zwischen der englischen und der chinesischen Version hat die englische Version Vorrang.",
        tip10:
            "SSL sichere Kommunikationsverschlüsselung. © StratTrade Alle Rechte vorbehalten.",
        link1: "Datenschutzerklärung",
        link2: "Erklärung zur Produktinformation",
        link3: "Beschwerdeverfahren",
        link4: "Risikohinweis",
        link5: "Kundenvereinbarung",
        toTop: "Sticky (von einem Internet Forum Thread etc.)",
    },
    nav: {
        tit1: "Märkte",
        tit2: "Handel",
        tit3: "Lernen",
        tit4: "Über uns",
        nav1: "Forex",
        nav2: "Indizes",
        nav3: "Rohstoffe",
        nav4: "Aktien",
        nav5: "Handelsplattformen",
        nav6: "Handelsstrategien",
        nav7: "Handelsperspektiven",
        nav8: "Finanzkalender",
        nav9: "Nachrichten in Echtzeit",
        nav10: "Marktdaten",
        nav11: "Emotionaler Index",
        nav12: "Risikomanagementsystem",
        nav13: "Vertragsspezifikationen",
        nav14: "Handelsgrundlagen",
        nav15: "Einblicke in Investitionen",
        nav16: "Akademie",
        nav17: "Über StratTrade",
        nav18: "Unsere Auszeichnungen",
        nav19: "Media Center",
        nav20: "Sicherheit von Kundengeldern",
        nav21: "Gebühren",
        nav22: "Tochtergesellschaften",
        nav23: "Kontaktieren Sie uns",
        nav24: "Help Center",
        nav25: "Help Center",
    },

    city: {
        albania: "Albanien",
        algeria: "Algerien",
        argentina: "Argentinien",
        armenia: "ARMENIEN",
        australia: "Australien",
        pakistan: "Pakistan",
        austria: "Österreich",
        bahrain: "Bahrain",
        belgium: "Belgien",
        bosnia_and_Herzegovina: "Bosnien und Herzegowina",
        brazil: "Brasilien",
        brunei: "Brunei",
        bulgaria: "Bulgarien",
        cambodia: "Kambodscha",
        canada: "Kanada",
        cameroon: "Kamerun",
        chile: "Chile",
        colombia: "Kolumbien",
        costa_Rica: "Costa Rica",
        croatia: "Kroatien",
        cyprus: "Zypern",
        czech_Republic: "Tschechische Republik",
        denmark: "Dänemark",
        dominican_Republic: "DOMINIKANISCHE REPUBLIK",
        egypt: "Ägypten",
        estonia: "Estland",
        ethiopia: "Äthiopien",
        finland: "Finnland",
        france: "Frankreich",
        georgia: "Georgien",
        germany: "Deutschland",
        ghana: "Ghana",
        greece: "Griechenland",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Ungarn",
        iceland: "Iceland",
        ireland: "Irland",
        italy: "Italien",
        india: "Indien",
        indonesia: "Indonesien",
        israel: "Israel",
        iran: "Iran",
        iraq: "Irak",
        japan: "Japan",
        kazakstan: "Kasachstan",
        kenya: "Kenia",
        korea: "Südkorea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kirgisistan",
        laos: "Laos",
        latvia: "Lettland",
        lithuania: "Litauen",
        luxembourg: "Luxemburg",
        macao_China: "Macao, China",
        macedonia: "Mazedonien",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexiko",
        moldova: "Moldawien",
        monaco: "Monaco",
        mongolia: "Mongolei",
        montenegro: "MONTENEGRO",
        morocco: "Marokko",
        myanmar: "Myanmar",
        netherlands: " Niederlande",
        new_Zealand: "Neuseeland",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norwegen",
        oman: "Oman",
        palestine: "Palästina",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "die Philippinen",
        poland: "Polen",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Katar",
        romania: "Rumänien",
        russia: "Russland",
        republic_of_Trinidad_and_Tobago: "trinidad and tobago",
        rwanda: "Ruanda",
        saudi_Arabia: "Saudi Arabien",
        serbia: "Serbien",
        singapore: "Singapur",
        slovakia: "Slowakei",
        slovenia: "Slowenien",
        south_Africa: "Südafrika",
        spain: "Spanien",
        sri_Lanka: "Sri Lanka",
        sweden: "Schweden",
        switzerland: "Schweiz",
        taiwan_China: "Taiwan, China",
        tajikistan: "Tadschikistan",
        tanzania: "Tansania",
        thailand: "Thailand",
        turkey: "Turkey",
        turkmenistan: "Turkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "Die Vereinigten Arabischen Emirate",
        united_Kingdom: "Großbritannien",
        united_States: "Amerika",
        uzbekistan: "Usbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Aserbaidschan",
        bangladesh: "Bangladesch",
        belarus: "Belarus",
        belize: "belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivien",
        botswana: "Botswana",
        british_Virgin_Islands: "Britische Jungferninseln",
        burkina_Faso: "burkina faso",
        burundi: "Burundi",
        cape_Verde: "Kap Verde",
        cayman_Islands: "Kaimaninseln",
        central_African_Republic: "Zentralafrikanische Republik",
        chad: "Tschad",
        comoros: "Komoren",
        the_Republic_of_the_Congo: "Kongo (Brazzaville)",
        democratic_Republic_of_the_Congo: "Kongo (Kinshasa)",
        djibouti: "Dschibuti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Äquatorialguinea",
        eritrea: "ERITREA",
        fiji: "Fidschi",
        gabon: "Gabun",
        gambia: "Gambia",
        greenland: "Grönland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: "Insel Man",
        cote_d_Ivoire: "Côte d'Ivoire",
        jamaica: "Jamaika",
        jordan: "Jordan",
        lebanon: "Libanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libyen",
        madagascar: "Madagaskar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauretanien",
        mauritius: "Mauritius",
        mozambique: "Mosambik",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "the Niger",
        north_Korea: "Korea",
        reunion: "Wiedervereinigung",
        san_Marino: "San Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "sultan",
        suriname: "Surinam",
        eswatini: "Eswatini",
        syria: "Syrien",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunesien",
        united_States_Virgin_Islands: "Virgin Islands, Vereinigte Staaten",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatikan",
        yemen: "Jemen",
        yugoslavia: "Jugoslawien",
        zambia: "Sambia",
        zimbabwe: "Simbabwe",
        china: "China",
    },
};
