export default {
	common: {
		learn: {
			title1: "Imbangan utama",
			content1: "StratTrade menawarkan wang tunai kita dan servis keseimbangan Cryptocurrency Hosted gratis, membolehkan anda menyimpan wang tunai anda dan menyokong valuta kripto tanpa biaya. Anda boleh memindahkan kripto dari satu keseimbangan utama pengguna StratTrade ke keseimbangan utama pengguna lain tanpa sebarang muatan.\n" +
				"\n" +
				"Gaji transaksi rangkaian\n" +
				"\n" +
				"Untuk transaksi pada rangkaian valuta kripto (iaitu pemindahan valuta kripto dari platform StratTrade), StratTrade mengalami dan membayar biaya transaksi rangkaian (iaitu biaya tambang). Apabila anda menghantar cryptocurrency dari dompet StratTrade anda ke dompet lain, kami akan meminta bayaran berdasarkan anggaran kami tentang bayaran rangkaian yang berkuasa untuk mengirim dompet-ke-dompet sendirian. Namun, bayaran akhir yang StratTrade bayar mungkin berbeza dari bayaran dijangka disebabkan faktor seperti transaksi batch atau perubahan dalam aras congestion rangkaian. Semua bayaran tersebar pada masa transaksi.\n" +
				"\n" +
				"Kebesaran efisiensi dan biaya rangkaian\n" +
				"\n" +
				"Sebab kemungkinan keuntungan efisiensi dari transaksi kumpulan, jumlah jumlah harga biaya rangkaian yang dibayar oleh pengguna dalam kumpulan tertentu boleh melebihi biaya rangkaian terakhir yang dibayar oleh StratTrade.\n" +
				"\n" +
				"Gaji pemprosesan Rangkaian Lightning\n" +
				"\n" +
				"Rangkaian Lightning adalah rangkaian pembayaran mikro yang membolehkan pemindahan bitcoin tanpa mengakibatkan bayaran transaksi yang berkaitan dengan rantai blok Bitcoin. Apabila anda menghantar bitcoin dari StratTrade melalui Rangkaian Lightning, kami akan meminta bayaran pemprosesan yang sama dengan 0.1% jumlah bitcoin dipindahkan.\n" +
				"\n" +
				"Menambah wang tunai dan menghabiskan wang\n" +
				"\n" +
				"Apabila anda menambah wang tunai atau wang tunai keluar, anda boleh meminta bayaran bergantung pada kaedah pembayaran yang anda pilih. Penting untuk mengulang perincian bayaran sebelum menyelesaikan transaksi untuk memahami mana-mana biaya yang berkaitan.\n" +
				"\n" +
				"Transaksi kredit\n" +
				"\n" +
				"Jika and a meminjam USD dari StratTrade atau sekutu StratTrade dan kami perlu menjual jaminan BTC anda (sebagai dibenarkan di bawah perjanjian pinjaman yang berlaku), kami akan meminta biaya rata 2% daripada keseluruhan transaksi.",
			title2: "Gaji perdagangan dan penyebaran",
			content2: "StratTrade fees\n" +
				"\n" +
				"Apabila anda membeli, menjual, atau menukar kriptovaluta di StratTrade, bayaran diwajibkan. Gaji ini dihitung pada masa anda meletakkan pesanan anda dan boleh dipengaruhi oleh faktor seperti kaedah pembayaran yang dipilih anda, saiz pesanan, syarat pasar, lokasi bidang, dan biaya lain yang kami terima untuk memudahkan transaksi anda.\n" +
				"\n" +
				"Sebelum menghantar transaksi anda, anda boleh melihat biaya dalam skrin pratonton perdagangan. Sila perhatikan bahawa biaya mungkin berbeza untuk transaksi yang sama. Dengan menyelesaikan perintah, anda setuju untuk membayar biaya StratTrade yang berlaku untuk transaksi itu.\n" +
				"\n" +
				"Penyebar\n" +
				"\n" +
				"Apabila and a menempatkan perintah membeli dan menjual sederhana, StratTrade termasuk penyebaran dalam harga yang dicetak. Penyebar juga termasuk dalam kadar pertukaran apabila menukar dari satu valuta kripto ke yang lain.\n" +
				"\n" +
				"Untuk melihat penyebaran sebelum meramalkan perintah beli, jual, atau tukar, pada skrin pratonton:\n" +
				"\n" +
				"Beli dan jual arahan: tekan pada tooltip di sebelah item garis harga aset anda untuk melihat penyebaran.\n" +
				"\n" +
				"Tukar perintah: tekan pada tooltip di sebelah kadar pertukaran yang ditutup anda.\n" +
				"\n" +
				"Penyebar membantu meningkatkan kemungkinan transaksi yang berjaya dan membolehkan StratTrade mengunci harga petikan and a secara sementara semasa memproses perintah anda. StratTrade boleh menyimpan sebarang penyebaran berlebihan dari transaksi. Sila perhatikan bahawa penyebaran boleh berbeza untuk transaksi yang sama.\n" +
				"\n" +
				"StratTrade Lanjutan\n" +
				"\n" +
				"Tiada penyebaran termasuk untuk StratTrade Advanced kerana anda berinteraksi secara langsung dengan buku perintah.\n" +
				"\n" +
				"StratTrade One\n" +
				"\n" +
				"StratTrade Satu adalah produk langganan yang menawarkan pembelian bebas bayaran dan menjual kriptocurrencies, dengan keterangan tertentu. Ahli-ahli mungkin masih menyebar termasuk dalam harga petikan mereka.\n" +
				"\n" +
				"Perubahan untuk bayaran perdagangan dan menyebarkan\n" +
				"\n" +
				"StratTrade kadang-kadang menguji perubahan biaya dan menyebar. Perubahan ini mungkin dilakukan melalui kawasan, aset, saiz perintah, dan jenis perdagangan yang berbeza.\n" +
				"\n" +
				"StratTrade menunjukkan jumlah keseluruhan, termasuk penyebaran dan bayaran, setiap kali and a meramalkan perdagangan dan kemudian dalam sejarah transaksi anda.\n" +
				"\n" +
				"Eksekusi perdagangan\n" +
				"\n" +
				"Semua perdagangan dari Imbangan Utama anda dilaksanakan melalui buku perintah batas pusat StratTrade Exchange atau lelongan kompetitif. Anda boleh melihat buku pesanan pasar, tawaran, tanya, dan kedalaman dalam masa sebenar. Buku perintah ini menentukan harga petikan yang anda lihat semasa perdagangan.\n" +
				"\n" +
				"StratTrade tidak mempunyai sebarang bayaran untuk hubungan aliran perintah (PFOF) dengan pembuat pasar.",
			title3: "Kad StratTrade",
			content3: "Menghabiskan dengan Kad StratTrade tidak mempunyai biaya transaksi.\n" +
				"\n" +
				"StratTrade termasuk penyebaran dalam harga untuk membeli atau menjual kriptocurrencies. Ini membolehkan kita mengunci sementara harga untuk pelaksanaan perdagangan semasa anda meninjau perincian transaksi sebelum menghantar transaksi anda. \n" +
				"\n" +
				"Anda boleh dipakai biaya oleh operator ATM. Sila rujuk ke Perjanjian Pemilik Kad StratTrade anda untuk perincian lanjut.",
			title4: "Comment",
			content4: "Tiada bayaran untuk ditaruh atau dibuang. StratTrade mengambil komisi berdasarkan hadiah yang and a terima dari rangkaian. komisi piawai kami adalah 35% untuk ADA, ATOM, DOT, MATIC, SOL, dan XTZ. Untuk ADA, ATOM, DOT, SOL, dan XTZ, komisi ialah 26.3% untuk ahli StratTrade Satu yang berhak. Anda boleh cari kadar komisi ETH anda dalam Perincian Asset. Beberapa pengguna mungkin menerima tawaran untuk promosi pasangan dengan kadar yang lebih rendah. APY dan hadiah yang dipaparkan dalam akaun anda sentiasa mencerminkan gaji yang anda terima selepas komisi kami. Anda boleh mencari lebih banyak tentang komisi dalam Perjanjian Pengguna StratTrade.",
			title5: "Pemulihan aset",
			content5: "Jika anda menghantar currency kripto tidak disokong ke akaun StratTrade anda, ia mungkin berhak untuk pemulihan. Kita akan meminta bayaran rangkaian untuk cubaan pemulihan. Untuk pemulihan dengan nilai dijangka lebih dari $100, kami juga akan meminta biaya pemulihan 5% pada jumlah lebih dari $100. Nilai dijangka pemulihan mungkin berbeza dari nilai pasar sebenar pemulihan.",
		},
		award1: "Perjanjian terbaik Asia untuk Penyedar Perbezaan pada tahun 2023",
		award2: "Broker Terbaik Global 2022",
		award3: "Broker yang paling dipercayai pada tahun 2022",
		award4: "Tanda yang paling dipercayai tahun 2021",
		award5: "Broker Teknologi Kewangan Terbaik",
		award6: "Broker paling transparan untuk transaksi",
		country: "analisis",
		health: "kesihatan",
		analysis: "Negara",
		information: "maklumat",
		help: "Bantuan",
		confirm: "Sahkan",
		cancel: "Batalkan",
		tip: "prompt",
		place_select: "Sila pilih",
		placeEnter: "Sila masukkan",
		countryCode: "Kod negara",
		submit: "Hantar",
		more: "Hantar",
		deposit: "Deposit tunai",
		real_trade: "Transaksi sebenar",
		paper_trade: "Transaksi Simulasi",
		build_paperTrade: "Menetapkan akaun simulasi",
		start_trade: "Mulakan perdagangan segera",
		app_name: "StratTrade",
		success: "Mulakan perdagangan segera！",
		dataSources_name: "Trading Central",
		reset: "Tetap Semula",
		iknow: "Dapat.",
		noData: "Semasa tiada data tersedia",
		modify: "Ubahsuai",
		pingcang: "Posisi menutup",
		all: "Keseluruhan",
		tip_cardMain: "Ambil atau muat naik foto FRONT kad identiti anda",
		tip_cardBack: "Ambil atau muat naik foto dari BALAT kad identiti anda",
		tip_cardMain1: "Ambil atau muat naik foto FRONT lesen pemandu anda",
		tip_cardBack1: "Ambil atau muat naik foto dari BALANG lesen pemandu anda",
		tip_cardMain2: "Ambil atau muat naik foto pasport anda",
		tip_cardBack2: "Ambil atau muat naik foto pasport anda",
	},
    currency: {
		btcusdt: "Bitcoin",
		ethusdt: "Ethereum",
		ltcusdt: "Litecoin",
		xrpusdt: "Coin Ruibo ",
		adausdt: "Koin Ada",
		eosusdt: "Coin Pomelo",
		dotusdt: "Coin Boca",
		trxusdt: "Koin medan gelombang",
		xmrusdt: "Coin Monroe",
		dogeusdt: "Koin anjing",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "AUD USD",
		fx_sgbpusd: "USD GBP",
		fx_seurusd: "Euro USD",
		fx_snzdusd: "Dollar New Zealand (NZD) USD",
		fx_susdcad: "CAD USD",
		fx_susdchf: "USD CHF",
		fx_susdjpy: "USD CHF USD JPY",
		fx_saudcad: "AUD CAD",
		fx_saudchf: "AUD CHF",
		fx_saudjpy: "AUD JPY",
		fx_saudnzd: "AUD New Zealand Dollar (NZD)",
		fx_scadchf: "CAD Franc Swiss",
		fx_scadjpy: "Kanada",
		fx_schfjpy: "Yen franc Swiss",
		fx_seuraud: "Euro AUD",
		fx_seurcad: "CAD EUR",
		fx_seurgbp: "EUR GBP",
		fx_seurjpy: "JPY EUR",
		fx_seurnzd: "Euro New Zealand Dollar (NZD)",
		fx_sgbpaud: "AUD GBP",
		fx_sgbpchf: "GBP CHF",
		fx_sgbpjpy: "GBP JPY",
		fx_sgbpnzd: "GBP NZD",
		fx_scadnzd: "Dolar Kanada dan Selandia Baru",
		hf_CL: "Minyak mentah WTI New York",
		hf_OIL: "Minyak mentah Brent",
		hf_GC: "New York Gold",
		hf_SI: "New York Silver",
		hf_HG: "Copper Amerika",
		hf_NG: "Gas alam di Amerika Syarikat",
		hf_CAD: "London Copper",
		hf_AHD: "London Aluminum",
		HX_AAPL: "Apel",
		HX_BABA: "Alibaba",
		HX_GOOG: "Google",
		HX_WMT: "Wal Mart",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Tesla",
		HX_N225: "Nikkei 225",
		HX_GDAXI: "Jerman DAX30",
		HX_IBEX: "IBEX35, Spanyol",
		HX_SPX: "Indeks S&P 500",
		HX_FTSE: "FTSE 100",
		HX_KS11: "Korea KOSPI",
		HX_NZ50: "New Zealand 50",
		HX_PSI20: "Portugal PSI20",
		hf_ZSD: "London Zinc",
		hf_NID: "London Nickel",
		hf_PBD: "London Lead",
		hf_SND: "London Tin",
		hf_XAU: "London Gold (Spot Gold)",
		hf_XAG: "London Silver (Spot Silver)",
		hf_XPT: "Masa depan Platinum",
		hf_XPD: "Masa depan Palladium",
		hf_CT: "Kawat Amerika",
		hf_SM: "Bubuk Kacang Meihuang",
		hf_BO: "Minyak soja Amerika",
		HX_SENSEX: "SENSEX, Mumbai, India",
		HX_KLSE: "FTSE Malaysia KLCI",
		HX_KSE100: "Karachi, Pakistan",
		HX_FCHI: "Perancis CAC40",
		HX_SX5E: "Snooker Eropah 50",
		HX_ICEXI: "Iceland ICEX",
		HX_ASE: "Athens, Greece ASE",
		HX_OMXC20: "OMX Copenhagen 20",
		HX_OSEBX: "Norway OSEBX",
		HX_OMXSPI: "Sweden OMXSPI",
		HX_AMD: "Chaowei Semiconductor",
		HX_DIS: "Disney",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "Mastercard",
		HX_NVDA: "INVIDIA",
		HX_INTC: "Intel",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Teknologi Seagate",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "Koin kayu Astro",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
	currenctType: {
        title: "Sila pilih valuta",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dolar',
        currenctType15: 'Dolar Hong Kong',
        currenctType16: 'Fijiworld. kgm',
        currenctType17: 'Myr',
        currenctType18: 'Warna baru',
        currenctType19: 'Yen Jepun',
        currenctType20: 'Euro',
        currenctType21: 'AUD',
        currenctType22: 'Indonesia',
        currenctType23: 'Unit',
        currenctType24: 'Baht Thailand',
        currenctType25: 'Cad',
        currenctType26: 'Vietnam dong',
        currenctType27: 'Korea menang',
        currenctType28: 'Macau Pataca',
    },
    addnew2: {
        address: {
			account_type: "Jenis Akaun",
			ifsc_code: "KOD IFSC",
            title: "Alamat tarik",
            account_number: "Nombor Akaun",
            add_btn: "Tambah Alamat",
            label_type: "Pilih Jenis",
            place_type: "Sila pilih jenis",
            label_number: "Nombor Akaun",
            place_number: "Sila masukkan nombor akaun",
            wallet_address: 'Alamat dompet',
            bank_name: 'Nama Bank',
            bank_address: "Alamat bank",
            name: "Nama",
            payee_address: 'Alamat Penerima',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nama Branch",
            branch_code: "Kod Branch",
            bank_code: "Kod bank",
            phone: "Nombor telefon bimbit",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Sila masukkan kandungan",
        },
    },
	addnew: {
		paypassTit: "Ubah kata laluan penarikan",
		paypassTit1: "Sila tetapkan kata laluan penarikan anda",
		paypassTit2: "Katalaluan sosial awal adalah kata laluan log masuk",
		loan: "Pinjaman",
		repaid: "Membayar semula",
        unpaid: "Tidak dibayar",
		loanMore: "Saya perlukan pinjaman.",
		immediateRepayment: "Pembayaran segera",
		accountType1: "Akaun monet",
		accountType2: "Akaun kontrak",
		accountType3: "Akaun pilihan",
		from: "Dari",
        to: "Ke",
		get: "Akan menerima",
		labelBreed: "Valuta",
		placeBreed: "Sila pilih valuta",
		labelTransAccount: "Pengalihan akaun",
		labelAmount: "Jumlah pemindahan",
		placeAmount: "Sila masukkan jumlah pemindahan",
		transferTit:"Pemindahan akaun",
		convertTit:"Penukaran flash wang",
		balanceTit:"Imbangan akaun",
		available: "Kredit Tersedia",
        pending: "Kunci",
        equivalent: "Sama",
		coinTrade: "Penjualan wang",
		secondContract: "Kontrak kedua",
		number: "Kuantiti",
		labelNumber: "Kuantiti",
		placeNumber: "Sila masukkan kuantiti tebusan",
		time: "Masa",
        loadAmount: "Jumlah pinjaman dijangka",
        repaymentCycle: "Ciklus pembayaran pinjaman",
        dailyRate: "Kadar faedah sehari",
        repaymentMethod: "Kaedah pembayaran",
        loanTip: "Pinjaman kredit (sila pastikan imej jelas dan kelihatan)",
        loanTip1: "Muat naik sijil sifat",
        loanTip2: "Bukti pendapatan (hubungan kerja)",
        loanTip3: "Perincian pernyataan bank",
        loanTip4: "Muat naik foto depan kad ID anda",
        interest: "Interest",
		repaymentMethod1: "Satu pembayaran selepas dewasa",
	},
	params: {
		product_foreign: "Pertukaran",
		product_shop: "Komoditi",
		product_number: "Indeks",
		product_stock: "Kongsi",
		product_coin: "Penyulitan",
		billTypes: [
            { v: 101, name: 'Muat Semula' },
            { v: 102, name: 'Tambah' },
            { v: 103, name: 'Beku' },
            { v: 104, name: 'Meluap' },
			{v: 105, name: 'Deposit'},
			{v: 106, name: 'Deposit'},
            { v: 201, name: 'Pembekalan tarik' },
            { v: 202, name: 'penambahan' },
            { v: 203, name: 'Pengunduran berjaya' },
            { v: 204, name: 'Pengunduran gagal' },
            { v: 205, name: 'Gaji tarik' },
			{v: 206, name: 'Pindahkan keluar'},
			{v: 207, name: 'Pemindahan masuk'},
			{v: 208, name: 'Pindahan wang keluar'},
			{v: 209, name: 'Pindahan wang dalam'},
            { v: 301, name: 'Pengurangan deposit' },
            { v: 302, name: 'Pengurangan biaya pengendalian' },
            { v: 303, name: 'Kembalian deposit' },
            { v: 304, name: 'Keuntungan' },
            { v: 305, name: 'Kehilangan' },
			{v: 311, name: 'Pilihan Pembelian'},
			{v: 312, name: 'Opsyen mengembalikan'},
			{v: 313, name: 'Pembalikan Opsyen'},
			{v: 314, name: 'Gaji pengendalian opsyen'},
			{v: 315, name: 'Pembelian wang beku'},
			{v: 316, name: 'Pengurangan pembelian wang'},
			{v: 317, name: 'Kembali Pembelian Coin'},
			{v: 318, name: 'Belian Coin Diterima'},
			{v: 319, name: 'Belian Coin Diterima'},
			{v: 320, name: 'Penjualan wang beku'},
			{v: 321, name: 'Pengurangan jualan wang'},
			{v: 322, name: 'Kembalian jualan monet'},
			{v: 323, name: 'Coins dijual untuk akaun'},
			{v: 324, name: 'Coins dijual untuk akaun'},
			{v: 325, name: 'Gaji pengendalian wang'},
			{v: 401, name: 'Tambahan mesin tambang'},
			{v: 402, name: 'Kembali mesin mining'},
			{v: 403, name: 'Penghasilan mesin tambang'},
			{v: 404, name: 'Keluar mesin perlombongan'},
			{v: 405, name: 'Gaji pengendalian keluar mesin tambang'},
			{v: 411, name: 'Pinjaman pendapatan'},
			{v: 412, name: 'Gaji pengendalian pinjaman'},
			{v: 413, name: 'Pembalasan berjaya'},
        ],
	},
	message: {
		logout: "Log keluar dari log masuk",
		register_success: "Log masuk berjaya",
		place_inner_personal: "Sila masukkan maklumat peribadi dahulu",
		submit_success: "Dihantar dengan berjaya！",
		copy_success: "Salin berjaya！",
		copy_error: "Dalinan gagal！",
		modify_success: "Berjaya diubahsuai",
		no_balance: "Imbangan tidak mencukupi untuk membayar deposit",
		palce_password: "Sila masukkan kata laluan",
		trade_success: "Transaksi berjaya",
		placeEnterAmount: "Sila masukkan jumlah",
		tipTit1: "Anda pasti ingin membayar untuk pesanan ini",
	},
	web: {
		account: {
			menu1: "Maklumat akaun",
			menu2: "Aset saya",
			menu3: "Laporan Akaun",
			menu4: "Keselamatan akaun",
			menu5: "Notice",
			menu6: "Pusat Aktiviti",
			menu7: "Dompet saya",
			menu8: "Cash Out",
			walletAdd: "Add a new wallet",
			report: {
				history_tit: "Laporan Sejarah Transaksi",
				stream_tit: "Laporan aliran utama",
				desc: "Sila pilih julat tarikh yang sesuai untuk mengeksport laporan akaun. (Anda boleh semak maklumat akaun dalam tahun lepas)",
				select_date: "Pilih julat tarikh",
				week: "Minggu lepas",
				month: "Bulan lepas",
				threeMonth: "Dalam tiga bulan terakhir",
				sixMonth: "Dalam enam bulan terakhir",
				time: "Masa",
				view_report: "Papar Laporan",
				email_send: "Hantar melalui emel",
				dialog_tit: "Laporan Akaun",
				dialog_p1: "StratTrade Holding Ltd terdaftar di Kepulauan Cayman (nombor SIB 1612446) dan memproses transaksi anda sebagai subjek transaksi.",
				tit2: "Laporan Sejarah Transaksi Mitade",
				tit4: "Laporan Aliran Dana Mitade",
				tit3: "Maklumat akaun",
				label1: "Tajuk akaun",
				label2: "ID Akaun",
				label3: "Akaun",
				label4: "Masa laporan",
				label5: "Period laporan",
				label6: "Aliran utama",
				empty: "Anda tidak mempunyai perdagangan tertutup",
				email_des: "Laporan akan dihantar ke e-mel anda",
				tab1: "Laporan",
				tab2: "Pernyataan sehari",
				tab3: "Pernyataan bulanan",
			},
			account: {
				tit1: "Selamat datang di StratTrade",
				tit2: "Pengesahan identiti lengkap untuk melanjutkan transaksi deposit sebenar",
				p1: "Membuka akaun transaksi sebenar",
				p2: "Pengesahihan",
				btn1: "Pengesahihan",
				tit3: "Maklumat peribadi",
				label_name: "Nama",
				label_email: "E-mel",
				label_address: "Alamat tinggal",
				tit4: "Maklumat akaun",
				label_account: "Akaun perdagangan (transaksi sebenar)",
				label_currency: "Warna asas (transaksi sebenar)",
				label_company: "Syarikat pembukaan akaun semasa",
				company_tip: "StratTrade Holding Ltd, syarikat di bawah Kumpulan StratTrade, telah mendapat kebenaran dan kebenaran dari Autoritas Monetar Kepulauan Cayman (CIMA).",
				tit5: "Membuka akaun transaksi sebenar",
				tit6: "Perbaiki maklumat",
				tit7: "Isi maklumat asas anda",
				tit8: "Penilaian risiko",
				tit9: "Nilai toleransi risiko perdagangan",
				tit10: "Pengesahihan",
				tit11: "Penyemak identiti lengkap mengikut keperluan peraturan",
				p3: "Sila pilih mana-mana dokumen sokongan berikut untuk pengesahan",
				type1: "Kad ID",
				type2: "Lesen pemandu",
				type3: "Pasport",
				p4: "Saya mengesahkan bahawa semua maklumat dan dokumen yang disediakan adalah lengkap, benar, dan tepat. jika ada kemaskini kepada maklumat, saya berjanji untuk menyediakan maklumat kemaskini kepada StratTrade dalam 30 hari.",
				next: "Langkah berikutnya",
				btn2: "Sijil",
				modify_personal_tit: "Ubahsuai maklumat peribadi",
			},
			activity: {
				tit: "Pusat Aktiviti",
				desc: "Anda boleh berpartisipasi dalam aktiviti untuk menerima imbalan dan meningkatkan pendapatan pelaburan anda",
			},
			capital: {
				real_trade: "Transaksi sebenar",
				label_nowAccount: "Akaun semasa",
				label_tradeAccount: "Akaun perdagangan",
				label_currency: "Warna asas",
				deposit_withdraw_record: "Rekod deposit dan penarikan",
				capital_flow_details: "Perincian aliran utama",
			},
			notification: {
				tit: "Tetapan Pemberitahuan",
				tit1: "Buka saluran pemberitahuan",
				email: "E-mel",
				message: "Mesej",
				push: "Tekan",
				verify: "Dahkan",
				verified: "Disahkan",
				p1: "Sila buka/sahkan saluran pemberitahuan berikut, mesej penting tidak terlepas!",
				type1: "Pemasaran",
				tit2: "Pemberitahuan Peristiwa Pemasaran",
				desc2: "Maklumat promosi platform, aktiviti operasi, dan mesej lain",
				tit3: "Arahan operasi",
				desc3: "Bahan pengetahuan kaya dan profesional",
			},
			security: {
				tit: "Katalaluan",
				isSet: "Tetapkan",
				set: "Untuk ditetapkan",
				verify: "Dahkan",
				verified: "Disahkan",
				tit1: "Pengesahan keselamatan",
				email: "E-mel",
				phone: "E-mel",
				phone_tit: "Pengikatan nombor telefon",
				password_tit: "Ubah kata laluan",
			},
		},
		market: {
			hot: "Popular",
			prev: "Halaman terakhir",
			next: "Halaman berikutnya",
			line: "Perkongsian masa",
			main_subplot: "Penunjuk imej utama dan bantuan",
			main: "Penunjuk imej utama",
			subplot: "Penunjuk imej sekunder",
			go_trade: "Lompat ke halaman transaksi",
		},
		news: {
			tip: "Penolakan: Maklumat di atas adalah umum dan hanya untuk rujukan, dan tidak digunakan sebagai dasar untuk keputusan perdagangan.",
		},
		trade: {
			state1: "Kedudukan memegang",
			state2: "Senarai",
			state3: "Dejarah",
			state: "Keadaan",
			state4: "Kedudukan ditutup",
			state5: "Dibatalkan",
			type: "Jenis",
			type1: "Keseluruhan",
			type2: "Jual",
			type3: "Beli",
			left_label1: "Pilihan sendiri",
			left_label2: "Paparan Baru-baru ini",
			left_type1: "Mod Lajur Kompak",
			left_type2: "Mod lajur lepas",
			left_type3: "Papar carta trend",
			all: "Keseluruhan",
			right_bp: "Diterima semalam",
			right_sp: "Koleksi hari ini",
			right_height: "Tertinggi",
			right_low: "Minimum",
			remind_tit: "Peringatan Baru",
			remindList: "Senarai Pengingatan",
			remind_btn: "Peringatan Baru",
			right_tab1: "Transaksi",
			right_tab2: "Perincian",
			right_sell: "Jual",
			right_buy: "Beli",
			right_type: "Jenis",
			right_type1: "Pasaran ",
			right_type2: "Tertunda ",
			right_priceMoney: "Gantung harga unit",
			right_pointerNumber: "Kuantiti (tangan)",
			right_lever: "Lever",
			right_balance: "Imbangan tersedia",
			right_stop_profit: "Hentikan tambahan",
			right_stop_loss: "Hentikan kehilangan",
			right_profit_loss: "Gaji dan kehilangan",
			now_order: "Tempatkan arahan segera",
			confirm_order: "Sahkan Perintah",
			right_tit1: "Strategi perdagangan",
			right_rinei: "Dalam hari",
			right_short: "Short-term",
			right_middle: "Tempoh tengah",
			right_time: "Masa pembebasan",
			right_tit2: "Strategi Alternatif",
			right_tit3: "Comment",
			right_tit4: "Data",
			right_tit5: "Sentimen perdagangan",
			right_label_sell: "Penjual",
			right_label_buy: "Pembeli",
			right_tip: "Untuk rujukan sahaja, bukan sebagai kedudukan syarikat kita",
			right_tip2: "Kemaskini setiap 15 minit",
			right_tit6: "Perubahan harga",
			minute: "Minit",
			today: "Hari ini",
			right_tit7: "Julat variasi harga",
			now_price: "Harga semasa",
			right_low_price: "Harga paling rendah",
			right_height_price: "Harga maksimum",
			right_tit8: "Maklumat Kontrak",
			right_tit9: "AUD ke CHF",
			right_label1: "Bilangan transaksi tunggal",
			right_label2: "Pangkalan maksimum",
			right_label3: "Saiz kontrak",
			right_label4: "Jumlah kedudukan maksimum",
			right_label5: "Perbezaan harga mengapung",
			right_label6: "Gaji semalam",
			hand: "Tangan",
			right_collection_time: "Masa koleksi",
			right_tip3: "Asas cukai: dimuatkan dari saiz keseluruhan transaksi",
			right_label7: "Aras Margin",
			right_label8: "Kekalkan nisbah margin",
			right_label9: "Masa perdagangan",
			right_label10: "Masa perdagangan semasa",
			right_label11: "Masa perdagangan berikutnya",


		},
	},
	home: {
		banner_p1: "Adop",
		banner_p2: "Transaksi Platform",
		banner_p3: "Penukaran asing, komoditi, indeks, stok",
		banner_p4: "Dan produk kewangan yang lebih populer",
		banner_p5: "Anda boleh mengeksplorasi pasar kewangan paling panas di dunia. StratTrade menawarkan kosong komisi, perbezaan harga rendah yang sangat bermanfaat, dan konfigurasi leverage fleksibel.",
		hot: "Popular",
		tit1: "Transaksi Lebih Sahabat",
		p1: "Antaramuka mudah dan intuitif untuk operasi mudah",
		p2: "Sokongan aplikasi bergerak berasaskan web dan transaksi desktop pada ujung jari anda",
		p3: "Alat pengurusan risiko seperti menghentikan kehilangan/jejak menghentikan kehilangan",
		p4: "Carta berbilang dan masa-real",
		p5: "Emel, SMS, dan tekan pemberitahuan",
		p6: "Perbaikan terus menerus untuk mencapai pengalaman perdagangan yang lebih baik",
		btn1: "Menjelaskan platform kami",
		row3_tit: "Perdagangan bebas bila-bila masa, di mana-mana",
		row3_tip: "Sokongan aplikasi bergerak berasaskan web dan transaksi desktop pada ujung jari anda",
		row3_tit2: "Muat Turun Kod Isian",
		row3_tit3: "Desktop",
		row3_tit4: "Penghujung bimbit",
		regular_tit: "Platform antarabangsa yang boleh dipercayai",
		regular_tip: "Kami berkomitmen untuk menyediakan pelanggan persekitaran perdagangan yang selamat dan bertanggungjawab。",
		regular_tit1: "Autoritas peraturan",
		regular_p1: "Pengawalan dan kebenaran kuasa industri, memenangkan kepercayaan pelanggan global",
		regular_tit2: "Melindungi Keselamatan Kewangan",
		regular_p2: "Deposit pelanggan retail dipindahkan secara terpisah dalam akaun kepercayaan seperti yang diperlukan oleh peraturan peraturan",
		regular_tit3: "Perlindungan keseimbangan negatif",
		regular_p3: "Kami memberikan and a perlindungan keseimbangan negatif untuk akaun anda, dan keseimbangan negatif dibersihkan kepada sifar pada masa yang tepat, memastikan kehilangan anda tidak melebihi jumlah input dan perdagangan dengan tenang fikiran",
		regular_tit4: "5 * Perkhidmatan pelanggan 24 jam",
		regular_p4: "Pasukan perkhidmatan pelanggan yang jujur dan profesional dengan sokongan online 24 jam bersedia untuk memecahkan sebarang masalah anda",
		service_tit: "Lebih banyak perkhidmatan untuk membantu anda",
		service_tit1: "Strategi perdagangan",
		service_p1: "Strategi perdagangan masa sebenar membantu anda memahami trends pasar terbaru dan mengambil peluang perdagangan lebih baik.",
		service_tit2: "Pengetahuan perdagangan",
		service_p2: "Belajar pengetahuan perdagangan secara percuma dengan StratTrade untuk meningkatkan kemahiran perdagangan anda.",
		service_tit3: "Pengurusan risiko",
		service_p3: "Belajar tentang alat pengurusan risiko bebas StratTrade untuk melindungi aset anda lebih baik.",
		step_tit: "Membuka akaun adalah mudah dan mudah",
		step_tip: "Tiga langkah sederhana untuk membuka akaun dalam beberapa minit",
		step_tit1: "Daftar",
		step_p1: "Isi maklumat dan hantar aplikasi anda",
		step_tit2: "Deposit tunai",
		step_p2: "Banyak cara untuk deposit dana dengan cepat",
		step_tit3: "Mulakan perdagangan",
		step_p3: "Menjelaskan peluang perdagangan dan letakkan perintah dengan cepat",
		award_tit: "Gred kami",
		award_p1: "Kami sentiasa mengejar keajaiban dan keajaiban, berkomitmen untuk menyediakan pelanggan dengan perkhidmatan perdagangan berkualiti tinggi.",
		award_p2: "Tanda StratTrade sangat terhormat untuk menerima hadiah dari institusi terkenal di industri, mengakui usaha dan komitmen pasukan untuk pelanggan.",
		row7_tit: "Maklumat keuangan global masa reali",
		row7_tit1: "Berita masa sebenar",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "Klik untuk muat turun",
		p1: "Berjual bila-bila masa, di mana-mana",
		p2: "Transaksi yang lebih cepat dan lebih bijak",
		p3: "0 transaksi komisi",
		p4: "Berjenis pelaburan paling panas di dunia",
		renderDom1: "Pertukaran asing",
		renderDom2: "Bahagian AS",
		renderDom3: "Emas",
		renderDom4: "Minyak mentah",
		renderDom5: "Indeks",
		renderDom6: "Penulisan",
		login: "Login",
		elail: "kotak mel",
		phone: "Nombor telefon bimbit",
		no_account: "Tiada akaun？",
		go_register: "Pergi mendaftar",
		register: "Daftar",
		now_register: "Daftar Sekarang",
		have_account: "Akaun yang wujud？",
		go_login: "Pergi log masuk",
	},
	about: {
		serviceFee: "Service charge",
		introduction: "Perkenalan Platform",
		trade_platform: "Platform perdagangan",
		complaint_process: "Proses keluhan",
		page: "Halaman",
		place_search: "Cari apa yang anda mahu tahu",
		no_answer: "Tidak dapat cari jawapan yang anda perlukan?",
		contact_us: "Hubungi Kami",
		awards: {
			h1: "Hadiah dan Kehormatan",
			p1: "Kami sentiasa mengejar keajaiban dan keajaiban, berkomitmen untuk menyediakan pelanggan dengan perkhidmatan perdagangan berkualiti tinggi. Tanda StratTrade sangat terhormat untuk menerima hadiah dari institusi terkenal di industri, mengakui usaha dan komitmen pasukan untuk pelanggan.",
			p2: "Atau segera mengalami platform perdagangan online yang menang"
		},
		charges: {
			h1: "Gaji dan gaji",
			p1: "Sebagai komponen untuk memperbaiki pengalaman pelanggan, StratTrade berharap untuk menyediakan pelanggan dengan perkhidmatan perdagangan yang lebih berkesan. Kami berkomitmen untuk menjaga keseluruhan dan keseluruhan dalam bayaran dan bayaran, kecuali bayaran tersembunyi. Biaya transaksi utama untuk pelanggan adalah penyebaran tawaran-minta dan faedah semalam pada kedudukan mereka.",
			h2: "Pertama, anda boleh menikmati",
			li1: "Petikan produk masa sebenar",
			li2: "Penunjuk teknikal profesional",
			li3: "Analisi pasar eksklusif",
			free: "Bebas",
			view: "Lihat pada",
			h3: "Jalur Tanda-Tanda",
			p2: "StratTrade membayar untuk perkhidmatannya dengan meminta bayaran perbezaan harga tawaran. Bahagian ini dari kos telah disebut dalam petikan produk, dan apabila and a menetapkan kedudukan, anda sebenarnya membayar perbezaan harga. Kerana bayaran perbezaan harga tidak ditetapkan, kami cadangkan anda pergi ke halaman petikan produk tertentu atau log masuk ke",
			h4: "Gaji deposit dan penarikan",
			p3: "Untuk mengurangi biaya pelanggan, kami tidak akan meminta bayaran untuk deposit dan penarikan anda dalam kebanyakan kes. Tetapi syarikat-syarikat-pihak ketiga atau bank boleh meminta bayaran pengurusan seperti bayaran bank intermedier semasa proses deposit dan penarikan anda",
			p4: "Untuk situasi di mana bayaran perlu ditanggung, anda boleh periksa dengan pakar perkhidmatan pelanggan kami.。",
			p5: "Contohnya, melalui transaksi kad kredit antarabangsa, pemindahan akaun bank, atau transaksi dalam valuta tidak disokong (pertukaran valuta asing), dll.",
			h5: "Kost pemegang sepanjang malam",
			p6: "Jika anda masih memegang kedudukan pada pukul 10:00 Greenwich Mean Time (GMT 22:00), anda akan diberikan bayaran semalam. Untuk maklumat mengenai biaya semalam untuk setiap produk, anda boleh pergi ke halaman petikan produk khusus atau log masuk ke",
			h6: "Biaya lain",
			p7: "Tiada bayaran lain. Polisi tuntutan kami benar-benar transparen, dan setiap item yang memerlukan tuntutan akan diberitahu dan diberitahu secara awal, tanpa bimbang tentang tuntutan implicit."
		},
		contact: {
			h1: "Hubungi Kami",
			address: "Alamat",
			custom_email: "E-mel kenalan pelanggan",
			business_email: "E-mel kerjasama perniagaan",
			h2: "Tulis surat.",
			p1: "Jika anda mempunyai sebarang soalan mengenai perkhidmatan kami, sila isi formulir berikut untuk menghantar soalan, dan pakar perkhidmatan pelanggan kami akan menjawab secepat mungkin. Selain itu, jika anda ingin mendaftar untuk strategi perdagangan harian yang disediakan oleh kerjasama kami dengan Pusat perdagangan, sila pilih 'Tanggalkan ke Analisi Pasar Harian' dalam jenis jadual berikut dan isi versi bahasa yang anda ingin menerima. Kami akan mendaftar untuk anda* Perkhidmatan pendaftaran hanya berlaku untuk pelanggan sebenar StratTrade yang wujud.",
			h3: "Prosedur panggilan",
			p2: "Kami akan mempunyai satu pasukan dedikasi untuk menyelidiki dan menyelesaikan semua keluhan untuk and a. Sila rujuk kepada perincian untuk rujukan",
		},
		faq: {
			h1: "Soalan Tertanya",
			tit1: "Apa guna akaun simulasi？",
			tit2: "Bagaimana untuk membuka akaun sebenar？",
			tit3: "Bagaimana untuk menetapkan senarai harga pasar？",
			tit4: "Mengapa saya tidak boleh meletakkan pesanan？",
			p1: "Akaun simulasi dan akaun sebenar adalah kebanyakan sama dalam terma antaramuka transaksi, data, dan operasi. Akaun simulasi mengandungi $50000 dalam dana maya, dengan tujuan untuk memperkenalkan pelanggan dengan berbagai-bagai fungsi platform melalui operasi simulasi tanpa sebarang risiko kewangan.",
			p2: "Sila ikut langkah di bawah untuk membuka akaun sebenar：",
			p3: "1. Masukkan halaman pendaftaran StratTrade dan ikut maklumat: daftar nombor e-mel/telefon, tetapkan kata laluan daftar masuk, dan secara automatik cipta 'akaun simulasi' selepas menghantar pengesahan.",
			p4: "2.Pada halaman akaun simulasi, pilih 'Tukar ke Akaun Sebenar' dan ikut proses untuk membuka 'Akaun Sebenar'.",
			p5: "Sebelum membuka akaun sebenar, kami cadangkan and a membaca dan memahami dokumen penemuan undang-undang berkaitan dengan transaksi.",
			p6: "Sila perhatikan bahawa anda hanya boleh deposit dana ke akaun sebenar anda selepas selesai pengesahan identiti.",
			p7: "Klik pada 'Perdagangan' akan memaparkan semua produk yang platform boleh tawarkan untuk perdagangan. Anda boleh masukkan kod atau nama pelbagai dalam kotak input di sebelah kanan untuk mencari, pilih produk, dan klik pada 'jual/BUY' untuk muncul tetingkap perdagangan. Dalam tetingkap perdagangan, and a boleh melihat harga semasa dan marji yang diperlukan. Anda boleh menyesuaikan secara manual bilangan perdagangan terbuka, menetapkan keuntungan berhenti dan berhenti kehilangan untuk kawalan risiko, dan kemudian klik 'jual/BUY' di bawah untuk menyelesaikan pembukaan. Sila perhatikan bahawa semua harga produk kewangan sentiasa berubah dan dikemaskini dengan pasar, dan pasar mungkin telah berubah sebelum anda tekan butang membeli/jual.",
			p8: "Pertama, sila periksa sama ada sambungan rangkaian normal. Apabila pasar pelbagai perdagangan ditutup sementara atau ditutup, ia akan menyebabkan ketidakmampuan untuk menempatkan perintah. Untuk maklumat lanjut, sila hubungi perkhidmatan pelanggan online kami untuk bantuan lanjut.",

		},
		fundSecurity: {
			h1: "Keselamatan keuangan",
			p1: "StratTrade dibenarkan dan diatur oleh agensi pengaturan yang berkuasa. Kehormatan adalah inti dan budaya setiap tugas yang kita lakukan. Keselamatan kewangan anda adalah penting, kerana semua deposit pelanggan terpisah dan disimpan.",
			p2: "Deposit pelanggan retail dipindahkan secara terpisah dalam akaun kepercayaan bila diperlukan sesuai dengan keperluan peraturan",
			p3: "StratTrade tidak menggunakan dana pelanggan untuk mana-mana aktiviti komersial diluar kebenaran undang-undang dan peraturan.",
			p4: "StratTrade tidak akan terlibat dalam aktiviti spekulatif di mana-mana pasar",
			p5: "Audit bebas oleh syarikat akaun pihak ketiga",
		},
		help: {
			h1: "Selamat datang ke Pusat Bantuan StratTrade",
		},
		media: {
			h1: "Pusat Media",
			p1: "Lihat maklumat, pemberitahuan pers, ciri media, pengumuman, dan maklumat lain berkaitan dengan markah StratTrade.",
		},
		mitrade: {
			h1: "Tentang StratTrade",
			view_certificate: "（Kaedah pengesahan）",
			p1: "StratTrade adalah syarikat teknologi keuangan yang dibenarkan dan diuruskan oleh institusi yang berwenang, fokus pada menyediakan pelabur dengan pengalaman perdagangan sederhana dan selesa. Platform perdagangan berbagai-bagai StratTrade yang inovatif telah berulang kali memenangkan hadiah seperti platform perdagangan bimbit terbaik dan syarikat sekuriti yang paling inovatif.",
			p2: "Melalui platform StratTrade, and a boleh melabur dan perdagangan dalam julat yang lebih luas pasar kewangan antarabangsa, meliputi ratusan jenis populari seperti stok, indeks, komoditi, pertukaran asing, dll. Bukan sahaja terbatas kepada platform perdagangan bimbit dan sesawang yang ramah bagi pengguna, StratTrade juga menyediakan and a dengan kos transaksi kompetitif, pelaksanaan transaksi cepat, perkhidmatan pelanggan yang baik, dan sokongan maklumat yang kaya, membantu anda mengambil peluang pelaburan dan perdagangan dengan cara yang tepat.",
			p3: "StratTrade dijalankan secara ketat oleh pihak berwenang industri dan beberapa institusi. StratTrade International dibenarkan dan diatur oleh Komisi Perkhidmatan Kewangan Mauritius (FSC) dengan nombor lesen GB20025791. Untuk maklumat lanjut pada plat lesen Mauritius, sila lawati laman web rasmi FSC https://www.fscmauritius.org",
			p4: "Lakukan pertanyaan. MStratTrade Global mempunyai Lesen Perkhidmatan Kewangan (AFSL 398528) yang dibenarkan oleh Australia Securities and Investments Commission (ASIC). Untuk maklumat lanjut pada plat lesen Australia, sila lawati laman web rasmi ASIC di www.asic.gov.au",
			p5: "Lakukan pertanyaan. ITrade Holding dibenarkan dan diatur oleh Kepulauan Kewangan Cayman Authority (CIMA) dengan nombor lesen SIB 1612446. Untuk maklumat lanjut pada plat lesen Cayman, sila lawati laman web rasmi CIMA di www.cima.ky",
			p6: "Lakukan pertanyaan. Semua operasi bisnes StratTrade dilakukan di bawah pengawasan ketat dan mematuhi semua peraturan.",
			h2: "Latar belakang StratTrade",
			p7: "StratTrade adalah satu pasukan senior dengan pengalaman dan pengetahuan yang kaya dalam perdagangan keuangan dan industri teknologi fintech, didirikan di Melbourne, Australia. Filosofi StratTrade adalah untuk membuat transaksi lebih mudah dan lebih ramah",
			p8: "Strategi terpasang dan model perniagaan kita memungkinkan kita mempunyai pengamatan dan pemahaman yang meliputi dan mendalam tentang pembangunan industri dan permintaan pasar, memungkinkan kita untuk mengalokasi sumber yang lebih sasaran, terus-menerus inovasi teknologi dan optimasi efisiensi, dan terus-menerus membawa pelanggan pengalaman perdagangan yang lebih selesa dan ramah. Pergi.",
			h3: "Mengapa memilih StratTrade",
			why1: "Keadaan perdagangan ambang rendah",
			why2: "Dipengawasi oleh pihak berwenang industri",
			why3: "Platform perdagangan sederhana dan intuitif",
			why4: "Sokongan talian tahap tinggi",
			why5: "Kost transaksi bersaing",
			why6: "Perlindungan keseimbangan negatif",
			h4: "Hadiah dan Kehormatan",
			p9: "Kami sentiasa mengejar keajaiban dan keajaiban, berkomitmen untuk menyediakan pelanggan dengan perkhidmatan perdagangan berkualiti tinggi.",
			p10: "Tanda StratTrade sangat terhormat untuk menerima hadiah dari institusi terkenal di industri, mengakui usaha dan komitmen pasukan untuk pelanggan.",
			new_add: "Pengguna Baru Global",
			ol1: "Pembekal terbaik maklumat berita dan analitik",
			ol2: "Perusahaan Securities Exchange Terbaik Asia",
			ol3: "Penghargaan Kesenangan Pelanggan Pertukaran Luar Negeri Australia",
			ol4: "Aplikasi Mobil Terbaik",
			ol5: "Syarikat jaminan yang tumbuh paling cepat di Australia",
			h5: "Filosofi operasi StratTrade",
			tip_tit1: "Tanggungjawab",
			tip_tit2: "Mudah tapi cantik",
			tip_tit3: "Transparen",
			tip_tit4: "Innovate",
			tip_p1: "Semua transaksi melibatkan risiko, dan StratTrade mengambil lebih banyak tindakan untuk menyediakan pelanggan dengan persekitaran perdagangan yang bertanggungjawab. StratTrade terkena kuasa industri, pengawasan ketat dari beberapa institusi, dan mematuhi standar operasi keselamatan tinggi dan kestabilan. Platform kita terus menyediakan pelbagai alat perdagangan bertanggungjawab, termasuk pengurusan risiko.",
			tip_p2: "Kesederhanaan adalah keindahan, dan perincian mencapai kelebihan. StratTrade berusaha untuk setiap perincian kesederhanaan, kemudahan penggunaan, dan pengalaman pengguna. Kami terus memperbaiki platform dan perkhidmatan kami, berharap bahawa semua orang, sama ada pelabur baru atau yang mengalami pengalaman, boleh dengan mudah mengambil peluang perdagangan melalui platform StratTrade.",
			tip_p3: "Untuk memberi keuntungan penuh kepada pelanggan kita dari persekitaran perdagangan yang adil dan efisien, StratTrade mengikuti sistem pengendalian diri yang ketat dan transparan, menyediakan maklumat platform yang meliputi dan transparan produk, persekitaran harga, dan data. Semua bayaran adalah jelas dan transparan, memastikan keseluruhan jujur dan transparan seluruh proses perkhidmatan.",
			tip_p4: "StratTrade yakin dengan teguh bahawa kemajuan teknologi, inovasi keuangan, dan perkhidmatan yang lebih luas adalah kekuatan mendorong untuk pertumbuhan, membolehkan penciptaan dan berkongsi nilai terus menerus. Untuk sebab ini, kami mendorong pemikiran terbuka, terus menerus mempromosikan inovasi, dan bertujuan untuk menjadi inovator dan pemimpin dalam teknologi keuangan.",
			row6_tit1: "Misi kita",
			row6_tit2: "Visi kita",
			row6_tit3: "Peluang kerja",
			row6_p1: "Untuk menyediakan persekitaran perdagangan yang adil, efisien dan transparan yang tidak terbatas oleh ruang, masa, atau pasar untuk lebih ramai orang yang mahu melakukan transaksi, memimpin inovasi keuangan dan teknologi industri.",
			row6_p2: "Kami percaya bahawa kemajuan teknologi, inovasi keuangan, dan perkhidmatan berbagai-bagai adalah kekuatan yang mendorong pertumbuhan kita, membolehkan kita terus-menerus mencipta dan berkongsi nilai dalam masyarakat.",
			row6_p3: "Untuk tujuan ini, kami mendorong pemikiran terbuka, mempromosikan inovasi, dan bertujuan untuk menjadi",
			row6_p4: "Syarikat pertukaran asing utama global",
			row6_p5: "Kami adalah pasukan global yang meliputi 7 negara, dan dengan pembangunan cepat pasukan, kami telah mencari bakat profesional untuk bergabung dengan kami. Bekerja dengan pasukan berbilang budaya untuk melakukan apa yang anda menikmati!",
			row6_p6: "Sila email kami resume terbaru anda dan sebab mengapa anda ingin bekerja dengan kami:",
			row6_p7: "Selain itu, anda juga boleh memeriksa",
			row6_p8: "Atau",
			row6_p9: "Lihat tempat kosong yang tersedia pada。",
			row6_p10: "StratTrade menjaga keselamatan maklumat peribadi anda. Semasa proses aplikasi, StratTrade tidak akan meminta maklumat keuangan dari anda. Penangkapan kami tidak memerlukan sebarang kaitan keuangan, kad kredit, maklumat akaun bank, atau sebarang bentuk pembayaran.",
			row6_p11: "Jika anda mencurigai perilaku penipuan dalam kedudukan tertentu, sila hubungi",
			row6_p12: "Hubungi Jabatan Sumber Manusia StratTrade. Jika anda mengesahkan bahawa anda telah ditipu oleh maklumat rekrutan palsu, sila hubungi jabatan institusi sesebuah anda.",

		}
	},
	product: {
		row2: {
			tit: "Variasi perdagangan",
			sell: "Jual",
			buy: "Beli",
			trade: "Transaksi",
			more_product: "Lebih banyak produk perdagangan",
		},
		row3: {
			tit: "Sokongan transaksi peranti berbilang",
			tit1: "Sokongan transaksi peranti berbilang",
			p1: "StratTrade memberikan anda platforma perdagangan web, bimbit, dan desktop. Anda boleh bertukar kapan-kapan, di mana-mana.",
			download: "Muat turun",
		},
		row4: {
			tit: "Pilih StratTrade",
			tit1: "Platform sederhana dan intuitif",
			p1: "Menyempurnakan pasar, perdagangan, pengurusan akaun, maklumat, dan pengurusan risiko, ia mempunyai fungsi yang kuat untuk mencapai pengalaman pengguna yang lebih baik",
			tit2: "Kost transaksi bersaing",
			p2: "0 komisi transaksi, perbezaan harga rendah yang sangat kompetitif dan transparan, faedah semalam yang rendah, membolehkan anda menikmati transaksi harga rendah",
			tit3: "Keadaan perdagangan ambang rendah",
			p3: "Bilangan minimum perdagangan per transaksi boleh menjadi sebanyak 0.01, dan margin rendah boleh digunakan untuk membuka perdagangan",
			tit4: "Di bawah pengawasan otoritas",
			p4: "Keizinan dan pengawasan berkuasa dalam industri, dengan semua deposit pelanggan terpisah dan disimpan secara terpisah untuk melindungi keselamatan aset pelanggan",
			tit5: "Perlindungan keseimbangan negatif",
			p5: "Dalam mana-mana syarat pasar, kehilangan akaun and a tidak akan melebihi jumlah utama, dan keseimbangan negatif akan dibersihkan pada masa yang tepat untuk meningkatkan kemampuan pengurusan risiko",
			tit6: "Sokongan talian tahap tinggi",
			p6: "Menyediakan balas pantas melalui perkhidmatan pelanggan online, kami akan menyediakan perkhidmatan kualiti yang lebih tinggi melalui usaha tanpa batas pasukan profesional",
		},
		row5: {
			tit: "Masalah berkaitan",
		},
		commodities: {
			title: "Perdagangan komoditi",
			p: "Logam berharga, tenaga dan komoditi lain dianggap berdua mampu melawan inflasi dan mempunyai peluang untuk menghargai pelaburan, menjadikannya komponen penting pelaburan berbagai-bagai. Di antara mereka, jenis-jenis perdagangan emas, perak, minyak dan barang-barang lain mempunyai volum perdagangan yang besar, dengan harga terbuka dan transparan dan cairan tinggi, yang telah diterima secara luas oleh pelabur. Harga komoditi bergerak secara signifikan disebabkan faktor seperti bekalan dan permintaan, situasi ekonomi dan polisi, dan wang, menyediakan peluang untuk kembalikan risiko.",
		},
		forex: {
			title: "Transaksi pertukaran asing",
			p: "Perdagangan pertukaran asing merujuk kepada pertukaran satu wang kepada yang lain, dan pasar pertukaran asing adalah pasar keuangan volum perdagangan terbesar di dunia. Terdapat banyak peserta dalam pasar pertukaran asing, yang membuat pembayaran, hedge terhadap perubahan wang, atau terlibat dalam transaksi untuk mendapatkan kembalian. Perdagangan pertukaran asing dilakukan melalui internet diantara bank, institusi, dan pedagang individu di seluruh dunia, dengan perbezaan harga berlaku 24 jam sehari, 5 hari seminggu, menyediakan peluang untuk kembalikan risiko.",
		},
		indices: {
			title: "Indices",
			p: "Indeks stok adalah nilai statistik yang mewakili jenis spesifik stok karakteristik pada pertukaran spesifik, yang mencerminkan aras harga keseluruhan dan perubahan bagi jenis spesifik stok pada pasar. Indeks saham perdagangan mencerminkan peluang pelaburan dalam pasar atau industri keseluruhan, yang boleh mengurangi risiko pelaburan dalam saham individu. Indeks paling populer di pasar Amerika Syarikat, Eropah, Asia dan Australia, seperti Australia 200, teknologi AS 100, Hong Kong 50, Jerman 30, dll., menyediakan peluang yang berbeza untuk pasar saham global.",
		},
		shares: {
			title: "Perdagangan saham",
			p: "Stok adalah jenis jaminan yang dikeluarkan dalam pertukaran. Untuk mengumpulkan dana, perusahaan akan meminjamkan kepemilikan syarikat melalui jaminan melalui pertukaran terdaftar. Pada tahun 2019 sahaja, jumlah perdagangan saham dunia melebihi $60 triliun, dengan skala besar dan limpah yang kuat. Oleh itu, walaupun selepas bertahun-tahun, ia tetap salah satu instrumen kewangan paling populer di mata pelabur global. Di platform StratTrade, pedagang boleh menggunakan leverage untuk perdagangan perubahan harga saham. Harga saham dipengaruhi oleh pelbagai faktor makroekonomi, prospek industri, dan operasi syarikat, dengan volatiliti tinggi dan peluang untuk pelaburan dan perdagangan setiap masa.",
		},
		coins: {
			title: "Penulisan",
			p: "Cryptocurrence adalah wang digital yang dicipta melalui kod. Mereka beroperasi secara bebas diluar skop sistem bank tradisional dan kerajaan. Seperti yang dikatakan nama, kriptocurrencies menggunakan teknologi penyulitan untuk memastikan keselamatan transaksi dan menguruskan penciptaan unit lain. Di antara mereka, Bitcoin adalah valuta kripto yang paling primitif dan terkenal sejauh ini. Bitcoin dicipta oleh Satoshi Nakamoto dan dilancarkan pada Januari 2009. Walaupun hari ini, masih belum diketahui sama ada nama Nakamoto merujuk kepada seseorang atau kumpulan. Bitcoin dianggap sebagai valuta kripto terhapus pertama. Seperti semua kriptocurrencies, ia dikawal melalui pangkalan data transaksi rangkaian blok, yang berkhidmat sebagai buku awam yang disebarkan. Sehingga hari ini, terdapat lebih dari 1000 kriptovaluta tersedia untuk perdagangan online."
		}
	},
	trade: {
		data_sources: "Sumber data",
		tip: "Maklumat di atas hanya untuk rujukan. StratTrade tidak menjamin ketepatan, masa sebenar, dan keseluruhan kandungan maklumat, jadi anda tidak perlu terlalu bergantung pada maklumat yang diberikan. Maklumat ini tidak termasuk rekod harga transaksi kita, atau apa-apa tawaran atau permintaan untuk transaksi instrumen keuangan. StratTrade bukan syarikat yang memberikan nasihat kewangan, dan hanya memberikan perkhidmatan dalam sifat arahan melaksanakan. Kami cadangkan bahawa pembaca mencari nasihat pelaburan yang relevan sendiri. Sila rujuk kepada penolakan lengkap kita.",
		tip2: "Dikemaskini setiap Jumaat pada 15:00 (GMT)",
		tip3: "Perspektif perdagangan adalah alat untuk meramalkan perasaan perdagangan pasar, mencerminkan pandangan jangka pendek dan jangka tengah ahli industri dan ramalan trends pada pelbagai produk populer.",
		analysis: {
			tit: "Strategi perdagangan",
			all: "keseluruhan",
			day: "Dalam hari",
			tit1: "Strategi perdagangan",
			tit2: "Comment",
		},
		calendar: {
			tit: "Kalendar Ekonomik",
			prev_week: "Minggu lepas",
			next_week: "Minggu depan",
			place_date: "Tarikh Carian",
			select: "Pilihan",
			select1: "Negara",
			select2: "Penting",
			start_time: "Masa permulaan",
			end_time: "Masa akhir",
			search: "Cari",
		},
		contract: {
			tit: "Perincian Kontrak",
			type1: "Keseluruhan",
			type2: "Pertukaran asing",
			type3: "Komoditi",
			type4: "Indeks",
			type5: "US Equity",
			type6: "Persamaan Australia",
			tit1: "Penukaran asing utama",
			tit2: "Penukaran asing sekunder",
			tit3: "Komoditi",
			tit4: "Indeks utama",
			tit5: "Indeks kedua",
			tit6: "US Equity",
			tit7: "Persamaan Australia",
			tit8: "Peraturan umum",
			p1: "Nisbah rata yang kuat",
			p2: "Period sah bagi tertib",
			p3: "Tanpa Had",
			p4: "Masa penyelesaian bayaran sepanjang malam",
			p5: "Pengurusan sehari; Musim panas: 05:00, Musim sejuk: 06:00",
			p6: "Aras, kadar semalam, dan maklumat lain",
			p7: "Sila rujuk ke platform perdagangan",
			p8: "Kandungan di atas berlaku untuk semua jenis perdagangan",
			tit9: "Kalendar Minggu Penjualan",
			p9: "Luar pasar",
			p10: "Jam perdagangan di atas tidak mempertimbangkan kesan cuti atau penyesuaian pasar istimewa。",
			p11: "Masa transaksi khusus disarankan",
			p12: "Platform Login",
			p13: "，Semak dalam perkenalan jenis yang berkaitan。",
		},
		forecast: {
			tit: "Perspektif perdagangan",
			p1: "Nilai rata-rata",
			p2: "Bullish",
			p3: "Beruang",
			p4: "Plat mengufuk",
			p5: "Tengah",
		},
		news: {
			tit: "Berita masa sebenar",
		},
		platforms: {
			tit: "Platform perdagangan StratTrade",
			p: "Antaramuka sederhana dan intuitif, prestasi perdagangan yang hebat, untuk memenuhi keperluan lebih pelabur. Mari kita mula bertukar di StratTrade hari ini.",
			tit1: "Platform perdagangan StratTrade yang sesuai dan mudah digunakan",
			tit2: "Berjual dengan Aplikasi StratTrade bila-bila masa, di mana-mana",
			li1: "Penjualan dalam ratusan pasar populer, pengawasan berkuasa memastikan keselamatan dana",
			li2: "Pemantauan trends pasar pada masa nyata, melihat analisis transaksi, dan melayar berita keuangan terbaru",
			li3: "Lihat jenis lain carta transaksi pada bila-bila masa, dengan fungsi lukisan aplikasi yang boleh capai",
			li4: "Pengalaman platform perdagangan pemenangan penghargaan StratTrade pada peranti Android atau iOS anda",
			code_download: "Muat Turun Kod Isian",
			tit3: "Tidak perlu muat turun, tukar secara talian melalui pelayar",
			li5: "Tiada muat turun diperlukan, transaksi secara langsung dalam pelayar PC",
			li6: "Dihadiahkan berbilang kali: Penyedar Pertukaran Luar Negeri Terbaik di Asia pada 2022, Platform Pertukaran Terbaik di Australia pada 2022, dan Penyedar Teknologi Pertukaran Luar Negeri Tercepat Bertumbuh di dunia pada 2022",
			li7: "Penunjuk teknik berkuasa: MACD, KDJ, RSI, TRIX, DMA, CCI, dll.",
			li8: "Dipersediakan dengan alat pengurusan risiko seperti menghentikan kehilangan dan perlindungan keseimbangan negatif untuk mengurangi risiko potensi transaksi anda",
			tit4: "Solusi sempurna untuk perdagangan desktop anda",
			li9: "Lihat trends pasar mudah dalam senarai yang dipilih",
			li10: "Benarkan fungsi peringatan untuk menerima peringatan perubahan harga dan pemberitahuan maklumat lain",
			li11: "Bentangan carta berbilang membolehkan anda mengesan trek pasar dari dimensi masa yang berbeza",
			li12: "Spesifikasi kontrak, perasaan transaksi, dan analisis transaksi semua berada pada antaramuka yang sama, tanpa perlukan untuk menukar",
			li13: "Dapatkan berita perdagangan terbaru langsung di StratTrade",
			tit5: "Mengapa memilih platform perdagangan StratTrade?",
			row5_p1: "Garansi keselamatan",
			row5_p2: "0 komisi",
			row5_p3: "Sokongan platform berbilang",
			row5_p4: "Carta Teknik",
			row5_p5: "Akses Ke Mana-mana",
			row5_p6: "Pelbagai alat lukisan",
		},
		quotes: {
			tit: "Petikan masa sebenar",
			p1: "Kami memberikan anda petikan masa sebenar, perasaan pasar, dan berita berkaitan dengan produk keuangan populer semasa.",
			hot: "Popular",
		},
		risk: {
			tit: "Pengurusan risiko",
			p1: "Semua transaksi melibatkan risiko, dan alat pengurusan risiko bebas yang disediakan oleh StratTrade boleh digunakan secara fleksibel untuk mengawal risiko secara efektif walaupun keadaan pasar.",
			function_tit1: "Hentikan fungsi keuntungan/hentikan kehilangan",
			function_tit2: "Mengejar fungsi kehilangan hentian",
			function_p1: "Kunci keuntungan",
			function_p2: "Kehilangan terhalang",
			function_p3: "Mengmaksimumkan terkunci dalam keuntungan",
			function_p4: "Tidak perlu mengawasi kedudukan secara automatik",
			function_p5: "Laras harga tutup untuk kerugian",
			row2_tit1: "Hentikan fungsi keuntungan/hentikan kehilangan",
			row2_p1: "Apabila menetapkan arahan baru atau mengubah arahan yang ada, kita boleh pilih untuk tetapkan 'hentikan keuntungan' dan 'hentikan kehilangan'. Selepas menetapkan, perintah secara umum akan dilikwidasi berdasarkan harga sasaran yang anda tetapkan, membantu anda mengunci keuntungan apabila mencapai sasaran atau mengurangi kerugian apabila tendangan pasar tidak baik. Sila perhatikan bahawa mana-mana perintah mungkin mengalami ruang disebabkan perubahan pasar, dan sistem tidak akan dapat melaksanakan perintah pada harga lalai, tetapi akan menutup kedudukan untuk and a pada harga yang paling baik dekat dengan harga sasaran.",
			row2_tit2: "Contoh",
			row2_p2: "Harga semasa EUR/USD adalah 1.13816/1.13837 (jual/beli). Anda telah menetapkan satu tangan (satu tangan = 100000 euro) membeli perintah pada 1.13837, dan menetapkan perintah berhenti kehilangan pada 1.13806.",
			row2_p3: "Secara umum, apabila harga turun ke 1.13806, arahan kehilangan hentian and a akan dipicu dan kedudukan akan ditutup dengan harga 1.13806, yang mengakibatkan kehilangan total $31.",
			row2_p4: "Namun, apabila persekitaran pasar mengalami perubahan tiba-tiba dan harga turun langsung dari 1.13837 kepada 1.13795, melepasi sasaran kehilangan hentian and a, pasar akan mengalami ruang dan sistem tidak akan dapat menutup kedudukan pada 1.13806. Namun, harga yang paling baik berikutnya, 1.13795, akan digunakan untuk menutup kedudukan untuk anda, yang mengakibatkan kehilangan akhir $42.",
			row3_tit1: "Mengejar fungsi kehilangan hentian",
			row3_tit2: "Contoh",
			row3_p1: "Fungsi menghentikan jejak kehilangan (juga dikenali sebagai kehilangan hentian bergerak) berkuasa, dan harga menghentikan kehilangan boleh berubah secara automatik dengan keuntungan, membolehkan anda maksimumkan keuntungan terkunci atau mengurangkan kehilangan tanpa memantau kedudukan anda secara terus menerus. Apabila mencipta perintah baru, anda hanya perlu tetapkan titik hentian pengjejak kehilangan. Apabila harga bergerak dalam arah yang baik untuk anda, perintah hentian kehilangan anda akan secara automatik dikemaskini berdasarkan harga terbaru. Sebaliknya, apabila harga berubah dalam arah yang tidak bermanfaat bagi and a, perintah berhenti kehilangan akan dimulakan dan kedudukan akan ditutup dalam jarak titik kehilangan yang anda tetapkan. Sila perhatikan bahawa mana-mana perintah mungkin mengalami ruang disebabkan perubahan pasar, dan sistem tidak akan dapat melaksanakan perintah pada harga lalai and a, tetapi akan menutup kedudukan untuk anda pada harga yang paling baik dekat dengan harga sasaran.",
			row3_p2: "Harga semasa untuk EUR/USD adalah 1.13816/1.13837 (jual/beli). Anda menetapkan satu arahan membeli tangan pada 1.13837 dan menetapkan kehilangan pengjejak 100 titik (0.00100).",
			row3_p3: "Apabila harga produk pada 1.13816, harga kehilangan hentian anda adalah 1.13716. Jika harga jualan produk meningkat ke 1.13845, harga kehilangan hentian akan dikemaskini mengikut jarak yang anda tetapkan, dan harga kehilangan hentian yang dikemaskini adalah 1.13745.",
			row3_p4: "Sebaliknya, bila harga produk turun dari 1.13845 ke 1.13745, kehilangan pengjejak akan dipicu dan kedudukan penutup akan dilaksanakan pada 1.13745.",
			tip: "Titik: Biasanya, unit minimum perubahan dalam harga instrumen kewangan dipanggil titik. Di platform StratTrade, ia merujuk kepada digit terakhir atau perpuluhan harga alat.",
		},
		sentiment: {
			tit: "Indeks Emosi",
			type1: "Keseluruhan",
			type2: "Pertukaran asing",
			type3: "Komoditi",
			type4: "Indeks",
			long: "Panjang",
			short: "Kedudukan Bearish",
		},
	},

	layout: {
		aside: {
			nav1: "Transaksi",
			nav2: "Pasar",
			nav3: "Maklumat masa-sebenar",
			nav4: "Sekolah",
			nav5: "Saya",
			newsDialog: {
				title: "Pusat Mesej",
				type1: "Pemberitahuan sistem",
				type2: "Pengumuman",
				allRead: "Tanda semua sebagai baca",
			},
			settingDialog: {
				title: "Tetapkan",
				nav1: "Rutin",
				nav2: "Papar keutamaan",
				nav3: "Maklumat sistem",
				logout: "Log keluar dari log masuk",
				setting1: "Bahasa",
				setting2: "Transaksi",
				setting2_tip: "Tambah automatik jenis gudang terbuka untuk pemilihan diri",
				setting3: "Feedback",
				setting3_tip: "Rekomendasi Fungsi",
				setting4: "Pengesahihan nama sebenar",
				setting4_tip: "Pengesahihan nama sebenar",
				setting5: "Mod tema",
				setting5_label1: "Gelap",
				setting5_label2: "Warna cerah",
				setting6: "Warna naik dan turun",
				setting6_label1: "Green rise and red fall",
				setting6_label2: "Bangun merah dan jatuh hijau",
				setting7: "Tetapan Carta",
				setting7_label1: "Editan piawai",
				setting7_label2: "Edit Paparan Penjualan",
				setting8: "Deklarasi dan Perjanjian",
				setting8_label1: "Polisi Privasi",
				setting8_label2: "Penjelasan Produk",
				setting8_label3: "Penjelasan Risiko",
				setting8_label4: "Perjanjian Pelanggan",
				feedback_title: "Rekomendasi Fungsi",
			}
		},
		footer: {
			hot: "Variasi Popular",
			sort: "Variasi",
			code: "Kod",
			buy_price: "Harga pembelian",
			sell_price: "Harga menjual",
			chg: "Julat pengalihan",
		},
		header: {
			place_search: "Cari jenis transaksi",
			balance: "Imbangan tersedia",
			balance_tip: "Imbangan yang tersedia: The amount in the account that can be used to open a new position",
			profit_loss: "Gaji dan kehilangan",
			asset_view: "Pangkalan Asas",
			netValue: "Nilai neto",
			netValue_tip: "Nilai neto: Nilai akaun semasa, termasuk keuntungan dan kehilangan semua posisi yang ditahan",
			marginLevel: "Aras Margin",
			margin: "Margin",
			margin_tip: "Margin: Jumlah yang diperlukan untuk membuka dan menyimpan semua kedudukan",
			maintainsMargin: "Kekalkan margin",
			maintainsMargin_tip: "Kekalkan Margin: Jumlah minimum margin yang anda perlukan untuk kekal dalam akaun anda apabila memegang semua posisi.",

		},
	},
	table: {
		label_name: "Nama",
		label_buyPrice: "Harga membeli",
		label_sellPrice: "Harga menjual",
		label_variableValue: "Nilai pembolehubah",
		trade: "Transaksi",
		label_code: "Kod produk",
		label_title: "Nama",
		label_start: "Penting",
		label_country: "Negara",
		label_event: "Peristiwa",
		label_previous: "Nilai terdahulu",
		label_predictive: "Estimate",
		label_announced: "Nilai yang diumumkan",
		label_stop_loss_min: "Jarak minimum tertib kehilangan hentian",
		label_difference: "Perbezaan harga dinamik",
		label_contract_size: "Saiz kontrak",
		label_max_hand: "Bilangan maksimum transaksi per transaksi",
		label_min_hand: "Bilangan minimum transaksi per transaksi",
		label_trade_week: "Kalendar Minggu Penjualan",
		label_week: "1 minggu",
		label_month: "1 Januari",
		label_quarter: "1 seperempat",
		label_dailyTrend: "Tenderasi sehari",
		label_tradeId: "ID Transaksi",
		label_breed: "Variasi",
		label_tradeType: "Jenis transaksi",
		label_currency: "Valuta",
		label_amount: "Wang",
		label_balance: "Balance",
		label_type: "Jenis",
		label_time: "Masa",
		label_orderNumber: "Nombor perintah",
		label_pointer_number: "Kiraan tangan",
		label_price_money: "Harga pembukaan",
		label_stop_win_price: "Hentikan tambahan",
		label_stop_lose_price: "Hentikan kehilangan",
		label_openTime: "Masa Buka",
		label_profit_loss: "Gaji dan kehilangan",
		label_sell_buy: "Jual/Pembelian Harga",
		label_chg: "Julat pengalihan",
		label_sell_price: "Harga menjual",
		label_buy_price: "Harga pembelian",
		label_condition: "Keadaan",
	},
	form: {
		label_feedback: "Soalan dan cadangan",
		place_feedback: "Sila masukkan soalan atau cadangan anda",
		label_img: "Gambar",
		label_img_tip: "Pilihan, sediakan gambar skrin masalah",
		feedback_tip: "Jika anda mempunyai soalan penting, sila hubungi",
		online: "Perkhidmatan Dalam Talian",
		label_name: "Nama",
		place_name: "Sila masukkan nama anda",
		label_email: "E-mel",
		place_email: "Alamat mel",
		message_email: "Sila masukkan alamat e-mel anda",
		label_nationality: "Kebangsaan",
		place_nationality: "Sila masukkan negara anda",
		place_questionType: "Jenis Masalah",
		message_questionType: "Sila pilih jenis",
		questionType0: "Platform perdagangan",
		questionType1: "Produk dan biaya",
		questionType2: "Tanggal ke analisis pasar sehari-hari",
		questionType3: "Perkhidmatan pelanggan",
		questionType4: "Lain",
		place_question: "Soalan anda",
		message_question: "Sila masukkan soalan",
		submit_question: "Hantar isu",
		label_phone: "Nombor telefon bimbit",
		place_phone: "Sila masukkan nombor telefon anda",
		message_phone: "Sila masukkan nombor telefon anda",
		label_password: "Katalaluan",
		place_password: "Sila masukkan kata laluan",
		message_password: "Sila masukkan kata laluan",
		label_confirmPassword: "Sahkan Katalaluan",
		place_confirmPassword: "Sila masukkan kata laluan lagi",
		message_confirmPassword: "Sila masukkan kata laluan lagi",
		label_captcha: "Kod pengesahan",
		place_captcha: "Sila masukkan kod pengesahan",
		message_captcha: "Sila masukkan kod pengesahan",
		get_captcha: "Dapatkan kod pengesahan",
		label_inviteId: "Kod jemputan",
		place_inviteId: "Kod jemputan (pilihan)",
		to: "Ke",
		start_time: "Masa permulaan",
		end_time: "Masa akhir",
		label_cardNumber: "Nombor Identifikasi",
		place_cardNumber: "Sila masukkan nombor ID anda",
		message_cardNumber: "Sila masukkan nombor ID anda",
		label_cardMain: "Hadapan ID",
		message_cardMain: "Sila masukkan depan ID",
		label_cardBack: "Di sisi belakang dokumen",
		message_cardBack: "Sila masukkan sisi belakang ID",
		confirm_modify: "Sahkan pengubahsuaian",
		label_realName: "Nama penuh",
		place_realName: "Sila masukkan nama pertama anda",
		message_realName: "Sila masukkan nama penuh anda",
		label_firstName: "Nama keluarga",
		place_firstName: "Sila masukkan nama terakhir anda",
		message_firstName: "Sila masukkan nama terakhir",
		label_lastName: "Nama",
		place_lastName: "Sila masukkan nama tengah anda",
		message_lastName: "Sila masukkan nama",
		label_birthday: "Tarikh lahir",
		place_birthday: "Sila masukkan tarikh kelahiran anda",
		message_birthday: "Sila masukkan tarikh lahir",
		place_nowPsd: "Sila masukkan kata laluan semasa",
		message_nowPsd: "Sila masukkan kata laluan semasa",
		place_newPsd: "Sila masukkan kata laluan baru",
		message_newPsd: "Sila masukkan kata laluan baru",
		place_crmPsd: "Sila sahkan kata laluan baru",
		message_crmPsd: "Sila sahkan kata laluan baru",
		label_breed: "Variasi",
		place_breed: "Sila masukkan jenis",
		label_phase: "Bila",
		label_buyPrice: "Harga pembelian",
		label_sellPrice: "Harga menjual",
		label_do: "Oleh",
		label_height: "Atas",
		label_low: "Bawah",
		label_equal: "Atau sama dengan",
		labelWalletName: "Nama Dompet",
		messageWalletName: "Sila masukkan nama dompet",
		placeWalletName: "Sila masukkan nama dompet",
		labelWalletAddress: "Alamat dompet",
		messageWalletAddress: "Sila masukkan alamat dompet",
		placeWalletAddress: "Sila masukkan alamat dompet",
		labelAmount: "Wang",
		messageAmount: "Sila masukkan jumlah",
		placeAmount: "Sila masukkan jumlah",
		placeTimeLimit: "Sila masukkan had masa",
		messageTimeLimit: "Sila masukkan had masa",
	},
	pay: {
		title: "Deposit dan penarikan yang sesuai",
		p1: "Banyak saluran pembayaran yang biasa digunakan, menyokong deposit dan tarik cepat",
		p2: "Beberapa kaedah pembayaran mungkin tidak tersedia di negara/kawasan anda",
		p3: "Selamat datang untuk mengalami perkhidmatan StratTrade",
		btn: "Buka akaun untuk transaksi secara segera",
	},
	header: {
		tip1: "Perdagangan berlebihan adalah produk keuangan kompleks dengan risiko tinggi kehilangan cepat.",
		btn1: "Platform perdagangan",
		btn2: "Log keluar dari log masuk",
		btn3: "Login",
		BigUint64Array: "Transaksi segera",
	},
	footer: {
		help: "Hubungi Kami",
		tip1: "Ikut komuniti kita.",
		tip2: "Sila perhatikan bahawa StratTrade tidak menetapkan kumpulan Telegram rasmi, dan mana-mana kumpulan Telegram yang dibentuk di bawah nama StratTrade adalah suspek penipuan.",
		tip3: "Peringatan risiko: Perdagangan boleh menyebabkan anda kehilangan semua dana anda. Perdagangan derivatif OTC tidak sesuai untuk semua orang. Sila baca dengan hati-hati dokumen undang-undang kami sebelum menggunakan perkhidmatan kami dan pastikan anda memahami sepenuhnya risiko yang terlibat sebelum perdagangan. Anda sebenarnya tidak memiliki atau memegang apa-apa aset yang berkaitan.",
		tip4: "StratTrade tidak memberikan nasihat, cadangan, atau pendapat mengenai membeli, memegang, atau menjual. Semua produk yang kita sediakan adalah derivat over-the-counter berdasarkan aset global. Semua perkhidmatan yang disediakan oleh StratTrade berdasarkan hanya melaksanakan arahan transaksi.",
		tip5: "StratTrade adalah markah perniagaan yang biasanya digunakan oleh beberapa syarikat dan beroperasi melalui syarikat berikut:",
		tip6: "StratTrade International Ltd adalah pengeluar produk kewangan yang diterangkan atau tersedia untuk digunakan di laman web ini. StratTrade International Ltd dibenarkan dan diuruskan oleh Komisi Perkhidmatan Kewangan Mauritius (FSC) dengan nombor lesen GB20025791 dan alamat terdaftar di 6 St Denis Street, 1st Floor River Court, Port Louis 11328, Mauritius",
		tip7: "StratTrade Global Pty Ltd mempunyai nombor pendaftaran ABN 90 149 011 361 dan nombor Lesen Perkhidmatan Kewangan Australia (AFSL) 398528.",
		tip8: "StratTrade Holding Ltd dibenarkan dan diatur oleh Autoritas Kewangan Kepulauan Cayman (CIMA) dengan nombor lesen SIB 1612446.",
		tip9: "Maklumat di laman web ini tidak ditakdirkan untuk penduduk Amerika Syarikat, Kanada, Jepun, atau New Zealand; Maklumat di laman web ini tidak sesuai bagi sesiapa di mana-mana negara atau yurisdiksi yang melanggar undang-undang atau peraturan tempatan kerana penerbitannya atau penggunaannya. Sila perhatikan bahasa Inggeris adalah bahasa utama yang digunakan dalam perkhidmatan kami dan juga bahasa yang mengikat secara sah dalam semua dokumen kontrak kami. Terjemahan bahasa lain hanya untuk rujukan. Dalam kes mana-mana ambiguiti antara terjemahan teks dan bahasa Inggeris, versi Inggeris akan menang.",
		tip10: "Penyulitan komunikasi SSL selamat. ©  StratTrade All rights reserved.",
		link1: "Polisi Privasi",
		link2: "Penjelasan Produk",
		link3: "Proses keluhan",
		link4: "Penjelasan Risiko",
		link5: "Perjanjian Pelanggan",
		toTop: "Atas",
	},
	nav: {
		tit1: "Produk",
		tit2: "Transaksi",
		tit3: "Pembelajaran",
		tit4: "Tentang kita",
		nav1: "Pertukaran asing",
		nav2: "Indeks",
		nav3: "Komoditi",
		nav4: "Kongsi",
		nav5: "Platform perdagangan",
		nav6: "Strategi perdagangan",
		nav7: "Perspektif perdagangan",
		nav8: "Kalendar Ekonomik",
		nav9: "Berita masa sebenar",
		nav10: "Petikan masa sebenar",
		nav11: "Indeks Emosi",
		nav12: "Pengurusan risiko",
		nav13: "Perincian Kontrak",
		nav14: "Pengenalan kepada Pelayanan",
		nav15: "Penglihatan Pelayanan",
		nav16: "Academy",
		nav17: "Tentang StratTrade",
		nav18: "Hadiah dan Kehormatan",
		nav19: "Pusat Media",
		nav20: "Keselamatan keuangan",
		nav21: "Gaji dan gaji",
		nav22: "Affiliates",
		nav23: "Hubungi Kami",
		nav24: "Soalan Tertanya",
		nav25: "Pusat Bantuan",
	},

	city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentina",
		armenia: "ARMENIA",
		australia: "Australia",
		pakistan: "Pakistan",
		austria: "Austria",
		bahrain: "Bahrain",
		belgium: "Belgium",
		bosnia_and_Herzegovina: "Bosnia and Herzegovina",
		brazil: "Brazil",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		cambodia: "Cambodia",
		canada: "Canada",
		cameroon: "Cameroon",
		chile: "Chile",
		colombia: "Columbia",
		costa_Rica: "Costa Rica",
		croatia: "Croatia",
		cyprus: "Cyprus",
		czech_Republic: "Czech Republic",
		denmark: "Denmark",
		dominican_Republic: "DOMINICAN REPUBLIC",
		egypt: "Egypt",
		estonia: "Estonia",
		ethiopia: "Ethiopia",
		finland: "Finland",
		france: "France",
		georgia: "Georgia",
		germany: "Germany",
		ghana: "Ghana",
		greece: "Greece",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Hungary",
		iceland: "Iceland",
		ireland: "Ireland",
		italy: "Italy",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Iran",
		iraq: "Iraq",
		japan: "Japan",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "South Korea",
		kuwait: "Kuwait",
		kyrgyzstan: "Kyrgyzstan",
		laos: "Laos",
		latvia: "Latvia",
		lithuania: "Lithuania",
		luxembourg: "Luxembourg",
		macao_China: "Macao, China",
		macedonia: "Macedonia",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Mexico",
		moldova: "Moldova",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "MONTENEGRO",
		morocco: "Morocco",
		myanmar: "Myanmar",
		netherlands: " Netherlands",
		new_Zealand: "New Zealand",
		nepal: "Nepal",
		nigeria: "Nigeria",
		norway: "Norway",
		oman: "Oman",
		palestine: "Palestine",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "the Philippines",
		poland: "Poland",
		portugal: "Portugal",
		puerto_Rico: "Puerto Rico",
		qatar: "Qatar",
		romania: "Romania",
		russia: "Russia",
		republic_of_Trinidad_and_Tobago: "trinidad and tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Saudi Arabia",
		serbia: "Serbia",
		singapore: "Singapore",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		south_Africa: "South Africa",
		spain: "Spain",
		sri_Lanka: "Sri Lanka",
		sweden: "Sweden",
		switzerland: "Switzerland",
		taiwan_China: "Taiwan, China",
		tajikistan: "Tajikistan",
		tanzania: "Tanzania",
		thailand: "Thailand",
		turkey: "Turkey",
		turkmenistan: "Turkmenistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "The United Arab Emirates",
		united_Kingdom: "Britain",
		united_States: "America",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaijan",
		bangladesh: "Bangladesh",
		belarus: "Belarus",
		belize: "belize",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "british virgin islands",
		burkina_Faso: "burkina faso",
		burundi: "Burundi",
		cape_Verde: "Cape Verde",
		cayman_Islands: "Cayman Islands",
		central_African_Republic: "Central African Republic",
		chad: "Chad",
		comoros: "Comoros",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Congo (Kinshasa)",
		djibouti: "Djibouti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Equatorial Guinea",
		eritrea: "ERITREA",
		fiji: "Fiji",
		gabon: "Gabon",
		gambia: "Gambia",
		greenland: "Greenland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Isle of Man",
		cote_d_Ivoire: "Côte d'Ivoire",
		jamaica: "Jamaica",
		jordan: "Jordan",
		lebanon: "Lebanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libya",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mozambique",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "the Niger",
		north_Korea: "Korea",
		reunion: "Reunion",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalia",
		sudan: "sultan",
		suriname: "Surinam",
		eswatini: "Eswatini",
		syria: "Syria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "Virgin Islands, United States",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatican",
		yemen: "Yemen",
		yugoslavia: "Yugoslavia",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
		china: "China",
	}
};
