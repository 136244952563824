export default {
    common: {
        learn: {
            title1: "L'équilibre principal",
            content1:
                "StratTrade offre gratuitement notre service de solde en espèces et en crypto - monnaies gérées, vous permettant de stocker gratuitement de l'argent et des crypto - monnaies prises en charge. Vous pouvez transférer des crypto - monnaies du solde principal d'un utilisateur StratTrade vers le compte principal d'un autre utilisateur sans frais.\n" +
                "\n" +
                "Frais de transaction réseau\n" +
                "\n" +
                "Pour les transactions sur le réseau de crypto - monnaie (c. - à - D. le transfert de crypto - monnaie depuis la plate - forme StratTrade), StratTrade encourt et paie des frais de transaction sur le réseau (par exemple, les frais de minage). Lorsque vous envoyez une crypto - monnaie d'un portefeuille StratTrade vers un autre portefeuille, nous facturons des frais basés sur notre estimation des frais Web actuels pour les envois de portefeuille à portefeuille indépendants. Cependant, les frais finaux payés par StratTrade peuvent différer des frais estimés en raison de facteurs tels que les transactions par lots ou les changements dans les niveaux de congestion du réseau. Tous les frais sont divulgués au moment de la transaction.\n" +
                "\n" +
                "Gains d'efficacité et frais de réseau\n" +
                "\n" +
                "Étant donné que les transactions par lots peuvent améliorer l'efficacité, le montant total estimé des frais de réseau payés par les utilisateurs dans un lot donné peut dépasser les frais de réseau finaux payés par StratTrade.\n" +
                "\n" +
                "Frais de traitement Lightning Network\n" +
                "\n" +
                "Lightning Network est un réseau de micropaiement qui peut transférer des bitcoins sans encourir les frais de transaction associés à la blockchain bitcoin. Lorsque vous envoyez des bitcoins de StratTrade via Lightning Network, nous facturons des frais de traitement équivalents à 0,1% du montant du bitcoin transféré.\n" +
                "\n" +
                "Ajouter de l'argent et encaisser\n" +
                "\n" +
                "Lorsque vous ajoutez de l'argent ou effectuez un retrait, des frais peuvent vous être facturés en fonction du mode de paiement que vous avez choisi. Avant de finaliser une transaction, il est important de vérifier les détails des frais pour comprendre les frais associés.\n" +
                "\n" +
                "Transactions de crédit\n" +
                "\n" +
                "Si vous empruntez des dollars américains à StratTrade ou à une filiale de StratTrade, nous devons vendre votre garantie BTC (autorisée en vertu du contrat de prêt applicable) et nous facturerons des frais fixes de 2% du montant total de la transaction.",
            title2: "Frais de transaction et Spreads",
            content2:
                "StratTrade dépenses\n" +
                "\n" +
                "Des frais sont facturés lorsque vous achetez, vendez ou convertissez des crypto - monnaies sur StratTrade. Ces frais sont calculés lorsque vous passez une commande et peuvent être influencés par des facteurs tels que le mode de paiement que vous choisissez, la taille de votre commande, les conditions du marché, la juridiction et d'autres coûts que nous engageons pour faciliter votre transaction.\n" +
                "\n" +
                "Vous pouvez voir les frais dans l'écran de prévisualisation des transactions avant de soumettre une transaction. Veuillez noter que les frais peuvent varier pour des transactions similaires. En complétant votre commande, vous acceptez de payer les frais StratTrade applicables pour cette transaction.\n" +
                "\n" +
                "Diffusion\n" +
                "\n" +
                "Lorsque vous passez un simple ordre d'achat et de vente, StratTrade inclut un spread dans votre devis. Lors de la conversion d'une crypto - monnaie à une autre, le Spread est également inclus dans le taux de change.\n" +
                "\n" +
                "Afficher les Spreads avant de prévisualiser les ordres d'achat, de vente ou de conversion sur l'écran de prévisualisation:\n" +
                "\n" +
                "Ordre d'achat et de vente: cliquez sur l'astuce à côté de l'élément de la ligne de prix de l'actif pour voir le Spread.\n" +
                "\n" +
                "Convertir un ordre: cliquez sur l'outil à côté du taux de change que vous avez indiqué.\n" +
                "\n" +
                "Les Spreads aident à augmenter les chances de réussite de la transaction et permettent à StratTrade de verrouiller temporairement votre offre pendant le traitement de votre commande. StratTrade peut conserver tout Spread excédentaire dans la transaction. Veuillez noter que les Spreads pour des transactions similaires peuvent varier.\n" +
                "\n" +
                "StratTrade avancé\n" +
                "\n" +
                "StratTrade Advanced n'inclut pas les Spreads car vous interagissez directement avec le carnet de commandes.\n" +
                "\n" +
                "StratTrade un\n" +
                "\n" +
                "StratTrade one est un produit d'abonnement qui offre l'achat et la vente gratuits de crypto - monnaie, sous réserve de certaines restrictions. Les membres peuvent toujours inclure la différence de prix dans leur offre.\n" +
                "\n" +
                "Variations des frais de transaction et Spreads\n" +
                "\n" +
                "StratTrade teste occasionnellement les variations des frais et des Spreads. Ces changements peuvent être déployés dans différentes régions, actifs, tailles d'ordres et types de transactions.\n" +
                "\n" +
                "StratTrade vous montre le montant total, y compris les Spreads et les frais, à chaque fois que vous prévisualisez une transaction et dans l'historique des transactions par la suite. \n" +
                "\n" +
                "Exécution des transactions\n" +
                "\n" +
                "Toutes les transactions de votre solde principal sont exécutées via le carnet d'ordres central à cours limité de StratTrade Exchange ou via des enchères concurrentielles. Vous pouvez voir le carnet de commandes, acheter, vendre et la profondeur du marché en temps réel. Ces carnets d'ordres déterminent les offres que vous voyez au cours de la négociation.\n" +
                "\n" +
                "StratTrade n'a aucune relation de paiement de flux d'ordres (pfof) avec les teneurs de marché.",
            title3: "La carte StratTrade",
            content3:
                "Pas de frais de transaction pour dépenser avec la carte StratTrade. \n" +
                "\n" +
                "StratTrade inclut des Spreads dans le prix d’achat ou de vente de crypto - monnaies. Cela nous permet de verrouiller temporairement le prix auquel la transaction est exécutée, tout en examinant les détails de la transaction avant que vous ne la soumettiez.  \n" +
                "\n" +
                "Un opérateur ATM peut vous facturer des frais. Consultez votre accord de titulaire de carte StratTrade pour plus de détails.",
            title4: "Service de gage",
            content4:
                "Aucun frais à payer. StratTrade prend une commission basée sur les récompenses que vous recevez du Web. La Commission standard est de 35% pour Ada, Atom, dot, Matic, sol et xtz. Pour Ada, Atom, dot, sol et xtz, la Commission est de 26,3% pour les membres StratTrade one éligibles. Vous pouvez trouver votre taux de commission ETH dans les détails de l'actif. Certains utilisateurs peuvent recevoir des offres pour parier sur des promotions à des prix inférieurs. L'apy et les récompenses affichées sur votre compte reflètent toujours les gains que vous gagnez après nos commissions. Vous trouverez plus d'informations sur les commissions dans le contrat utilisateur StratTrade.",
            title5: "Recouvrement d'avoirs",
            content5:
                "Si vous avez envoyé une crypto - monnaie non prise en charge à votre compte StratTrade, vous pouvez être éligible à une récupération. Nous facturerons des frais de réseau pour les tentatives de récupération. Pour les recouvrements dont la valeur estimée est supérieure à 100 $, nous appliquerons également des frais de recouvrement de 5% sur les montants supérieurs à 100 $. La valeur estimative du recyclage peut différer de la valeur marchande réelle du recyclage.",
        },
        award1: "Meilleurs courtiers CFD en Asie en 2023",
        award2: "Meilleurs courtiers au monde en 2022",
        award3: "Les courtiers les plus fiables en 2022",
        award4: "Les marques les plus fiables en 2021",
        award5: "Meilleur courtier fintech",
        award6: "Le courtier commercial le plus transparent",
        analysis: "Analyse",
        health: "Santé",
        country: "Pays",
        information: "Bureau d'enquête",
        help: "Aide",
        confirm: "Connaissance",
        cancel: "Abolition",
        tip: "Conseils",
        place_select: "Veuillez sélectionner",
        placeEnter: "Veuillez entrer",
        countryCode: "Code du pays",
        submit: "Soumettre ",
        more: "Plus",
        deposit: "Dépôt",
        real_trade: "La vraie transaction",
        paper_trade: "Transactions simulées",
        build_paperTrade: "Créer un compte démo",
        start_trade: "Commencez à trader maintenant",
        app_name: "StratTrade",
        success: "Cas de réussite！",
        dataSources_name: "Centre de Trading",
        reset: "Replay",
        iknow: "Savoir.!",
        noData: "Aucune donnée disponible",
        modify: "Modifier",
        pingcang: "Fermer une position",
        all: "Pleine",
        tip_cardMain: "Prenez ou téléchargez une photo du recto de votre carte d'identité",
        tip_cardBack: "Prendre ou télécharger une photo au dos de votre carte d'identité",
        tip_cardMain1:
            "Prendre ou télécharger une photo du recto de votre permis de conduire",
        tip_cardBack1:
            "Prendre ou télécharger une photo au dos de votre permis de conduire",
        tip_cardMain2: "Prendre ou télécharger une photo de passeport",
        tip_cardBack2: "Prendre ou télécharger une photo de passeport",
    },
    currency: {
        btcusdt: "Bitcoin",
        ethusdt: "Etherlands",
        ltcusdt: "Litecoin",
        xrpusdt: "Ripple (coin)",
        adausdt: "Ada currency",
        eosusdt: "Pomelo Coin",
        dotusdt: "Boca coin",
        trxusdt: "Wave field coin",
        xmrusdt: "Monroe Coin",
        dogeusdt: "Dogecoin",
        bnbusdt: "BNB",
        solusdt: "SOL",
        aptusdt: "Aptusdt",
        fx_saudusd: "AUD USD",
        fx_sgbpusd: "GBP USD",
        fx_seurusd: "Eurodollar",
        fx_snzdusd: "New Zealand Dollar (NZD) USD",
        fx_susdcad: "USD CAD",
        fx_susdchf: "USD CHF",
        fx_susdjpy: "USD JPY",
        fx_saudcad: "AUD CAD",
        fx_saudchf: "AUD CHF",
        fx_saudjpy: "AUD JPY",
        fx_saudnzd: "AUD New Zealand Dollar (NZD)",
        fx_scadchf: "CAD Swiss Franc",
        fx_scadjpy: "Canadian yen",
        fx_schfjpy: "CHF JPY",
        fx_seuraud: "EUR AUD",
        fx_seurcad: "EUR CAD",
        fx_seurgbp: "Euro Pound",
        fx_seurjpy: "Euro Yen",
        fx_seurnzd: "Euro New Zealand dollar (NZD)",
        fx_sgbpaud: "GBP AUD",
        fx_sgbpchf: "GBP CHF",
        fx_sgbpjpy: "GBP JPY",
        fx_sgbpnzd: "GBP NZD",
        fx_scadnzd: "Dollars canadiens et néo - zélandais",
        hf_CL: "WTI New York Crude Oil",
        hf_OIL: "Brent crude oil",
        hf_GC: "New York gold",
        hf_SI: "New York silver",
        hf_HG: "Copper (US)",
        hf_NG: "U.S. Natural Gas",
        hf_CAD: "Copper (chemistry)",
        hf_AHD: "Aluminium à Londres",
        HX_AAPL: "Pomegranate",
        HX_BABA: "Alibaba, PRC e-commerce company",
        HX_GOOG: "Google",
        HX_WMT: "Wal-Mart, Walmart (retailer)",
        HX_AMZN: "HX_AMZN",
        HX_TSLA: "Tesla",
        HX_N225: "Nikkei 225",
        HX_GDAXI: "DAX30, Allemagne",
        HX_IBEX: "IBEX35, Espagne",
        HX_SPX: "S&P 500 index",
        HX_FTSE: "FTSE 100",
        HX_KS11: "KOSPI Korea",
        HX_NZ50: "Nouvelle - Zélande 50",
        HX_PSI20: "Portugal PSI20",
        hf_ZSD: "London Zinc",
        hf_NID: "London Nickel",
        hf_PBD: "London lead",
        hf_SND: "London tin",
        hf_XAU: "London Gold (Spot Gold)",
        hf_XAG: "London Silver (Spot Silver)",
        hf_XPT: "Platinum futures",
        hf_XPD: "Palladium Futures",
        hf_CT: "US Coton",
        hf_SM: "Tourteau de soja américain",
        hf_BO: "U.S. soybean oil",
        HX_SENSEX: "SENSEX, Mumbai, India",
        HX_KLSE: "FTSE Malaysia KLCI",
        HX_KSE100: "Karachi, Pakistan",
        HX_FCHI: "CAC40, France",
        HX_SX5E: "European Snooker 50",
        HX_ICEXI: "ICEX, Iceland",
        HX_ASE: "ASE Athens, Grèce",
        HX_OMXC20: "OMX Copenhagen 20",
        HX_OSEBX: "OSEBX, Norvège",
        HX_OMXSPI: "OMXSPI, Suède",
        HX_AMD: "Chaowei Semi - conducteurs",
        HX_DIS: "Disney (Le nom)",
        HX_MELI: "MercadoLibre Inc",
        HX_PSX: "Phillips 66",
        HX_TRUE: "TrueCar Inc",
        HX_SDRL: "Seadrill Ltd",
        HX_MA: "MasterCard (La marque)",
        HX_NVDA: "NVIDIA, Société de cartes graphiques informatiques",
        HX_INTC: "Intel",
        HX_GOGO: "Gogo Inc",
        HX_STX: "Technologie Seagate",
        usdcusdt: "USDC",
        filusdt: "FIL",
        maticusdt: "MATIC",
        linkusdt: "LINK",
        avaxusdt: "AVAX",
        atomusdt: "AstroCoin",
        icpusdt: "ICP",
        uniusdt: "UNI",
    },
    currenctType: {
        title: "Veuillez sélectionner une devise",
        currenctType6: "USDT-ERC20",
        currenctType7: "USDT-TRC20",
        currenctType8: "BTC",
        currenctType13: "ETH",
        currenctType14: "Dollars américains",
        currenctType15: "Hong Kong currency",
        currenctType16: "Taiwan currency",
        currenctType17: "Ringgit",
        currenctType18: "Singapore dollar",
        currenctType19: "Yen",
        currenctType20: "Euro",
        currenctType21: "Dollar A",
        currenctType22: "Rupiah",
        currenctType23: "Pound",
        currenctType24: "Thai baht",
        currenctType25: "Dollars américains",
        currenctType26: "VND",
        currenctType27: "Korean won",
        currenctType28: "MOP",
    },
    addnew2: {
        address: {
            account_type: "Type de compte",
            ifsc_code: "IFSC CODE",
            title: "Adresse du portefeuille",
            account_number: "Numéro de compte",
            add_btn: "Ajouter une adresse",
            label_type: "Choisissez le type",
            place_type: "Veuillez sélectionner le type",
            label_number: "Compte",
            place_number: "Veuillez entrer le numéro de compte",
            wallet_address: "Adresse du portefeuille",
            bank_name: "Nom de la Banque",
            bank_address: "Adresse de la Banque",
            name: "Nom et prénom",
            payee_address: "Adresse du bénéficiaire",
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nom de la succursale",
            branch_code: "Code de branche",
            bank_code: "Code de la Banque",
            phone: "Numéro de téléphone portable",
            iban: "IBAN",
            sort_code: "Code de tri",
            place_inner: "Veuillez entrer le contenu",
        },
    },
    addnew: {
        paypassTit: "Modifier le mot de passe de retrait",
        paypassTit1: "Veuillez définir votre mot de passe de retrait",
        paypassTit2: "Initialiser le mot de passe à login password.",
        loan: "Prêts",
        repaid: "Remboursé",
        unpaid: "Non payé",
        loanMore: "J'ai besoin d'un prêt.",
        immediateRepayment: "Remboursement immédiat",
        accountType1: "Comptes de crypto - monnaie",
        accountType2: "Compte du contrat",
        accountType3: "Compte d'options",
        from: "Obéissance ",
        to: "À",
        get: "Obtiendra",
        labelBreed: "La monnaie",
        placeBreed: "Veuillez sélectionner une devise",
        labelTransAccount: "Compte",
        labelAmount: "Quantité",
        placeAmount: "Veuillez entrer le montant du transfert",
        transferTit: "Transfer",
        convertTit: "Conversion",
        balanceTit: "Solde estimé",
        available: "Disponible",
        pending: "Fermer avec un loquet",
        equivalent: "Équivalent à",
        coinTrade: "Trading de crypto - monnaie",
        secondContract: "Deuxième contrat",
        number: "Quantité",
        labelNumber: "Quantité",
        placeNumber: "Veuillez entrer le montant de la rançon",
        time: "Horaire",
        loadAmount: "Montant estimé du prêt",
        repaymentCycle: "Cycle de remboursement des prêts",
        dailyRate: "Tarifs quotidiens",
        repaymentMethod: "Modalités de remboursement",
        loanTip: "Prêt de crédit (s'il vous plaît assurez - vous que les images sont clairement visibles)",
        loanTip1: "Télécharger un certificat de propriété",
        loanTip2: "Preuve de revenu (emploi)",
        loanTip3: "Détails des relevés bancaires",
        loanTip4: "Télécharger une photo frontale de la carte d'identité",
        interest: "Intérêt (désir de savoir quelque chose)",
        repaymentMethod1: "Doit être remboursé en une seule fois",
    },
    params: {
        product_foreign: "Forex",
        product_shop: "Marchandises",
        product_number: "Index",
        product_stock: "Stock",
        product_coin: "Crypto - monnaie",
        billTypes: [
            { v: 101, name: "Recharge" },
            { v: 102, name: "Dépôt" },
            { v: 103, name: "Congelé" },
            { v: 104, name: "Décongeler" },
            { v: 105, name: "Dépôt" },
            { v: 106, name: "Dépôt" },
            { v: 201, name: "Retrait gelé" },
            { v: 202, name: "Retrait de fonds" },
            { v: 203, name: "Retrait réussi" },
            { v: 204, name: "Défaut de retirer des fonds" },
            { v: 205, name: "Frais de retrait" },
            { v: 206, name: "Transfer out" },
            { v: 207, name: "Basculer vers" },
            { v: 208, name: "Transfert de pièces de monnaie" },
            { v: 209, name: "Transfert de pièces de monnaie" },
            { v: 301, name: "Coût du contrat" },
            { v: 302, name: "Revenus des contrats" },
            { v: 303, name: "Pertes liées aux contrats" },
            { v: 304, name: "Dépôt de garantie du contrat" },
            { v: 305, name: "Retour sur marge contractuelle" },
            { v: 311, name: "Options d'achat" },
            { v: 312, name: "Gains d'options" },
            { v: 313, name: "Retour sur Options" },
            { v: 314, name: "Frais de traitement des options" },
            { v: 315, name: "Achat de pièces de monnaie Gel" },
            { v: 316, name: "Déduction pour achat de pièces de monnaie" },
            { v: 317, name: "Coins acheter retours" },
            { v: 318, name: "Coin acheter sur le compte" },
            { v: 319, name: "Coin acheter sur le compte" },
            { v: 320, name: "Gel des ventes de pièces de monnaie" },
            { v: 321, name: "Déduction pour vente de pièces de monnaie" },
            { v: 322, name: "Pièces de monnaie à vendre retours" },
            { v: 323, name: "Pièces de monnaie vendues au compte" },
            { v: 324, name: "Pièces de monnaie vendues au compte" },
            { v: 325, name: "Frais de traitement des pièces de monnaie" },
            { v: 401, name: "Les mineurs rejoignent" },
            { v: 402, name: "Retour des mineurs" },
            { v: 403, name: "Revenus miniers" },
            { v: 404, name: "Exportations minières" },
            { v: 405, name: "Frais de sortie minière" },
            { v: 411, name: "Revenus d'emprunt" },
            { v: 412, name: "Frais d'emprunt" },
            { v: 413, name: "Remboursement réussi" },
        ],
    },
    message: {
        logout: "Déconnexion",
        register_success: "Inscription réussie",
        place_inner_personal: "Veuillez d'abord entrer vos informations personnelles",
        submit_success: "Soumettre avec succès！",
        copy_success: "Copie réussie！",
        copy_error: "échec de reproduction！",
        modify_success: "Modification réussie",
        no_balance: "Le solde n'est pas suffisant pour payer le dépôt de garantie",
        palce_password: "Veuillez entrer votre mot de passe",
        trade_success: "Transaction réussie",
        placeEnterAmount: "Veuillez entrer le montant",
        tipTit1: "Êtes - vous sûr de vouloir payer pour cette commande?",
    },
    web: {
        account: {
            menu1: "Informations sur le compte",
            menu2: "Mes actifs",
            menu3: "Rapport de compte",
            menu4: "Sécurité du compte",
            menu5: "Notifications",
            menu6: "Centre d'activités",
            menu7: "Mon portefeuille",
            menu8: "Retrait",
            walletAdd: "Ajouter un nouveau portefeuille",
            report: {
                history_tit: "Rapport sur l'historique des transactions",
                stream_tit: "Rapport sur les flux financiers",
                desc: "Veuillez sélectionner la plage de dates appropriée pour exporter les rapports de compte. (vous aurez accès aux informations de votre compte pour les 1 dernières années)",
                select_date: "Choisissez une plage de dates",
                week: "La semaine dernière",
                month: "Environ un mois",
                threeMonth: "Trois mois",
                sixMonth: "Six mois",
                time: "Horaire",
                view_report: "Afficher le rapport",
                email_send: "Envoyer par e - mail",
                dialog_tit: "Rapport de compte",
                dialog_p1:
                    "Conisp Holding Ltd est enregistrée aux îles Caïmans (numéro SIB: 1612446) et traite vos transactions en tant qu'entité commerciale.",
                tit2: "Rapport d'historique des transactions mitade",
                tit4: "Rapport mitade sur les flux financiers",
                tit3: "Informations sur le compte",
                label1: "Nom du compte",
                label2: "ID du compte",
                label3: "Devise du compte",
                label4: "Heure du rapport",
                label5: "Période de rapport",
                label6: "Flux de trésorerie",
                empty: "Vous n'avez pas de transaction fermée",
                email_des: "Le rapport sera envoyé à votre adresse e - mail",
                tab1: "Rapport",
                tab2: "Déclaration quotidienne",
                tab3: "Déclaration mensuelle",
            },
            account: {
                tit1: "Bienvenue chez StratTrade",
                tit2: "Une fois l'authentification terminée, vous pouvez commencer à déposer et à trader dans un environnement en temps réel.",
                p1: "Ouvrir un compte réel",
                p2: "Authentification",
                btn1: "Authentification",
                tit3: "Informations personnelles",
                label_name: "Nom et prénom",
                label_email: "E-mail",
                label_address: "Adresse:",
                tit4: "Informations sur le compte",
                label_account: "Numéro de compte",
                label_currency: "Monnaie de base",
                label_company: "Entités de service actuelles",
                company_tip:
                    "Conisp Holding Ltd of Companies est agréée et autorisée par la Cayman Islands Financial Authority (CIMA).",
                tit5: "Ouvrir un compte réel",
                tit6: "Données personnelles complètes",
                tit7: "Remplissez vos informations de base",
                tit8: "Évaluation des risques",
                tit9: "Évaluer votre tolérance au risque",
                tit10: "Authentification",
                tit11:
                    "Vérification d'identité complète conformément aux exigences réglementaires",
                p3: "Veuillez sélectionner l'un des documents justificatifs ci - dessous pour l'authentification",
                type1: "Carte nationale d'identité",
                type2: "Permis de conduire",
                type3: "Passeport",
                p4: "Je confirme que toutes les informations et documents fournis sont complets, véridiques et exacts. Je reconnais toute modification des informations fournies et j'en informerai mitrade dans les 30 jours.",
                next: "Prochaines étapes",
                btn2: "Confirmé",
                modify_personal_tit: "Modifier vos informations",
            },
            activity: {
                tit: "Centre d'activités",
                desc: "Vous pouvez participer à des événements pour obtenir des récompenses qui augmentent votre revenu de placement",
            },
            capital: {
                real_trade: "La vraie transaction",
                label_nowAccount: "Compte courant",
                label_tradeAccount: "Compte de Trading",
                label_currency: "Monnaie de base",
                deposit_withdraw_record: "Enregistrement des dépôts et retraits",
                capital_flow_details: "Détails des flux financiers",
            },
            notification: {
                tit: "Paramètres de notification",
                tit1: "Ouverture des canaux de notification",
                email: "Adresse e - mail",
                message: "Text messaging",
                push: "Pousser",
                verify: "Validation (théorie)",
                verified: "Validation (théorie)",
                p1: "Veuillez ouvrir / vérifier les canaux de notification suivants pour suivre les nouvelles importantes!",
                type1: "Marketing",
                tit2: "Notification des activités de marketing",
                desc2:
                    "Plate - forme informations thématiques, activités opérationnelles et plus Actualités",
                tit3: "Instructions de fonctionnement",
                desc3: "Matériel de connaissance riche et professionnel",
            },
            security: {
                tit: "Cryptographie",
                isSet: "Configuré",
                set: "Veuillez continuer à configurer",
                verify: "Validation (théorie)",
                verified: "Vérifié",
                tit1: "Certification de sécurité",
                email: "Adresse e - mail",
                phone: "Numéro de téléphone portable",
                phone_tit: "Liaison de numéro de téléphone portable",
                password_tit: "Changer le mot de passe",
            },
        },
        market: {
            hot: "Populaire",
            prev: "Page précédente",
            next: "Page suivante",
            line: "Partage du temps",
            main_subplot: "Indicateur de graphique principal et indicateur de graphique secondaire",
            main: "Indicateur graphique principal",
            subplot: "Indicateur graphique",
            go_trade: "Aller à la page de Trading",
        },
        news: {
            tip: "Avertissement: les informations ci - dessus sont fournies à titre informatif uniquement et ne doivent pas servir de base à des décisions commerciales.",
        },
        trade: {
            state1: "Société holding",
            state2: "Commandes en attente",
            state3: "Historique",
            state: "Mode",
            state4: "Position fermée",
            state5: "A été révoqué",
            type: "Typologie",
            type1: "Pleine",
            type2: "Vendu",
            type3: "Acheter",
            left_label1: "Self-service",
            left_label2: "Récemment consulté",
            left_type1: "Mode colonne compact",
            left_type2: "Motif en colonne lâche",
            left_type3: "Afficher le graphique",
            all: "Pleine",
            right_bp: "La récolte d'hier",
            right_sp: "Moderne",
            right_height: "Supreme",
            right_low: "Le plus bas",
            remind_tit: "Nouvelle alerte",
            remindList: "Liste de rappels",
            remind_btn: "Nouvelle alerte",
            right_tab1: "Transactions",
            right_tab2: "Détails",
            right_sell: "Vendu",
            right_buy: "Acheter",
            right_type: "Typologie",
            right_type1: "Liste des marchés",
            right_type2: "Commandes en attente",
            right_priceMoney: "Prix des commandes en attente",
            right_pointerNumber: "Quantité (lot)",
            right_lever: "Pied de biche",
            right_balance: "Disponible",
            right_stop_profit: "Profit",
            right_stop_loss: "Stop perte",
            right_profit_loss: "P/L",
            now_order: "Commandez maintenant",
            confirm_order: "Confirmation de commande",
            right_tit1: "Stratégie de Trading",
            right_rinei: "Après quelques jours",
            right_short: "Court terme",
            right_middle: "Moyen terme",
            right_time: "Heure de publication",
            right_tit2: "Stratégies alternatives",
            right_tit3: "Revue technique",
            right_tit4: "Numérique",
            right_tit5: "Sentiment de Trading",
            right_label_sell: "Vendeur (marchandises)",
            right_label_buy: "Acheteur",
            right_tip:
                "À titre informatif seulement, pas en tant que poste dans la Division",
            right_tip2: "Mise à jour toutes les 15 minutes",
            right_tit6: "Changement de prix",
            minute: "Minutes",
            today: "Actuellement",
            right_tit7: "Gamme de changement de prix",
            now_price: "Prix actuel",
            right_low_price: "Prix le plus bas",
            right_height_price: "Prix maximum",
            right_tit8: "Informations sur le contrat",
            right_tit9: "AUD/CHF",
            right_label1: "Nombre de transactions individuelles",
            right_label2: "Levier maximal",
            right_label3: "Taille du contrat",
            right_label4: "Nombre total maximal de postes",
            right_label5: "Nombre total maximal de postes",
            right_label6: "Coût de la nuit",
            hand: "Hand",
            right_collection_time: "Temps de collecte",
            right_tip3: "Base de frais: de la taille globale de la transaction",
            right_label7: "Ratio de marge",
            right_label8: "Ratio de marge de réparation",
            right_label9: "Heures de transaction",
            right_label10: "La période de Trading actuelle",
            right_label11: "Prochaine séance de Trading",
        },
    },
    home: {
        banner_p1: "",
        banner_p2: "Commerce ",
        banner_p3: "Forex, matières premières,",
        banner_p4: "Indices, actions, etc.",
        banner_p5:
            "Vous pouvez explorer les marchés financiers les plus chauds du monde. StratTrade offre 0 Commission, un différentiel de creux extrêmement favorable et une configuration de levier flexible.",
        hot: "Populaire",
        tit1: "Des transactions plus amicales",
        p1: "Interface simple et intuitive, facile à utiliser",
        p2: "Support Web, mobile app et Desktop, transactions à portée de main",
        p3: "Outils de gestion des risques tels que stop loss / tail stop loss",
        p4: "Plusieurs graphiques techniques et calendriers financiers, actualités en temps réel",
        p5: "E - mail en temps réel, SMS et notifications Push",
        p6: "Amélioration continue pour une meilleure expérience de Trading",
        btn1: "Découvrez notre plateforme",
        row3_tit: "Trading gratuit partout et à tout moment",
        row3_tip: "Support Web, mobile app et Desktop, transactions à portée de main",
        row3_tit2: "Scan code télécharger",
        row3_tit3: "Desktop",
        row3_tit4: "Téléphone portable",
        regular_tit: "Plateforme internationale de confiance",
        regular_tip:
            "Nous nous engageons à fournir à nos clients un environnement de Trading sûr et responsable.",
        regular_tit1: "Régulé par une autorité",
        regular_p1:
            "Autorisation réglementaire faisant autorité dans l'industrie pour gagner la confiance des clients du monde entier",
        regular_tit2: "Protéger la sécurité des fonds",
        regular_p2:
            "Les dépôts des clients de détail sont isolés au besoin dans des comptes en fiducie conformément aux exigences réglementaires",
        regular_tit3: "Protection contre l'équilibre négatif",
        regular_p3:
            "Protection de solde négatif de votre compte pour vous, solde négatif est nettoyé à temps, laissez vos pertes ne dépassent pas le montant enregistré, tradez en toute tranquillité d'esprit",
        regular_tit4: "Service à la clientèle 5 * 24 heures",
        regular_p4:
            "Équipe de service à la clientèle sincère et professionnelle, 24 heures de soutien en ligne, heureux de résoudre n'importe quel problème pour vous!",
        service_tit: "Plus de services pour vous aider",
        service_tit1: "Stratégie de Trading",
        service_p1:
            "Stratégies de Trading en temps réel pour vous aider à rester à jour sur les dernières tendances du marché et à mieux saisir le moment du Trading.",
        service_tit2: "Connaissance des transactions",
        service_p2:
            "Apprenez à trader gratuitement avec StratTrade et améliorez vos compétences en Trading.",
        service_tit3: "Gestion des risques",
        service_p3:
            "Découvrez les outils gratuits de gestion des risques proposés par StratTrade pour mieux protéger vos actifs.",
        step_tit: "Ouverture de compte simple et facile",
        step_tip: "Trois étapes simples pour ouvrir un compte en quelques minutes",
        step_tit1: "Nombre d'admissions",
        step_p1: "Remplissez les informations et Soumettez votre demande",
        step_tit2: "Dépôt",
        step_p2: "Dépôt rapide de fonds de plusieurs façons",
        step_tit3: "Début de la transaction",
        step_p3: "Découvrez les opportunités de Trading et passez des commandes rapidement",
        award_tit: "Nos réalisations",
        award_p1:
            "Nous visons toujours l'excellence, l'excellence et nous nous engageons à fournir à nos clients un service de Trading de qualité.",
        award_p2:
            "La marque StratTrade est fière de recevoir un prix d'une organisation reconnue dans l'industrie pour les efforts inlassables et l'engagement de l'équipe envers ses clients.",
        row7_tit: "Informations financières mondiales en temps réel",
        row7_tit1: "Nouvelles en temps réel",
        exchange_tit1: "Façonner l'industrie des options cryptographiques.",
        exchange_tit2: "Depuis 2016.",
        exchange_subtit: "StratTrade échange",
        exchange_card1_tit: "Pour les débutants.",
        exchange_card1_p:
            "Ouvrez vos compétences de Trading avec notre cours d'options gratuit. Expérimentez sur notre plateforme de test sans aucun risque. Pour toute question, veuillez utiliser notre support 24 / 7. Votre voyage avec les dérivés commence ici.",
        exchange_card2_tit: "Trader expérimenté.",
        exchange_card2_p:
            "Plongez - vous dans notre environnement de Trading au niveau institutionnel. Utilisez les outils les plus avancés de l'industrie sur le marché des options le plus liquide au monde.",
        exchange_card3_tit: "Pour les institutions.",
        exchange_card3_p:
            "Découvrez pourquoi les institutions les plus prestigieuses préfèrent StratTrade depuis 2016. Colo services en ld4, modèles de marge de portefeuille, négociation de blocs, portefeuille, liquidité optimale, multidiffusion et plus encore.",
    },
    learn: {
        education: {},
    },
    login: {
        click_download: "Cliquez pour télécharger",
        p1: "Trading à tout moment, n'importe où",
        p2: "Tradez plus rapidement et plus intelligemment",
        p3: "0 transactions de Commission",
        p4: "Les investissements les plus chauds au monde",
        renderDom1: "Change de devises étrangères",
        renderDom2: "Bourse américaine",
        renderDom3: "Platine",
        renderDom4: "Pétrole brut",
        renderDom5: "Index",
        renderDom6: "Crypto - monnaie numérique",
        login: "Se connecter",
        elail: "Email",
        phone: "Numéro de téléphone",
        no_account: "Pas de numéro de compte？",
        go_register: "Aller à l'enregistrement",
        register: "Nombre d'admissions",
        now_register: "Inscrivez - vous maintenant",
        have_account: "Déjà un compte？",
        go_login: "Veuillez continuer à vous connecter.",
    },
    about: {
        serviceFee: "Frais de service",
        introduction: "Présentation de la plateforme",
        trade_platform: "Plateforme de Trading",
        complaint_process: "Processus de plainte",
        page: "Page Web",
        place_search: "Rechercher ce que vous voulez savoir",
        no_answer: "Impossible de trouver la réponse dont vous avez besoin?",
        contact_us: "Contactez - nous",
        awards: {
            h1: "Prix et distinctions",
            p1: "Dans notre quête d'excellence et notre engagement à fournir des services transactionnels de qualité à nos clients, la marque StratTrade est fière de recevoir un prix d'une organisation reconnue dans l'industrie, en reconnaissance des efforts continus et de l'engagement de l'équipe envers ses clients.",
            p2: "Ou Découvrez dès maintenant notre plateforme de Trading en ligne primée!",
        },
        charges: {
            h1: "Frais et charges",
            p1: "Dans le cadre de nos efforts pour améliorer l'expérience client, StratTrade souhaite offrir à ses clients des services de transaction plus rentables. Nous nous engageons à facturer les frais de manière ouverte et transparente, sans frais cachés. Les principaux coûts de transaction pour nos clients sont les Spreads d'achat et de vente et les intérêts sur les positions au jour le jour.",
            h2: "Tout d'abord, lorsque vous négociez avec StratTrade, vous pouvez profiter",
            li1: "Devis produit instantané",
            li2: "Indicateurs techniques spécialisés",
            li3: "Analyse exclusive du marché",
            free: "Gratuit (gratuit)",
            view: "Trouver",
            h3: "Spread d'achat et de vente",
            p2: "StratTrade facture la différence entre l'achat et la vente en compensation de ses services. Ces frais sont reflétés dans le devis du produit et vous payez réellement le Spread à l'ouverture de la position. Comme les frais de Spread ne sont pas fixes, nous vous recommandons de visiter une page de devis produit spécifique ou de vous connecter",
            h4: "Frais de dépôt et de retrait",
            p3: "Afin de minimiser les coûts pour nos clients, dans la plupart des cas, nous ne vous facturerons pas de frais pour l'utilisation des fonds. Cependant, des sociétés tierces ou des banques peuvent vous facturer des frais de dépôt ou de retrait, tels que des frais bancaires de transit.",
            p4: "Vous pouvez demander des informations sur les frais à facturer à nos experts du service à la clientèle.",
            p5: "Par exemple, par le biais de transactions internationales par carte de crédit, de transferts / transferts de comptes bancaires ou de transactions qui ne prennent pas en charge les devises (change de devises), etc.",
            h5: "Coût des positions au jour le jour",
            p6: "Si vous avez toujours une position à 22h00 GMT (22h00 GMT), vous serez facturé / déposé pour des frais au jour le jour. Les demandes de renseignements sur les frais de nuitée pour chaque produit peuvent être effectuées sur la page de devis produit spécifique ou en vous connectant",
            h6: "Autres dépenses",
            p7: "Il n'y a pas d'autres frais. Notre politique de tarification est totalement transparente et tout projet nécessitant des frais est annoncé et communiqué à l'avance, il n'y a donc pas de frais cachés à craindre.",
        },
        contact: {
            h1: "Contactez - nous",
            address: "Adresse",
            custom_email: "Email de contact client",
            business_email: "Email de coopération commerciale",
            h2: "Écrivez - nous.",
            p1: "Si vous avez des questions concernant nos services, veuillez remplir le formulaire ci - dessous pour soumettre votre question et un de nos spécialistes du service à la clientèle vous répondra dans les meilleurs délais. De plus, pour vous inscrire à la stratégie de Trading quotidienne que nous proposons en partenariat avec Trading central, sélectionnez « s'abonner à l'analyse quotidienne du marché» dans le formulaire ci - dessous et remplissez la version linguistique que vous souhaitez recevoir et nous vous enregistrerons. * Le Service d'abonnement est disponible uniquement pour les vrais clients StratTrade existants.",
            h3: "Procédure de représentation",
            p2: "Toutes les plaintes nous avons une équipe dédiée pour enquêter et résoudre pour vous, les détails peuvent être consultés",
        },
        faq: {
            h1: "Questions fréquemment posées",
            tit1: "Quel est l'intérêt d'un compte démo？",
            tit2: "Comment ouvrir un compte réel？",
            tit3: "Comment créer un ordre de marché？",
            tit4: "Pourquoi je ne peux pas passer une commande?？",
            p1: "Un compte démo est fondamentalement identique à un compte réel en termes d'interface de Trading, de données et d'opérations. Le compte de démonstration contient 50 000 $de fonds virtuels et est conçu pour permettre aux clients de se familiariser avec les fonctionnalités de la plate - forme grâce à des opérations de simulation sans risquer leur argent.",
            p2: "Pour ouvrir un compte réel, suivez les étapes ci - dessous:",
            p3: "1. Accédez à la page d'inscription StratTrade et suivez les instructions: enregistrez votre adresse e - mail / numéro de téléphone mobile, définissez un mot de passe de connexion et envoyez une confirmation pour créer automatiquement un [compte démo].",
            p4: "2. Sur la page comptes démo, sélectionnez 'passer à un compte réel' et suivez la procédure pour ouvrir un 'compte réel'.",
            p5: "Avant d'ouvrir un compte réel, nous vous recommandons de lire et de comprendre les divulgations légales relatives aux transactions.",
            p6: "Veuillez noter que vous ne pouvez déposer sur votre compte réel qu'après avoir terminé votre authentification.",
            p7: "Cliquez sur 'Trade' pour afficher tous les produits négociables sur la plate - forme, vous pouvez effectuer une recherche en entrant le Code ou le nom d'un produit dans la zone de saisie à droite, en sélectionnant un, puis en cliquant sur 'Sell / buy' pour ouvrir la fenêtre de négociation où vous pouvez voir le prix actuel et la marge estimée requise. Vous pouvez ajuster manuellement le nombre de transactions que vous souhaitez ouvrir, définir un stop loss et un profit pour contrôler le risque, puis cliquer sur 'vendre / acheter' Pour terminer l'ouverture de la position. Veuillez noter que les prix de tous les produits financiers fluctuent et sont mis à jour avec le marché à tout moment et que le marché peut avoir changé avant d'appuyer sur le bouton acheter / vendre.",
            p8: "Tout d'abord, vérifiez que votre connexion Internet est correcte. Lors de la négociation de divers marchés, la fermeture temporaire d'une cotation ou la fermeture d'un marché peut entraîner l'impossibilité de passer une commande. Pour plus de détails, veuillez contacter notre service clientèle en ligne pour obtenir de l'aide supplémentaire.",
        },
        fundSecurity: {
            h1: "Sécurité financière",
            p1: "StratTrade est autorisé et réglementé par un organisme de réglementation faisant autorité. La conformité est au cœur de tout ce que nous faisons et de notre culture. La sécurité de vos fonds est primordiale et tous les dépôts des clients sont conservés dans des endroits séparés.",
            p2: "Conformément aux exigences réglementaires, les dépôts des clients de détail sont déposés séparément dans un compte en fiducie, au besoin",
            p3: "StratTrade n'utilisera pas les fonds des clients pour des activités commerciales autres que celles autorisées par les lois et règlements.",
            p4: "StratTrade ne spéculera sur aucun marché",
            p5: "Audit indépendant par un cabinet comptable tiers",
        },
        help: {
            h1: "Bienvenue dans le Centre d'aide StratTrade!",
        },
        media: {
            h1: "Centre des médias",
            p1: "Voir les informations, communiqués de presse, reportages, annonces et autres informations relatives à la marque StratTrade.",
        },
        mitrade: {
            h1: "À propos de StratTrade",
            view_certificate: "(Méthode d'identification)",
            p1: "StratTrade est une société de technologie financière autorisée et réglementée par une autorité spécialisée dans la fourniture d'une expérience de Trading simple et pratique pour les investisseurs. La plateforme de Trading innovante et diversifiée StratTrade a remporté à plusieurs reprises des prix tels que la meilleure plateforme de Trading mobile et le courtage le plus innovant.",
            p2: "Avec la plate - forme StratTrade, vous pouvez investir et négocier sur un large éventail de marchés financiers internationaux, couvrant les actions, les indices, les matières premières, le forex et des centaines d'autres variétés populaires. StratTrade ne se limite pas à une plate - forme de Trading mobile et Web conviviale, mais vous offre également des coûts de transaction compétitifs, une exécution rapide des transactions, un service client de qualité et un support informatif riche pour vous aider à saisir les opportunités d'investissement et de Trading en temps opportun.",
            p3: "StratTrade est strictement réglementé par les autorités de l'industrie et par plusieurs organismes. StratTrade international est agréée et réglementée par la Mauritius Financial Services Commission (FSC) sous le numéro de licence gb20025791. Pour plus d'informations sur les licences mauriciennes, vous pouvez visiter le site officiel de la FSC. https://www . fscmaritius.org",
            p4: "MStratTrade global est agréée par l'Australian Securities and Investments Commission (ASIC) sous licence de services financiers (afsl 398528). Pour plus d'informations sur les licences australiennes, visitez le site Web de l'ASIC à l'adresse www.asic.gov.au.",
            p5: "Itrade Holding est autorisée et réglementée par la Cayman Islands Financial Authority (CIMA) sous le numéro de licence SIB 1612446. Pour plus d'informations sur les licences Caïmans, visitez le site officiel de la CIMA à l'adresse www.cima.ky.",
            p6: "Toutes les opérations commerciales de StratTrade sont menées sous surveillance stricte et dans le respect de toutes les réglementations.",
            h2: "Contexte de StratTrade",
            p7: "StratTrade a été fondée à Melbourne, en Australie, par une équipe expérimentée et compétente de professionnels du Trading financier et de la fintech avec la vision de rendre le trading plus facile et plus convivial!",
            p8: "Notre stratégie globale et notre modèle d'affaires nous permettent d'avoir une vision globale et une compréhension approfondie des développements de l'industrie et des besoins du marché, ce qui nous permet d'allouer des ressources plus ciblées, d'innover continuellement en matière de technologie, d'optimiser l'efficacité et de continuer à offrir à nos clients une expérience de Trading plus pratique et conviviale. ",
            h3: "Pourquoi choisir StratTrade",
            why1: "Conditions de Trading à seuil bas",
            why2: "Réglementé par les autorités de l'industrie",
            why3: "Plateforme de Trading simple et intuitive",
            why4: "Support en ligne de haut niveau",
            why5: "Coûts de transaction compétitifs",
            why6: "Protection contre l'équilibre négatif",
            h4: "Prix et distinctions",
            p9: "Nous visons toujours l'excellence, l'excellence et nous nous engageons à fournir à nos clients un service de Trading de qualité.",
            p10: "La marque StratTrade est fière de recevoir un prix d'une organisation reconnue dans l'industrie pour les efforts inlassables et l'engagement de l'équipe envers ses clients.",
            new_add: "Nouveaux utilisateurs dans le monde entier",
            ol1: "Meilleur fournisseur de nouvelles et d'analyses",
            ol2: "Meilleur courtier Forex en Asie",
            ol3: "Prix australien de la satisfaction client Forex",
            ol4: "Les meilleures applications mobiles",
            ol5: "Les courtiers à la croissance la plus rapide en Australie",
            h5: "La philosophie d'entreprise de StratTrade",
            tip_tit1: "Obligations",
            tip_tit2: "Simple et belle",
            tip_tit3: "Public (non secret)",
            tip_tit4: "Ouvrir de nouvelles voies",
            tip_p1:
                "Toutes les transactions comportent des risques et StratTrade a pris des mesures supplémentaires pour offrir à ses clients un environnement de Trading responsable. StratTrade est strictement réglementé par les autorités du secteur, plusieurs organisations et opère selon des normes de sécurité et de stabilité de haut niveau. Notre plateforme continue d'offrir de nombreux outils de Trading responsables, y compris la gestion des risques.",
            tip_p2:
                "La simplicité est la beauté et les détails sont remarquables, StratTrade s'engage à fournir une facilité d'utilisation et une expérience utilisateur dans tous les détails. Nous continuerons d'optimiser notre plateforme et nos services dans l'espoir que tout le monde, des débutants aux investisseurs expérimentés, puisse tirer parti des opportunités de Trading via la plateforme StratTrade.",
            tip_p3:
                "Afin de permettre à nos clients de bénéficier pleinement d'un environnement de Trading équitable et efficace, StratTrade suit un système d'autorégulation strict et transparent qui fournit des informations complètes et transparentes sur les produits de la plate - forme, un environnement de tarification et des données, tous les frais étant clairement divulgués publiquement pour garantir une totale honnêteté et transparence tout au long du processus de service.",
            tip_p4:
                "StratTrade nous croyons fermement que le progrès technologique, l’innovation financière et une gamme plus large de services sont les moteurs de la croissance et que ce n’est qu’ainsi que nous pouvons créer et partager continuellement de la valeur. Par conséquent, nous encourageons l'esprit ouvert, nous encourageons constamment l'innovation et nous nous engageons à être des innovateurs et des leaders dans le domaine des technologies financières.",
            row6_tit1: "Notre mission",
            row6_tit2: "Notre vision",
            row6_tit3: "Opportunités de travail",
            row6_p1:
                "Fournir un environnement de Trading équitable, efficace et transparent sans contraintes d'espace, de temps et de marché pour plus de gens qui veulent Trader et diriger l'industrie en matière d'innovation financière et technologique.",
            row6_p2:
                "Nous croyons que les progrès technologiques, l’innovation financière et les services diversifiés sont les forces qui alimentent notre croissance et nous permettent de continuer à créer et à partager de la valeur dans la société.",
            row6_p3:
                "Pour ce faire, nous encourageons l'ouverture d'esprit et favorisons l'innovation, visant à devenir, grâce à une croissance et à un progrès continus, un",
            row6_p4: "Leader mondial du Forex",
            row6_p5:
                "Nous sommes une équipe mondiale dans 7 pays, l'équipe se développe rapidement et nous sommes toujours à la recherche de professionnels pour nous rejoindre. Faites ce que vous aimez avec une équipe multiculturelle!",
            row6_p6:
                "Envoyez - nous votre CV le plus récent et les raisons pour lesquelles vous souhaitez travailler avec nous:",
            row6_p7: "Alternativement, vous pouvez également en savoir plus sur",
            row6_p8: "Peut - être",
            row6_p9: "Visitez voir nos postes vacants",
            row6_p10:
                "StratTrade protège strictement la sécurité de vos informations personnelles. StratTrade ne vous demandera pas d'informations financières dans le cadre de votre recherche d'emploi. Nous n'avons pas besoin d'informations financières, de cartes de crédit, d'informations de compte bancaire ou de toute forme de paiement pour effectuer le recrutement.",
            row6_p11:
                "Si vous soupçonnez une fraude dans un poste, passez",
            row6_p12:
                "Contactez le Département des ressources humaines de StratTrade. Si vous confirmez que vous avez été trompé par de fausses offres d'emploi, contactez le Département de votre agence locale compétente.",
        },
    },
    product: {
        row2: {
            tit: "Variété de Trading",
            sell: "Vendu",
            buy: "Acheter",
            trade: "Transactions",
            more_product: "Plus de produits de Trading",
        },
        row3: {
            tit: "Support des transactions Multi - appareils",
            tit1: "Support des transactions Multi - appareils",
            p1: "StratTrade vous offre une plateforme de Trading Web, mobile et de bureau. Vous pouvez trader n'importe où, n'importe quand.",
            download: "En téléchargement",
        },
        row4: {
            tit: "Choisir StratTrade",
            tit1: "Une plateforme simple et intuitive",
            p1: "Intégrez des fonctionnalités puissantes telles que devis, Trading, gestion de compte, informations, gestion des risques, etc. pour une meilleure expérience utilisateur",
            tit2: "Coûts de transaction compétitifs",
            p2: "0 Commission de Trading, très compétitif et transparent, faible swap, vous pouvez donc profiter de transactions à faible coût!",
            tit3: "Conditions de Trading à seuil bas",
            p3: "Le nombre minimum de lots par transaction est aussi bas que 0,01 lot et vous pouvez ouvrir des positions avec une marge faible.",
            tit4: "Régulé par une autorité",
            p4: "Tous les dépôts des clients sont autorisés et réglementés par les autorités de l'industrie et sont conservés séparément et isolés pour protéger la sécurité des actifs des clients",
            tit5: "Protection contre l'équilibre négatif",
            p5: "Dans toutes les conditions du marché, les pertes de votre compte ne dépasseront pas le capital et les soldes négatifs seront compensés à temps pour améliorer vos capacités de gestion des risques.",
            tit6: "Support en ligne de haut niveau",
            p6: "Fournir un service à la clientèle en ligne rapide et réactif, fournira un meilleur service grâce aux efforts inlassables de l'équipe professionnelle",
        },
        row5: {
            tit: "Questions connexes",
        },
        commodities: {
            title: "Commerce des marchandises",
            p: "Les produits de base tels que les métaux précieux et l'énergie sont considérés comme des éléments importants de la diversification des investissements, car ils sont considérés à la fois comme résistants à l'inflation et comme ayant la possibilité d'ajouter de la valeur aux investissements. Parmi ceux - ci, l'or, l'argent et le pétrole sont des matières premières avec un volume d'échange énorme, dont les prix ouverts et transparents et la liquidité élevée ont été largement appréciés par les investisseurs. Les prix des matières premières fluctuent considérablement en fonction de facteurs tels que l'offre et la demande, la situation économique et politique et les devises, offrant des opportunités de rendement du risque.",
        },
        forex: {
            title: "Trading forex",
            p: "Le trading forex est la conversion d'une devise en une autre et le marché des changes est le marché financier le plus négocié au monde. Il y a un grand nombre de participants sur le marché Forex qui négocient via le marché Forex pour effectuer des paiements, couvrir le risque de volatilité des devises ou faire des profits. Le trading forex est effectué par le biais d'un réseau mondial de banques, d'institutions et de Traders individuels, et les prix fluctuent 24 heures sur 24, 5 jours sur 7, offrant une opportunité de rendement sur risque.",
        },
        indices: {
            title: "Indices",
            p: "Un indice boursier est une valeur statistique qui représente un type particulier d'actions caractéristiques dans un échange particulier et est un indicateur du niveau de prix global et des mouvements d'un type particulier d'actions sur un marché. Le Trading d'indices boursiers qui reflètent les opportunités d'investissement globales du marché ou de l'industrie peut réduire le risque d'investir dans des actions individuelles. Les indices les plus populaires sur les marchés américains, européens, asiatiques et australiens, tels que l'Australian 200, l'American Tech 100, le Hong Kong 50, l'Allemagne 30, etc., offrent différentes opportunités pour les marchés boursiers mondiaux.",
        },
        shares: {
            title: "Transactions sur actions",
            p: "Les actions sont des titres négociables émis en bourse. Pour lever des fonds, la société utilise des certificats pour distribuer la propriété de la société via une bourse cotée. Avec plus de 60 billions de dollars de transactions boursières mondiales rien qu’en 2019, sa taille et sa liquidité sont telles que, même après de nombreuses années, il reste l’un des instruments financiers les plus populaires aux yeux des investisseurs mondiaux. Sur la plate - forme StratTrade, les Traders peuvent utiliser l'effet de levier pour négocier les variations de prix des actions. Les prix des actions sont influencés par une variété de facteurs tels que la macroéconomie, les perspectives de l'industrie et les opérations de l'entreprise, ce qui les rend très volatiles et il y a toujours des opportunités pour les transactions d'investissement.",
        },
        coins: {
            title: "Crypto - monnaie",
            p: "Les crypto - monnaies sont des monnaies numériques créées par Code. Ils fonctionnent de manière autonome en dehors des systèmes bancaires et gouvernementaux traditionnels. Comme son nom l'indique, la crypto - monnaie utilise le cryptage pour protéger les transactions et permettre la création d'autres unités, bitcoin est la crypto - monnaie originale et de loin la plus célèbre, créée par Satoshi nakamoto et lancée en janvier 2009. Même aujourd'hui, on ne sait toujours pas si le nom Satoshi nakamoto se réfère à une personne ou à un groupe de personnes. Le bitcoin est considéré comme la première crypto - monnaie décentralisée. Comme toutes les crypto - monnaies, il est contrôlé par une base de données de transactions blockchain qui agit comme un grand livre public distribué. À ce jour, plus de 1 000 crypto - monnaies ont été échangées en ligne.",
        },
    },
    trade: {
        data_sources: "Source des données",
        tip: " Les informations ci - dessus sont fournies à titre indicatif seulement. StratTrade ne garantit pas l'exactitude, l'actualité ou l'exhaustivité des informations et vous ne devez pas vous fier indûment aux informations fournies. Ces informations ne comprennent pas nos enregistrements de prix ou toute offre ou sollicitation de négocier des instruments financiers. StratTrade n’est pas une société de conseil financier et ne fournit que des services de nature exécutive. Nous recommandons à nos lecteurs de demander leurs propres conseils en investissement. Voir notre Disclaimer complet.",
        tip2: "Mise à jour hebdomadaire vendredi 15: 00 (GMT)",
        tip3: "Trading perspectives est un outil qui prédit le sentiment de négociation du marché et reflète les perspectives à court et moyen terme et les prévisions de tendance des experts de l'industrie pour chaque produit populaire.",
        analysis: {
            tit: "Stratégie de Trading",
            all: "Pleine",
            day: "Après quelques jours",
            tit1: "Stratégie de Trading",
            tit2: "Revue technique",
        },
        calendar: {
            tit: "Calendrier financier",
            prev_week: "La semaine dernière",
            next_week: "La semaine prochaine",
            place_date: "Date de recherche",
            select: "Options",
            select1: "Notre pays",
            select2: "Signification",
            start_time: "Heure de début",
            end_time: "Temps d'achèvement",
            search: "Cherchez quelque chose.",
        },
        contract: {
            tit: "Spécifications du contrat",
            type1: "Pleine",
            type2: "Forex",
            type3: "Marchandises",
            type4: "Indices",
            type5: "Actions américaines",
            type6: "Stocks australiens",
            tit1: "Principaux Forex",
            tit2: "Petit Forex",
            tit3: "Marchandises",
            tit4: "Principaux indicateurs",
            tit5: "Indicateurs secondaires",
            tit6: "Actions américaines",
            tit7: "Stocks australiens",
            tit8: "Règles générales",
            p1: "Ratio de planéité forcé",
            p2: "Période de validité des commandes en attente",
            p3: "Illimité",
            p4: "Heure de règlement des frais de nuit",
            p5: "Règlement quotidien; Été: 05: 00, hiver: 06: 00",
            p6: "Levier, taux au jour le jour, etc. informations.",
            p7: "Voir la plateforme de Trading",
            p8: "Ce qui précède s'applique à toutes les variétés de Trading",
            tit9: "Calendrier de la semaine de Trading",
            p9: "Fermé",
            p10: "Les heures de négociation ci - dessus ne tiennent pas compte des effets des jours fériés ou des ajustements spéciaux du marché",
            p11: "Recommandations spécifiques de temps de transaction",
            p12: "Se connecter à la plateforme",
            p13: "，Voir le profil des variétés concernées",
        },
        forecast: {
            tit: "Perspectives de Trading",
            p1: "Moyenne",
            p2: "Haussier",
            p3: "Ours",
            p4: "Les côtés",
            p5: "Direction",
        },
        news: {
            tit: "Nouvelles en temps réel",
        },
        platforms: {
            tit: "Plateforme de Trading StratTrade",
            p: "Interface simple et intuitive, excellente performance de Trading pour répondre aux besoins de plus d'investisseurs. Commencez à trader avec StratTrade dès aujourd'hui.",
            tit1: "Plateforme de Trading StratTrade pratique et facile à utiliser.",
            tit2: "Tradez n'importe où, n'importe quand avec l'application StratTrade!",
            li1: "Sécurisez votre argent en négociant des centaines de marchés populaires avec une réglementation faisant autorité",
            li2: "Surveillez les tendances du marché en temps réel, consultez les analyses des transactions et parcourez les dernières nouvelles financières.",
            li3: "Consultez différents types de graphiques de Trading à tout moment et appliquez facilement les fonctions de graphique",
            li4: "Découvrez la plateforme de Trading primée de StratTrade sur votre appareil Android ou iOS!",
            code_download: "Scan code télécharger",
            tit3: "Aucun téléchargement requis, Trading en ligne via le Navigateur",
            li5: "Aucun téléchargement requis, Trading en ligne directement depuis le navigateur de votre PC",
            li6: "Gagnants: meilleur courtier Forex en Asie 2022, meilleure plateforme de Trading en Australie 2022, courtier fintech Forex à la croissance la plus rapide au monde 2022",
            li7: "Fournit des indicateurs techniques puissants: macd, kdj, RSI, TRIX, DMA, CCI, etc.",
            li8: "Équipé d'outils de gestion des risques tels que stop loss et protection contre les soldes négatifs pour réduire les risques potentiels de Trading",
            tit4: "La solution parfaite pour Desktop Trading",
            li9: "Visualisez facilement les offres de marché dans votre liste auto - sélectionnée",
            li10: "Activer les alertes pour recevoir des alertes de changement de prix et d'autres notifications d'information",
            li11: "Plusieurs dispositions de graphique vous permettent de suivre le marché à partir de différentes dimensions temporelles.",
            li12: "Spécifications du contrat, sentiment de Trading et analyse de Trading dans une seule interface sans basculement",
            li13: "Recevez les dernières nouvelles de Trading directement chez StratTrade!",
            tit5: "Pourquoi choisir la plateforme de Trading StratTrade?",
            row5_p1: "Sécurité",
            row5_p2: "0% de Commission",
            row5_p3: "Support multiplateforme",
            row5_p4: "Diagramme technique",
            row5_p5: "Accès à tout moment, n'importe où",
            row5_p6: "Divers outils de dessin",
        },
        quotes: {
            tit: "Données du marché",
            p1: "Vous fournit des citations instantanées, le sentiment du marché et des nouvelles pertinentes sur les instruments financiers populaires actuels.",
            hot: "Populaire",
        },
        risk: {
            tit: "Système de gestion des risques",
            p1: "Toutes les transactions comportent des risques. En utilisant les outils gratuits de gestion des risques de StratTrade, vous contrôlez efficacement vos risques, quelles que soient les conditions du marché.",
            function_tit1: "Fonction stop - profit / Stop - LOSS",
            function_tit2: "Fonction d'arrêt de queue",
            function_p1: "Verrouiller les bénéfices",
            function_p2: "Limiter les pertes",
            function_p3: "Maximiser le verrouillage des bénéfices",
            function_p4: "Pas besoin de surveiller automatiquement l'emplacement",
            function_p5: "Ajustement du prix de clôture de la perte",
            row2_tit1: "Fonction stop - profit / Stop - LOSS",
            row2_p1:
                " Lors de la création d'un nouvel ordre ou de la modification d'un ordre existant, nous offrons la possibilité de définir un Stop - profit et un Stop - loss. Une fois mis en place, les ordres sont généralement fermés au prix cible que vous avez fixé, vous aidant à verrouiller les profits lorsque vous atteignez votre objectif ou à minimiser les pertes lorsque les mouvements du marché sont défavorables. Notez que tout ordre peut être court en raison des fluctuations du marché, auquel cas le système n'exécutera pas l'ordre au prix par défaut, mais fermera votre position au prochain meilleur prix du prix cible.",
            row2_tit2: "Exemples ",
            row2_p2:
                "Le prix actuel de la paire EUR / USD est 1.13816 / 1.13837 (Sell / buy). Vous ouvrez un ordre d'achat de 1 Lot (1 lot = 100 000 €) à 1.13837 et définissez un stop loss à 1.13806.",
            row2_p3:
                "En général, lorsque le prix tombe à 1.13806, votre ordre stop sera déclenché et la position sera fermée à 1.13806 pour une perte totale de 31 $.",
            row2_p4:
                "Cependant, lorsque l'environnement du marché change soudainement et que le prix chute directement de 1.13837 à 1.13795, sautant directement votre objectif de stop loss, le marché saute et le système ne sera pas en mesure de fermer la position à 1.13806, mais exécutera l'un des prix les plus favorables suivants, le prix de 1.13795, avec une perte finale de 42 $.",
            row3_tit1: "Fonction d'arrêt de queue",
            row3_tit2: "Exemples",
            row3_p1:
                "Les stop - Loss (également appelés stop - Loss) sont puissants car ils changent automatiquement en fonction de vos profits, vous permettant de maximiser vos profits ou de réduire vos pertes sans avoir à surveiller constamment vos positions. Lorsque vous créez un nouvel ordre, définissez simplement un stop de suivi et lorsque le prix est en votre faveur, votre ordre stop est automatiquement mis à jour avec le prix le plus récent. Au lieu de cela, lorsque le prix est contre vous, un ordre stop sera activé et la position sera fermée à la distance que vous avez définie pour la perte. Veuillez noter que tout ordre peut être short en raison des fluctuations du marché, auquel cas le système ne sera pas en mesure d'exécuter l'ordre à votre prix par défaut, mais clôturera votre position au prochain meilleur prix du prix cible.",
            row3_p2:
                "Le prix actuel de la paire EUR / USD est 1.13816 / 1.13837 (Sell / buy). Vous avez établi un ordre d'achat de 1 lot à 1.13837 avec un stop de 100 PIPS (0.00100).",
            row3_p3:
                "Lorsque le prix du produit est 1.13816, votre stop loss est 1.13716. Si le prix de vente du produit augmente à 1.13845, le stop loss sera mis à jour à la distance que vous avez définie et le stop loss mis à jour sera 1.13745.",
            row3_p4:
                " Au lieu de cela, lorsque le prix du produit tombe de 113845 à 113745, un stop - Loss sera déclenché et la position sera clôturée à 113745.",
            tip: "Point: En règle générale, la plus petite unité de variation du prix d'un instrument financier est appelée un point. Sur la plateforme StratTrade, il s’agit du dernier chiffre ou décimale du prix d’un outil.",
        },
        sentiment: {
            tit: "Indice de sentiment",
            type1: "Pleine",
            type2: "Forex",
            type3: "Marchandises",
            type4: "Indices",
            long: "Long terme (financier)",
            short: "Courte",
        },
    },
    layout: {
        aside: {
            nav1: "Transactions",
            nav2: "Le marché",
            nav3: "Informations de presse",
            nav4: "Collège",
            nav5: "Le mien",
            newsDialog: {
                title: "Centre de presse",
                type1: "Notification du système",
                type2: "Barre d'annonce",
                allRead: "Marquer tout comme lu",
            },
            settingDialog: {
                title: "Paramètres",
                nav1: "Aperçu",
                nav2: "Afficher les préférences",
                nav3: "Informations système",
                logout: "Déconnexion",
                setting1: "Multilingue",
                setting2: "Transactions",
                setting2_tip:
                    "Ajouter automatiquement des variétés auto - sélectionnées aux postes vacants",
                setting3: "Renvoyer des informations",
                setting3_tip: "Recommandations fonctionnelles",
                setting4: "Vérification du vrai nom",
                setting4_tip: "Vérification du vrai nom",
                setting5: "Mode thème",
                setting5_label1: "Inexplicable",
                setting5_label2: "Couleurs vives",
                setting6: "La couleur monte ou descend",
                setting6_label1: "Allumé. Vert vers le haut, rouge vers le bas",
                setting6_label2: "Allumé. Rouge ascendant, vert descendant",
                setting7: "Paramètres du diagramme",
                setting7_label1: "Standard Edition",
                setting7_label2: "Tradingview version",
                setting8: "Déclarations et accords",
                setting8_label1: "Politique de confidentialité",
                setting8_label2: "Déclaration de divulgation du produit",
                setting8_label3: "Déclaration de divulgation des risques",
                setting8_label4: "Accord client",
                feedback_title: "Recommandations fonctionnelles",
            },
        },
        footer: {
            hot: "Variétés populaires",
            sort: "Symboles",
            code: "Codage",
            buy_price: "Prix d'achat",
            sell_price: "Prix du vendeur",
            chg: "Hausse ou baisse des prix",
        },
        header: {
            place_search: "Recherche de variétés de Trading",
            balance: "Disponible",
            balance_tip:
                "Disponible: montant disponible sur le compte pour ouvrir une nouvelle position",
            profit_loss: "P/L",
            asset_view: "Aperçu des actifs",
            netValue: "Valeur nette",
            netValue_tip:
                "Valeur nette: valeur du compte courant, y compris les profits et pertes de toutes les positions",
            marginLevel: "Niveau de marge",
            margin: "Marge (Trading de produits dérivés)",
            margin_tip: "Marge: le montant nécessaire pour ouvrir et maintenir une position",
            maintainsMargin: "Marge de maintenance",
            maintainsMargin_tip:
                "Marge de maintien: le montant minimum de marge à maintenir sur votre compte lorsque vous détenez toutes vos positions.",
        },
    },
    table: {
        label_name: "Nom et prénom",
        label_buyPrice: "Acheter",
        label_sellPrice: "Vendu",
        label_variableValue: "Changement",
        trade: "Transactions",
        label_code: "Symboles",
        label_title: "Nom et prénom",
        label_start: "Signification",
        label_country: "Notre pays",
        label_event: "Activités",
        label_previous: "Valeurs précédentes",
        label_predictive: "Valeur estimée",
        label_announced: "Valeur publiée",
        label_stop_loss_min: "Distance minimale des ordres stop loss",
        label_difference: "Spread dynamique",
        label_contract_size: "Taille du contrat",
        label_max_hand: "Volume maximal pour une seule transaction",
        label_min_hand: "Lot minimum",
        label_trade_week: "Calendrier de la semaine de Trading",
        label_week: "1 semaine",
        label_month: "1er janvier",
        label_quarter: "1 trimestre",
        label_dailyTrend: "Tendance d'un jour",
        label_tradeId: "Id de transaction",
        label_breed: "Symboles",
        label_tradeType: "Types de transactions",
        label_currency: "L'argent",
        label_amount: "L'argent",
        label_balance: "Solde",
        label_type: "Typologie",
        label_time: "Horaire",
        label_orderNumber: "Quantité commandée",
        label_pointer_number: "Handful (de)",
        label_price_money: "Cérémonie d'ouverture",
        label_stop_win_price: "Profit",
        label_stop_lose_price: "Stop perte",
        label_openTime: "Heures d'ouverture",
        label_profit_loss: "P/L",
        label_sell_buy: "Demander / prix d'achat",
        label_chg: "Hausse ou baisse des prix",
        label_sell_price: "Prix du vendeur",
        label_buy_price: "Prix d'achat",
        label_condition: "Prérequis",
    },
    form: {
        label_feedback: "Questions et suggestions",
        place_feedback: "Veuillez saisir votre question ou suggestion",
        label_img: "Photos",
        label_img_tip: "Facultatif, fournir une capture d'écran du problème",
        feedback_tip: "Si vous avez des questions urgentes, veuillez contacter",
        online: "Service à la clientèle en ligne",
        label_name: "Nom et prénom",
        place_name: "Veuillez entrer votre nom",
        label_email: "Adresse e - mail",
        place_email: "Adresse e - mail",
        message_email: "Veuillez entrer votre adresse e - mail",
        label_nationality: "Citoyenneté",
        place_nationality: "Veuillez entrer votre nationalité",
        place_questionType: "Types de problèmes",
        message_questionType: "Veuillez sélectionner le type",
        questionType0: "Plateforme de Trading",
        questionType1: "Produits et frais",
        questionType2: "Abonnez - vous à Daily Market Analysis",
        questionType3: "Service à la clientèle",
        questionType4: "Le reste",
        place_question: "Votre question",
        message_question: "Veuillez entrer une question",
        submit_question: "Soumettre une question",
        label_phone: "Numéro de téléphone portable",
        place_phone: "Veuillez entrer votre numéro de téléphone portable",
        message_phone: "Veuillez entrer votre numéro de téléphone portable",
        label_password: "Cryptographie",
        place_password: "Veuillez entrer votre mot de passe",
        message_password: "Veuillez entrer votre mot de passe",
        label_confirmPassword: "Confirmer le mot de passe",
        place_confirmPassword: "Veuillez saisir à nouveau votre mot de passe",
        message_confirmPassword: "Veuillez saisir à nouveau votre mot de passe",
        label_captcha: "Captcha, un défi - test de réponse (computing)",
        place_captcha: "Veuillez entrer le Code de vérification",
        message_captcha: "Veuillez entrer le Code de vérification",
        get_captcha: "Code de vérification",
        label_inviteId: "Code d'invitation",
        place_inviteId: "Code d'invitation (facultatif)",
        to: "À",
        start_time: "Heure de début",
        end_time: "Heure de fin",
        label_cardNumber: "Numéro de carte d'identité",
        place_cardNumber: "Veuillez entrer votre numéro d'identification",
        message_cardNumber: "Veuillez entrer votre numéro d'identification",
        label_cardMain: "Front du document",
        message_cardMain: "Veuillez entrer le recto du document",
        label_cardBack: "Le contraire du document",
        message_cardBack: "Veuillez entrer le verso du document",
        confirm_modify: "Confirmation du changement",
        label_realName: "Nom",
        place_realName: "Veuillez entrer votre prénom",
        message_realName: "Veuillez entrer votre prénom",
        label_firstName: "Nom de famille",
        place_firstName: "Veuillez entrer votre nom de famille",
        message_firstName: "Veuillez entrer votre nom de famille",
        label_lastName: "Prénom moyen",
        place_lastName: "Facultatif",
        message_lastName: "Veuillez entrer un prénom intermédiaire",
        label_birthday: "Naissance",
        place_birthday: "Veuillez entrer votre date de naissance",
        message_birthday: "Veuillez entrer votre date de naissance",
        place_nowPsd: "Veuillez entrer votre mot de passe actuel",
        message_nowPsd: "Veuillez entrer votre mot de passe actuel",
        place_newPsd: "Veuillez entrer un nouveau mot de passe",
        message_newPsd: "Veuillez entrer un nouveau mot de passe",
        place_crmPsd: "Veuillez confirmer votre nouveau mot de passe",
        message_crmPsd: "Veuillez confirmer votre nouveau mot de passe",
        label_breed: "Symboles",
        place_breed: "Veuillez entrer une espèce",
        label_phase: "Le be",
        label_buyPrice: "Prix d'achat",
        label_sellPrice: "Prix du vendeur",
        label_do: "Parce que",
        label_height: "Supérieure à",
        label_low: "Inférieur à",
        label_equal: "Ou égale à",
        labelWalletName: "Nom du portefeuille",
        messageWalletName: "Veuillez entrer le nom de votre portefeuille",
        placeWalletName: "Veuillez entrer le nom de votre portefeuille",
        labelWalletAddress: "Adresse du portefeuille",
        messageWalletAddress: "Veuillez entrer l'adresse de votre portefeuille",
        placeWalletAddress: "Veuillez entrer l'adresse de votre portefeuille",
        labelAmount: "L'argent",
        messageAmount: "Veuillez entrer le montant",
        placeAmount: "Veuillez entrer le montant",
        placeTimeLimit: "Veuillez entrer une limite de temps",
        messageTimeLimit: "Veuillez entrer une limite de temps",
    },
    pay: {
        title: "Accès facile aux fonds",
        p1: "Plusieurs canaux de paiement courants, permettant un accès rapide aux paiements",
        p2: "Certains modes de paiement peuvent ne pas être disponibles dans votre pays.",
        p3: "Bienvenue sur StratTrade services!",
        btn: "Tradez maintenant ",
    },
    header: {
        tip1: "Le Trading à effet de levier est un produit financier complexe avec un risque élevé de perte rapide.",
        btn1: "Plateforme de Trading",
        btn2: "Déconnexion",
        btn3: "Se connecter",
        BigUint64Array: "Tradez maintenant",
    },
    footer: {
        help: "Contactez - nous",
        tip1: "Suivez notre communauté",
        tip2: "Notez que StratTrade n'a pas établi de groupe Telegram officiel et que tout groupe Telegram créé sous le nom de StratTrade est soupçonné de tromperie.",
        tip3: "Avertissement de risque: le trading peut entraîner la perte de tous vos fonds. Le Trading de dérivés de gré à gré n'est pas pour tout le monde. Veuillez lire attentivement nos documents juridiques avant d'utiliser nos services et assurez - vous de bien comprendre les risques encourus avant de Trader. Vous ne possédez ni ne détenez réellement aucun actif sous - jacent.",
        tip4: "StratTrade ne fournit aucun conseil, recommandation ou opinion concernant l'achat, la détention ou la vente. Tous les produits que nous offrons sont des dérivés de gré à gré basés sur des actifs mondiaux. Tous les services proposés par StratTrade sont basés uniquement sur l’exécution des ordres de Trading.",
        tip5: "StratTrade est une marque commerciale partagée par plusieurs sociétés et exploitée par:",
        tip6: "Conisp International Ltd est l'émetteur des produits financiers décrits ou disponibles sur ce site. Conisp International Ltd est autorisé et réglementé par la Commission des services financiers (FSC) de Maurice sous le numéro de licence gb20025791 et son siège social est situé au 6, Rue Saint - Denis, 1er étage, River court, Port Louis, 11328, Maurice.",
        tip7: "Conisp global Pty Ltd est enregistrée sous le numéro ABN 90 149 011 361 et la licence australienne de services financiers (afsl) sous le numéro 398528.",
        tip8: "Conisp Holdings Limited est autorisée et réglementée par la Cayman Islands Financial Authority (CIMA) sous le numéro de licence SIB 1612446.",
        tip9: "Les informations sur ce site ne sont pas destinées aux résidents des États - Unis, du Canada, du Japon ou de la Nouvelle - Zélande, ni à quiconque dans un pays ou une juridiction où leur publication ou leur utilisation serait contraire aux lois ou réglementations locales. Veuillez noter que l'anglais est la langue principale de nos services et la langue juridiquement contraignante de tous nos documents contractuels. Les traductions dans d'autres langues ne sont fournies qu'à titre indicatif et, en cas de divergence entre la version anglaise et la version chinoise, la version anglaise prévaudra.",
        tip10:
            "Cryptage sécurisé des communications SSL. © StratTrade tous droits réservés.",
        link1: "Politique de confidentialité",
        link2: "Déclaration de divulgation du produit",
        link3: "Processus de plainte",
        link4: "Déclaration de divulgation des risques",
        link5: "Accord client",
        toTop: "Collant (se réfère aux messages du Forum Internet, etc.)",
    },
    nav: {
        tit1: "Le marché",
        tit2: "Transactions",
        tit3: "Apprendre",
        tit4: "À propos de nous",
        nav1: "Forex",
        nav2: "Indices",
        nav3: "Marchandises",
        nav4: "Partager",
        nav5: "Plateforme de Trading",
        nav6: "Stratégie de Trading",
        nav7: "Perspectives de Trading",
        nav8: "Calendrier financier",
        nav9: "Nouvelles en temps réel",
        nav10: "Données du marché",
        nav11: "Indice de sentiment",
        nav12: "Système de gestion des risques",
        nav13: "Spécifications du contrat",
        nav14: "Base des transactions",
        nav15: "Aperçu des investissements",
        nav16: "Collège",
        nav17: "À propos de StratTrade",
        nav18: "Nos récompenses",
        nav19: "Centre des médias",
        nav20: "Sécurité des fonds des clients",
        nav21: "Frais et charges",
        nav22: "Sociétés affiliées",
        nav23: "Contactez - nous",
        nav24: "Centre d'aide",
        nav25: "Centre d'aide",
    },

    city: {
        albania: "Albanie",
        algeria: "Algérie",
        argentina: "Argentine",
        armenia: "Arménie",
        australia: "Australie",
        pakistan: "Pakistan",
        austria: "Autriche",
        bahrain: "Bahreïn",
        belgium: "Belgique",
        bosnia_and_Herzegovina: "Bosnie - Herzégovine",
        brazil: "Brésil",
        brunei: "Brunei",
        bulgaria: "Bulgarie",
        cambodia: "Cambodge",
        canada: "Canada",
        cameroon: "Cameroun",
        chile: "Chile",
        colombia: "Colombie",
        costa_Rica: "Costa Rica",
        croatia: "Croatie",
        cyprus: "Cyprus",
        czech_Republic: "République tchèque",
        denmark: "Danemark",
        dominican_Republic: "République dominicaine",
        egypt: "Égypte",
        estonia: "Estonie",
        ethiopia: "Éthiopie",
        finland: "Finlande",
        france: "France",
        georgia: "Géorgie",
        germany: "Allemagne",
        ghana: "Ghana",
        greece: "Grèce",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Hongrie",
        iceland: "Islande",
        ireland: "Irlande",
        italy: "Italie",
        india: "India",
        indonesia: "Indonésie",
        israel: "Israël",
        iran: "Iran",
        iraq: "Irak",
        japan: "Japon",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Corée du Sud",
        kuwait: "Koweït",
        kyrgyzstan: "Kirghizistan",
        laos: "Laos",
        latvia: "Lettonie",
        lithuania: "Lituanie",
        luxembourg: "Luxembourg",
        macao_China: "Macao, China",
        macedonia: "Macédoine",
        malaysia: "Malaisie",
        malta: "Malta",
        mexico: "Mexique",
        moldova: "Moldavie",
        monaco: "Monaco",
        mongolia: "Mongolie",
        montenegro: "Monténégro",
        morocco: "Maroc",
        myanmar: "Myanmar",
        netherlands: " Netherlands",
        new_Zealand: "Nouvelle - Zélande",
        nepal: "Népal",
        nigeria: "Nigéria",
        norway: "Norvège",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Pérou",
        philippines: "the Philippines",
        poland: "Pologne",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Roumanie",
        russia: "Russie",
        republic_of_Trinidad_and_Tobago: "République de Trinité - et - Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Arabie saoudite",
        serbia: "Serbie",
        singapore: "Singapore",
        slovakia: "Slovaquie",
        slovenia: "Slovénie",
        south_Africa: "Afrique du Sud",
        spain: "Espagne",
        sri_Lanka: "Sri Lanka",
        sweden: "Suède",
        switzerland: "Suisse",
        taiwan_China: "Taiwan, China",
        tajikistan: "Tadjikistan",
        tanzania: "Tanzania",
        thailand: "Thaïlande",
        turkey: "Dinde",
        turkmenistan: "Turkménistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "Émirats arabes unis",
        united_Kingdom: "Royaume - Uni de Grande - Bretagne",
        united_States: "États - Unis",
        uzbekistan: "Ouzbékistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaïdjan",
        bangladesh: "Bangladesh",
        belarus: "Biélorussie",
        belize: "belize",
        benin: "Bénin",
        bhutan: "Bhoutan",
        bolivia: "Bolivie",
        botswana: "Botswana",
        british_Virgin_Islands: "Îles Vierges britanniques",
        burkina_Faso: "burkina faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Îles Caïmans",
        central_African_Republic: "République centrafricaine",
        chad: "Tchad",
        comoros: "Comoros",
        the_Republic_of_the_Congo: "La République du Congo",
        democratic_Republic_of_the_Congo: "Congo démocratique",
        djibouti: "Djibouti",
        ecuador: "Équateur",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Guinée équatoriale",
        eritrea: "Érythrée",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambie",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinée",
        haiti: "Haïti",
        isle_of_Man: "Isle of Man",
        cote_d_Ivoire: "Côte d'Ivoire",
        jamaica: "Jamaïque",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Libéria",
        libya: "Libye",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibie",
        nicaragua: "Nicaragua",
        republic_of_Niger: "République du Niger",
        north_Korea: "Corée du Nord",
        reunion: "Reunion",
        san_Marino: "San Marino",
        senegal: "Sénégal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalie",
        sudan: "Soudan",
        suriname: "Surinam",
        eswatini: "Eswatini",
        syria: "Syrie",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisie",
        united_States_Virgin_Islands: "Îles Vierges américaines",
        uganda: "Ouganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yémen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambie",
        zimbabwe: "Zimbabwe",
        china: "China",
    },
};
