export default {
	common: {
		learn: {
			title1: "Saldo primario",
			content1: "StratTrade ofrece nuestro servicio de saldo de efectivo y criptodivisas alojadas de forma gratuita, lo que le permite almacenar su efectivo y criptodivisas admitidas sin coste alguno. Puedes transferir criptomonedas del saldo principal de un usuario de StratTrade al saldo principal de otro usuario sin ningún coste.\n" +
				"\n" +
				"Comisiones de transacción de la red\n" +
				"\n" +
				"Para las transacciones en redes de criptomonedas (es decir, transferencias de criptomonedas fuera de la plataforma StratTrade), StratTrade incurre y paga las tasas de transacción de la red (por ejemplo, tasas de mineros). Cuando envíe criptomonedas desde su monedero StratTrade a otro monedero, le cobraremos una tarifa basada en nuestra estimación de las tarifas de red vigentes para un envío independiente de monedero a monedero. Sin embargo, la tarifa final que paga StratTrade puede diferir de la tarifa estimada debido a factores como la agrupación de transacciones o cambios en los niveles de congestión de la red. Todas las comisiones se indican en el momento de la transacción.\n" +
				"\n" +
				"Aumento de la eficiencia y tarifas de red\n" +
				"\n" +
				"Debido a las posibles mejoras de eficiencia derivadas de la agrupación de transacciones, el importe total de las tarifas de red estimadas pagadas por los usuarios dentro de un lote determinado puede superar la tarifa de red final pagada por StratTrade.\n" +
				"\n" +
				"Tasa de procesamiento de la Red Lightning\n" +
				"\n" +
				"La Lightning Network es una red de micropagos que permite la transferencia de bitcoin sin incurrir en la comisión de transacción asociada a la blockchain de Bitcoin. Cuando envíes bitcoin desde StratTrade a través de Lightning Network, cobraremos una comisión de procesamiento equivalente al 0,1% del importe de bitcoin transferido.\n" +
				"\n" +
				"Añadir efectivo y cobrar\n" +
				"\n" +
				"Al añadir efectivo o retirar efectivo, es posible que se le cobre una comisión en función del método de pago que seleccione. Es importante revisar los detalles de la comisión antes de completar la transacción para entender cualquier cargo asociado..\n" +
				"\n" +
				"Operaciones de crédito\n" +
				"\n" +
				"Si pide un préstamo en USD a StratTrade o a una filial de StratTrade y tenemos que vender su garantía en BTC (según lo autorizado en el contrato de préstamo aplicable), le cobraremos una comisión fija del 2% del total de la transacción.",
			title2: "Comisiones de negociación y diferencial",
			content2: "Tarifas de StratTrade\n" +
				"\n" +
				"Cuando compras, vendes o conviertes criptomonedas en StratTrade, se cobran comisiones. Estas comisiones se calculan en el momento de realizar el pedido y pueden verse influidas por factores como el método de pago elegido, el volumen del pedido, las condiciones del mercado, la ubicación jurisdiccional y otros costes en los que incurramos para facilitar la transacción.\n" +
				"\n" +
				"Antes de enviar su operación, puede ver las comisiones en la pantalla de vista previa de la operación. Ten en cuenta que las comisiones pueden variar para transacciones similares. Al completar un pedido, aceptas pagar las comisiones de StratTrade aplicables a esa transacción.\n" +
				"\n" +
				"Difundir\n" +
				"\n" +
				"Cuando realizas órdenes simples de compra y venta, StratTrade incluye un diferencial en el precio cotizado. El diferencial también se incluye en el tipo de cambio al convertir de una criptomoneda a otra.\n" +
				"\n" +
				"Para ver el diferencial antes de previsualizar una orden de compra, venta o conversión, en la pantalla de previsualización:\n" +
				"\n" +
				"Órdenes de compra y venta: pulse sobre la información sobre herramientas situada junto a la partida del precio de su activo para ver el diferencial.\n" +
				"\n" +
				"Convertir órdenes: pulse sobre la información sobre herramientas situada junto al tipo de cambio cotizado.\n" +
				"\n" +
				"El diferencial ayuda a aumentar la probabilidad de éxito de la transacción y permite a StratTrade bloquear temporalmente el precio cotizado mientras procesa su pedido. StratTrade puede retener el exceso de margen de una transacción. Tenga en cuenta que el diferencial puede variar para transacciones similares.\n" +
				"\n" +
				"StratTrade Avanzado\n" +
				"\n" +
				"StratTrade Advanced no incluye spread porque interactúas directamente con el libro de pedidos.\n" +
				"\n" +
				"Tienda de monedasClub One\n" +
				"\n" +
				"StratTrade One es un producto de suscripción que ofrece la compra y venta de criptomonedas sin comisiones, con ciertas limitaciones. Los miembros aún pueden tener el diferencial incluido en sus precios de cotización.\n" +
				"\n" +
				"Cambios en las comisiones de negociación y el diferencial\n" +
				"\n" +
				"StratTrade prueba ocasionalmente cambios en las comisiones y el diferencial. Estos cambios pueden aplicarse en diferentes regiones, activos, tamaños de órdenes y tipos de operaciones.\n" +
				"\n" +
				"StratTrade te muestra el importe total, incluido el diferencial y las comisiones, cada vez que previsualizas una operación y posteriormente en tu historial de transacciones. \n" +
				"\n" +
				"Ejecución de operaciones\n" +
				"\n" +
				"Todas las operaciones de tu Saldo Primario se ejecutan a través del libro central de órdenes a precio limitado de StratTrade Exchange o de subastas competitivas. Puedes ver el libro de órdenes de un mercado, la oferta, la demanda y la profundidad en tiempo real. Estos libros de órdenes determinan los precios de cotización que ves durante las operaciones.\n" +
				"\n" +
				"StratTrade no tiene ninguna relación de pago por flujo de órdenes (PFOF) con creadores de mercado..",
			title3: "Tarjeta StratTrade",
			content3: "Gastar con la Tarjeta StratTrade no tiene comisiones por transacción. \n" +
				"\n" +
				"StratTrade incluye un diferencial en el precio de compra o venta de criptomonedas. Esto nos permite fijar temporalmente un precio para la ejecución de la operación mientras revisas los detalles de la transacción antes de enviarla.  \n" +
				"\n" +
				"Es posible que el operador del cajero automático le cobre comisiones. Consulte su Contrato de Titular de Tarjeta StratTrade para obtener más información.",
			title4: "Servicios de replanteo",
			content4: "No hay comisión por apostar o des-apostar. StratTrade se lleva una comisión basada en las recompensas que recibes de la red. Nuestra comisión estándar es del 35% para ADA, ATOM, DOT, MATIC, SOL y XTZ. Para ADA, ATOM, DOT, SOL y XTZ, la comisión es del 26,3% para los miembros elegibles de StratTrade One. Puedes encontrar tu tasa de comisión de ETH en Detalles del activo. Algunos usuarios pueden recibir ofertas para promociones de apuestas con tasas más bajas. El APY y las recompensas que aparecen en tu cuenta siempre reflejan las ganancias que recibes después de nuestra comisión. Puedes encontrar más información sobre las comisiones en el Acuerdo de usuario de StratTrade.",
			title5: "Recuperación de activos",
			content5: "Si enviaste una criptomoneda no admitida a tu cuenta StratTrade, es posible que se pueda recuperar. Cobraremos una comisión de red por el intento de recuperación. Para recuperaciones con un valor estimado de más de 100 $, también cobraremos una comisión de recuperación del 5% sobre la cantidad que supere los 100 $. El valor estimado de la recuperación puede diferir del valor real de mercado de la recuperación..",
		},
		award1: "Mejor corredor de contratos diferenciales de Asia 2023",
		award2: "El mejor corredor del mundo 2022",
		award3: "El corredor más confiable de 2022",
		award4: "La marca más confiable del año 2021",
		award5: "Mejor corredor de fintech",
		award6: "Los corredores más transparentes en las transacciones",
		country: "Análisis",
		health: "Salud",
		analysis: "País",
		information: "Información",
		help: "Ayudar",
		confirm: "Determinar",
		cancel: "Cancelación",
		tip: "Consejos",
		place_select: "Por favor, elija",
		placeEnter: "Por favor, introduzca",
		countryCode: "Código de país / región",
		submit: "Presentación",
		more: "Más",
		deposit: "Entrada de oro",
		real_trade: "Transacciones reales",
		paper_trade: "Transacciones simuladas",
		build_paperTrade: "Establecer una cuenta simulada",
		start_trade: "Iniciar la transacción de inmediato",
		app_name: "StratTrade",
		success: "éxito！",
		dataSources_name: "Trading Central",
		reset: "Restablecer",
		iknow: "Lo Tengo.",
		noData: "No hay datos por el momento",
		modify: "Modificación",
		pingcang: "Cierre de posiciones",
		all: "Todo",
		tip_cardMain: "Tomar o subir una foto de la parte delantera de su tarjeta de identificación",
		tip_cardBack: "Tomar o subir una foto en la parte posterior de su tarjeta de identificación",
		tip_cardMain1: "Tomar o subir una foto de la parte delantera de la licencia de conducir",
		tip_cardBack1: "Tomar o subir fotos detrás de la licencia de conducir",
		tip_cardMain2: "Tomar o subir fotos del pasaporte",
		tip_cardBack2: "Tomar o subir fotos del pasaporte",
	},
    currency: {
		btcusdt: "Bitcoin (moneda virtual)",
		ethusdt: "Etherlands",
		ltcusdt: "Litecoin (moneda virtual)",
		xrpusdt: "Ripple (moneda)",
		adausdt: "Moneda Ada (utilizada en fracciones de moneda)",
		eosusdt: "Pomelo Coin",
		dotusdt: "Boca Ratón (antes Boca Ratón), moneda rusa",
		trxusdt: "Moneda de la tierra de cultivo de olas (informática)",
		xmrusdt: "Moneda Monroe (por ejemplo, dólar estadounidense)",
		dogeusdt: "Moneda perruna",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "Dólar australiano Dólar estadounidense",
		fx_sgbpusd: "Libra-dólar",
		fx_seurusd: "Eurodólar",
		fx_snzdusd: "Dólar neozelandés (NZD) Dólar estadounidense",
		fx_susdcad: "Dólar estadounidense-Dólar canadiense",
		fx_susdchf: "Dólar estadounidense Franco suizo",
		fx_susdjpy: "Dólar estadounidense-yen",
		fx_saudcad: "Dólar australiano Dólar canadiense",
		fx_saudchf: "Dólar australiano y franco suizo",
		fx_saudjpy: "Dólar australiano Yen japonés",
		fx_saudnzd: "Dólar australiano Dólar neozelandés (NZD)",
		fx_scadchf: "Dólar canadiense Franco suizo",
		fx_scadjpy: "Dólar canadiense Yen japonés",
		fx_schfjpy: "Franco suizo Yen",
		fx_seuraud: "Euro Dólar australiano",
		fx_seurcad: "Euro Dólar canadiense",
		fx_seurgbp: "Euro Libra esterlina",
		fx_seurjpy: "Euro Yen",
		fx_seurnzd: "Euro Dólar neozelandés (NZD)",
		fx_sgbpaud: "Libra esterlina Dólar australiano",
		fx_sgbpchf: "Libra esterlina Franco suizo",
		fx_sgbpjpy: "GBPJPY",
		fx_sgbpnzd: "Libra Dólar neozelandés (NZD)",
		fx_scadnzd: "Dólar canadiense Dólar neozelandés",
		hf_CL: "Petróleo WTI Nueva York",
		hf_OIL: "Petróleo Brent",
		hf_GC: "Oro de Nueva York",
		hf_SI: "Plata de Nueva York",
		hf_HG: "Cobre (préstamo)",
		hf_NG: "Estados Unidos Gas natural",
		hf_CAD: "Cobre (préstamo)",
		hf_AHD: "Londres aluminio",
		HX_AAPL: "Granada",
		HX_BABA: "Alí Babá, personaje de Las mil y una noches",
		HX_GOOG: "Google",
		HX_WMT: "Wal-Mart, Walmart (minorista)",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Nikola Tesla (1856-1943), inventor e ingeniero serbio",
		HX_N225: "Nikkei 225",
		HX_GDAXI: "DAX30, Alemania",
		HX_IBEX: "IBEX35, España",
		HX_SPX: "Indice S&P 500",
		HX_FTSE: "FTSE 100",
		HX_KS11: "KOSPI Corea",
		HX_NZ50: "Nueva Zelanda 50",
		HX_PSI20: "Portugal PSI20",
		hf_ZSD: "Londres Zinc",
		hf_NID: "Níquel de Londres",
		hf_PBD: "Plomo de Londres",
		hf_SND: "Lata de Londres",
		hf_XAU: "Oro de Londres (oro al contado)",
		hf_XAG: "Plata de Londres (plata al contado)",
		hf_XPT: "Futuros del platino",
		hf_XPD: "Futuros del paladio",
		hf_CT: "Algodón estadounidense",
		hf_SM: "Soja en polvo",
		hf_BO: "Aceite de soja estadounidense",
		HX_SENSEX: "SENSEX, Bombay, India",
		HX_KLSE: "FTSE Malasia KLCI",
		HX_KSE100: "Karachi, Pakistán",
		HX_FCHI: "CAC40, Francia",
		HX_SX5E: "Snooker Europeo 50",
		HX_ICEXI: "ICEX, Islandia",
		HX_ASE: "ASE Atenas, Grecia",
		HX_OMXC20: "OMX Copenhague 20",
		HX_OSEBX: "OSEBX, Noruega",
		HX_OMXSPI: "OMXSPI, Suecia",
		HX_AMD: "Semiconductores Chaowei",
		HX_DIS: "Disney (nombre)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "MasterCard (marca)",
		HX_NVDA: "NVIDIA, empresa de tarjetas gráficas",
		HX_INTC: "Intel",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Tecnología Seagate",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "AstroCoin",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
	currenctType: {
        title: "Seleccione por favor uma moeda",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dólar',
        currenctType15: 'Dólares de Hong Kong',
        currenctType16: 'Moeda de Taiwan',
        currenctType17: 'Myr',
        currenctType18: 'Nova moeda',
        currenctType19: 'Iene japonês',
        currenctType20: 'Euro',
        currenctType21: 'AUD',
        currenctType22: 'Rupiah Indonésia',
        currenctType23: 'libra',
        currenctType24: 'Baht tailandês',
        currenctType25: 'Cad',
        currenctType26: 'Dong vietnamita',
        currenctType27: 'Won coreano',
        currenctType28: 'Macau Pataca',
    },
    addnew2: {
        address: {
			account_type: "Account Type",
			ifsc_code: "Código IFSC",
            title: "Dirección de la cartera",
            account_number: "Número de cuenta",
            add_btn: "Añadir dirección",
            label_type: "Seleccionar tipo",
            place_type: "Seleccione el tipo",
            label_number: "Número de cuenta",
            place_number: "Introduzca el número de cuenta",
            wallet_address: 'Dirección de la billetera',
            bank_name: 'Nombre del Banco',
            bank_address: "Dirección bancaria",
            name: "Nombre",
            payee_address: 'Dirección del beneficiario',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nombre de la sucursal",
            branch_code: "Código de la sucursal",
            bank_code: "Código bancario",
            phone: "Número de teléfono móvil",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Por favor, introduzca el contenido",
        },
    },
	addnew: {
		paypassTit: "Modificar la contraseña de retiro",
		paypassTit1: "Por favor, establezca su contraseña de retiro.",
		paypassTit2: "Inicializar su contraseña a su contraseña de inicio de sesión",
		loan: "Préstamos",
		repaid: "Ha sido reembolsado",
        unpaid: "No reembolsado",
		loanMore: "Quiero un préstamo.",
		immediateRepayment: "Reembolso inmediato",
		accountType1: "Cuenta de moneda",
		accountType2: "Cuenta contractual",
		accountType3: "Cuenta de opciones",
		from: "De",
        to: "De",
		get: "Obtendrá",
		labelBreed: "Moneda",
		placeBreed: "Por favor, elija la moneda",
		labelTransAccount: "Cuenta de transferencia",
		labelAmount: "Importe de la transferencia",
		placeAmount: "Por favor, introduzca el monto de la transferencia.",
		transferTit:"Transferencias de cuentas",
		convertTit:"Cambio flash de moneda y moneda",
		balanceTit:"Saldo de la cuenta",
		available: "Cuota disponible",
        pending: "Bloqueo",
        equivalent: "Equivalente",
		coinTrade: "Transacciones en moneda y moneda",
		secondContract: "Contrato de segundos",
		number: "Cantidad",
		labelNumber: "Cantidad",
		placeNumber: "Por favor, introduzca el número de redenciones",
		time: "Tiempo",
		loadAmount: "Importe previsto del préstamo",
		repaymentCycle: "Ciclo de reembolso del préstamo",
		dailyRate: "Tasa de interés diaria",
		repaymentMethod: "Método de reembolso",
		loanTip: "Préstamos de crédito (asegúrese de que la imagen sea claramente visible)",
        loanTip1: "Cargar certificado de propiedad",
        loanTip2: "Certificado de ingresos (relación laboral)",
        loanTip3: "Detalles de la factura bancaria",
        loanTip4: "Sube la foto frontal de la tarjeta de identificación",
        interest: "Intereses",
		repaymentMethod1: "Reembolso único al vencimiento",
	},
	params: {
		product_foreign: "Forex",
		product_shop: "Materias primas",
		product_number: "Índices",
		product_stock: "Acciones",
		product_coin: "Coin",
		billTypes: [
            { v: 101, name: 'Recargar' },
            { v: 102, name: 'Añadir' },
            { v: 103, name: 'Congelación' },
            { v: 104, name: 'Descongelación' },
			{v: 105, name: 'Depósito'},
			{v: 106, name: 'Depósito'},
            { v: 201, name: 'Congelación de retiros de efectivo' },
            { v: 202, name: 'Deducción' },
            { v: 203, name: 'Retiro exitoso' },
            { v: 204, name: 'Falló el retiro de efectivo' },
            { v: 205, name: 'Comisión de retiro' },
			{v: 206, name: 'Traslado'},
			{v: 207, name: 'Transferencia'},
			{v: 208, name: 'Transferencia de moneda y moneda'},
			{v: 209, name: 'Transferencia de moneda y moneda'},
            { v: 301, name: 'Deducción de la fianza' },
            { v: 302, name: 'Deducción de Comisiones' },
            { v: 303, name: 'Devolución de la fianza' },
            { v: 304, name: 'Ganancias' },
            { v: 305, name: 'Pérdidas' },
			{v: 311, name: 'Opciones de compra'},
			{v: 312, name: 'Ganancias de opciones'},
			{v: 313, name: 'Devolución de opciones'},
			{v: 314, name: 'Comisión de opciones'},
			{v: 315, name: 'Congelación de la compra de monedas y monedas'},
			{v: 316, name: 'Deducción de compra de moneda y moneda'},
			{v: 317, name: 'Compra y devolución de monedas y monedas'},
			{v: 318, name: 'La moneda se compra en la cuenta'},
			{v: 319, name: 'La moneda se compra en la cuenta'},
			{v: 320, name: 'Congelación de la venta de monedas y monedas'},
			{v: 321, name: 'Deducción por la venta de monedas y monedas'},
			{v: 322, name: 'Moneda vendida y devuelta'},
			{v: 323, name: 'Las monedas se venden a la cuenta'},
			{v: 324, name: 'Las monedas se venden a la cuenta'},
			{v: 325, name: 'Tarifa de manejo de monedas y monedas'},
			{v: 401, name: 'Se unen las máquinas mineras'},
			{v: 402, name: 'Retorno de la máquina minera'},
			{v: 403, name: 'Ingresos por máquinas mineras'},
			{v: 404, name: 'Retirada de la máquina minera'},
			{v: 405, name: 'Tarifa de salida de la máquina minera'},
			{v: 411, name: 'Ingresos por préstamos'},
			{v: 412, name: 'Comisiones de préstamo'},
			{v: 413, name: 'Reembolso exitoso'},
        ],
	},
	message: {
		logout: "Salir de inicio de sesión",
		register_success: "Registro exitoso",
		place_inner_personal: "Por favor, introduzca su información personal primero.",
		submit_success: "Presentación exitosa！",
		copy_success: "Copia exitosa！",
		copy_error: "Falló la copia！",
		modify_success: "Modificación exitosa",
		no_balance: "Saldo insuficiente para pagar la fianza",
		palce_password: "Por favor, introduzca la contraseña",
		trade_success: "Transacción exitosa",
		placeEnterAmount: "Por favor, introduzca la cantidad",
		tipTit1: "¿¿ estás seguro de pagar este pedido?",
	},
	web: {
		account: {
			menu1: "Información de la cuenta",
			menu2: "Mis activos",
			menu3: "Informe de la cuenta",
			menu4: "Seguridad de la cuenta",
			menu5: "Aviso",
			menu6: "Centro de actividades",
			menu7: "Mi billetera",
			menu8: "Fuera del oro",
			walletAdd: "añadir nueva billetera",
			report: {
				history_tit: "Informe sobre el historial de transacciones",
				stream_tit: "Informe sobre el flujo de fondos",
				desc: "Por favor, elija el rango de fecha adecuado para exportar el informe de la cuenta. (puede consultar la información de la cuenta en el último año)",
				select_date: "Selección del rango de fechas",
				week: "Casi una semana",
				month: "Casi un mes",
				threeMonth: "Casi tres meses",
				sixMonth: "Casi medio año",
				time: "Tiempo",
				view_report: "Mostrar informe",
				email_send: "Enviado por correo electrónico",
				dialog_tit: "Informe de la cuenta",
				dialog_p1: "StratTrade Holdings Ltd. está registrada en las Islas Caimán (número sib: 1612446) y tramita su transacción como sujeto de la transacción.",
				tit2: "Informe Histórico de transacciones de mitade",
				tit4: "Informe sobre el flujo de fondos de mitade",
				tit3: "Información de la cuenta",
				label1: "Nombre de la cuenta",
				label2: "ID de la cuenta",
				label3: "Moneda de la cuenta",
				label4: "Tiempo de presentación de informes",
				label5: "Período del informe",
				label6: "Flujo de fondos",
				empty: "No tiene una transacción cerrada.",
				email_des: "El informe se enviará a su buzón",
				tab1: "Informe",
				tab2: "Declaración diaria",
				tab3: "Declaración mensual",
			},
			account: {
				tit1: "Bienvenidos a StratTrade",
				tit2: "Se puede realizar una transacción real de entrada de oro completando la autenticación.",
				p1: "Abrir una cuenta de transacción real",
				p2: "Autenticación",
				btn1: "Autenticación",
				tit3: "Información personal",
				label_name: "Nombre",
				label_email: "Correo electrónico",
				label_address: "Dirección de residencia",
				tit4: "Información de la cuenta",
				label_account: "Cuenta de transacción (transacción real)",
				label_currency: "Moneda básica (transacciones reales)",
				label_company: "Empresa que abre una cuenta ahora",
				company_tip: "StratTrade Holdings limited, empresa del Grupo StratTrade Holdings limited, ha obtenido el permiso y la autorización de la Autoridad Monetaria de las Islas Caimán (cima).",
				tit5: "Abrir una cuenta de transacción real",
				tit6: "Mejorar la información",
				tit7: "Rellene su información básica",
				tit8: "Evaluación de riesgos",
				tit9: "Evaluar la tolerancia al riesgo de las transacciones",
				tit10: "Autenticación",
				tit11: "Completar la autenticación de acuerdo con los requisitos regulatorios",
				p3: "Por favor, seleccione cualquiera de los siguientes documentos justificativos para verificar",
				type1: "Tarjeta de identificación",
				type2: "Licencia de conducir",
				type3: "Pasaporte",
				p4: "Confirmo que toda la información y los documentos proporcionados son completos, verdaderos y precisos, y me comprometo a proporcionar información actualizada a StratTrade dentro de los 30 días si se produce alguna actualización de la información.",
				next: "Siguiente paso",
				btn2: "Certificación aprobada",
				modify_personal_tit: "Modificar la información personal",
			},
			activity: {
				tit: "Centro de actividades",
				desc: "Puede participar en actividades para recibir recompensas y aumentar sus ingresos de inversión.",
			},
			capital: {
				real_trade: "Transacciones reales",
				label_nowAccount: "Cuenta actual",
				label_tradeAccount: "Cuenta de transacción",
				label_currency: "Moneda básica",
				deposit_withdraw_record: "Registro de entrada y salida",
				capital_flow_details: "Detalles del flujo de fondos",
			},
			notification: {
				tit: "Configuración de notificación",
				tit1: "Abrir canales de notificación",
				email: "Correo electrónico",
				message: "SMS",
				push: "Empujar",
				verify: "Verificación",
				verified: "Verificado",
				p1: "¡Por favor, abra / verifique los siguientes canales de notificación, ¡ no se pierdan noticias importantes!",
				type1: "Categoría de marketing",
				tit2: "Aviso de campaña de marketing",
				desc2: "Información preferencial de la plataforma, actividades operativas, etc.",
				tit3: "Orientación operativa",
				desc3: "Materiales de conocimiento ricos y profesionales",
			},
			security: {
				tit: "Contraseña",
				isSet: "Se ha establecido",
				set: "Ir a configurar",
				verify: "Verificación",
				verified: "Verificado",
				tit1: "Verificación de Seguridad",
				email: "Correo electrónico",
				phone: "Número de teléfono móvil",
				phone_tit: "Vinculación del número de teléfono móvil",
				password_tit: "Modificar la contraseña",
			},
		},
		market: {
			hot: "Popular",
			prev: "En la página anterior",
			next: "Siguiente página",
			line: "Tiempo compartido",
			main_subplot: "Indicadores del mapa principal y secundario",
			main: "Indicadores del mapa principal",
			subplot: "Indicadores del mapa adjunto",
			go_trade: "Saltar a la página de transacciones",
		},
		news: {
			tip: "Declaración de exención de responsabilidad: la información anterior es información general, solo para referencia y no sirve de base para la toma de decisiones comerciales.",
		},
		trade: {
			state1: "Posiciones",
			state2: "Lista",
			state3: "Historia",
			state: "Estado",
			state4: "Posiciones cerradas",
			state5: "Revocado",
			type: "Tipo",
			type1: "Todo",
			type2: "Vender",
			type3: "Comprar",
			left_label1: "Opcional",
			left_label2: "Vistas recientes",
			left_type1: "Modo de columna compacta",
			left_type2: "Modo de columna laxa",
			left_type3: "Muestra el mapa de tendencias",
			all: "Todo",
			right_time: "Tiempo de actualización",
			right_bp: "Recibido ayer",
			right_sp: "Recibido hoy",
			right_height: "Máximo",
			right_low: "Mínimo",
			remind_tit: "Nuevo recordatorio",
			remindList: "Lista de recordatorios",
			remind_btn: "Nuevo recordatorio",
			right_tab1: "Transacciones",
			right_tab2: "Detalles",
			right_sell: "Vender",
			right_buy: "Comprar",
			right_type: "Tipo",
			right_type1: "Mercado ",
			right_type2: "Pendiente ",
			right_priceMoney: "Colgar el precio unitario",
			right_pointerNumber: "Cantidad (mano)",
			right_lever: "Apalancamiento",
			right_balance: "Saldo disponible",
			right_stop_profit: "Detener las ganancias",
			right_stop_loss: "Stop loss",
			right_profit_loss: "Ganancias y pérdidas",
			now_order: "Hacer un pedido de inmediato",
			confirm_order: "Confirmar pedido",
			right_tit1: "Estrategia comercial",
			right_rinei: "Durante el día",
			right_short: "A corto plazo",
			right_middle: "A medio plazo",
			right_tit2: "Estrategias alternativas",
			right_tit3: "Comentarios técnicos",
			right_tit4: "Datos",
			right_tit5: "Emoción transaccional",
			right_label_sell: "Vendedor",
			right_label_buy: "Compradores",
			right_tip: "Solo para referencia y no como posición de la empresa",
			right_tip2: "Actualización cada 15 minutos",
			right_tit6: "Los precios suben y bajan",
			minute: "Min",
			today: "Hoy.",
			right_tit7: "Rango de variación de precios",
			now_price: "Precios actuales",
			right_low_price: "Precio más bajo",
			right_height_price: "Precio máximo",
			right_tit8: "Información contractual",
			right_tit9: "Dólar australiano frente franco suizo",
			right_label1: "Número de transacciones individuales",
			right_label2: "Apalancamiento máximo",
			right_label3: "Tamaño del contrato",
			right_label4: "Número máximo de posiciones totales",
			right_label5: "Diferencial flotante",
			right_label6: "Tarifa nocturna",
			hand: "Mano",
			right_collection_time: "Tiempo de cobro",
			right_tip3: "Referencia de la tarifa: cobrada de todo el tamaño de la transacción",
			right_label7: "Proporción de margen",
			right_label8: "Mantener la proporción de margen",
			right_label9: "Período de negociación",
			right_label10: "Período de negociación actual",
			right_label11: "Próxima sesión de negociación",


		},
	},
	home: {
		banner_p1: "A través",
		banner_p2: "Transacciones de la plataforma",
		banner_p3: "Divisas, productos básicos, índices, acciones",
		banner_p4: "Y más productos financieros populares",
		banner_p5: "Puede explorar los mercados financieros más populares del mundo. StratTrade ofrece 0 comisiones, un diferencial de bajo precio muy ventajoso y una configuración de apalancamiento flexible.",
		hot: "Popular",
		tit1: "Un trato más amigable",
		p1: "Interfaz simple e intuitiva, fácil de operar",
		p2: "Soporte para páginas web, aplicaciones móviles y escritorios, con transacciones en la punta de los dedos",
		p3: "Herramientas de gestión de riesgos como STOP loss / Track Stop loss",
		p4: "Múltiples gráficos técnicos y calendarios financieros, noticias en tiempo real",
		p5: "Correo en tiempo real, mensajes de texto y notificaciones de empuje",
		p6: "Mejorar constantemente para lograr una mejor experiencia comercial",
		btn1: "Explorar nuestra plataforma",
		row3_tit: "En cualquier momento y en cualquier lugar, libre comercio",
		row3_tip: "Soporte para páginas web, aplicaciones móviles y escritorios, con transacciones en la punta de los dedos",
		row3_tit2: "Descarga de código de escaneo",
		row3_tit3: "Lado del escritorio",
		row3_tit4: "Extremo móvil",
		regular_tit: "Una plataforma internacional de confianza",
		regular_tip: "Estamos comprometidos a proporcionar a nuestros clientes un entorno de transacción seguro y responsable.。",
		regular_tit1: "Supervisión de las autoridades",
		regular_p1: "Supervisión y autorización autorizadas de la industria para ganar la confianza de los clientes globales",
		regular_tit2: "Proteger la seguridad de los fondos",
		regular_p2: "Los depósitos de los clientes minoristas se depositan por separado en cuentas fiduciarias según sea necesario de acuerdo con los requisitos regulatorios.",
		regular_tit3: "Protección del saldo negativo",
		regular_p3: "Le proporciona protección de saldo negativo de la cuenta, el saldo negativo se elimina a tiempo, para que su pérdida no supere el monto de entrada, transacciones tranquilas",
		regular_tit4: "Servicio al cliente 5 * 24 horas",
		regular_p4: "Equipo de servicio al cliente sincero y profesional, soporte en línea las 24 horas del día, dispuesto a resolver cualquier problema suyo",
		service_tit: "Más servicios le ayudan",
		service_tit1: "Estrategia comercial",
		service_p1: "La estrategia de comercio en tiempo real le ayuda a comprender las últimas tendencias del mercado y aprovechar mejor el momento de la transacción.实时交易策略助您了解最新市场走势，更好把握交易时机。",
		service_tit2: "Conocimiento de las transacciones",
		service_p2: "Junto con StratTrade, aprenda conocimientos sobre transacciones de forma gratuita y mejore sus habilidades comerciales.",
		service_tit3: "Gestión de riesgos",
		service_p3: "Conozca las herramientas gratuitas de gestión de riesgos que ofrece StratTrade para proteger mejor sus activos.",
		step_tit: "Abrir una cuenta es simple y conveniente",
		step_tip: "Tres pasos simples, la cuenta se puede abrir en unos minutos",
		step_tit1: "Registro",
		step_p1: "Rellene la información y presente su solicitud",
		step_tit2: "Entrada de oro",
		step_p2: "Los fondos se pueden depositar rápidamente de varias maneras.",
		step_tit3: "Iniciar la transacción",
		step_p3: "Explorar oportunidades comerciales y hacer pedidos rápidamente",
		award_tit: "Nuestros logros",
		award_p1: "Siempre hemos buscado la excelencia, la excelencia y nos hemos comprometido a proporcionar a nuestros clientes servicios comerciales de alta calidad.",
		award_p2: "La marca StratTrade se siente muy honrada de recibir los honores otorgados por instituciones famosas de la industria, afirmando los esfuerzos continuos del equipo y el compromiso con los clientes.",
		row7_tit: "Información financiera mundial en tiempo real",
		row7_tit1: "Noticias en tiempo real",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "Haga clic para descargar",
		p1: "Comerciar en cualquier momento y en cualquier lugar",
		p2: "Las transacciones son más rápidas e inteligentes",
		p3: "0 transacciones por Comisiones",
		p4: "La variedad de inversión más popular del mundo",
		renderDom1: "Divisas",
		renderDom2: "Acciones estadounidenses",
		renderDom3: "Oro",
		renderDom4: "Petróleo crudo",
		renderDom5: "Índice",
		renderDom6: "Criptomonedas",
		login: "Iniciar sesión",
		elail: "Buzón",
		phone: "Número de teléfono móvil",
		no_account: "No hay cuenta？",
		go_register: "Ir a registrarse",
		register: "Registro",
		now_register: "Registrarse de inmediato",
		have_account: "Cuenta existente？",
		go_login: "Ir a iniciar sesión",
	},
	about: {
		serviceFee: "Tarifas de servicio",
		introduction: "Introducción de la plataforma",
		trade_platform: "Plataforma de Comercio",
		complaint_process: "Proceso de queja",
		page: "Página",
		place_search: "Busca lo que quieras saber",
		no_answer: "No se puede encontrar la respuesta que necesita?",
		contact_us: "Póngase en contacto con nosotros",
		awards: {
			h1: "Premios y honores",
			p1: "Siempre hemos buscado la excelencia, la excelencia y nos hemos comprometido a proporcionar a nuestros clientes servicios comerciales de alta calidad. La marca StratTrade se siente muy honrada de recibir los honores otorgados por instituciones famosas de la industria, afirmando los esfuerzos continuos del equipo y el compromiso con los clientes.",
			p2: "O experimentar nuestra premiada Plataforma de comercio en línea"
		},
		charges: {
			h1: "Tarifas y tarifas",
			p1: "Como parte de la mejora de la experiencia del cliente, StratTrade espera poder proporcionar a los clientes servicios comerciales más rentables. Nos comprometemos a ser abiertos y transparentes en términos de tarifas y tarifas, sin tarifas ocultas. Los principales costos de transacción de los clientes son la diferencia de precio de compra y venta y el interés de las posiciones durante la noche.",
			h2: "En primer lugar, puede disfrutar de las transacciones con StratTrade",
			li1: "Cotización instantánea del producto",
			li2: "Indicadores profesionales y Técnicos",
			li3: "Análisis exclusivo del mercado",
			free: "Gratis",
			view: "Ver arriba",
			h3: "Diferencia de precio de compra y venta",
			p2: "StratTrade cobra la diferencia de precio de compra y venta como compensación por el servicio. Esta parte de la tarifa se ha reflejado en la oferta del producto, cuando está estableciendo una posición, en realidad ha pagado la diferencia. Debido a que la tarifa de diferencia no es fija, se recomienda ir a la página de cotización del producto específico o iniciar sesión en",
			h4: "Cargos por depósitos y retiros",
			p3: "Para reducir el costo de los clientes, en la mayoría de los casos no cobramos sus retiros *. Pero una empresa de terceros o un banco puede cobrar comisiones como tarifas bancarias de tránsito durante su acceso.",
			p4: "En caso de necesidad de cobrar, puede consultar a nuestro Comisionado de servicio al cliente.。",
			p5: "Por ejemplo, transacciones a través de tarjetas de crédito internacionales, transferencias / transferencias de cuentas bancarias o transacciones con monedas no apoyadas (conversión de divisas), etc.",
			h5: "Costo de mantener posiciones durante la noche",
			p6: "Si todavía tiene una posición a las 22: 00 GMT (22: 00 gmt), se le cobrará / depositará la tarifa de la noche a la mañana. Las consultas sobre el costo de la noche para cada producto se pueden consultar en la página de cotización del producto específico o iniciar sesión en",
			h6: "Otros gastos",
			p7: "No hay otros gastos. Nuestra política de tarifas es completamente transparente, y cualquier proyecto que necesite tarifas se anunciará e informará con antelación, sin preocuparse por las tarifas ocultas."
		},
		contact: {
			h1: "Póngase en contacto con nosotros",
			address: "Dirección",
			custom_email: "Correo electrónico de contacto con el cliente",
			business_email: "Correo electrónico de cooperación empresarial",
			h2: "Escríbenos",
			p1: "Si tiene alguna pregunta sobre nuestro servicio, complete el siguiente formulario para enviar la pregunta, y nuestro Comisionado de servicio al cliente responderá lo antes posible. Además, si desea inscribirse para obtener la estrategia de transacción diaria que ofrecemos en colaboración con Trading central, elija 'suscribirse al análisis diario del mercado' en el siguiente tipo de tabla y rellene la versión del idioma que desea cobrar, y le registraremos. El Servicio de suscripción solo está disponible para clientes reales de los StratTrade existentes.",
			h3: "Procedimiento de declaración",
			p2: "Tendremos un equipo especial para investigar y resolver todas las quejas para usted. para más detalles, consulte.",
		},
		faq: {
			h1: "Preguntas frecuentes",
			tit1: "¿¿ de qué sirve una cuenta simulada?？",
			tit2: "Cómo abrir una cuenta real？",
			tit3: "Cómo establecer una lista de precios de mercado？",
			tit4: "¿¿ por qué no puedo hacer un pedido?？",
			p1: "La cuenta simulada es la misma que la cuenta real en la mayoría de las interfaces de transacción, datos y operaciones. la cuenta simulada tiene $50000 en fondos virtuales, con el objetivo de familiarizar a los clientes con las funciones de la plataforma a través de operaciones simuladas sin riesgo financiero.",
			p2: "Para abrir una cuenta real, siga los siguientes pasos:：",
			p3: "1. ingrese a la página de registro de StratTrade y siga las indicaciones: registre el número de correo electrónico / teléfono móvil, establezca la contraseña de inicio de sesión y cree automáticamente [cuenta simulada] después de enviar la confirmación.",
			p4: "2. en la página de cuenta simulada, seleccione cambiar a cuenta real y siga el proceso para abrir [cuenta real].",
			p5: "Antes de abrir su cuenta real, le recomendamos que lea los documentos de divulgación legal relacionados con la transacción.",
			p6: "Tenga en cuenta que solo puede ingresar el dinero a su cuenta real después de completar la autenticación.",
			p7: "Haga clic en comercio para mostrar todos los productos que la Plataforma puede proporcionar para la transacción. puede introducir el Código de variedad o el nombre en el cuadro de entrada a la derecha para buscar. al seleccionar un producto, haga clic en 'comprar / vender' (sell / buy) aparecerá la ventana de transacción. en la ventana de transacción, puede ver el precio actual y el margen esperado. Puede ajustar manualmente el número de transacciones de apertura de posiciones, establecer ganancias y paradas para controlar el riesgo, y luego hacer clic en 'comprar / vender' (sell / buy) a continuación para completar la apertura de posiciones. Tenga en cuenta que los precios de todos los productos financieros están siguiendo las fluctuaciones y actualizaciones del mercado en cualquier momento, y el mercado puede haber cambiado antes de presionar el botón de compra / venta.",
			p8: "En primer lugar, compruebe si la conexión de red es normal. Cuando el mercado de variedades comerciales cierra temporalmente la cotización o cierra el mercado, no se puede hacer un pedido. Para obtener más ayuda, Póngase en contacto con nuestro servicio al cliente en línea.",

		},
		fundSecurity: {
			h1: "Seguridad financiera",
			p1: "StratTrade está autorizado y regulado por reguladores autorizados. El cumplimiento es el núcleo y la cultura de cada uno de nuestros trabajos. La seguridad de sus fondos es crucial, y todos los depósitos de los clientes se depositan en cuarentena.",
			p2: "Los depósitos de los clientes minoristas se depositan por separado en cuentas fiduciarias si es necesario, de acuerdo con los requisitos regulatorios.",
			p3: "StratTrade no utiliza fondos de clientes para actividades comerciales fuera de los permitidos por ninguna ley y reglamento.",
			p4: "StratTrade no especula en ningún mercado",
			p5: "Auditoría Independiente de empresas de contabilidad de terceros",
		},
		help: {
			h1: "Bienvenidos al Centro de ayuda StratTrade",
		},
		media: {
			h1: "Centro de medios",
			p1: "Vea información relacionada con la marca StratTrade, comunicados de prensa, primeros planos de medios, anuncios y otra información.",
		},
		mitrade: {
			h1: "Sobre StratTrade",
			view_certificate: "（Método de verificación）",
			p1: "StratTrade es una compañía fintech autorizada y regulada por una autoridad, centrada en proporcionar a los inversores una experiencia comercial simple y conveniente. La innovadora plataforma de comercio diversificada de StratTrade ha ganado repetidamente honores como la mejor plataforma de comercio móvil y la empresa de valores más innovadora.",
			p2: "A través de la Plataforma StratTrade, puede invertir y comerciar en mercados financieros internacionales más amplios, cubriendo cientos de variedades populares como acciones, índices, productos básicos y divisas. No solo se limita a la experiencia de plataformas de comercio de versiones móviles y web amigables, StratTrade también le proporciona costos de transacción competitivos, ejecución rápida de transacciones, excelente servicio al cliente y rico soporte de información para ayudarle a aprovechar las oportunidades de inversión y transacciones a tiempo.",
			p3: "StratTrade está estrictamente regulado por la autoridad de la industria y múltiples instituciones. StratTrade International está autorizado y regulado por la Comisión de servicios financieros de Mauricio (fsc), con el número de licencia gb20025791. Para obtener más información sobre la licencia de mauricio, consulte el sitio web oficial del fsc. https://www.fscmauritius.org",
			p4: "Haga una consulta. MStratTrade global posee una licencia de servicio financiero autorizada por la Comisión Australiana de valores e inversiones (asic) (afsl 398528). Para obtener más detalles sobre la licencia australiana, consulte el sitio web oficial de asic www.asic.gov.au.",
			p5: "Haga una consulta. Itrade Holdings está autorizada y regulada por la Autoridad Monetaria de las Islas Caimán (cima), con el número de licencia SIB 1612446. Para obtener más detalles sobre la licencia de caimán, consulte el sitio web oficial de cima www.cima.ky.",
			p6: "Haga una consulta. Todas las operaciones comerciales de StratTrade se llevan a cabo bajo una estricta supervisión y cumplen con todas las regulaciones.",
			h2: "Antecedentes de StratTrade",
			p7: "StratTrade está compuesto por un equipo senior con experiencia y conocimiento en transacciones financieras y la industria de la tecnología financiera, y fue fundada en melbourne, Australia. La idea de StratTrade es que las transacciones se vuelvan más sencillas y amigables",
			p8: "Nuestra estrategia holística y nuestro modelo de negocio nos permiten tener una observación y comprensión global y profunda del desarrollo de la industria y la demanda del mercado, lo que permite asignar recursos de manera más específica, innovar constantemente la tecnología y optimizar la eficiencia, y seguir trayendo a los clientes una experiencia comercial más conveniente y amigable.",
			h3: "Por qué elegir StratTrade",
			why1: "Condiciones de negociación de umbral bajo",
			why2: "Regulado por la autoridad de la industria",
			why3: "Plataforma de comercio simple e intuitiva",
			why4: "Soporte en línea de alto nivel",
			why5: "Costos de transacción competitivos",
			why6: "Protección del saldo negativo",
			h4: "Premios y honores",
			p9: "Siempre hemos buscado la excelencia, la excelencia y nos hemos comprometido a proporcionar a nuestros clientes servicios comerciales de alta calidad.",
			p10: "La marca StratTrade se siente muy honrada de recibir los honores otorgados por instituciones famosas de la industria, afirmando los esfuerzos continuos del equipo y el compromiso con los clientes.",
			new_add: "Nuevos usuarios en todo el mundo",
			ol1: "Mejor proveedor de información de noticias y análisis",
			ol2: "El mejor emisor de divisas de Asia",
			ol3: "Premio australiano de satisfacción del cliente de divisas",
			ol4: "La mejor aplicación móvil",
			ol5: "El comerciante de valores de más rápido crecimiento de Australia",
			h5: "La filosofía operativa de StratTrade",
			tip_tit1: "Responsabilidad",
			tip_tit2: "Simple y hermoso",
			tip_tit3: "Transparencia",
			tip_tit4: "Innovación",
			tip_p1: "Todas las transacciones implican riesgos y StratTrade toma más medidas para proporcionar a los clientes un entorno de negociación responsable. StratTrade está estrictamente regulado por la autoridad de la industria, múltiples instituciones y sigue estándares operativos de Seguridad y estabilidad de alto nivel. Nuestra plataforma continúa proporcionando una serie de herramientas de transacción responsables, incluida la gestión de riesgos.",
			tip_p2: "La simplicidad es belleza, los detalles son excelentes, y StratTrade se esfuerza por cada detalle de la facilidad de uso y la experiencia del usuario. Continuamos optimizando la Plataforma y los servicios, con la esperanza de que todos, ya sean novatos o inversores experimentados, puedan aprovechar fácilmente las oportunidades comerciales a través de la Plataforma StratTrade.",
			tip_p3: "Para que nuestros clientes se beneficien plenamente de un entorno comercial justo y eficiente, StratTrade sigue un sistema de autorregulación riguroso y transparente, proporcionando información integral y transparente sobre los productos de la plataforma, un entorno de precios y datos, y todos los costos son claros y abiertos para garantizar la sinceridad y transparencia completas de todo el proceso de servicio.",
			tip_p4: "StratTrade está convencido de que el progreso científico y tecnológico, la innovación financiera y los servicios más amplios son las fuerzas que impulsan el crecimiento y pueden crear y compartir valor constantemente. Con este fin, fomentamos el pensamiento abierto y continuamos promoviendo la innovación, con el objetivo de convertirnos en innovadores y líderes en tecnología financiera.",
			row6_tit1: "Nuestra misión",
			row6_tit2: "Nuestra visión",
			row6_tit3: "Oportunidades de trabajo",
			row6_p1: "Proporcionar un entorno de comercio justo, eficiente y transparente sin restricciones de espacio, tiempo y mercado para más personas que quieran realizar transacciones, liderando la innovación financiera y tecnológica de la industria.",
			row6_p2: "Creemos que el progreso tecnológico, la innovación financiera y los servicios diversificados son las fuerzas que impulsan nuestro crecimiento y nos permiten seguir creando y compartiendo valor en la sociedad.",
			row6_p3: "Para ello, fomentamos el pensamiento abierto y promovemos la innovación, con el objetivo de convertirnos en",
			row6_p4: "Las principales empresas de divisas del mundo",
			row6_p5: "Somos un equipo global que abarca siete países, y con el rápido desarrollo del equipo, hemos estado buscando profesionales para unirnos. ¡¡ haz lo que te guste con un equipo multicultural!",
			row6_p6: "Envíenos por correo electrónico su último currículum y las razones por las que desea trabajar con nosotros:",
			row6_p7: "Además, también puede estar en",
			row6_p8: "O",
			row6_p9: "Revisa nuestros puestos vacantes abiertos.。",
			row6_p10: "StratTrade protege estrictamente la seguridad de su información personal. En el proceso de solicitud, StratTrade no le pedirá información financiera. Nuestra contratación no requiere ninguna información financiera, tarjeta de crédito, cuenta bancaria o cualquier tipo de pago.",
			row6_p11: "Si sospecha fraude en un puesto, pase",
			row6_p12: "Póngase en contacto con el Departamento de recursos humanos de StratTrade. Si confirma que ha sido engañado por información de reclutamiento falsa, Póngase en contacto con las agencias locales pertinentes.",

		}
	},
	product: {
		row2: {
			tit: "Variedad de Comercio",
			sell: "Vender",
			buy: "Comprar",
			trade: "Transacciones",
			more_product: "Más productos negociados",
		},
		row3: {
			tit: "Soporte para transacciones de varios dispositivos",
			tit1: "Soporte para transacciones de varios dispositivos",
			p1: "StratTrade le ofrece una versión web, una plataforma de comercio móvil y de escritorio. Puede realizar transacciones en cualquier momento y en cualquier lugar.",
			download: "Descargar",
		},
		row4: {
			tit: "Elegir StratTrade",
			tit1: "Plataforma simple e intuitiva",
			p1: "Integra el mercado, las transacciones, la gestión de cuentas, la información y la gestión de riesgos, con funciones potentes para lograr una mejor experiencia de uso.",
			tit2: "Costos de transacción competitivos",
			p2: "0 comisiones de transacción, muy competitivas, transparentes y de bajo precio, bajo interés a la noche, para que disfrute de transacciones de bajo costo",
			tit3: "Condiciones de negociación de umbral bajo",
			p3: "el número mínimo de manos por transacción es tan bajo como 0,01 manos, y el margen bajo puede abrir posiciones",
			tit4: "Regulado por la autoridad",
			p4: "Autorización y supervisión autorizada de la industria, depósitos aislados de todos los clientes por separado para proteger la seguridad de los activos de los clientes",
			tit5: "Soporte en línea de alto nivel",
			p5: "En cualquier situación de mercado, las pérdidas de su cuenta no superarán el principal, y el saldo negativo se eliminará a tiempo para mejorar la capacidad de gestión de riesgos",
			tit6: "Soporte en línea de alto nivel",
			p6: "Proporcionar un servicio al cliente en línea de respuesta rápida proporcionará un mejor servicio a través de los incansables esfuerzos de un equipo profesional",
		},
		row5: {
			tit: "Cuestiones relacionadas",
		},
		commodities: {
			title: "Comercio de mercancías",
			p: "Los productos básicos como los metales preciosos y la energía se consideran resistentes tanto a la inflación como a la oportunidad de aumentar el valor de la inversión y son una parte importante de la inversión diversificada. entre ellos, el oro, la plata, el petróleo, etc., son variedades de productos básicos con un gran volumen de transacciones, precios abiertos, transparentes y alta liquidez, que Son muy bien recibidos por los inversores. los precios de los productos básicos fluctúan significativamente debido a factores como la oferta y la demanda, la situación económica y política y la moneda. Se ofrece la oportunidad de riesgos y beneficios.",
		},
		forex: {
			title: "Transacciones en divisas",
			p: "Las transacciones en divisas se refieren a la conversión de una moneda en otra, y el mercado de divisas es el mercado financiero con el mayor volumen de transacciones del mundo. Hay muchos participantes en el mercado de divisas que pagan a través del mercado de divisas, cubren el riesgo de cambios monetarios o negocian para obtener ganancias. Las transacciones de divisas se realizan a través de la red entre bancos, instituciones y comerciantes individuales en todo el mundo, con fluctuaciones de precios de cinco días a la semana y 24 horas al día, ofreciendo oportunidades de rendimiento de riesgo.",
		},
		indices: {
			title: "Indices",
			p: "El índice bursátil es un valor estadístico que representa un determinado tipo de acciones características en una bolsa específica, y es un indicador que refleja el nivel general de precios y los cambios de un determinado tipo de acciones constituyentes en el mercado. El índice bursátil negociado, que refleja las oportunidades de inversión en el mercado o la industria en su conjunto, puede reducir el riesgo de invertir en acciones individuales. Los índices más populares de los mercados estadounidense, europeo, asiático y australiano, como Australia 200, las acciones tecnológicas estadounidenses 100, Hong Kong 50, Alemania 30, etc., ofrecen diferentes oportunidades para las bolsas globales.",
		},
		shares: {
			title: "Comercio de acciones",
			p: "Las acciones son un cupón emitido en la bolsa. Para recaudar fondos, las empresas distribuirán los valores de uso de la propiedad de la empresa a través de las bolsas cotizadas. Solo en 2019, las acciones mundiales negociaron más de 60 billones de dólares, son grandes y tienen una fuerte liquidez, por lo que incluso después de muchos años, sigue siendo uno de los instrumentos financieros más populares a los ojos de los inversores globales. En la Plataforma StratTrade, los comerciantes pueden usar el apalancamiento para negociar cambios de precios en las acciones. Los precios de las acciones se ven afectados por diversos factores, como la macroeconomía, las perspectivas industriales y el funcionamiento de la empresa, con una gran volatilidad y oportunidades de negociación de inversiones en todo momento.",
		},
		coins: {
			title: "Criptomoneda",
			p: "Las criptodivisas son una moneda digital creada mediante código. Funcionan de forma autónoma al margen de los sistemas bancarios y gubernamentales tradicionales. Como su nombre indica, las criptodivisas utilizan el cifrado para asegurar las transacciones y permiten la creación de otras unidades, siendo Bitcoin la criptodivisa original y, con diferencia, la más famosa, creada por Satoshi Nakamoto y lanzada en enero de 2009. Aún hoy se desconoce si el nombre de Satoshi Nakamoto se refiere a una persona o a un grupo de personas. Bitcoin se considera la primera criptomoneda descentralizada. Como todas las criptomonedas, se controla a través de una base de datos de transacciones blockchain que sirve como libro mayor público distribuido. Hasta la fecha, hay más de 1.000 criptodivisas disponibles para el comercio en línea."
		}
	},
	trade: {
		data_sources: "Fuentes de datos",
		tip: "La información anterior es solo para referencia. StratTrade no garantiza la precisión, el tiempo real y la integridad del contenido de la información, por lo que no debe confiar demasiado en la información proporcionada. Esta información no contiene nuestros registros de precios de transacción, ni ofertas o convocatorias para transacciones de ningún instrumento financiero. StratTrade no es una empresa que ofrece asesoramiento financiero, sino que solo ofrece servicios de naturaleza Ejecutiva. Recomendamos a los lectores que busquen sugerencias de inversión relevantes por sí mismos. Consulte nuestra declaración completa de exención de responsabilidad.",
		tip2: "Actualización todos los viernes a las 15: 00 (gmt)",
		tip3: "La perspectiva comercial es una herramienta para predecir el sentimiento comercial del mercado, que refleja la visión a corto y medio plazo y las previsiones de tendencias de los expertos de la industria sobre los productos populares.",
		analysis: {
			tit: "Estrategia comercial",
			all: "Todo",
			day: "Durante el día",
			tit1: "Estrategia comercial",
			tit2: "Comentarios técnicos",
		},
		calendar: {
			tit: "Calendario financiero",
			prev_week: "La semana pasada",
			next_week: "La próxima semana",
			place_date: "Fecha de búsqueda",
			select: "Opciones",
			select1: "País",
			select2: "Importancia",
			start_time: "Hora de inicio",
			end_time: "Fin del tiempo",
			search: "Buscar",
		},
		contract: {
			tit: "Reglas del contrato",
			type1: "Todo",
			type2: "Divisas",
			type3: "Productos Básicos",
			type4: "Índice",
			type5: "Acciones estadounidenses",
			type6: "Acciones australianas",
			tit1: "Principales divisas",
			tit2: "Divisas secundarias",
			tit3: "Productos Básicos",
			tit4: "Principales índices",
			tit5: "Índice secundario",
			tit6: "Acciones estadounidenses",
			tit7: "Acciones australianas",
			tit8: "Reglas generales",
			p1: "Fuerte proporción plana",
			p2: "Período de validez del pedido",
			p3: "Sin restricciones",
			p4: "Tiempo de liquidación de la tarifa de la noche a la mañana",
			p5: "Liquidación diaria; Verano: 05: 00, invierno: 06: 00",
			p6: "Apalancamiento, tarifas a la noche, etc.",
			p7: "Consulte la Plataforma de Comercio",
			p8: "Lo anterior se aplica a todas las variedades comerciales.",
			tit9: "Calendario semanal de transacciones",
			p9: "Cierre de la ciudad",
			p10: "El horario de negociación anterior no tiene en cuenta el impacto de las vacaciones o los ajustes especiales del mercado.。",
			p11: "Recomendaciones específicas de tiempo de transacción",
			p12: "Iniciar sesión en la plataforma",
			p13: "，Ver en el perfil de la variedad relevante。",
		},
		forecast: {
			tit: "Punto de vista comercial",
			p1: "Media",
			p2: "Alcista",
			p3: "Bajista",
			p4: "Placa transversal",
			p5: "Tendencia",
		},
		news: {
			tit: "Noticias en tiempo real",
		},
		platforms: {
			tit: "Plataforma de comercio de StratTrade",
			p: "Una interfaz simple e intuitiva y un excelente rendimiento comercial satisfacen las necesidades de más inversores. Empecemos a comerciar en StratTrade hoy.",
			tit1: "Una plataforma de comercio de StratTrade conveniente y fácil de usar",
			tit2: "Transacciones en cualquier momento y en cualquier lugar con la aplicación StratTrade",
			li1: "Comerciar cientos de mercados populares, supervisión autorizada para garantizar la seguridad de los fondos",
			li2: "Monitorear las tendencias del mercado en tiempo real, ver el análisis de transacciones y navegar por las últimas noticias financieras",
			li3: "Ver diferentes tipos de gráficos de transacciones en cualquier momento, aplicar la función de dibujo sin barreras",
			li4: "Experimente la premiada Plataforma de comercio de StratTrade en su dispositivo Android o Ios",
			code_download: "Descarga de código de escaneo",
			tit3: "No es necesario descargar, comerciar en línea a través del navegador",
			li5: "No se necesita ninguna descarga, se negocia directamente en línea en el navegador del lado de la pc",
			li6: "Galardonado repetidamente: el mejor corredor de divisas de Asia en 2022, la mejor plataforma de comercio de Australia en 2022 y el corredor de tecnología financiera de divisas de más rápido crecimiento del mundo en 2022",
			li7: "Ofrece indicadores técnicos potentes: macd, kdj, rsi, trix, dma, cci, etc.",
			li8: "Equipado con herramientas de gestión de riesgos, como STOP loss y protección de saldo negativo, para mitigar los riesgos potenciales de su transacción",
			tit4: "La solución perfecta para sus transacciones de escritorio",
			li9: "Ver fácilmente el mercado en la lista opcional",
			li10: "Activar la función de recordatorio y recibir recordatorios de cambios de precios y otros avisos de información",
			li11: "Múltiples diseños gráficos le permiten rastrear las tendencias del mercado desde diferentes dimensiones temporales",
			li12: "Las especificaciones del contrato, el sentimiento de la transacción y el análisis de la transacción están en la misma interfaz, sin necesidad de cambiar.",
			li13: "Acceso directo a las últimas noticias comerciales en StratTrade",
			tit5: "¿¿ por qué se eligió la Plataforma de comercio StratTrade?",
			row5_p1: "Seguridad",
			row5_p2: "0 Comisiones",
			row5_p3: "Soporte multiplataforma",
			row5_p4: "Gráfico técnico",
			row5_p5: "Visita en cualquier momento y en cualquier lugar",
			row5_p6: "Diversas herramientas de dibujo",
		},
		quotes: {
			tit: "Cotización en tiempo real",
			p1: "Le ofrece cotizaciones instantáneas, sentimientos del mercado y noticias relacionadas de las variedades financieras populares actuales.",
			hot: "Popular",
		},
		risk: {
			tit: "Gestión de riesgos",
			p1: "Todas las transacciones implican riesgos, utilizando con flexibilidad las herramientas gratuitas de gestión de riesgos proporcionadas por StratTrade, independientemente de las condiciones del mercado, los riesgos se pueden controlar eficazmente.",
			function_tit1: "Función de parada de ganancias / paradas",
			function_tit2: "Función de rastreo y parada de pérdidas",
			function_p1: "Bloquear las ganancias",
			function_p2: "Limitar las pérdidas",
			function_p3: "Maximizar las ganancias bloqueadas",
			function_p4: "No es necesario monitorear la posición automáticamente",
			function_p5: "Ajustar el precio de cierre de posiciones con pérdidas",
			row2_tit1: "Función de parada de ganancias / paradas",
			row2_p1: "Al crear un nuevo pedido o modificar un pedido existente, podemos optar por establecer 'stop loss' y 'stop loss'. Después de la configuración, el pedido generalmente se cierra de acuerdo con el precio objetivo que ha establecido, lo que le ayuda a bloquear las ganancias cuando llega al objetivo o reducir las pérdidas cuando la tendencia del mercado es desfavorable. Tenga en cuenta que cualquier pedido puede saltar en corto debido a las fluctuaciones de las condiciones del mercado, cuando el sistema no ejecutará el pedido en el precio predeterminado, pero cerrará su posición en el próximo precio más favorable al precio objetivo.",
			row2_tit2: "Ejemplo",
			row2_p2: "El precio actual del euro / dólar es de 113816 / 113837 (venta / compra). Estableció una orden de compra de una mano (una mano = 100.000 euros) en 113837, mientras que estableció una orden de stop loss en 113806.",
			row2_p3: "En general, cuando el precio cae a 113806, su orden de stop loss se activará y se cerrará a 113806, con una pérdida total de 31 dólares.",
			row2_p4: "Sin embargo, cuando el entorno del mercado cambia repentinamente y el precio cae directamente de 113837 a 113795, saltándose directamente su objetivo de stop loss, el mercado salta corto y el sistema no podrá cerrar sus posiciones en 113806, pero se cerrará para usted en el próximo precio más favorable, 113795, y la pérdida final será de 42 dólares.",
			row3_tit1: "Función de rastreo y parada de pérdidas",
			row3_tit2: "Ejemplo",
			row3_p1: "Rastrear la Stop loss (también conocida como STOP loss móviles) es potente, y el precio de stop loss puede cambiar automáticamente con las ganancias, lo que le permite bloquear las ganancias o reducir las pérdidas al máximo sin monitorear su posición en todo momento. Al crear un nuevo pedido, solo tiene que establecer un punto de stop loss de seguimiento, y cuando el precio camina en una dirección favorable para usted, su pedido de stop loss se actualizará automáticamente de acuerdo con el último precio. Por el contrario, cuando el precio cambia en una dirección desfavorable para usted, el pedido de stop loss se activará y se cerrará a la distancia de puntos de pérdida que establezca. Tenga en cuenta que cualquier pedido puede saltar en corto debido a las fluctuaciones de las condiciones del mercado, cuando el sistema no podrá ejecutar el pedido en su precio predeterminado, pero cerrará su posición en el próximo precio más favorable al precio objetivo.",
			row3_p2: "El precio actual del euro / dólar es de 113816 / 113837 (venta / compra). Estableció un pedido de compra de 1 mano en 113837, mientras establecía un Stop loss de seguimiento de 100 puntos (000100).",
			row3_p3: "Cuando el precio del producto es de 113816, su precio de stop loss es de 113716. Si el precio de venta del producto sube a 113845, el precio de stop loss se actualizará de acuerdo con la distancia que establezca, y el precio de stop loss actualizado será de 113745.",
			row3_p4: "Por el contrario, cuando el precio del producto cae de 113845 a 113745, se activa el stop loss de rastreo y se realiza el cierre en 113745.",
			tip: "Punto: por lo general, la unidad de variación mínima del precio de los instrumentos financieros se llama punto. En la Plataforma StratTrade se refiere al último dígito o decimal del precio de la herramienta.",
		},
		sentiment: {
			tit: "Índice emocional",
			type1: "Todo",
			type2: "Divisas",
			type3: "Productos Básicos",
			type4: "Índice",
			long: "Cabeza larga",
			short: "Cabeza corta",
		},
	},
	layout: {
		aside: {
			nav1: "Transacciones",
			nav2: "Mercado",
			nav3: "Información",
			nav4: "Escuela",
			nav5: "El mío",
			newsDialog: {
				title: "Centro de mensajes",
				type1: "Notificación del sistema",
				type2: "Anuncio",
				allRead: "Todo marcado como leído",
			},
			settingDialog: {
				title: "Configuración",
				nav1: "Rutina",
				nav2: "Mostrar preferencias",
				nav3: "Información del sistema",
				logout: "Salir de inicio de sesión",
				setting1: "Idiomas",
				setting2: "Transacciones",
				setting2_tip: "Las variedades de apertura de almacenes se agregan automáticamente y se seleccionan por sí mismas.",
				setting3: "Comentarios",
				setting3_tip: "Recomendaciones funcionales",
				setting4: "Autenticación de nombre real",
				setting4_tip: "Autenticación de nombre real",
				setting5: "Modo temático",
				setting5_label1: "Color oscuro",
				setting5_label2: "Color brillante",
				setting6: "Color de subida y caída",
				setting6_label1: "El verde sube y el rojo baja",
				setting6_label2: "Rojo sube y verde baja",
				setting7: "Configuración del gráfico",
				setting7_label1: "Versión estándar",
				setting7_label2: "Versión estándar",
				setting8: "Declaraciones y acuerdos",
				setting8_label1: "Política de privacidad",
				setting8_label2: "Declaración de divulgación del producto",
				setting8_label3: "Declaración de divulgación de riesgos",
				setting8_label4: "Acuerdo con el cliente",
				feedback_title: "Recomendaciones funcionales",
			}
		},
		footer: {
			hot: "Variedades populares",
			sort: "Variedad",
			code: "Código",
			buy_price: "Precio de compra",
			sell_price: "Precio de venta",
			chg: "Subidas y bajadas",
		},
		header: {
			place_search: "Buscar variedades de transacciones",
			balance: "Saldo disponible",
			balance_tip: "Saldo disponible: cantidad en la cuenta que se puede utilizar para abrir una nueva posición",
			profit_loss: "Ganancias y pérdidas",
			asset_view: "Perfil de los activos",
			netValue: "Valor neto",
			netValue_tip: "Valor neto: valor actual de la cuenta, incluidas las ganancias y pérdidas de todas las posiciones",
			marginLevel: "Nivel de margen",
			margin: "Margen",
			margin_tip: "Margen: importe necesario para abrir y mantener posiciones",
			maintainsMargin: "Mantener la fianza",
			maintainsMargin_tip: "Margen de mantenimiento: el monto mínimo de margen que debe mantener en su cuenta cuando tiene todas sus posiciones.",

		},
	},
	table: {
		label_name: "Nombre",
		label_buyPrice: "Precio de compra",
		label_sellPrice: "Precio de venta",
		label_variableValue: "Valor variable",
		trade: "Transacciones",
		label_code: "Código del producto",
		label_title: "Nombre",
		label_start: "Importancia",
		label_country: "País",
		label_event: "Incidencias",
		label_previous: "Valor anterior",
		label_predictive: "Valor predictivo",
		label_announced: "Valor publicado",
		label_stop_loss_min: "Distancia mínima de la orden de stop loss",
		label_difference: "Diferencial dinámico",
		label_contract_size: "Tamaño del contrato",
		label_max_hand: "Número máximo de comerciantes individuales",
		label_min_hand: "Número mínimo de comerciantes individuales",
		label_trade_week: "Calendario semanal de transacciones",
		label_week: "1 Zhou",
		label_month: "1 Mes",
		label_quarter: "1Trimestral",
		label_dailyTrend: "Tendencia de un solo día",
		label_tradeId: "ID de la transacción",
		label_breed: "Variedad",
		label_tradeType: "Tipo de transacción",
		label_currency: "Moneda",
		label_amount: "Importe",
		label_balance: "Saldo",
		label_type: "Tipo",
		label_time: "Tiempo",
		label_orderNumber: "Número de pedido",
		label_pointer_number: "Número de manos",
		label_price_money: "Precio de apertura",
		label_stop_win_price: "Detener las ganancias",
		label_stop_lose_price: "Stop loss",
		label_openTime: "Hora de apertura",
		label_profit_loss: "Ganancias y pérdidas",
		label_sell_buy: "Precio de venta / precio de compra",
		label_chg: "Subidas y bajadas",
		label_sell_price: "Precio de venta",
		label_buy_price: "Precio de compra",
		label_condition: "Condiciones",
	},
	form: {
		label_feedback: "Preguntas y sugerencias",
		place_feedback: "Introduzca su pregunta o sugerencia",
		label_img: "Imagen",
		label_img_tip: "Selección y llenado, proporcionando capturas de pantalla de preguntas",
		feedback_tip: "Si tiene una pregunta urgente, Póngase en contacto",
		online: "Servicio al cliente en línea",
		label_name: "Nombre",
		place_name: "Por favor, introduzca su nombre.",
		label_phone: "Teléfono",
		place_phone: "Por favor, introduzca el teléfono",
		label_email: "Correo electrónico",
		place_email: "Dirección de correo electrónico",
		message_email: "Por favor, introduzca el buzón",
		label_nationality: "Nacionalidad",
		place_nationality: "Por favor, introduzca la nacionalidad",
		place_questionType: "Tipo de problema",
		message_questionType: "Por favor, elija el tipo",
		questionType0: "Plataforma de Comercio",
		questionType1: "Productos y tarifas",
		questionType2: "Análisis diario del mercado de suscripciones",
		questionType3: "Servicio al cliente",
		questionType4: "Otros",
		place_question: "Su pregunta",
		message_question: "Por favor, introduzca la pregunta",
		submit_question: "Presentación de preguntas",
		message_phone: "Por favor, introduzca el número de teléfono móvil",
		label_password: "Contraseña",
		place_password: "Por favor, introduzca la contraseña",
		message_password: "Por favor, introduzca la contraseña",
		label_confirmPassword: "Confirmar contraseña",
		place_confirmPassword: "Introduzca la contraseña de nuevo",
		message_confirmPassword: "Introduzca la contraseña de nuevo",
		label_captcha: "Código de verificación",
		place_captcha: "Por favor, introduzca el Código de verificación",
		message_captcha: "Por favor, introduzca el Código de verificación",
		get_captcha: "Obtener el Código de verificación",
		label_inviteId: "Código de invitación",
		place_inviteId: "Código de invitación (opcional)",
		to: "A",
		start_time: "Hora de inicio",
		end_time: "Fin del tiempo",
		label_cardNumber: "Número de documento",
		place_cardNumber: "Por favor, introduzca el número de identificación.",
		message_cardNumber: "Por favor, introduzca el número de identificación.",
		label_cardMain: "Parte delantera del documento",
		message_cardMain: "Por favor, introduzca la parte delantera del documento.",
		label_cardBack: "Reversión del documento",
		message_cardBack: "Por favor, introduzca el reverso del documento.",
		confirm_modify: "Confirmación de la modificación",
		label_realName: "Nombre completo",
		place_realName: "Introduzca su nombre",
		message_realName: "Por favor, introduzca el nombre completo",
		label_firstName: "Apellidos",
		place_firstName: "Introduzca su apellido",
		message_firstName: "Por favor, introduzca el apellido",
		label_lastName: "Nombre",
		place_lastName: "Introduzca su segundo nombre",
		message_lastName: "Por favor, introduzca el nombre",
		label_birthday: "Fecha de nacimiento",
		place_birthday: "Introduzca su fecha de nacimiento",
		message_birthday: "Por favor, introduzca la fecha de nacimiento",
		place_nowPsd: "Introduzca la contraseña actual",
		message_nowPsd: "Introduzca la contraseña actual",
		place_newPsd: "Por favor, introduzca una nueva contraseña",
		message_newPsd: "Por favor, introduzca una nueva contraseña",
		place_crmPsd: "Por favor, confirme la nueva contraseña",
		message_crmPsd: "Por favor, confirme la nueva contraseña",
		label_breed: "Variedad",
		place_breed: "Por favor, introduzca la variedad",
		label_phase: "Cuando",
		label_buyPrice: "Precio de compra",
		label_sellPrice: "Precio de venta",
		label_do: "Para",
		label_height: "Por encima",
		label_low: "Por debajo",
		label_equal: "O igual a",
		labelWalletName: "Wallet Name",
		messageWalletName: "Wallet Name",
		placeWalletName: "Wallet Name",
		labelWalletAddress: "Wallet Address",
		messageWalletAddress: "Wallet Address",
		placeWalletAddress: "Wallet Address",
		labelAmount: "Amount",
		messageAmount: "Por favor, introduzca la cantidad",
		placeAmount: "Por favor, introduzca la cantidad",
		placeTimeLimit: "Por favor, introduzca el límite de tiempo",
		messageTimeLimit: "Por favor, introduzca el límite de tiempo",
	},
	pay: {
		title: "Acceso conveniente al oro",
		p1: "Una variedad de canales de pago comunes para apoyar la entrada y salida rápidas",
		p2: "Algunos métodos de pago pueden no estar disponibles en su país / región",
		p3: "Bienvenido al servicio de StratTrade",
		btn: "Operación de apertura de cuenta inmediata",
	},
	header: {
		tip1: "Las transacciones apalancadas son productos financieros complejos y tienen un alto riesgo de pérdidas rápidas.",
		btn1: "Plataforma de Comercio",
		btn2: "Salir de inicio de sesión",
		btn3: "Iniciar sesión",
		BigUint64Array: "Transacciones",
	},
	footer: {
		help: "Contacto",
		tip1: "Preste atención a nuestra comunidad",
		tip2: "Tenga en cuenta que migrade no ha creado un grupo oficial de telegram, y todos los grupos de Telegram formados a nombre de migrade son sospechosos de fraude.",
		tip3: "Advertencia de riesgo: la transacción puede causar que pierda todo su dinero. Las transacciones de derivados extrabursátiles no son adecuadas para todos. Por favor, lea cuidadosamente nuestros documentos legales antes de usar nuestros servicios y asegúrese de comprender completamente los riesgos involucrados antes de la transacción. Usted no posee ni posee realmente ningún activo subyacente relevante.",
		tip4: "StratTrade no ofrece ninguna recomendación, recomendación o opinión sobre la compra, tenencia o venta. Todos los productos que ofrecemos son derivados extrabursátiles basados es es en activos globales. Todos los servicios prestados por StratTrade se basan únicamente en la ejecución de instrucciones de transacción.",
		tip5: "StratTrade es una marca comercial utilizada conjuntamente por varias empresas y opera a través de las siguientes empresas:",
		tip6: "StratTrade International Ltd. es el emisor de productos financieros descritos en este sitio web o disponibles para su uso. StratTrade International Ltd. está autorizada y regulada por la Comisión de servicios financieros de Mauricio (fsc), con el número de licencia gb20025791, y su dirección registrada es: 6 St Denis street, 1st Floor River court, Port Louis 11328, Mauritius",
		tip7: "El número de registro de StratTrade global Pty Ltd. es ABN 90 149 011 361, y el número de licencia de servicios financieros australianos (afsl) es 398528.",
		tip8: "StratTrade Holdings Ltd. está autorizada y regulada por la Autoridad Monetaria de las Islas Caimán (cima), con el número de licencia SIB 1612446.",
		tip9: "La información de este sitio web no está dirigida a residentes de Estados unidos, canadá, Japón y Nueva zelanda; La información de este sitio web tampoco es adecuada para nadie en ningún país o jurisdicción que viole las leyes o regulaciones locales debido a su publicación o uso. Tenga en cuenta que el inglés es el idioma principal utilizado por nuestros servicios y el idioma con efecto legal en todos nuestros documentos contractuales. Las traducciones a otros idiomas son solo para referencia, y si la traducción es ambigua con el inglés, prevalecerá el inglés.",
		tip10: "Cifrado de comunicación seguro ssl. ©  Derechos de autor de StratTrade, todos los derechos reservados.",
		link1: "Política de privacidad",
		link2: "Declaración de divulgación del producto",
		link3: "Proceso de queja",
		link4: "Declaración de divulgación de riesgos",
		link5: "Acuerdo con el cliente",
		toTop: "En la parte superior",
	},
	nav: {
		tit1: "Productos",
		tit2: "Transacciones",
		tit3: "Invertir",
		tit4: "Sobre nosotros",
		nav1: "Divisas",
		nav2: "Índice",
		nav3: "Productos Básicos",
		nav4: "Acciones",
		nav5: "Plataforma de Comercio",
		nav6: "Estrategia comercial",
		nav7: "Punto de vista comercial",
		nav8: "Calendario financiero",
		nav9: "Noticias en tiempo real",
		nav10: "Cotización en tiempo real",
		nav11: "Índice emocional",
		nav12: "Gestión de riesgos",
		nav13: "Reglas del contrato",
		nav14: "Introducción a la inversión",
		nav15: "Inversión inteligente",
		nav16: "Academy",
		nav17: "Sobre StratTrade",
		nav18: "Premios y honores",
		nav19: "Centro de medios",
		nav20: "Seguridad financiera",
		nav21: "Tarifas y tarifas",
		nav22: "Affiliates",
		nav23: "Póngase en contacto con nosotros",
		nav24: "Preguntas frecuentes",
		nav25: "Centro de ayuda",
	},

	city: {
        albania: "Albania",
        algeria: "Argelia",
	    argentina: "Argentina",
	    armenia:  "Armenia",
	    australia: "Australia",
	    pakistan: "Pakistán",
        austria:  "Austria",
	    bahrain: "Bahrein",
	    belgium: "Bélgica",
	    bosnia_and_Herzegovina: "Bosnia y Herzegovina",
	    brazil: "Brasil",
	    brunei: "Brunei",
	    bulgaria: "Bulgaria",
	    cambodia: "Camboya",
		canada: "Canadá",
		cameroon: "Camerún",
		chile: "Chile",
		colombia: "Colombia",
		costa_Rica: "Costa Rica",
		croatia: "Croacia",
		cyprus: "Chipre",
		czech_Republic: "República Checa",
		denmark: "Dinamarca",
		dominican_Republic: "República Dominicana",
		egypt: "Egipto",
		estonia: "Estonia",
		ethiopia: "Etiopía",
        finland : "Finlandia",
		france: "Francia",
		georgia: "Georgia",
		germany: "Alemania",
		ghana: "Ghana",
		greece: "Grecia",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong kong, China",
		hungary: "Hungría",
		iceland: "Islandia",
		ireland: "Irlanda",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Irán",
		iraq: "Irak",
		japan: "Japón",
		kazakstan: "Kazajstán",
		kenya: "Kenia",
		korea: "Corea del Sur",
		kuwait: "Kuwait",
		kyrgyzstan:"Kirguistán",
		laos:"Laos",
		latvia:"Letonia",
		lithuania:"Lituania",
		luxembourg:"Luxemburgo",
		macao_China:"Macao, China",
		macedonia:"Macedonia",
		malaysia:"Malasia",
		malta:"Malta",
		mexico:"México",
		moldova:"Moldavia",
		monaco:"Mónaco",
		mongolia:"Mongolia",
		montenegro:"República de Montenegro",
		morocco:"Marruecos",
		myanmar:"Myanmar",
		netherlands:"Países Bajos",
		new_Zealand:"Nueva Zelanda",
		nepal:"Nepal",
		nigeria:"Nigeria",
		norway:"Noruega",
		oman:"Omán",
		palestine:"Palestina",
		panama:"Panamá",
		paraguay:"Paraguay",
		peru:"Perú",
		philippines:"Filipinas",
		poland:"Polonia",
		portugal:"Portugal",
		puerto_Rico:"Puerto Rico",
		qatar:"Qatar",
		romania:"Rumanía",
		russia:"Rusia",
		republic_of_Trinidad_and_Tobago:"República de Trinidad y Tobago",
		rwanda:"Rwanda",
		saudi_Arabia:"Arabia Saudita",
		serbia:"Serbia",
		singapore:"Singapur",
		slovakia:"Eslovaquia",
		slovenia:"Eslovenia",
		south_Africa:"Sudáfrica",
		spain:"España",
		sri_Lanka:"Sri Lanka",
		sweden:"Suecia",
		switzerland:"Suiza",
		taiwan_China:"Taiwán, China",
		tajikistan:"Tayikistán",
		tanzania:"Tanzania",
		thailand:"Tailandia",
		turkey:"Turquía",
		turkmenistan:"Turkmenistán",
		ukraine:"Ucrania",
		united_Arab_Emirates:"Emiratos Árabes Unidos",
		united_Kingdom:"Reino Unido",
		united_States:"Estados Unidos",
		uzbekistan:"Uzbekistán",
		venezuela:"Venezuela",
		vietnam:"Vietnam",
		afghanistan:"Afganistán",
		angola:"Angola",
		azerbaijan:"Azerbaiyán",
		bangladesh:"Bangladesh",
		belarus:"Bielorrusia",
		belize:"Belice",
		benin:"Benin",
		bhutan:"Bhután",
		bolivia:"Bolivia",
		botswana:"Botswana",
		british_Virgin_Islands:"Islas Vírgenes Británicas",
		burkina_Faso:"Burkina Faso",
		burundi:"Burundi",
		cape_Verde:"Cabo Verde",
		cayman_Islands:"Islas Caimán",
		central_African_Republic:"República Centroafricana",
		chad:"Chad",
		comoros:"Comoras",
		the_Republic_of_the_Congo:"Congo (brazzaville)",
		democratic_Republic_of_the_Congo:"República Democrática del Congo (rdc)",
		djibouti:"Djibouti",
		ecuador:"Ecuador",
		el_Salvador:"El Salvador",
		equatorial_Guinea:"Guinea Ecuatorial",
		eritrea:"Eritrea",
		fiji:"Fiji",
		gabon:"Gabón",
		gambia:"Gambia",
		greenland:"Groenlandia",
		guatemala:"Guatemala",
		guinea:"Guinea",
		haiti:"Haití",
		isle_of_Man:"Isla de Man",
		cote_d_Ivoire:"Costa de Marfil",
		jamaica:"Jamaica",
		jordan:"Jordania",
		lebanon:"Líbano",
		lesotho:"Lesotho",
		liberia:"Liberia",
		libya:"Libia",
		madagascar:"Madagascar",
		malawi:"Malawi",
		maldives:"Maldivas",
		mali:"Malí",
		mauritania:"Mauritania",
		mauritius:"Mauricio",
		mozambique:"Mozambique",
		namibia:"Namibia",
		nicaragua:"Nicaragua",
		republic_of_Niger:"Níger",
		north_Korea:"Corea del Norte",
		reunion:"Reunión",
		san_Marino:"San Marino",
		senegal:"Senegal",
		sierra_Leone:"Sierra Leona",
		somalia:"Somalia",
		sudan:"Sudán",
		suriname:"Surinam",
		eswatini:"Swazilandia",
		syria:"Siria",
		togo:"Togo",
		tonga:"Tonga",
		tunisia:"Túnez",
		united_States_Virgin_Islands:"Islas Vírgenes de los Estados Unidos",
		uganda:"Uganda",
		uruguay:"Uruguay",
		vatican:"Vaticano",
		yemen:"Yemen",
		yugoslavia:"Yugoslavia",
		zambia:"Zambia",
		zimbabwe:"Zimbabwe",
		china:"China",
	}
};
